export const SUBSCRIBE_EVENT_CHANNEL = "SUBSCRIBE_EVENT_CHANNEL";
export const subscribeEventChannelAC = (actionType: string, componentId: string, fn: (actionType?) => any) => ({
    type: SUBSCRIBE_EVENT_CHANNEL,
    payload: {
        actionType,
        componentId,
        fn,
    },
});

export const UNSUBSCRIBE_ALL_EVENT_CHANNEL = "UNSUBSCRIBE_ALL_EVENT_CHANNEL";
export const unsubscribeAllEventChannelAC = (componentId: string) => ({
    type: UNSUBSCRIBE_ALL_EVENT_CHANNEL,
    payload: {
        componentId,
    },
});