import { normalize, schema } from "normalizr";

import { EntityTypes } from "@/messenger/constants/entities";
import { GraphQLUrl } from "@/messenger/constants/urls";
import { customerAdapter } from "@/messenger/ducks/entities/customers";
import { incomingsAdapter, upsertIncomings } from "@/messenger/ducks/entities/incomings";
import { upsertMessageTemplates } from "@/messenger/ducks/entities/messageTemplates";
import { upsertParticipations } from "@/messenger/ducks/entities/participations";
import { representativesAdapter, upsertRepresentatives } from "@/messenger/ducks/entities/representatives";
import { representativeSettingsAdapter, upsertRepresentativeSettings } from "@/messenger/ducks/entities/representativeSettings";
import { upsertTopics } from "@/messenger/ducks/entities/topics";
import { widgetsAdapter } from "@/messenger/ducks/entities/widgets";
import { TWidget } from "@/messenger/types/entities/conversation";
import { TTopic } from "@/messenger/types/entities/topic";
import { Conversation, IncomingType, Participation, Query, RepresentativeType , TopicType } from "@/messenger/types/graphql/autogenerated";
import { NormalizerResult } from "@/messenger/types/store.types";

const getResponseSchema = () => {
    const topicSchema = new schema.Entity("topics", {});
    const contactScheme = new schema.Entity("contacts", {});
    const conversationSchema = new schema.Entity("conversations", {
        topic: topicSchema,
        contact: contactScheme,
    });
    
    const getType = (type: string) => {
       return type.toLowerCase();
    };
    
    const responseSchema = new schema.Array({
        conversations: conversationSchema,
        topics: topicSchema,
        contacts: contactScheme,
    }, (input) => {
        // eslint-disable-next-line no-underscore-dangle
        return `${getType(input.__typename)}s`;
    });


    return [responseSchema];
};


export const standardizeTopicType = (topic: Partial<TTopic>) => {
    return { ...topic,...{ __typename: EntityTypes.Topic} };
};

export const normalizeOneConversation = (conversationData: Conversation): NormalizerResult => {
    const formatOneConversationResult = [];
    const updatedTopic = standardizeTopicType(conversationData.topic);
    const conversation = { ...conversationData, topic: updatedTopic};
    formatOneConversationResult.push({ conversation, topic: updatedTopic});

    const normalizedData: NormalizerResult = normalize(formatOneConversationResult, getResponseSchema());

    return normalizedData;
};

export const normalizeFindAllConversationsResponse = (findAllConversationsResult: Query[GraphQLUrl.findAllConversations]): NormalizerResult => {
    const formatConversationsResult = findAllConversationsResult.map(res => {
        const updatedTopic = standardizeTopicType(res.topic);
        const conversation = { ...res, topic: updatedTopic};
        return { conversation };
    });
    
    const normalizedData: NormalizerResult = normalize(formatConversationsResult, getResponseSchema());
    
    return normalizedData;
};

export const normalizeFindAllWidgetsResponse = (findAllWidgetsResponse: TWidget[]): NormalizerResult => {
    const widgetSchema = new schema.Entity("widgets", {}, {
        idAttribute: widgetsAdapter.selectId as any,
    });
    
    const formatWidgetResult = findAllWidgetsResponse.map(v => ({ widget: v }));
    
    const responseSchema = new schema.Array({
        widgets: widgetSchema,
    }, (_) => {
        return "widgets";
    });
    
    const normalizedData: NormalizerResult = normalize(formatWidgetResult, [responseSchema]);
    
    return normalizedData;
};

export const normalizeFindCustomerResponse = (findCustomerResult: Query[GraphQLUrl.findCustomer]): NormalizerResult => {
    
    const customerSchema = new schema.Entity("customers", {}, {
        idAttribute: customerAdapter.selectId as any,
    });
    
    const formatCustomerResult = [{customers: findCustomerResult}];
    
    const responseSchema = new schema.Array({
        customers: customerSchema,
    }, (_) => {
        return "customers";
    });
    
    const normalizedData: NormalizerResult = normalize(formatCustomerResult, [responseSchema]);
    
    return normalizedData;
};


export const normalizeTopics = (topics: TopicType[]) => dispatch => {
    const messageTemplate = new schema.Entity("messageTemplates", {});
    const topic = new schema.Entity("topics", {
        messageTemplates: [messageTemplate],
    });
    
    const normalizedData: NormalizerResult = normalize(topics, [topic]);
    
    if(Object.keys(normalizedData.entities.topics || []).length){
        dispatch(upsertTopics(normalizedData.entities));
    }

    if(Object.keys(normalizedData.entities.messageTemplates || []).length){
        dispatch(upsertMessageTemplates(normalizedData.entities));
    }
};

export const normalizeRepresentatives = (representatives: RepresentativeType[]) => dispatch => {
    const representative = new schema.Entity("representatives", {}, {
        idAttribute: representativesAdapter.selectId as any,
    });
    
    const normalizedData: NormalizerResult = normalize(representatives, [representative]);
    
    if(Object.keys(normalizedData.entities. representatives|| []).length){
        dispatch(upsertRepresentatives(normalizedData.entities));
    }

};

export const normalizeRepresentativeSettings = (representativeSettingsResult: RepresentativeType) => dispatch => {
    const representativeSettingsSchema = new schema.Entity("representativeSettings", {}, {
        idAttribute: representativeSettingsAdapter.selectId as any,
    });

    const responseSchema = new schema.Array({
        representativeSettings: representativeSettingsSchema,
    }, (_) => {
        return "representativeSettings";
    });

    const formatRepresentativeSettings = [{representativeSettings: representativeSettingsResult}];
    
    const normalizedData: NormalizerResult = normalize(formatRepresentativeSettings, [responseSchema]);
    
    if(Object.keys(normalizedData.entities.representativeSettings|| []).length){
        dispatch(upsertRepresentativeSettings(normalizedData.entities));
    }

};

export const normalizeIncomings = (incomings: IncomingType[]) => dispatch => {
    const incoming = new schema.Entity("incomings", {}, {
        idAttribute: incomingsAdapter.selectId as any,
    });

    const normalizedData: NormalizerResult = normalize(incomings, [incoming]);
    
    if(Object.keys(normalizedData.entities. incomings|| []).length){
        dispatch(upsertIncomings(normalizedData.entities));
    }

};

export const normalizeParticipations = (participations: Participation[]) => dispatch => {
    const topicSchema = new schema.Entity("topics", {});
    const participationSchema = new schema.Entity("participations", {
        topic: topicSchema,
    });
    
    const normalizedData: NormalizerResult = normalize(participations, [participationSchema]);

    if(Object.keys(normalizedData.entities.topics || []).length){
        dispatch(upsertTopics(normalizedData.entities));
    }

    if(Object.keys(normalizedData.entities.participations || []).length){
        dispatch(upsertParticipations(normalizedData.entities));
    }

    return normalizedData;
};