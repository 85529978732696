import "./index.scss";
import "../../locales/i18n";

import * as moment from "moment-timezone";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@/components/components/Button";
import { SimpleDropdown } from "@/components/components/Dropdown";
import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";
import icons from "@/components/icons";

import TermsAndConditions from "../TermsAndConditions";

export interface TUserMetadata {
    timezone: string;
    wasFirstLoggedIn: boolean;
}

interface TFirstLoginProps {
    updateMetadata: (m: any) => void;
}

enum TFirstLoginPages {
    termsAndConditions = "termsAndConditions",
    timezones = "timezones",
}

const FirstLogin = (props: TFirstLoginProps) => {
    const [timezone, setTimezone] = useState<string>(null);
    const timezones: { label: string; heading: string }[] = moment.tz.names().map(z => ({ label: z, heading: z }));
    const [currentPage, setCurrentPage] = useState<TFirstLoginPages>(TFirstLoginPages.timezones);
    const { t } = useTranslation();

    const onTimezoneSelect = (tz: TDropdownOption) => {
        setTimezone(tz.label);
    };

    const onContinue = () => setCurrentPage(TFirstLoginPages.termsAndConditions);

    const onAccept = () => {
        const userMetadata: TUserMetadata = {
            wasFirstLoggedIn: true,
            timezone,
        };

        props.updateMetadata(userMetadata);
    };

    const selectedOption = timezone ? {
        label: timezone,
        heading: timezone,
    } : null;

    const optionClassNames = {
        optionHeading: "c-first-login__dropdown-option-heading",
    };

    return (
        <div className="p-first-login">
            {currentPage === TFirstLoginPages.timezones ? (
                <div className="c-first-login" role="application" aria-label="firstLogin">
                    <span className="c-first-login__heading">{t("Welcome")}</span>
                    <span className="c-first-login__subheading">{t("Set up your TextKit account in a few easy steps!")} </span>
                    <i className="c-first-login__icon">{icons.NETWORK}</i>
                    <span className="c-first-login__label">{t("Time-zone")}</span>
                    <SimpleDropdown
                        className="c-first-login__dropdown"
                        dropdownDefaultRenderClassNames={{header: "c-first-login__dropdown-header"}}
                        heading={t("Select time-zone")}
                        onSelect={onTimezoneSelect}
                        optionClassNames={optionClassNames}
                        options={timezones}
                        optionsContainerClassNames={{ menu: "c-first-login__dropdown-options" }}
                        search={true}
                        selectedOption={selectedOption}
                    />
                    <Button id={"FIRST_LOGIN_CONTINUE_BUTTON"} disabled={!timezone} className="c-first-login__button" onClick={onContinue} ariaLabel={t("First Login Continue Button")}>
                        {t("Continue")}
                    </Button>
                </div>
            ) : (
                <TermsAndConditions onAccept={onAccept} />
            )}
        </div>
    );
};

export default FirstLogin;
