import "./index.scss";
import "../../locales/i18n";

import {useMutation} from "@apollo/client";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { AccentedDropdown } from "@/components/components/Dropdown";
import { MessageType } from "@/components/enums";
import FormBody from "@/messenger/components/FormBody";
import FormFooter from "@/messenger/components/FormFooter";
import FormHeader from "@/messenger/components/FormHeader";
import TextInput from "@/messenger/components/TextInput";
import { addNotification, removeNotification } from "@/messenger/ducks/general/notifications.duck";
import { updateContact } from "@/messenger/graphql/mutations";
import { TContact } from "@/messenger/types/entities/conversation";
import { editContactValidation, getLocaleLabelFromCode } from "@/messenger/utils/helpers";

interface TTextkitEditAContactProps {
    contact: TContact;
    onClose: () => void;
}

const defaultLanguageOptions = [
    {label: "en_US", heading: "English"},
    {label: "es_US", heading: "Spanish"},
    {label: "fr_CA", heading: "French"},
];

const TextKitEditAContact = ({ contact, onClose }: TTextkitEditAContactProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const defaultSelectedLanguage = 
    contact.locale 
    ? {label: contact.locale, heading: getLocaleLabelFromCode(contact.locale, false)}
    : {label: "en_US", heading: "English"};

    const [selectedLang, setSelectedLang] = useState(defaultSelectedLanguage.label);

    const [updateContactMutation] = useMutation(updateContact, {
        onCompleted: () => {
            const notification = {
                id: `${contact.id}_updated`,
                type: MessageType.NEUTRAL,
                message: "Contact updated",
            };

            dispatch(addNotification(notification));
            setTimeout(() => dispatch(removeNotification(`${contact.id}_updated`)), 3000);
            onClose();
        },
    });


    const { values, errors, handleChange, handleSubmit, setFieldValue, dirty, isValid, touched, resetForm, setFieldError } = useFormik({
        initialValues: {
            firstname: contact.firstName ? contact.firstName : "",
            lastname: contact.lastName ? contact.lastName : "",
            language: defaultSelectedLanguage.heading,
        },
        validationSchema: editContactValidation,
        onSubmit: () => {
            if (isValid) {
                updateContactMutation({
                    variables: {
                        UpdateContactInput: {
                            participantId: contact.id,
                            firstName: values.firstname,
                            lastName: values.lastname,
                            locale: selectedLang,
                        },
                    },
                });
            }
        },
    });

    const clearForm = () => {
        resetForm();
        setFieldValue("firstname", contact.firstName ? contact.firstName : "");
        setFieldValue("lastname", contact.lastName ? contact.lastName : "");
        setFieldValue("language", defaultSelectedLanguage.heading);

        setFieldError("firstname", undefined);
        setFieldError("lastname", undefined);
    };

    const handleCancel = () => {
        clearForm();
        onClose();
    };

    const handleSubmitForm = () => {
        handleSubmit();
    };

    return (
        <div className="c-textkit-edit-a-contact">
            <FormHeader name={"EDIT_CONTACT"} title={"Edit A Contact"} onClose={onClose}/>
            <FormBody>
                <TextInput
                    required
                    name="firstname"
                    label={t("First Name")}
                    inputId={"EDIT_CONTACT_FIRSTNAME"}
                    value={values.firstname}
                    onChange={handleChange}
                    error={(touched.firstname && errors.firstname) ? errors.firstname : undefined}
                />
                <div className="c-textkit-edit-a-contact__space"></div>
                <TextInput 
                    required
                    name="lastname"
                    label={t("Last Name")}
                    inputId={"EDIT_CONTACT_LASTNAME"}
                    value={values.lastname}
                    onChange={handleChange}
                />
                <div className="c-textkit-edit-a-contact__space"></div>
                <div className="c-textkit-edit-a-contact__dropdown">
                    <div className="c-textkit-edit-a-contact__dropdown-title" >
                    {t("Customer Language Preference")}
                    </div>
                    
                    <AccentedDropdown 
                        heading={values.language}
                        options={defaultLanguageOptions}
                        className={"c-textkit-edit-a-contact__dropdown-list"}
                        headerClassName={"c-textkit-edit-a-contact__dropdown-header"}
                        headerTextClassName={"c-textkit-edit-a-contact__dropdown-text"}
                        onSelect={(opt) => {
                            setFieldValue("language", opt.heading);
                            setSelectedLang(opt.label);
                        }}
                        search={false}
                    />
                    
                </div>
                <div className="c-textkit-edit-a-contact__space"></div>
            </FormBody>
            <FormFooter
                name={"EDIT_CONTACT"}
                onCancel={handleCancel} 
                submitTitle={t("Save")}
                submitDisabled={!isValid || !dirty}
                onSubmit={handleSubmitForm} />
        </div>
    );
};

export default TextKitEditAContact;