import { SDKLoadingStatus } from "@/messenger/types/store.types";

export const SET_LOADING_STATUS = "SET_LOADING_STATUS";

export const setLoadingStatus = (data: SDKLoadingStatus) => ({
    type: SET_LOADING_STATUS,
    data,
});

const loadingStatusReducer = (state = SDKLoadingStatus.NOT_LOADED, action: ReturnType<typeof setLoadingStatus>) => {
    switch (action.type) {
        case SET_LOADING_STATUS:
            return action.data;
        default:
            return state;
    }
};

export default loadingStatusReducer;
