import "./index.scss";

import cx from "classnames";
import { t } from "i18next";
import React, { ReactElement, useState } from "react";

import { AccentedDropdown } from "@/components/components/Dropdown";
import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";

interface TChatFilterDesktopProps {
    selectedFilterContent: string;
    onSelectFilter: (name: string) => void;
    onCancel?: () => void;
    options: TDropdownOption[];
    optionsContainerAriaLabel?: string;
    name: string;
    headingValue: string;

    whenEmptyHeader?: string;
    whenEmptySubheader?: string;
}

export default function ChatFilterDesktop({
    selectedFilterContent,
    options,
    optionsContainerAriaLabel,
    onSelectFilter,
    name,
    headingValue,
    whenEmptyHeader,
    whenEmptySubheader,
}: TChatFilterDesktopProps): ReactElement {
    const [isActive, setIsActive] = useState<boolean>(false);
    const selectedOption = options?.find(o => o.label === selectedFilterContent);
    const labelId = `${name?.toLowerCase()}-dropdown`;
    const onOpen = () => {
        setIsActive(true);
    };
    
    const onClose = () => {
        setIsActive(false);
    };
    
    const onSelect = (o: TDropdownOption) => {
        onSelectFilter(o.label);
    };
    
    return (
        <div className="c-chat-filter-desktop">
            <>
                <div className="c-chat-filter-desktop__title" id={labelId}>
                    {name}
                </div>
                <AccentedDropdown
                    options={options}
                    heading={headingValue}
                    isMobileView={false}
                    onSelect={onSelect}
                    onOpen={onOpen}
                    onClose={onClose}
                    selectedOption={selectedOption}
                    whenEmptyHeader={whenEmptyHeader}
                    whenEmptySubheader={whenEmptySubheader}
                    headerClassName={cx(
                        "c-chat-filter-desktop__header",
                        {"c-chat-filter-desktop__header--filled": !!selectedFilterContent},
                        {"c-chat-filter-desktop__header--active": isActive,
                        })}
                    headerTextClassName={"c-chat-filter-desktop__dropdown-header-text"}
                    search={true}
                    ariaLabelledBy={labelId}
                    optionsContainerAriaLabel={optionsContainerAriaLabel}
                    testid={"Chats-filter"}
                    className={"c-chat-filter-desktop__dropdown"}
                    noResultMsg={t("No Results Found")}
                    rewordMsg={t("Try rewording your search")}
                />
            </>
        </div>
    );
}