import "./index.scss";
import "../../locales/i18n";

import React from "react";
import { useTranslation } from "react-i18next";

import Button from "@/components/components/Button/Button";
import { ButtonColor, ButtonSize } from "@/components/components/Button/button.enum";


interface TFormFooterProps {
    name: string;
    onCancel: () => void,
    submitTitle: string,
    submitDisabled: boolean,
    onSubmit: () => void
}

export const cancelButtonTestId = "cancelButtonTestId";
export const submitButtonTestId = "submitButtonTestId";

const FormFooter = ({ name, onCancel, submitTitle, submitDisabled, onSubmit }: TFormFooterProps) => {
    const { t } = useTranslation();
    return (
        <footer className="c-form__footer">
            <Button
                id={`${name}_CANCEL_BUTTON`}
                testId={cancelButtonTestId}
                className="c-form__footer-button"
                disabled={false}
                size={ButtonSize.REGULAR}
                border={false}
                color={ButtonColor.SECONDARY}
                onClick={onCancel}
                ariaLabel={t("Cancel Button")}
            >
                {t("Cancel")}
            </Button>
            <Button
                id={`${name}_SUBMIT_BUTTON`}
                testId={submitButtonTestId}
                className="c-form__footer-button c-form__footer-button-wide"
                disabled={submitDisabled}
                size={ButtonSize.REGULAR}
                border={true}
                color={ButtonColor.PRIMARY}
                onClick={onSubmit}
                ariaLabel={submitTitle}
            >
                {submitTitle}
            </Button>
        </footer>
    );
};

export default FormFooter;