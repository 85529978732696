import "./index.scss";
import "../../locales/i18n";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Button from "@/components/components/Button";
import { ButtonColor } from "@/components/components/Button/button.enum";
import Search from "@/components/components/Search";
import { setSearchValueAC } from "@/messenger/ducks/general/conversationRefinementsReducer.duck";
import { conversationRefinementsSelector } from "@/messenger/selectors";

const SearchFilter = () => {
    const [showCancelButton, setShowCancelButton] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const searchValue = useSelector(conversationRefinementsSelector).search.value;
    const hasQuery = searchValue?.length > 0;

    
    useEffect(() => {
        if (hasQuery && !showCancelButton) {
            setShowCancelButton(true);
        }
    }, [hasQuery, showCancelButton]);
    

    const onChange = (v: string): void => {
        setShowCancelButton(true);
        dispatch(setSearchValueAC(v));
    };

    const onClear = (): void => {
        dispatch(setSearchValueAC(""));
    };

    const onCancel = (): void => {
        dispatch(setSearchValueAC(""));
        setTimeout(() => setShowCancelButton(false), 10);
    };

    const handleOnBlur = () => {
        if (!hasQuery && showCancelButton) {
            setShowCancelButton(false);
        }
    };

    return (
        <div className="c-search-filter" data-testid="searchFilter">
            <Search
                inputId={"CHAT_SEARCH_INPUT"}
                buttonId={"CHAT_SEARCH_CANCEL_BUTTON"}
                onChange={onChange}
                value={searchValue || ""}
                onClear={onClear}
                onBlur={handleOnBlur}
                classNames={{ formContainer: "c-search-filter__search" }}
            />
            {hasQuery || showCancelButton ? (
                <Button 
                    onClick={onCancel} 
                    color={ButtonColor.SECONDARY} 
                    ariaLabel={t("Search Filter Cancel Button")}
                    className={"c-search-filter__button"}
                    >
                    {t("Cancel")}
                </Button>
            ) : null}
        </div>
    );
};

export default SearchFilter;
