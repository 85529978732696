import "./index.scss";

import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import CategoryCards from "@/messenger/components/CategoryCards";
import HeaderControl from "@/messenger/components/HeaderControl";
import ChatCardList from "@/messenger/containers/ChatCardList";
import SearchFilter from "@/messenger/containers/SearchFilter";
import { setSelectedCategory } from "@/messenger/ducks/general/selectedCategory.duck";
import { setTertiaryCategory } from "@/messenger/ducks/general/tertiaryCategory.duck";
import { conversationRefinementSelector, deviceTypeSelector, selectedCategorySelector, tertiaryCategorySelector } from "@/messenger/ducks/selectors/general";
import Notifications from "@/messenger/providers/Notifications";
import { conversationRefinementsSelector } from "@/messenger/selectors";
import { ConversationCategory } from "@/messenger/types/entities/conversation";
import { detectMobile } from "@/messenger/utils/deviceType";
import { getChatCategoryCounts , getChatCategoryOptions } from "@/messenger/utils/getChatCategoryOptions";


interface TLeftSidebarProps {
    style?: React.CSSProperties
}
const LeftSidebar = ({ style }: TLeftSidebarProps) => {
    const category = useSelector(selectedCategorySelector);
    const tertiaryCategory = useSelector(tertiaryCategorySelector);
    const hasSearch = useSelector(conversationRefinementsSelector).search.value?.length > 0;
    const isMobile = detectMobile(useSelector(deviceTypeSelector));
    
    const dispatch = useDispatch();
    
    const setTertiaryCategoryDispatch = useCallback(
        (nextCategory: ConversationCategory) => {
            dispatch(setTertiaryCategory(nextCategory));
        },
        [],
    );


    const { result } = useSelector(conversationRefinementSelector);
    const chatCategoryOptions = getChatCategoryOptions(getChatCategoryCounts(result));
    const onClickCategoryCard = (cat: ConversationCategory) => dispatch(setSelectedCategory(cat));

    return (<div className="c-left-sidebar" role="application" aria-label="leftSidebar" style={style}>
        <div className="c-left-sidebar__header">
            <HeaderControl />
            <SearchFilter />
        </div>
    
        { hasSearch ? null : <CategoryCards 
            isMobile={isMobile}
            onClick={onClickCategoryCard} 
            options={chatCategoryOptions} 
            selectedCategory={category} 
            tertiaryCategory={tertiaryCategory}
            setTertiaryCategory={setTertiaryCategoryDispatch}
        /> }
        <ChatCardList />
        <Notifications />
    </div>
);};

export default LeftSidebar;
