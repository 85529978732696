export enum MessageSource {
    EMAIL = "email",
    SMS = "sms",
    WHATSAPP = "whatsApp",
    MESSENGER = "messenger",
}

export enum Action {
    TEMPLATES = "templates",
    REPLIES = "replies",
    CARDS = "cards",
    MEDIA = "media",
}

export enum ComplianceValidationStatus {
    OK = "OK",
    ERROR = "ERROR",
}
