import conversationIdReducer from "./conversationId.duck";
import tokenReducer from "./token.duck";
import userReducer from "./user.duck";

const authReducer = {
    token: tokenReducer,
    conversationId: conversationIdReducer,
    user: userReducer,
};

export default authReducer;
