import { toTitleCase } from "src/sagas/util";

import { FindAllConversations,findAllIncoming,findAllRepresentatives,findAllWidgets,findRepresentativeSettings,getParticipationsForConversation,getTopics } from "@/messenger/graphql/queries";
import { ConversationCategory } from "@/messenger/types/entities/conversation";
import { BasicTopicInfoType, ChannelTypeEnum, Conversation,TopicType, WidgetScopeEnum, WidgetTypeEnum } from "@/messenger/types/graphql/autogenerated";
import { WidgetScope, WidgetType } from "@/messenger/widgets/api/events";

import { EntityTypes } from "./entities";
import { WidgetNames } from "./misc";




const getMockParticipationId = (conversationId: string) => `${conversationId}__contact_id`;

const getMessageTemplates = (topicId: string) => ([{
    id: `${topicId}_Maintenance appointment`,
    locale: "en_US",
    topicId,
    template: [
      "Howdy ",
      "{{displayName}}",
      ". Your ",
      "{{manufacturer}}",
      " ",
      "{{model}}",
      " is due for maintenance. Would you like to schedule an appointment?",
    ],
    placeholders: [{
      placeholder: "displayName",
      indices: [1],
    }, {
      placeholder: "manufacturer",
      indices: [3],
    }, {
      placeholder: "model",
      indices: [5],
    }],
  }, {
    id: `${topicId}_Loyalty points`,
    topicId,
    locale: "fr_CA",
    template: [
      "Howdy ",
      "{{name}}",
      ", you are eligible for 5000 points in you ",
      "{{loyaltyType}}",
      " loyalty program. Would you like to redeem it at your next appointment next week?",
    ],
    placeholders: [{
        placeholder: "name",
        indices: [1],
      }, {
        placeholder: "loyaltyType",
        indices: [3],
      },
    ],
  },
]);


const TOPIC_ONE_ID = "topic_1_id";
const TOPIC_ONE: TopicType = {
  id: TOPIC_ONE_ID,
  name: "topic_1_name",
  description: "topic_1_description",
  messageTemplates: getMessageTemplates(TOPIC_ONE_ID),
  __typename: EntityTypes.Topic,
  contactCount: 1,
};

const TOPIC_TWO_ID = "topic_2_id";
const TOPIC_TWO: TopicType = {
  id: TOPIC_TWO_ID,
  name: "topic_2_name",
  description: "topic_2_description",
  messageTemplates: getMessageTemplates(TOPIC_TWO_ID),
  contactCount: 1,
  __typename: EntityTypes.Topic,
};

const createTopicMock = (index: number) => {
  const mockTopicId = `mock_topic_${index}`;
  const topic: TopicType = {
    id: mockTopicId,
    name: `mock_topic_${index}`,
    description: `"topic_${index}_description"`,
    messageTemplates: [],
    __typename: EntityTypes.Topic,
    contactCount: 1,
  };
  
  return topic;
};

const createManyTopicsMock = (quantity: number) => {
  const topics = [];
  for (let i = 0; i < quantity; i++) {
    topics.push(createTopicMock(i));
  }
  return topics;
};


const createConversationMock = (conversationId: string, category?: ConversationCategory, topics = [TOPIC_ONE]) => {
  const basicTopic: BasicTopicInfoType = {
    id: topics[0].id,
    name: topics[0].name,
  };

  const conversation: Conversation = {
    id: `${conversationId}_id`,
    __typename: EntityTypes.Conversation,
    category: category || ConversationCategory.OPEN,
    nextCategories: [ConversationCategory.CLOSED],
    topic: basicTopic,
    topicIds: topics.length > 1 ? [topics[0].id, topics[1].id] : [topics[0].id],
    externalId: null,
    hydration: null,
    optOutChannels: [],
    participationMonths: ["Nov", "Oct", "Sept"],
    isInbound: true,
    representativePhone: "+123456789",
    contact: {
        id: getMockParticipationId(conversationId),
        firstName: `${conversationId}_contact_firstName`,
        lastName: `${conversationId}_contact_lastName`,
        businessName: "Statflo inc.",
        address: {
          streetNumber: "7611",
          streetName: "Lucas Richmond Rd",
          city: "",
          postalCode: "V6G 1V1",
          province: "BC",
          country: "",
        },
        externalId: `${conversationId}_contact_externalId`,
        locale: "en_CA",
        phoneNumber: {
          number: "+16479992299",
          extension: "231",
        },
        external: {
          externalId: `${conversationId}_id`,
          metadata: `metadata + ${conversationId}_id`,
        },
        capabilities: [ChannelTypeEnum.Voice, ChannelTypeEnum.Sms],
        __typename: EntityTypes.Contact,
    },
  };

  return conversation;
};

const createManyConversations = (quantity: number) => {
  const conversations: Conversation[] = [];

  for (let i = 0; i < quantity; i++) {
    conversations.push(createConversationMock(`convo_${i}`));
    conversations.push(createConversationMock(`convo_${i + quantity}`, ConversationCategory.TO_BE_CONTACTED, [TOPIC_ONE]));
    conversations.push(createConversationMock(`convo_${i + quantity * 10}`, ConversationCategory.CLOSED, [TOPIC_TWO]));
    conversations.push(createConversationMock(`convo_${i + quantity * 100}`, ConversationCategory.OPEN, [TOPIC_ONE, TOPIC_TWO]));
    conversations.push(createConversationMock(`convo_${i + quantity * 1000}`, ConversationCategory.TO_ANSWER, [TOPIC_TWO, TOPIC_ONE]));
  }

  return conversations;
};


const widgetUrl = "http://shop-floor.localhost:3000/";
const participationForConversation = (conversationId: string) => [{
    participantId: getMockParticipationId(conversationId),
    topic: {
        id: TOPIC_ONE.id,
        name: TOPIC_ONE.name,
        description: TOPIC_ONE.description,
    },
    topicId: TOPIC_ONE.id,
    templateVariableMappings: [
      {
        templateName: "Loyalty points",
        variables: [
          {
            name: "name",
            value: "Alice",
            __typename: "TemplateVariableType",
          },
          {
            name: "loyaltyType",
            value: "Gold",
            __typename: "TemplateVariableType",
          },
        ],
      },
      {
        templateName: "Maintenance appointment",
        variables: [
          {
            name: "displayName",
            value: "Alice A. Abel",
            __typename: "TemplateVariableType",
          },
          {
            name: "manufacturer",
            value: "Ford",
            __typename: "TemplateVariableType",
          },{
            name: "model",
            value: "Model T",
            __typename: "TemplateVariableType",
          },
        ],
      },
    ],
  }, {
    participantId: getMockParticipationId(conversationId),
    topic: {
      id: TOPIC_TWO.id,
      name: TOPIC_TWO.name,
      description: TOPIC_TWO.description,
    },
    topicId: TOPIC_TWO.id,
    templateVariableMappings: [
      {
        templateName: "Loyalty points",
        variables: [
          {
            name: "name",
            value: "Alice",
            __typename: "TemplateVariableType",
          },
          {
            name: "loyaltyType",
            value: "Gold",
            __typename: "TemplateVariableType",
          },
        ],
      },
      {
        templateName: "Maintenance appointment",
        variables: [
          {
            name: "displayName",
            value: "Alice A. Abel",
            __typename: "TemplateVariableType",
          },
          {
            name: "manufacturer",
            value: "Ford",
            __typename: "TemplateVariableType",
          },{
            name: "model",
            value: "Model T",
            __typename: "TemplateVariableType",
          },
        ],
      },
    ],
},
];


export const mockWidgets = [
{
  __typename: "WidgetType",
  name: WidgetNames.LogAnActivity,
  url: widgetUrl,
  type: WidgetTypeEnum.Action,
  scope: WidgetScopeEnum.Conversation,
  domains: [widgetUrl],
  isEnabled: true,
  path: null,
},
{
  __typename: "WidgetType",
  name: "one standard widget",
  url: widgetUrl,
  type: WidgetTypeEnum.Standard,
  scope: WidgetScopeEnum.Conversation,
  domains: [widgetUrl],
  isEnabled: false,
  path: null,
},
{
  __typename: "WidgetType",
  name: "two standard widget",
  url: widgetUrl,
  type: WidgetTypeEnum.Standard,
  scope: WidgetScopeEnum.Conversation,
  domains: [widgetUrl],
  isEnabled: true,
  path: null,
}, 
{
  __typename: "WidgetType",
  name: "my module",
  url: widgetUrl,
  type: WidgetTypeEnum.Module,
  scope: WidgetScope.User,
  isEnabled: true,
  domains: [widgetUrl],
  path: "mymodule",
},
{
  __typename: "WidgetType",
  name: "calculator",
  url: "https://www.calculator.net/",
  type: WidgetTypeEnum.Module,
  scope: WidgetScopeEnum.User,
  isEnabled: true,
  domains: ["https://www.calculator.net/"],
  path: "calculator",
}, 
{
  __typename: "WidgetType",
  name: "graph",
  url: "https://www.desmos.com/calculator",
  type: WidgetTypeEnum.Module,
  scope: WidgetScopeEnum.User,
  isEnabled: true,
  domains: ["https://www.desmos.com/calculator"],
  path: "graph",
}, 
{
  __typename: "WidgetType",
  name: "crepes",
  url: "https://www.allrecipes.com/search/results/?search=crepe",
  type: WidgetTypeEnum.Module,
  scope: WidgetScopeEnum.User,
  isEnabled: false,
  domains: ["https://www.allrecipes.com/search/results/?search=crepe"],
  path: "crepes",
}, 
{
  __typename: "WidgetType",
  name: "pickmeup",
  url: "https://archive.org/details/Rick_Astley_Never_Gonna_Give_You_Up",
  type: WidgetTypeEnum.Module,
  scope: WidgetScopeEnum.User,
  domains: ["https://archive.org/details/Rick_Astley_Never_Gonna_Give_You_Up"],
  isEnabled: true,
  path: "pickmeup",
}, 
{
  __typename: "WidgetType",
  name: "analytics",
  url: "http://graphics.wsj.com/job-market-tracker/",
  type: WidgetTypeEnum.Module,
  scope: WidgetScopeEnum.User,
  domains: ["http://graphics.wsj.com/job-market-tracker/"],
  isEnabled: true,
  path: "analytics",
}, 
{
  __typename: "WidgetType",
  name: "boo",
  url: widgetUrl,
  type: WidgetTypeEnum.Standard,
  scope: WidgetScope.Conversation,
  domains: [widgetUrl],
  isEnabled: true,
  path: null,
}, 
{
  __typename: "WidgetType",
  name: WidgetNames.AddaLead,
  url: widgetUrl,
  type: WidgetTypeEnum.Action,
  scope: WidgetScopeEnum.User,
  domains: [widgetUrl],
  isEnabled: true,
  path: null,
},
{
  __typename: "WidgetType",
  name: "my timeline",
  url: widgetUrl,
  type: WidgetTypeEnum.Timeline,
  scope: WidgetScopeEnum.Conversation,
  domains: [widgetUrl],
  isEnabled: true,
  path: null,
}, 
{
  __typename: "WidgetType",
  name: "my sendable",
  url: widgetUrl,
  type: WidgetTypeEnum.Sendable,
  scope: WidgetScopeEnum.Conversation,
  domains: [widgetUrl],
  isEnabled: true,
  path: null,
}, {
  __typename: "WidgetType",
  name: "your sendable",
  url: widgetUrl,
  type: WidgetTypeEnum.Sendable,
  scope: WidgetScopeEnum.Conversation,
  domains: [widgetUrl],
  isEnabled: true,
  path: null,
},
];

const mockRepresentatives = [
  {
    __typename: "RepresentativeType",
    participantId: "mock-representative-01",
    identifier: "mockrep01",
    locale: "en_US",
  },
  {
    __typename: "RepresentativeType",
    participantId: "mock-representative-02",
    identifier: "mockrep02",
    locale: "en_US",
  },
  {
    __typename: "RepresentativeType",
    participantId: "mock-representative-03",
    identifier: "mockrep03",
    locale: "en_US",
  },
];

const mockRepresentativeSettings = {
    __typename: "RepresentativeType",
    participantId: "mock-representative-00",
    identifier: "mockrep00",
    locale: "en_US",
    incomingEnabled: false,
};

const mockIncoming = [
  {
    __typename: "IncomingType",
    incomingId: "mock-incoming-00",
    outboundNumber: "+123456789",
    name: "Mock Incoming",
    description: "This line is assigned to customers who reach out about rates on loans, credit scores, and requirements for getting a mortgage",
    defaultRepresentative: "mock-representative-00",
  },
  {
    __typename: "IncomingType",
    incomingId: "mock-incoming-01",
    outboundNumber: "+198765432",
    name: "Mock Incoming too",
    description: "this is another mock incoming",
    defaultRepresentative: "mock-representative-01",
  },
];

const MockData = {
  topics: [TOPIC_ONE, TOPIC_TWO, ...createManyTopicsMock(25)],
  conversations: createManyConversations(10),
  widgets: mockWidgets,
  participationForConversation: participationForConversation("convo_10_id"),
};

const getWidgetsMock = {
  request: {
    query: findAllWidgets,
    variables: {},
  },
  result: {
    data: {
      findAllWidgets: MockData.widgets,
    },
  },
};



const getRepresentativesMock = {
  request: {
    query: findAllRepresentatives,
    variables: {},
  },
  result: {
    data: {
      findAllRepresentatives: mockRepresentatives,
    },
  },
};

const getRepresentativeSettingsMock = {
  request: {
    query: findRepresentativeSettings,
    variables: {},
  },
  result: {
    data: {
      findRepresentativeSettings: mockRepresentativeSettings,
    },
  },
};

const getIncomingMock = {
  request: {
    query: findAllIncoming,
    variables: {},
  },
  result: {
    data: {
      findAllIncoming: mockIncoming,
    },
  },
};

const getTopicsMock = {
  request: {
    query: getTopics,
    variables: {},
  },
  result: {
    data: {
      topics: MockData.topics,
    },
  },
};

const findAllConversationsMock = {
  request: {
    query: FindAllConversations,
    variables: {},
  },
  result: {
    data: {
      findAllConversations: MockData.conversations,
    },
  },
};

const participationForConversationMock = {
  request: {
    query: getParticipationsForConversation,
    variables: {
      conversationId: "convo_10_id",
    },
  },
  result: {
    data: {
      participationForConversation: MockData.participationForConversation,
    },
  },
};

export const getMockWidgetTypeByName = (name: string) => {
  const widget = mockWidgets.find(w => w.name === name);
  return toTitleCase(widget.type) as WidgetType;
};
export const ApolloMockResponses = [getIncomingMock, findAllConversationsMock, getTopicsMock, getWidgetsMock, getRepresentativesMock, getRepresentativeSettingsMock, participationForConversationMock];