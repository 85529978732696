import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { entitiesSelector } from "@/messenger/selectors";
import { NormalizedEntities, TStore  } from "@/messenger/types/store.types";
import { TTwilioChannel } from "@/messenger/types/twilioChatSDK.types";


export const twilioChannelsAdapter = createEntityAdapter<TTwilioChannel>({
    selectId: (channel) => channel.attributes.conversation_id,
});

export const initialState = twilioChannelsAdapter.getInitialState({});

const twilioChannels = createSlice({
    name: "twilioChannels",
    initialState,
    reducers: {
        addOneTwilioChannel(state, action: PayloadAction<TTwilioChannel>) {
            twilioChannelsAdapter.addOne(state as any, action.payload);
        },
        upsertTwilioChannels(state, action: PayloadAction<NormalizedEntities>) {
            twilioChannelsAdapter.upsertMany(state as any, action.payload.twilioChannels);
        },
    },
});

export const twilioChannelsSelectors = twilioChannelsAdapter.getSelectors((state: TStore) => entitiesSelector(state).twilioChannels);

export const {
    addOneTwilioChannel,
    upsertTwilioChannels,
} = twilioChannels.actions;



export default twilioChannels.reducer;
