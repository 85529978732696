import { MODULE } from "@/messenger/constants/routing";
import { contactsSelectors } from "@/messenger/ducks/entities/contacts";
import { conversationsSelectors } from "@/messenger/ducks/entities/conversations";
import { customerSelector } from "@/messenger/ducks/entities/customers";
import { generalSelector } from "@/messenger/ducks/selectors/general";
import { chatsSelectors } from "@/messenger/ducks/userInterface/chats";
import { ConversationCategory, ECustomerConfigCooloffUnitType } from "@/messenger/types/entities/conversation";
import { ChannelTypeEnum } from "@/messenger/types/graphql/autogenerated";
import { TStore } from "@/messenger/types/store.types";



export const safeSelect = (val: any, fallback: any) => val ?? fallback;

export const selectReduxStore = ((s: TStore) => s);
// root store selectors
export const entitiesSelector = ((s:TStore) => s.entities);

// entities selectors
export const conversationsSelector = ((s:TStore) => conversationsSelectors.selectAll(s));

export const conversationById = (id: string) => (s: TStore) => conversationsSelector(s).find(convo => convo.id === id);
export const contactsSelector = ((s:TStore) => entitiesSelector(s).contacts);

export const conversationIdByPhoneNumber = (phoneNumber: string) => (s: TStore) => {
    const contact = contactsSelectors.selectAll(s).find(c => c.phoneNumber.number === phoneNumber);
    const conversation = conversationsSelector(s).find(convo => convo.contact === contact?.id);
    return conversation?.id;
};

export const conversationsHasToAnswerSelector = (state: TStore) => conversationsSelector(state).find(c => c?.category === ConversationCategory.TO_ANSWER);
export const conversationsHasToBeContactedSelector = (state: TStore) => conversationsSelector(state).find(c => c?.category === ConversationCategory.TO_BE_CONTACTED);
export const conversationsHasOpenSelector = (state: TStore) => conversationsSelector(state).find(c => c?.category === ConversationCategory.OPEN);

export const routerSelector = ((s:TStore) => s.router);
export const locationSelector = ((s: TStore) => routerSelector(s).location);

export const activeConversationIdSelector = (s : TStore) => s.router.location.pathname.split("/")[2];
export const activeModuleIdSelector = (s : TStore) => {
    return s.router.location.pathname.split("/")[1] === `${MODULE}` 
    ? s.router.location.pathname.split("/")[2]
    : null;
}; 

export const activeConversationSelector = ((s: TStore) => {
    const conversationId = activeConversationIdSelector(s);
    const conversation = conversationsSelector(s).find(c => c.id === conversationId);
    return conversation;
});

export const activeChatSelector = ((s: TStore) => {
    const conversationId = activeConversationIdSelector(s);
    const chat = chatsSelectors.selectById(s, conversationId);
    return chat;
});

export const nextConversationIdSelector = ((s: TStore) => {
    const activeConversationId = activeConversationIdSelector(s);
    const activeCategory = activeConversationSelector(s)?.category;
    const conversationList = conversationsSelector(s).filter(c => c?.category === activeCategory);
    const conversationIdList = conversationList.map((c) => c.id);

    if(conversationList.length > 1) {
        const activeConversationIndex = conversationIdList.indexOf(activeConversationId);
        const nextConversationIndex = activeConversationIndex < conversationList.length - 1 ? activeConversationIndex + 1 : activeConversationIndex - 1;
        return conversationIdList[nextConversationIndex];
    } 
        return null;
    
});

export const activeContactSelector = (state: TStore) => {
    const convo = activeConversationSelector(state);
    return contactsSelectors.selectById(state, convo?.contact);
};

export const activeConversationIsOptOutSms = (state: TStore) => {
    const activeConversation = activeConversationSelector(state);
    return activeConversation?.category === ConversationCategory.OPT_OUT || activeConversation?.optOutChannels?.includes(ChannelTypeEnum.Sms);
};

export const activeConversationIsOptOutVoice = (state: TStore) => {
    const activeConversation = activeConversationSelector(state);
    return activeConversation?.category === ConversationCategory.OPT_OUT || activeConversation?.optOutChannels?.includes(ChannelTypeEnum.Voice);
};

export const conversationRefinementsSelector = (s:TStore) => generalSelector(s).conversationRefinements;

const addTimeToDatetime = (originalDate: Date, unit: ECustomerConfigCooloffUnitType, numUnits: number) => {
    if (unit === ECustomerConfigCooloffUnitType.DAYS) {
        const newDate = new Date(Number(originalDate));
        newDate.setDate(originalDate.getDate() + numUnits);
        return newDate;
    }
    if (unit === ECustomerConfigCooloffUnitType.HOURS) {
        return new Date(originalDate.getTime() + numUnits * 60 * 60 * 1000);
    }
    if (unit === ECustomerConfigCooloffUnitType.MINUTES) {
        return new Date(originalDate.getTime() + numUnits * 60 * 1000);
    }
    return originalDate;
};

const formatDatetimeAsString = (date: Date) => {
    return date.toLocaleString("default", { month: "short", day: "numeric", hour: "numeric", minute: "numeric" });
};

export const conversationCooldownSelector = ((s: TStore) => {
    let isCooldownActive = false;
    let cooldownUntilDatetime = "";
    const id = activeConversationIdSelector(s);
    const chatState = chatsSelectors.selectById(s, id);
    
    const mostRecentMessage = chatState?.messages[chatState.messages.length-1];
    if (mostRecentMessage?.twilio?.attributes.chatStarter) {
        const customer = customerSelector.selectAll(s)[0];
        if (!customer) {
            return {};
        }
        const cooldownUnits = customer?.config?.coolOffPeriod.unit;
        const cooldownNumUnits = customer.config.coolOffPeriod.duration;
        const lastMessageTimestamp = mostRecentMessage.twilio.dateUpdated;
        const lastMessageTimestampPlusCooldownTime = addTimeToDatetime(lastMessageTimestamp, cooldownUnits, cooldownNumUnits);
        const nowDateTime = new Date();
        isCooldownActive = nowDateTime < lastMessageTimestampPlusCooldownTime;
        cooldownUntilDatetime = formatDatetimeAsString(lastMessageTimestampPlusCooldownTime);
    }

    return { isCooldownActive, cooldownUntilDatetime };
});

export const filterCounterSelector = (state: TStore) => {
    const {selectedTopicName, isSmsCapable, selectedMonth } = conversationRefinementsSelector(state).filter;
    
    let filterCount = 0;
    if (selectedTopicName) {
        filterCount += 1;
    }
    if (selectedMonth) {
        filterCount += 1;
    }
    if (isSmsCapable) {
        filterCount += 1;
    }

    return filterCount;
};

export const textkitEnvSelector = (state: TStore) => {
    const customers = customerSelector.selectAll(state);
    if (customers?.length > 0) {
        return customers[0].config?.textkitEnvironment;
    }
    return null;
};