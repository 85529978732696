import { TTwilioMessage } from "./twilioChatSDK.types";

export interface TMessage {
    twilio?: TTwilioMessage;
    local?: TLocalMessage;
    computed?: TComputed;
} 
export interface TLocalMessage {
    sid: string;
    status: TMessageDeliveryStatus;
    index?: number;
    body?: string;
    media?: File;
    mediaUrl?: string;
    prevDeliveredMessageIndex?: number;
}

export interface TComputed {
    mediaUrl?: string;
    hasMediaDownloadFailed?: boolean;
    messageOrigin?: TMessageOrigin;
}

export enum TMessageOrigin {
    NEW = "new",
    LOAD_MORE = "load more",
};

export enum TMessageMediaType {
    TEXT = "text",
    IMAGE = "image",
    VIDEO = "video",
}

export enum TMessageDeliveryStatus {
    SENDING = "sending",
    DELIVERED = "delivered",
    NOT_DELIVERED = "not-delivered",
}

export interface TDraftMessage {
    body: string;
    metadata?: TMetadata;
}

export interface TLinkPreview {
    url: string;
}

export interface TMetadata {
    complianceAdvice: TComposedComplianceAdvice[];
    linkPreview: TLinkPreview;
    mediaFile: any;
}

export enum TAdviceAction {
    MODIFY,
    REJECT,
}

interface TContentArea {
    start: number;
    end: number;
}

export interface TComplianceAdvice {
    complianceRule: string;
    adviceAction: TAdviceAction;
    reasonCode: string;
    reasonDescription: string;
    reasonMessage: string;
    content: string;
    contentArea: TContentArea;
}

export interface TComposedComplianceAdvice {
    complianceAdvice: TComplianceAdvice[];
    start: number;
    end: number;
}

export interface TVerifyMessageResult {
    verifyMessage: {
        originalContent: string;
        content: string;
        complianceAdvices: [TComplianceAdvice];
    };
}
