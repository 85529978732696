import "./index.scss";
import "../../locales/i18n";

import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import Button from "@/components/components/Button";
import { ButtonColor, ButtonIconPosition, ButtonSize } from "@/components/components/Button/button.enum";
import {Loading} from "@/components/icons";

interface TLoadMoreMessagesProps {
    isLoading: boolean;
}

export const LoadMoreMessages = (props: TLoadMoreMessagesProps) => {
    const { t } = useTranslation();
    return (
        <div className="load-more-messages">
            <hr className="load-more-messages__line load-more-messages__line--left" />
            <Button
                color={ButtonColor.SECONDARY}
                size={ButtonSize.SMALL}
                border={true}
                icon={<Loading />}
                iconPosition={ButtonIconPosition.RIGHT}
                className={cx("load-more-messages__button", {
                    "load-more-messages__button--loading": props.isLoading,
                })}
                ariaLabel={t("Load More Messages Button")}
            >
                {t("Loading ...")}
            </Button>
            <hr className="load-more-messages__line load-more-messages__line--right" />
        </div>
    );
};

export default LoadMoreMessages;
