import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { entitiesSelector } from "@/messenger/selectors";
import { TRepresentative } from "@/messenger/types/entities/representative";
import { TStore  } from "@/messenger/types/store.types";


export const representativeSettingsAdapter = createEntityAdapter<TRepresentative>({
    selectId: (representative) => representative.participantId,
});

export const initialState = representativeSettingsAdapter.getInitialState({});


const representativeSettings = createSlice({
    name: "representativeSettings",
    initialState,
    reducers: {
        upsertRepresentativeSettings(state, action: PayloadAction<any>) {
            representativeSettingsAdapter.upsertMany(state, action.payload.representativeSettings);
        },
        upsertOneRepresentativeSettings(state, action:PayloadAction<any>) {
            representativeSettingsAdapter.upsertOne(state, action.payload);
        },
    },
});

export const representativeSettingsSelectors = representativeSettingsAdapter.getSelectors((state: TStore) => entitiesSelector(state).representativeSettings);

export const {
    upsertRepresentativeSettings,
    upsertOneRepresentativeSettings,
} = representativeSettings.actions;

export const updateRepresentativeSettingsAC =  (eventPayload) => (dispatch, getState: () => TStore) => {
    const state = getState();
    const { incomingEnabled } = eventPayload;
    
    
    const currentRepresentativeSettings = representativeSettingsSelectors.selectAll(state)[0];
    
    const updatedSettings = {...currentRepresentativeSettings, incomingEnabled};
    
    dispatch(upsertOneRepresentativeSettings(updatedSettings));
};

export default representativeSettings.reducer;