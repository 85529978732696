import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { entitiesSelector } from "@/messenger/selectors";
import { TConversationNormalized } from "@/messenger/types/entities/conversation";
import { TParticipationNormalized } from "@/messenger/types/entities/participation";
import { NormalizedEntities, TStore } from "@/messenger/types/store.types";


export const getParticipationId = (conversation: TConversationNormalized) => `${conversation.contact}__${conversation.topic}`;

const participationsAdapter = createEntityAdapter<TParticipationNormalized>({
    selectId: ({ participantId, topic }) => `${participantId}__${topic}`,
});

export const initialState = participationsAdapter.getInitialState({});

export const participationsSelectors = participationsAdapter.getSelectors((state: TStore) => entitiesSelector(state).participations);

const participations = createSlice({
    name: "participations",
    initialState,
    reducers: {
        upsertParticipations(state, action: PayloadAction<NormalizedEntities>) {
            participationsAdapter.upsertMany(state, action.payload.participations as any);
        },
        removeParticipationByConversation(state, action: PayloadAction<any>) {
            // get the conversation somehow here
            state.ids.filter(id => {
                const participation = state.entities[id];

                return participation.participantId !== action.payload.contactId;
            });
        },
    },
});

export const {
    upsertParticipations,
    removeParticipationByConversation,
} = participations.actions;

export default participations.reducer;
