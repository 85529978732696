import "./index.scss";

import React from "react";

import { SimpleDropdown } from "@/components/components/Dropdown";
import { OptionVariant } from "@/components/components/Dropdown/dropdown.enum";
import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";

import ChatStatusOptionSelected from "../ChatStatusDropdownSelectedOption";

const baseClassName = "c-chat-status-dropdown";

interface ChatStatusDropdownProps {
    disabled?: boolean;
    isMobileView?: boolean;
    options: TDropdownOption[];
    selectedOption: TDropdownOption;
    heading?: string;
    optionsContainerAriaLabel?: string;


    onSelect: (o: TDropdownOption) => void;
}

function ChatStatusDropdown(props: ChatStatusDropdownProps) {
    const { options, selectedOption, disabled, isMobileView, onSelect, heading, optionsContainerAriaLabel } = props;
        
    const renderOverride = (_, toggleMenu: () => void) => <ChatStatusOptionSelected 
                                label={selectedOption.label} 
                                heading={heading || "Status"}
                                value={selectedOption.heading} 
                                showCaret={options.length > 0}
                                disabled={disabled}
                                toggleMenu={toggleMenu}
                                />;
    
    return <SimpleDropdown
        className={`${baseClassName}__dropdown`}
        disabled={disabled}
        dropdownDefaultRenderClassNames={{ header: `${baseClassName}__dropdown-header`}}
        isMobileView={isMobileView}
        onSelect={onSelect}
        options={options}
        optionsContainerAriaLabel={optionsContainerAriaLabel || "Status Dropdown Options"}
        optionsVariant={isMobileView ? OptionVariant.ACCENTED : OptionVariant.NORMAL}
        renderOverride={renderOverride}
        search={false}
        selectedOption={selectedOption}
    />;
}

export default ChatStatusDropdown;