import { useEffect, useState } from "react";

import { TAuth0Credentials } from "@/messenger/types/auth.types";

export function useMessengerAuthorize(auth0) {
    const [credentials, setCredentials] = useState<TAuth0Credentials>(null);

    useEffect(() => {
        const getToken = async () => {
            const token = await auth0.getTokenSilently();
            const { customer } = auth0;
            const { email } = auth0.user;

            setCredentials({ token, customer, email });
        };

        if (auth0?.user) {
            getToken();
        }
    }, [auth0]);

    return credentials;
}
