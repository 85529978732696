import { TAction } from "@/messenger/types/action.types";

enum ActionTypes {
    SET = "hummingbird/auth/conversationId/SET",
}

export const setConversationId = (id: string) => ({
    type: ActionTypes.SET,
    data: id,
});

const conversationIdReducer = (state: string = null, action: TAction<ActionTypes, string>) => {
    switch (action.type) {
        case ActionTypes.SET:
            return action.data;
        default:
            return state;
    }
};

export default conversationIdReducer;
