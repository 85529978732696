import { windowBreakpoints } from "../app.settings";

export enum EDeviceType {
    MOBILE = "MOBILE",
    TABLET = "TABLET",
    DESKTOP = "DESKTOP",
}

export const getDeviceType = (): EDeviceType => {
    const { innerWidth } = window;
    if (innerWidth < windowBreakpoints.tablet) return EDeviceType.MOBILE;
    if (innerWidth < windowBreakpoints.desktop) return EDeviceType.TABLET;
    return EDeviceType.DESKTOP;
};

export const detectMobile = (device: EDeviceType): boolean => device === EDeviceType.MOBILE;

export const detectDesktop = (device: EDeviceType): boolean => device === EDeviceType.DESKTOP;


