import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import { GraphQLUrl } from "@/messenger/constants/urls";
import { messageTemplatesSelectors } from "@/messenger/ducks/entities/messageTemplates";
import { topicsSelectors } from "@/messenger/ducks/entities/topics";
import { initializeChatStarter } from "@/messenger/ducks/userInterface/chats";
import { getParticipationsForConversation } from "@/messenger/graphql/queries";
import { activeConversationSelector } from "@/messenger/selectors";
import { Query } from "@/messenger/types/graphql/autogenerated";
import { TStore } from "@/messenger/types/store.types";
import { normalizeParticipations } from "@/messenger/utils/normalize";


const GetParticipationForConversation = () => {
    const activeConversation = useSelector(activeConversationSelector);
    const topic = useSelector((state: TStore) => topicsSelectors.selectById(state, activeConversation?.topic));
    const allMessageTemplates = useSelector((state: TStore) => messageTemplatesSelectors.selectAll(state));

    const dispatch = useDispatch();
    
    const id = activeConversation?.id;
    useQuery(getParticipationsForConversation, {
        skip: !id,
        variables: { conversationId: id },
        errorPolicy: "ignore",
        // disables cache so Apollo does not merge records by typename & id for campaignMembers because they are non-unique here.
        fetchPolicy: "no-cache",
        onCompleted: (data: { participationForConversation: Query[GraphQLUrl.participationForConversation]}) => {
            try {
                normalizeParticipations(data.participationForConversation)(dispatch);
                
                const participation = data.participationForConversation.find(part => part.topicId === activeConversation.topic);
                const topicMessageTemplates = topic?.messageTemplates.map(mtId => allMessageTemplates.find(mt => mt.id === mtId));
                
                dispatch(initializeChatStarter(activeConversation.id, participation.templateVariableMappings, topicMessageTemplates, topic?.id));

            } catch (e) {
                console.error(e);
            }
        },
    });
    
    return null;
};

export default GetParticipationForConversation;