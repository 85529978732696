import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ACCOUNT_DETAILS_CONTENT, RIGHT_SIDEBAR_STANDIN, SENDABLE_STANDIN } from "@/messenger/constants/domID";
import { hasShownSendableSelector } from "@/messenger/ducks/selectors/aggregate";
import { upsertOneNodeOffsets } from "@/messenger/ducks/userInterface/nodeOffsets";
import { usePosition } from "@/messenger/hooks/usePosition";
import { locationSelector } from "@/messenger/selectors";
import { TNodeOffsets } from "@/messenger/types/store.types";

const WidgetDOMMediator = () => {
    const { pathname } = useSelector(locationSelector);
    const dispatch = useDispatch();
    const [dimensions, updatePosition] = usePosition(RIGHT_SIDEBAR_STANDIN);
    const [sendableDimensions, updateSendablePosition] = usePosition(SENDABLE_STANDIN);
    const [accountDimensions, updateAccountPosition] = usePosition(ACCOUNT_DETAILS_CONTENT);


    const { height, width, left, top } = dimensions as TNodeOffsets;
    const { height: sendableHeight, width: sendableWidth, left: sendableLeft, top: sendableTop } = sendableDimensions as TNodeOffsets;
    const { height: accountHeight } = accountDimensions as TNodeOffsets;

    
    const hasShownSendable = useSelector(hasShownSendableSelector);

    useEffect(() => {
        updatePosition();
        updateSendablePosition();
        updateAccountPosition();
    }, [pathname]);

    useEffect(() => {
        updateSendablePosition();
    }, [hasShownSendable]);

    useEffect(() => {
        dispatch(upsertOneNodeOffsets({
            id: RIGHT_SIDEBAR_STANDIN,
            height, 
            width,
            left,
            top,
        }));

    }, [height, width, left, top]);

    useEffect(() => {
        dispatch(upsertOneNodeOffsets({
            id: SENDABLE_STANDIN,
            height: sendableHeight, 
            width: sendableWidth, 
            left: sendableLeft, 
            top: sendableTop,
        }));

    }, [sendableHeight, sendableWidth, sendableLeft, sendableTop, hasShownSendable]);
    
    useEffect(() => {
        dispatch(upsertOneNodeOffsets({
            id: ACCOUNT_DETAILS_CONTENT,
            height: accountHeight,
        }));
    }, [accountHeight]);

    
    return null;
};

export default WidgetDOMMediator;