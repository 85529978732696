import { TAction } from "@/messenger/types/action.types";
import { TError } from "@/messenger/types/error.types";

enum ErrorsActionTypes {
    ADD = "hummingbird/errors/ADD",
}

export const addError = (data: TError) => ({
    type: ErrorsActionTypes.ADD,
    data,
});

const errorsReducer = (state: TError[] = [], action: TAction<ErrorsActionTypes, TError>) => {
    switch (action.type) {
        case ErrorsActionTypes.ADD:
            return [...state, action.data];
        default:
            return state;
    }
};

export default errorsReducer;
