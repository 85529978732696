import { call,delay,put, select, spawn } from "@redux-saga/core/effects";

import { contactsSelectors } from "@/messenger/ducks/entities/contacts";
import { topicsSelectors } from "@/messenger/ducks/entities/topics";
import { setRefinementResultAC } from "@/messenger/ducks/general/conversationRefinementsReducer.duck";
import { chatsSelectors } from "@/messenger/ducks/userInterface/chats";
import { formatContactAddress, formatContactName } from "@/messenger/models/contact";
import { conversationRefinementsSelector, conversationsSelector } from "@/messenger/selectors";
import { TConversationNormalized } from "@/messenger/types/entities/conversation";
import { ChannelTypeEnum } from "@/messenger/types/graphql/autogenerated";
import { TStore } from "@/messenger/types/store.types";
import { convertToNumbers } from "@/messenger/utils/helpers";

import { onSelectorDataChange } from "./util";


function* handleRefinementChange() {
    const { search: { value: searchQuery }, filter: {selectedTopicName: campaignQuery, selectedMonth: monthQuery, isSmsCapable: smsQuery }} = yield select(conversationRefinementsSelector);
    const conversations = yield select(conversationsSelector);
    const state = yield select((s: TStore) => s);

    const searchQueryFilter = (c: TConversationNormalized) => {
        if (!searchQuery) {
            return true;
        }
        
        const contact =  contactsSelectors.selectById(state, c.contact);

        const businessName = contact?.businessName ? contact?.businessName : "";

        const address = contact?.address ? formatContactAddress(contact.address) : "";

        const phoneNumber = contact.phoneNumber 
            ? convertToNumbers(`${contact.phoneNumber.number}${contact.phoneNumber.extension ?? ""}`)
            : "";
        const phoneNumberMatch = phoneNumber.includes(convertToNumbers(searchQuery));
        
        const searchableString = `${formatContactName(contact)} ${businessName} ${address}`.toLowerCase();
        const genericMatch = searchableString.includes(searchQuery.toLowerCase());

        return phoneNumberMatch || genericMatch;
    };

    const campaignQueryFilter = (c: TConversationNormalized) => {
        if (!campaignQuery) {
            return true;
        }
        
        return c.topicIds.find(topicId => {
            const topic = topicsSelectors.selectById(state, topicId);
    
            return topic?.name === campaignQuery;
        });
    };

    const monthQueryFilter = (c: TConversationNormalized) => {
        if (!monthQuery) {
            return true;
        }
        
        return c.participationMonths?.includes(monthQuery);
    };

    const smsCapableFilter = (c: TConversationNormalized) => {
        if (!smsQuery) {
            return true;
        }
        
        const contact =  contactsSelectors.selectById(state, c.contact);
        
        return contact?.capabilities?.includes(ChannelTypeEnum.Sms) || contact?.capabilities?.includes(ChannelTypeEnum.SmsAndVoice);
    };

    const filteredConversations = conversations
        .filter(searchQueryFilter)
        .filter(campaignQueryFilter)
        .filter(smsCapableFilter)
        .filter(monthQueryFilter);

    yield put(setRefinementResultAC(filteredConversations));
}

let waiting = false;
/**
 * Throttles and debounces the refinementChange saga to occur once every 500ms
 */
function* refinementLoadBalancer() {
    if (!waiting) {
        waiting = true;
        yield call(handleRefinementChange);
        yield delay(500);
        waiting = false;
        yield call(handleRefinementChange);
    }
}

export default function* refinementSaga() {
    yield spawn(onSelectorDataChange, conversationRefinementsSelector, refinementLoadBalancer);
    yield spawn(onSelectorDataChange, conversationsSelector, refinementLoadBalancer);
    yield spawn(onSelectorDataChange, contactsSelectors.selectAll, refinementLoadBalancer);
    yield spawn(onSelectorDataChange, topicsSelectors.selectAll, refinementLoadBalancer);
    yield spawn(onSelectorDataChange, chatsSelectors.selectAll, refinementLoadBalancer);
}
