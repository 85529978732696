import "./index.scss";

import cx from "classnames";
import IframeResizer from "iframe-resizer-react";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { onWidgetError, WidgetErrorComponent } from "@/messenger/containers/WidgetContainer";
import { TWidgetState } from "@/messenger/types/store.types";

interface TWidgetProps {
    widgetState: TWidgetState
    widgetWrapperStyle: React.CSSProperties
}

const baseClassName = "c-widget";

export const getWidgetContainerId = (name: string) => name ? `${name.replace(/ /g,"_")}--container` : null;
export const timelineWidgetTestId = "timeline-widget";

const TimelineWidget = ({ widgetState, widgetWrapperStyle }: TWidgetProps) => {
    const { id, url } = widgetState;

    const widgetContainerClassName = cx(
        {[`${baseClassName}__widget-container`]: true},
    );

    return <div style={widgetWrapperStyle} data-testid={timelineWidgetTestId}>
        <div key={id}>
            <div className={widgetContainerClassName} id={getWidgetContainerId(id)}>
            <ErrorBoundary FallbackComponent={WidgetErrorComponent} onError={onWidgetError}>
                <IframeResizer
                    className={`${baseClassName}__iframe`}
                    seamless
                    src={url} 
                    id={id}
                    title={id}
                    name={id}
                    />
                    </ErrorBoundary>
            </div>
        </div>
    </div>;
};

export default TimelineWidget;