import "./headings.scss";

import React from "react";

interface HeadingProps {
    children: any;
    className?: string;
    style?: { [key: string]: string };
}

export const H1: React.FC<HeadingProps> = (props: HeadingProps) => (
    <h1 className={`e-heading e-heading--h1 ${props.className ? props.className : ""}`} style={props.style}>
        {props.children}
    </h1>
);

export const H2: React.FC<HeadingProps> = (props: HeadingProps) => (
    <h2 className={`e-heading e-heading--h2 ${props.className ? props.className : ""}`} style={props.style}>
        {props.children}
    </h2>
);

export const H4: React.FC<HeadingProps> = (props: HeadingProps) => (
    <h4 className={`e-heading e-heading--h4 ${props.className ? props.className : ""}`} style={props.style}>
        {props.children}
    </h4>
);

export const H6: React.FC<HeadingProps> = (props: HeadingProps) => (
    <h6 className={`e-heading e-heading--h6 ${props.className ? props.className : ""}`} style={props.style}>
        {props.children}
    </h6>
);
