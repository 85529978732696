import React from "react";
import Div100vh from "react-div-100vh";
import Media, { QueryResults } from "react-media";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import GetIncomings from "src/api/graphql/getIncomings";
import ModuleWidgetNotFound from "src/components/ModuleWidgetNotFound";
import Analytics from "src/containers/Analytics";
import PersistentWidgetContainer from "src/containers/PersistentWidgetsContainer";

import GetConversations from "@/messenger/api/graphql/getConversations";
import GetConversationsSubscription from "@/messenger/api/graphql/getConversationSubscription";
import GetCustomer from "@/messenger/api/graphql/getCustomer";
import GetParticipationForConversation from "@/messenger/api/graphql/getParticipationForConversation";
import GetRepresentatives from "@/messenger/api/graphql/getRepresentatives";
import GetRepresentativeSettings from "@/messenger/api/graphql/getRepresentativeSettings";
import GetTopics from "@/messenger/api/graphql/getTopics";
import GetWidgets from "@/messenger/api/graphql/getWidgets";
import ModuleStandin from "@/messenger/components/ModuleStandin";
import { CHAT, CONVERSATION_NOT_FOUND, LayoutComponentKeys, LayoutComponents, LOGIN ,LOGOUT, MODULE, MODULE_NOT_FOUND, NOT_FOUND } from "@/messenger/constants/routing";
import LostInternet from "@/messenger/containers/LostInternet";
import UserActivityDetector from "@/messenger/containers/UserActivityDetector";
import WidgetDOMMediator from "@/messenger/containers/WidgetDOMMediator";
import { customerSelector } from "@/messenger/ducks/entities/customers";
import RingCentral from "@/messenger/integration/RingCentral";
import Navigation from "@/messenger/modules/Navigation";
import { RightSideBarWrapper } from "@/messenger/modules/RightSidebar";
import WithTwilioChatSDK from "@/messenger/providers/WithTwilioChatSDK";
import { TAuth0Credentials } from "@/messenger/types/auth.types";
import { isLocalEnv, isSeedEnv } from "@/messenger/utils/helpers";
import queries from "@/messenger/utils/queries";

import FirstLogin from "./FirstLogin";

export const getRouteComponentConfig = (pathname: string) => {
  if (pathname === `/${CHAT}` || pathname === `/${CHAT}/`) {
      return {
          left: LayoutComponentKeys.LeftSidebar,
          main: LayoutComponentKeys.NoChatSelected,
          right: null,
      };
  }
  if (pathname.includes(`${CONVERSATION_NOT_FOUND}`)){
      return {
          left: LayoutComponentKeys.LeftSidebar,
          main: LayoutComponentKeys.Error404Convo,
          right: null,
      };
  }
  if (pathname.startsWith(`/${CHAT}`) && pathname.length > `/${CHAT}/`.length) {
      return {
          left: LayoutComponentKeys.LeftSidebar,
          main: LayoutComponentKeys.ChatWindow,
          right: LayoutComponentKeys.RightSidebarStandin,
      };
  }
  if (pathname.startsWith(`/${NOT_FOUND}`)) {
      return {
          left: LayoutComponentKeys.LeftSidebar,
          main: LayoutComponentKeys.Error404,
          right: null,
      };
  }

  return {};
};

const getLayoutStyles = (path: string, matches: QueryResults) => {
  const { mobile, tablet } = matches;

  let left: React.CSSProperties = {};
  let main: React.CSSProperties = {};
  let right: React.CSSProperties = {};

  const isLeftFocusedOverwrite = path.includes(`${CONVERSATION_NOT_FOUND}`) || path.includes(`/${NOT_FOUND}`) || path === `/${CHAT}` || path === `/${CHAT}/`;

  const offScreenStyles: React.CSSProperties = {
    position: "fixed",
    left: "200%",
  };

  if (!path.endsWith("left")) {
    if (path.endsWith("right")) {
      if (mobile || tablet) {
        left = offScreenStyles;
      }
    } else if (mobile && !isLeftFocusedOverwrite) {
      left = offScreenStyles;
    }
  }

  if (!path.endsWith("right")) {
    if (path.endsWith("left")) {
      if (mobile) {
        right = offScreenStyles;
      }
    }

    if (tablet || mobile) {
      right = offScreenStyles;
    }
  }

  if (mobile) {
    if (isLeftFocusedOverwrite || path.endsWith("left") || path.endsWith("right")) {
      main = offScreenStyles;
    }
  }

  return {
    leftStyles: left,
    mainStyles: main,
    rightStyles: right,
  };

};
interface TAppContentProps {
  matches: QueryResults
  path?: string
  LeftSidebar?: JSX.Element
  MainContainer?: JSX.Element
  RightSidebar?: JSX.Element
}

interface TModuleContentProps {
  path?: string
}
interface TGlobalRouterProps {
  userMetadata: any
  auth0Credentials: any
  updateMetadata: any
}

interface TLoginRouterProps {
  updateMetadata: any
}

interface TAppRouterProps {
  auth0Credentials: TAuth0Credentials
}

const AppContent = ({ matches, path }: TAppContentProps) => {
  const { left, main, right } = getRouteComponentConfig(path);
  const { mobile } = matches;

  const LeftComponent = LayoutComponents[left];
  const MainContainer = LayoutComponents[main];
  const RightComponent = LayoutComponents[right];

  const { leftStyles, mainStyles, rightStyles } = getLayoutStyles(path, matches);

  const content = <>
    {!mobile && <Navigation /> }
    {LeftComponent && <LeftComponent style={leftStyles} />}
    {MainContainer && <MainContainer style={mainStyles}/>}
    {RightComponent && <RightComponent style={rightStyles}/>}
  </>;

  return content;
};

// An empty LOGOUT route is required to capture the URL change from being caught by the global not-found redirection.
const universalRoutes = <Route path={`/${LOGOUT}`}/>;

const LoginRouter = ({updateMetadata}: TLoginRouterProps) => {
  return <Switch >
    {universalRoutes}
    <Route exact path={[`/${LOGIN}`]}>
      <FirstLogin updateMetadata={updateMetadata} />;
    </Route>

    <Route exact path={["*"]}>
      <Redirect to={`/${LOGIN}`} />
    </Route>
  </Switch>;
};

const ModuleContent = ({ path }: TModuleContentProps) => {
  if (path.includes(`${MODULE_NOT_FOUND}`)) {
    return (
      <>
          <Navigation />
          <ModuleWidgetNotFound />;
        </>
    );
  } 
      return (
        <>
          <Navigation />
          <ModuleStandin />;
        </>
      );
    
  };


const AppRoutes = ({ matches }: { matches: QueryResults }) => {
  return <div style={{ display: "flex", flex: 1, width: "100%" }}>
    <Switch>
      {universalRoutes}
      <Route exact path={["/"]}>
        <Redirect to={`/${CHAT}`} />;
      </Route>

      <Route
        strict
        exact
        path={[
          `/${CHAT}`,
          `/${CHAT}/`,
          `/${CHAT}/${CONVERSATION_NOT_FOUND}/:id`,
          `/${CHAT}/:id?/:direction?`,
          `/${NOT_FOUND}`,
        ]}
        render={({ history: { location: { pathname }}}) => {
          return (<div style={{ display: "flex", flex: 1, boxSizing: "border-box", width: "100%" }}>
            <AppContent
              path={pathname}
              matches={matches}
            />
          </div>);
        }} />

      <Route path={[`/${MODULE}/:id`]} render={({ history: { location: { pathname }}}) => {
        return <>
          <ModuleContent path={pathname} />
        </>;
      }} />

        <Route path={[`/${LOGIN}`]}>
          <Redirect to={`/${CHAT}`} />
        </Route>

        <Route path={["*"]}>
          <Redirect to={`/${NOT_FOUND}`} />
        </Route>
    </Switch>
  </div>;
};

const AppRouter = ({auth0Credentials}: TAppRouterProps) => {
  const customers = useSelector(customerSelector.selectAll);
  const customer = customers.length > 0 && customers[0];
  const ringCentralEnabled = customer?.config?.ringCentralEnabled;

  return (<>
    <WithTwilioChatSDK auth0Credentials={auth0Credentials} />
    <GetCustomer />
    <GetTopics />
    
    <GetParticipationForConversation />
    <GetConversations />
    <GetConversationsSubscription />
    <GetWidgets />
    <GetRepresentatives />
    <GetRepresentativeSettings />
    <GetIncomings />
    {!isSeedEnv && <Analytics />}
    
    <Div100vh className="p-main" role="main">
        <LostInternet />
        <WidgetDOMMediator />
        <UserActivityDetector />
        {!isSeedEnv && ringCentralEnabled && <RingCentral
            userEmail={auth0Credentials.email}
            clientId={"vxM9MG4yT6qx5DieeQDMaA"}
            disableSMS={true}
            disableMinimize={false}
            />}
        
        <Media queries={queries}>
          {matches => (<AppRoutes matches={matches} />)}
        </Media>
        <RightSideBarWrapper />
        <PersistentWidgetContainer />
    </Div100vh>
    </>
  );
};

const GlobalRouter = ({ userMetadata, updateMetadata, auth0Credentials }: TGlobalRouterProps) => {
  if (userMetadata !== null && !userMetadata.wasFirstLoggedIn && !isLocalEnv) {
    return <LoginRouter updateMetadata={updateMetadata} />;
  }

  if (isSeedEnv || (userMetadata !== null && auth0Credentials)) {
    return <AppRouter auth0Credentials={auth0Credentials} />;
  }

  return null;
};

export default GlobalRouter;
