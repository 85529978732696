import { TComplianceAdvice } from "../message";

// extends the API's MessageTemplate type with 'complianceAdvice'
export interface TMessageTemplate {
    id: string;
    topicId: string;
    locale: string;
    template: string[];
    placeholders: TMessageTemplatePlaceholder[];
    complianceAdvice?: TComplianceAdvice[];
}

export interface TMessageTemplatePlaceholder {
    placeholder: string;
    indices: number[];
    offsets: Map<number, number>;
    value?: string;
    draftValue?: string;
}

export interface TMessageTemplatePlaceholderInPlace {
    placeholder: TMessageTemplatePlaceholder;
    index: number;
    type?: EMessageTemplateSelectedPlaceholderType;
    offset?: number;
}

export enum EMessageTemplateSelectedPlaceholderType {
    AUTO_SELECTED = "AUTO_SELECTED",
    SELECTED_ON_CLICK = "SELECTED_ON_CLICK",
    SELECTED_ON_TAB = "SELECTED_ON_TAB",
}