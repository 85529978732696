import { PayloadAction } from "@reduxjs/toolkit";
import { WidgetPostEventType } from "@statflo/widget-sdk/dist/shared";
import { call, put, select, spawn, takeEvery } from "redux-saga/effects";

import { MessageType } from "@/components/enums";
import { ACTIONS_BAR_HEIGHT } from "@/messenger/components/ActionsBar";
import { ACCOUNT_DETAILS_CONTENT, RIGHT_SIDEBAR_STANDIN, SENDABLE_STANDIN } from "@/messenger/constants/domID";
import { WidgetNames } from "@/messenger/constants/misc";
import { ACCOUNT_DETAILS_HEIGHT } from "@/messenger/containers/AccountDetails";
import { contactsSelectors } from "@/messenger/ducks/entities/contacts";
import { widgetsSelectors } from "@/messenger/ducks/entities/widgets";
import { addNotification } from "@/messenger/ducks/general/notifications.duck";
import { chatsSelectors, upsertOneChat } from "@/messenger/ducks/userInterface/chats";
import { DEFAULT_OFFSET, nodeOffsetsSelectors } from "@/messenger/ducks/userInterface/nodeOffsets";
import { upsertOneWidgetsState, widgetsStateSelectors, widgetStateSelectorsCustom } from "@/messenger/ducks/userInterface/widgetsState";
import { activeConversationIdSelector, activeConversationSelector, locationSelector, safeSelect } from "@/messenger/selectors";
import { TContact, TConversationNormalized, TWidget } from "@/messenger/types/entities/conversation";
import { TNodeOffsets, TSendableWidgetState, TStore, TWidgetState } from "@/messenger/types/store.types";
import { EventNames, widgetContainerClient } from "@/messenger/widgets";
import { WidgetViewSize } from "@/messenger/widgets/api";
import { WidgetType } from "@/messenger/widgets/api/events";
import { WidgetState } from "@/messenger/widgets/types";

import { onSelectorDataChange } from "./util";



const UPDATE_ONE_WIDGET_CONTEXT = "UPDATE_ONE_WIDGET_CONTEXT";
export const updateOneWidgetContextsAC = (id: string) => ({
    type: UPDATE_ONE_WIDGET_CONTEXT,
    payload: id,
});
const UPDATE_ALL_WIDGET_CONTEXTS = "UPDATE_ALL_WIDGET_CONTEXTS";
export const updateAllWidgetContextsAC = () => ({
    type: UPDATE_ALL_WIDGET_CONTEXTS,
});

const WIDGET_EVENT_RECEIVED = "WIDGET_EVENT_RECEIVED";
export const widgetEventReceivedAC = (event: WidgetPostEventType) => ({
    type: WIDGET_EVENT_RECEIVED,
    payload: event,
});


function* getWidgetContainerHeight(height: number, widgetState: TWidgetState) {
    const { type, size } = widgetState as any;

    let containerHeight: number = null;
    if (type === WidgetType.Standard && size === WidgetViewSize.Large) {
        containerHeight = height - 60;
    } else if (type === WidgetType.Action) {
        containerHeight = height - (25 + 44); // 44 is the header height
    } else if (type === WidgetType.Timeline) {
        const { height: accountHeight }: TNodeOffsets = safeSelect(yield select((s: TStore) => nodeOffsetsSelectors.selectById(s, ACCOUNT_DETAILS_CONTENT)), DEFAULT_OFFSET);
        containerHeight = height - ACCOUNT_DETAILS_HEIGHT - accountHeight - ACTIONS_BAR_HEIGHT;
    } else if (type === WidgetType.Sendable) {
        containerHeight = height - 40;
    }

    return containerHeight;
};

function* updateOneWidgetHeight(height: number, widgetState: TWidgetState) {
    const widget: TWidget = yield select((s: TStore) => widgetsSelectors.selectById(s, widgetState.id));
    const documentHeight = document.body.offsetHeight;

    const containerHeight = widget.name === WidgetNames.AddaLead ? documentHeight - (25 + 44) : yield getWidgetContainerHeight(height, widgetState);

    if (widgetState.isReady) {
        widgetContainerClient.setState(widgetState.id, WidgetState.maxHeight, containerHeight);
    }
}

function* widgetHeightTrafficControl() {
    try {
        const offsets: TNodeOffsets = safeSelect(yield select((s: TStore) => nodeOffsetsSelectors.selectById(s, RIGHT_SIDEBAR_STANDIN)), DEFAULT_OFFSET);
        const sendableOffsets: TNodeOffsets = safeSelect(yield select((s: TStore) => nodeOffsetsSelectors.selectById(s, SENDABLE_STANDIN)), DEFAULT_OFFSET);
        const widgetStates: TWidgetState[] = yield select(widgetsStateSelectors.selectAll);
        for (let i = 0; i < widgetStates.length; i++) {
            
            if (widgetStates[i].type === WidgetType.Sendable) {
                yield updateOneWidgetHeight(sendableOffsets.height, widgetStates[i]);
            } else {
                yield updateOneWidgetHeight(offsets.height, widgetStates[i]);
            }
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateOneWidgetContext({ payload: id }: ReturnType<typeof updateOneWidgetContextsAC>) {
    const activeConversation: TConversationNormalized = yield select(activeConversationSelector);

    if (!activeConversation) {
        widgetContainerClient.post(id, EventNames.container.activeConversationChanged, null);

        return;
    }

    const { contact, id: convoId, topic } = activeConversation;

    const contactEntity: TContact = yield select((store: TStore) => contactsSelectors.selectById(store, contact));

    const newEvent = {
        name: EventNames.container.activeConversationChanged,
        payload: {
            external: contactEntity?.external,
            conversationId: convoId,
            campaignId: topic,
        },
    };

    widgetContainerClient.post(id, newEvent.name, newEvent.payload);
}

/**
 * Handle incoming events from widgets
*/
function* onWidgetEventReceived(action: PayloadAction<WidgetPostEventType>) {
    const widgetEvent = action.payload;
    const conversationId = yield select(activeConversationIdSelector);

    if (widgetEvent) {
        const { name, payload } = widgetEvent;

        try {

            if (name === EventNames.widget.actionCreationFailed) {
                const notification = {
                    id: "action_creation_failed",
                    type: MessageType.WARNING,
                    message: "Failed to create an activity",
                };
    
                yield put(addNotification(notification));
            } else if (name === EventNames.widget.appendTextToMessage) {
                const { draftMessage } = yield select((s: TStore) => chatsSelectors.selectById(s, conversationId));
                const dm = { body: draftMessage.body + payload, metadata: draftMessage.metadata };
                yield put(upsertOneChat({
                    id: conversationId,
                    draftMessage: dm,
                    error: null,
                }));
            } else if (name === EventNames.widget.replaceTextMessage) {
                const { draftMessage } = yield select((s: TStore) => chatsSelectors.selectById(s, conversationId));
                const dm = { body: payload, metadata: draftMessage.metadata };
                yield put(upsertOneChat({
                    id: conversationId,
                    draftMessage: dm,
                    error: null,
                }));
            } 
        } catch (e) {
            console.error(e);
        }
    }
}

function* updateAllWidgetContexts() {
    const widgets: TWidgetState[] = yield select(widgetsStateSelectors.selectAll);

    for (let i = 0; i < widgets.length; i++) {
        const widget = widgets[i];

        if (widget.isReady) {
            yield call(updateOneWidgetContext, updateOneWidgetContextsAC(widget.id));
        }
    }
}

export function* hideShownSendable() {
    const sendableWidgets: TSendableWidgetState[] = yield select(widgetStateSelectorsCustom.selectAllByTypeSendable);
    const shownSendable = sendableWidgets.find(s => s.isShown);
    if (shownSendable) {
        widgetContainerClient.setState(shownSendable.id, WidgetState.isShown, false);
    }
}

export default function* widgetSaga() {
    const sendableOffsetSelector = (s: TStore) => nodeOffsetsSelectors.selectById(s, SENDABLE_STANDIN);
    const rightSidebarOffsetSelector = (s: TStore) => nodeOffsetsSelectors.selectById(s, RIGHT_SIDEBAR_STANDIN);

    yield takeEvery(upsertOneWidgetsState.toString(), widgetHeightTrafficControl);
    yield spawn(onSelectorDataChange, rightSidebarOffsetSelector, widgetHeightTrafficControl);
    yield spawn(onSelectorDataChange, sendableOffsetSelector, widgetHeightTrafficControl);
    yield spawn(onSelectorDataChange, locationSelector, updateAllWidgetContexts);
    yield takeEvery(UPDATE_ALL_WIDGET_CONTEXTS, updateAllWidgetContexts);
    yield takeEvery(UPDATE_ONE_WIDGET_CONTEXT, updateOneWidgetContext);
    yield takeEvery(WIDGET_EVENT_RECEIVED, onWidgetEventReceived);
}
