import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} fill="currentColor" {...props}>
        <path d="M8.95131 1C4.54054 1 0.951965 3.95628 0.951965 7.59007C0.965566 8.46362 1.17034 9.32358 1.55189 10.1095C1.93344 10.8954 2.4825 11.5883 3.16048 12.1393L1.56061 15.33C1.5282 15.3946 1.51672 15.4677 1.52777 15.5391C1.53882 15.6105 1.57185 15.6767 1.62226 15.7285C1.67267 15.7803 1.73797 15.8151 1.80908 15.8281C1.88018 15.841 1.95356 15.8315 2.01901 15.8009L6.29623 13.8101C7.1594 14.058 8.05325 14.1828 8.95131 14.1808C13.3621 14.1808 16.9507 11.2246 16.9507 7.59007C16.9507 3.95559 13.3621 1 8.95131 1Z" fill="#757575"/>
    </svg>
  );
}

export default SvgComponent;
