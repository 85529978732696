import "./index.scss";
import "../../locales/i18n";

import cx from "classnames";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useOutsideClick from "react-outside-click-hook";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { activeModuleIdSelector } from "src/selectors";

import { SimpleDropdown } from "@/components/components/Dropdown";
import { OptionVariant } from "@/components/components/Dropdown/dropdown.enum";
import { Chart, Chat, Ellipse, Home, PhoneBoxed, Support, Users } from "@/components/icons";
import Logout from "@/components/icons/LogOutTextKit";
import TextKitLogo from "@/components/icons/TextKitLogo";
import { redirectToLogoutAC } from "@/messenger/actions/saga";
import { NAV_ACCOUNTS, NAV_CHAT, NAV_LOG_OUT, NAV_MODULE, NAV_REPORTS, NAV_SUPPORT, NAV_WELCOME } from "@/messenger/constants/domID";
import { CHAT } from "@/messenger/constants/routing";
import { customerSelector } from "@/messenger/ducks/entities/customers";
import { deviceTypeSelector, hasLogoSelector } from "@/messenger/ducks/selectors/general";
import { widgetStateSelectorsCustom } from "@/messenger/ducks/userInterface/widgetsState";
import { toggleHideRingCentral } from "@/messenger/integration/RingCentral";
import { useAuth0 } from "@/messenger/providers/Auth0Provider";
import { TCustomer } from "@/messenger/types/entities/conversation";
import { detectMobile } from "@/messenger/utils/deviceType";
import { executeStringifiedJS, handleOnKeyDown, modulesEnabled } from "@/messenger/utils/helpers";

interface TNavigationMenuProps {
    customer: TCustomer
}

const NavigationMenu = ({ customer: {config: {webapp}}}: TNavigationMenuProps) => {
    const handleOnClick = (url) => () => {
        window.location.href = url; 
    };

    const handleSupportClick = executeStringifiedJS(webapp?.supportJsFunction);

    return <>
        {webapp?.welcomeUrl && (
            <button id={NAV_WELCOME}
                className={cx({
                    "c-navigation--nav-item": true,
                    "c-navigation--nav-item_active": false,
                })}
                onClick={handleOnClick(webapp.welcomeUrl)}
            >
                <div className="c-navigation--nav-item_action c-navigation--nav-item_action__stroke-icon" >
                    <Home />
                </div>
            </button>
        )}
        {webapp?.accountsUrl && (
            <button id={NAV_ACCOUNTS}
                className={cx({
                    "c-navigation--nav-item": true,
                    "c-navigation--nav-item_active": false,
                })}
                onClick={handleOnClick(webapp.accountsUrl)}>
                <div className="c-navigation--nav-item_action c-navigation--nav-item_action__stroke-icon" >
                    <Users />
                </div>
            </button>
        )}
        {webapp?.reportsUrl && (
            <button id={NAV_REPORTS}
                className={cx({
                    "c-navigation--nav-item": true,
                    "c-navigation--nav-item_active": false,
                })}
                onClick={handleOnClick(webapp.reportsUrl)}>
                <div className="c-navigation--nav-item_action c-navigation--nav-item_action__stroke-icon" >
                    <Chart />
                </div>
            </button>
        )}
        {webapp?.supportJsFunction && (
            <button id={NAV_SUPPORT}
                className={cx({
                    "c-navigation--nav-item": true,
                    "c-navigation--nav-item_active": false,
                    "c-navigation--nav-item_action__stroke-icon": true,
                })}
                onClick={handleSupportClick}>
                <div className="c-navigation--nav-item_action" >
                    <Support />
                </div>
            </button>
        )}
    </>;
};

const Navigation = () => {
    const menu = useRef<HTMLDivElement>(null);
    const supportMenu = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [openSupport, setOpenSupport] = useState<boolean>(false);
    const selectedIcon = useSelector(activeModuleIdSelector) ? NAV_MODULE : NAV_CHAT;
    const { t } = useTranslation();
    const auth0 = useAuth0();

    const customers = useSelector(customerSelector.selectAll);
    const hasLogo = useSelector(hasLogoSelector);
    const history = useHistory();

    const isMobile = detectMobile(useSelector(deviceTypeSelector));

    const moduleWidgetStates = useSelector(widgetStateSelectorsCustom.selectAllByTypeModule);

    const dispatch = useDispatch();

    useOutsideClick(menu, () => {
        setTimeout(() => setOpen(false), 5);
    }, open);
    useOutsideClick(supportMenu, () => {
        setTimeout(() => setOpenSupport(false), 5);
    }, openSupport);

    const onClickLogOut = () => {
        dispatch(redirectToLogoutAC());
    };

    const customer = customers?.length > 0 && customers[0];
    const ringCentralEnabled = customer?.config?.ringCentralEnabled;
    
    const renderModuleButtons = () => {
        const baseClassName = "c-chategories-dropdown";
        const optionClassNames = {
            icon: `${baseClassName}__dd-options`,
        };
        const onOptionClick = (o) => {
            history.push(`/module/${o.label}`);
        }; 
        const options = moduleWidgetStates.map(item => ({
            label: item.path,
            ariaLabel: item.id,
            heading: item.id,
        }));
        
        const renderOverride = (_, toggleMenu: () => void) => 
        <div className={cx({
            "c-navigation--nav-item": true,
            "c-navigation--nav-item_active": selectedIcon === NAV_MODULE,
        })}
            onClick={toggleMenu}
            >
            <div className={"c-navigation--nav-item_action c-navigation--nav-item_action__fill-icon"}>{<Ellipse/>}</div>
        </div>;

        return <SimpleDropdown
        isMobileView={isMobile}
        onSelect={(o) => onOptionClick(o)}
        options={options}
        optionsVariant={isMobile ? OptionVariant.ACCENTED : OptionVariant.NORMAL}
        optionClassNames={optionClassNames}
        optionsContainerClassNames={{ menu: `${baseClassName}__menu`, container: `${baseClassName}__container` }}
        renderOverride={renderOverride}
        search={false}
    />;

    };

    const onChatClick = () => {
        history.push(`/${CHAT}`);
    };
    return (
        <div className="c-navigation" role="navigation" aria-label={t("left")}>
            <div className="c-navigation--top">
                {hasLogo && (
                    <div className="c-navigation--logo_container">
                        <div className="c-navigation--logo"></div>
                    </div>
                )}
                {!hasLogo && (
                    <div className="c-navigation--logo-textkit">
                        <TextKitLogo />
                    </div>
                )}
                {/* place top items here */}
                {(modulesEnabled || customer)  && 
                <div id={NAV_CHAT}
                    className={cx({
                        "c-navigation--nav-item": true,
                        "c-navigation--nav-item_active": selectedIcon === NAV_CHAT,
                    })}
                    onClick={onChatClick}>
                    <div className="c-navigation--nav-item_action c-navigation--nav-item_action__fill-icon" >
                        <Chat />
                    </div>
                </div>
                }

                {customer && <NavigationMenu customer={customer} />}
                {ringCentralEnabled && (
                    <button className="c-navigation--nav-item" onClick={toggleHideRingCentral}>
                        <div className="c-navigation--nav-item_action">
                            <PhoneBoxed />
                        </div>
                    </button>
                )}
                {moduleWidgetStates?.length > 0 && renderModuleButtons()}
            </div>
            <div className="c-navigation--bottom">
                {/* place more icons here */}
                <div
                    ref={menu}
                    className={cx({
                        "c-navigation--nav-item": true,
                        "c-navigation--nav-item_active": open,
                    })}
                    onClick={() => setOpen(!open)}
                    onKeyDown={e => handleOnKeyDown(e, {
                        enter: () => setOpen(!open),
                        esc: () => setOpen(false),
                        shiftTab: () => setOpen(false),
                    })}
                    tabIndex={0}
                >
                    <div className="c-navigation--nav-item_action">
                        <img src={auth0.user.picture} className="c-navigation--avatar" alt={t("avatar")} />
                    </div>
                    {open && (
                        <div className="c-navigation--menu">
                            <button className="c-navigation--menu_item"
                                 onClick={onClickLogOut} 
                                 id={NAV_LOG_OUT} 
                                 onKeyDown={e => handleOnKeyDown(e, {
                                    enter: () => onClickLogOut(),
                                    tab: () => setOpen(false),
                                })}
                            >
                                <Logout width={16} height={16} fill={"hsl(0, 0%, 47.6%)"} />
                                <span>{t("Log Out")}</span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Navigation;
