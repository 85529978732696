import gql from "graphql-tag";

export const subscribeToConversations = gql`
    subscription {
        subscribeToConversations {
            ... on ConversationCategoryChangedEvent {
                id
                category
                nextCategories
                __typename
            }
            ... on ConversationOptOutChangedEvent {
                id
                optOutChannels
                __typename
            }
            ... on ParticipantRemovedFromConversationEvent {
                conversationId
                fromHydrationEvent
                __typename
            }
            ... on ParticipantAddedToConversationEvent {
                conversation {
                    id
                    category
                    nextCategories
                    contact {
                        id
                        firstName
                        lastName
                        businessName
                        address {
                            streetNumber
                            streetName
                            city
                            province
                            country
                            postalCode
                        }
                        externalId
                        locale
                        phoneNumber {
                            number
                            extension
                        }
                        capabilities
                        external {
                            externalId
                            metadata
                        }
                    }
                    hydration
                    topic {
                        id
                        name
                    }
                    topicIds
                    externalId
                    optOutChannels
                    participationMonths
                    isInbound
                }
                highlight
                fromHydrationEvent
                __typename
            }

            ... on ConversationContactChangedEvent {
                id
                firstName
                lastName
                businessName
                locale
            }

            ... on RepresentativeIncomingAvailableChangedEvent {
                incomingEnabled
            }
        }
    }
`;