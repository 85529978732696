import "./DropdownOptions.scss";

import React, { PureComponent } from "react";

import { OptionVariant } from "@/components/components/Dropdown/dropdown.enum";
import { Search as SearchIcon } from "@/components/icons";

import { TDropdownOption } from "../dropdown.type";
import DropdownOption from "../DropdownOption";

const baseClassName = "c-dropdown-options-container";


export interface OptionClassNamesProps {
    option?: string,
    optionHeading?: string
    icon?: string
}

interface DropdownOptionsProps {
    options: TDropdownOption[];
    variant?: OptionVariant;

    whenEmptyHeader?: string
    whenEmptySubheader?: string

    hasOptions: boolean
    noResultMsg?: string
    rewordMsg?: string

    onOptionSelect: (o: TDropdownOption) => void;

    classNames?: OptionClassNamesProps
    selectedOption?: TDropdownOption
}

class DropdownOptions extends PureComponent<DropdownOptionsProps> {

    render(): React.ReactNode {
        const { options, variant, onOptionSelect, classNames = {}, hasOptions, whenEmptyHeader, whenEmptySubheader, noResultMsg, rewordMsg, selectedOption } = this.props;
        const { option, optionHeading, icon } = classNames;

        if (!hasOptions) {
            return <div className={`${baseClassName}__no-result`}>
                <div className={`${baseClassName}__no-result__header`}>{whenEmptyHeader}</div>
                <div className={`${baseClassName}__no-result__heading`}>{whenEmptySubheader}</div>
            </div>;
        }
        if (options.length === 0) {
            return <div className={`${baseClassName}__no-result`}>
                <div className={`${baseClassName}__no-result__icon`}>
                    <SearchIcon width={28} height={28} />
                </div>
                <div className={`${baseClassName}__no-result__header`}>{noResultMsg || "No Results Found"}</div>
                <div className={`${baseClassName}__no-result__heading`}>{rewordMsg || "Try rewording your search"}</div>
            </div>;
        }

        return options.map((o: TDropdownOption) => (
            <DropdownOption
                key={o.label}
                option={o}
                onOptionSelect={onOptionSelect}
                variant={variant}
                className={option}
                classNameHeading={optionHeading}
                classNameIcon={icon}
                selected={(selectedOption && selectedOption.label === o.label)}
            />
        ));
    }
}

export default DropdownOptions;
