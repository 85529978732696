import React, { useEffect, useLayoutEffect } from "react";

import { useGetDimensions } from "@/messenger/hooks/useGetDimensions";

const offScreenDimensions: React.CSSProperties =  { left: -999999, width: 0, height: 0, top: 0, position: "fixed", overflow: "hidden" };

type TUsePosition = (id: string) => [React.CSSProperties, () => void]
export const usePosition: TUsePosition = (id) => {
  const [exists, width, height, top, left, updateSize] = useGetDimensions(id);

  const updateSizeWrapper = () => updateSize();
  useEffect(() => {
    window.addEventListener("resize", updateSizeWrapper);
    return () => {
      window.removeEventListener("resize", updateSizeWrapper);
    };
  }, []);

  const updatePosition = () => updateSize(true);


  useLayoutEffect(() => {
    updatePosition();
  });
  
  if (!exists) {
    return [offScreenDimensions, updatePosition];
  }

  const dimensions = {
    width,
    height,
    top,
    left,
  };
  
  return [dimensions, updatePosition];
};