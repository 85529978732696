import "./index.scss";

import cx from "classnames";
import React, { CSSProperties,FocusEvent,FormEvent  } from "react";

import icons from "@/components/icons";

interface TextareaProps {
    error?: string;
    label: string;
    name: string;
    placeholder?: string;
    type?: string;
    value?: string;
    required?: boolean;
    style?: CSSProperties;
    onChange?: (e: FormEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<any>) => void;
    disabled?: boolean;
    customMessage?: string | JSX.Element;
    inputId?: string;
}

const TextInput = ({ error, label, name, placeholder, type = "text", value = "", required = false, style, onChange, onBlur, disabled, customMessage, inputId }: TextareaProps) => {
    
    return (
        <div className="c-text-input__wrapper">
            <label className="c-text-input__label">
                {label}
                {required && <span className="c-text-input__label-required">*</span>}
            </label>
            <input id={inputId} className={cx("c-text-input__input", {
            "c-text-input__input-error": error && error.length > 0,
        })}
                type={type}
                name={name}
                placeholder={placeholder} 
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                style={style}
                disabled={disabled}
            />
            {error && 
            <div className="c-text-input__error-wrapper">
                <span className="c-text-input__error-notice">{icons.ERROR} Invalid Format</span>
                <span className="c-text-input__error-message">{error}</span>
            </div>}
            {customMessage && 
            <div className="c-text-input__error-wrapper">
                <span className="c-text-input__error-custom-message">{icons.WARNING} {customMessage}</span>
            </div>}
        </div>
    );
};

export default TextInput;