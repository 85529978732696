import "./index.scss";
import "../../locales/i18n";

import {useMutation} from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";

import Button from "@/components/components/Button";
import {ButtonColor, ButtonSize} from "@/components/components/Button/button.enum";
import Checkbox from "@/components/components/Checkbox";
import {getContactName} from "@/messenger/ducks/selectors/aggregate";
import {saveOptOut as saveOptOutOperation} from "@/messenger/graphql/mutations";
import { activeConversationIsOptOutSms, activeConversationIsOptOutVoice } from "@/messenger/selectors";
import {ChannelTypeEnum, SaveOptOutInput} from "@/messenger/types/graphql/autogenerated";
import {TStore} from "@/messenger/types/store.types";

interface TActionsBarDialogProps {
    onClose: () => void;
}

const ActionsBarDialog = (props: TActionsBarDialogProps) => {
    const { t } = useTranslation();
    const name = useSelector(getContactName);
    const pathname = useSelector((s:TStore) => s.router.location.pathname);
    const conversationId = pathname.split("/")[2];
    const isOptOutSms = useSelector(activeConversationIsOptOutSms);
    const isOptOutVoice = useSelector(activeConversationIsOptOutVoice);
    const [toOptOutSms, setToOptOutSms] = useState<boolean>(isOptOutSms);
    const [toOptOutVoice, setToOptOutVoice] = useState<boolean>(isOptOutVoice);
    const [optOutSmsTouched, setOptOutSmsTouched] = useState<boolean>(false);
    const [optOutVoiceTouched, setOptOutVoiceTouched] = useState<boolean>(false);
 
    const handleSaveOptOutError = err => {
        console.log("Todo: we need an error indicator to inform the user", err);
        props.onClose();
    };

    const handleSaveOptOutCompleted = data => {
        if (data?.saveOptOut === true) {
            props.onClose();
        } else {
            handleSaveOptOutError(new Error("Operation not successful with unknown error"));
        }
    };

    useEffect(() => {
        if (isOptOutSms) {
            setToOptOutSms(true);
            setOptOutSmsTouched(false);
        } else if (!isOptOutSms && toOptOutSms) {
            setToOptOutSms(false);
            setOptOutSmsTouched(false);
        }
        if (isOptOutVoice) {
            setToOptOutVoice(true);
            setOptOutVoiceTouched(false);
        } else if (!isOptOutVoice && toOptOutVoice) {
            setToOptOutVoice(false);
            setOptOutVoiceTouched(false);
        }
    }, [isOptOutSms, isOptOutVoice]);

    const [saveOptOut] = useMutation(saveOptOutOperation,
        {onCompleted: handleSaveOptOutCompleted, onError: handleSaveOptOutError});

    const onConfirm = () => {
        const optOutTypes: ChannelTypeEnum[] = [];
        if (toOptOutSms && isOptOutSms !== toOptOutSms) optOutTypes.push(ChannelTypeEnum.Sms);
        if (toOptOutVoice && isOptOutVoice !== toOptOutVoice) optOutTypes.push(ChannelTypeEnum.Voice);

        if(optOutTypes.length > 0){
            const saveOptOutInput: SaveOptOutInput = {
                conversationId,
                channelTypes: optOutTypes,        
            };
            saveOptOut({variables: {SaveOptOutInput: saveOptOutInput}});
        }else{
            props.onClose();
        }
    };

    return (
        <div className="c-action-bar-dialog">
            <div className="c-action-bar-dialog__inner">
                <header>
                    <div className="c-action-bar-dialog__title">{t("Opt-out Settings")}</div>
                    <div className="c-action-bar-dialog__name">{name}</div>
                </header>
                <main className="c-action-bar-dialog__main">
                    <div className="c-action-bar-dialog__text">
                        {t("Choose a channel to opt this customer out of:")}
                    </div>
                    <Checkbox className="c-action-bar-dialog__checkbox"
                        id={"SMS_OPTOUT_CHECKBOX"}
                        value={{checked: toOptOutSms, label: "SMS"}}
                        onChange={(box) => {
                            setToOptOutSms(box.checked);
                            setOptOutSmsTouched(true);
                        }}
                        disabled={toOptOutSms && !optOutSmsTouched}
                    ></Checkbox>
                    <Checkbox className="c-action-bar-dialog__checkbox"
                        id={"VOICE_OPTOUT_CHECKBOX"}
                        value={{checked: toOptOutVoice, label: t("Call")}}
                        onChange={(box) => {
                            setToOptOutVoice(box.checked);
                            setOptOutVoiceTouched(true);
                        }}
                        disabled={toOptOutVoice && !optOutVoiceTouched}
                    ></Checkbox>
                </main>
                <footer className="c-action-bar-dialog__footer">
                    <Button
                        id={"ACTION_BAR_CANCEL_BUTTON"}
                        className="c-action-bar-dialog__footer-button"
                        disabled={false}
                        size={ButtonSize.REGULAR}
                        border={false}
                        color={ButtonColor.SECONDARY}
                        onClick={props.onClose}
                        ariaLabel={t("Actions Bar Dialog Cancel Button")}
                    >
                        {t("Cancel")}
                    </Button>
                    <Button
                        id={"ACTION_BAR_CONFIRM_BUTTON"}
                        className="c-action-bar-dialog__footer-button c-action-bar-dialog__footer-button-wide"
                        disabled={false}
                        size={ButtonSize.REGULAR}
                        border={true}
                        color={ButtonColor.PRIMARY}
                        onClick={onConfirm}
                        ariaLabel={t("Actions Bar Dialog Confirm Button")}
                    >
                        {t("Save")}
                    </Button>
                </footer>
            </div>
        </div>
    );
};

export default ActionsBarDialog;
