import { all } from "redux-saga/effects";

import eventChannelSaga from "./eventChannel";
import intervalListenerSaga from "./intervalListener";
import messageSaga from "./message";
import refinement from "./refinement";
import routeSaga from "./route";
import twilioChannelSaga from "./twilioChannel";
import widgetSaga from "./widget";

export default function* rootSaga() {
    yield all([
      eventChannelSaga(),
      routeSaga(),
      refinement(),
      messageSaga(),
      twilioChannelSaga(),
      intervalListenerSaga(),
      widgetSaga(),
    ]);
  }