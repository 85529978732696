import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { activeConversationSelector, entitiesSelector } from "@/messenger/selectors";
import { TContact } from "@/messenger/types/entities/conversation";
import { NormalizedEntities, TStore  } from "@/messenger/types/store.types";

export const contactsAdapter = createEntityAdapter<TContact>();

export const initialState = contactsAdapter.getInitialState({});

const contacts = createSlice({
    name: "conversations",
    initialState,
    reducers: {
        upsertContacts(state, action: PayloadAction<NormalizedEntities>) {
            contactsAdapter.upsertMany(state, action.payload.contacts);
        },
        upsertOneContact(state, action: PayloadAction<any>) {
            contactsAdapter.upsertOne(state, action.payload);
        },
    },
});

export const contactsSelectors = contactsAdapter.getSelectors((state: TStore) => entitiesSelector(state).contacts);

export const {
    upsertContacts,
    upsertOneContact,
} = contacts.actions;


export const selectActiveConversationContact = (state: TStore) => {
    const activeConversation = activeConversationSelector(state);
    
    const activeContact = contactsSelectors.selectById(state, activeConversation?.contact);
    return activeContact;    
};

export default contacts.reducer;
