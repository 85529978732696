import "./index.scss";

import React, { useState } from "react";

import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";
import TextKitLogo from "@/components/icons/TextKitLogo";



const baseClassName = "c-navigation-dropdown";

interface TNoChatSelectedProps {
    style?: React.CSSProperties
    options?: TDropdownOption[];
    MenuComponent?: ({ isOpen }: { isOpen: boolean }) => JSX.Element;
    onSelect: (value: string) => void
}

interface TOptionsComponentProps {
    header: string
    icon: React.ReactNode
    id?: string
}

const NavigationDropdown = ({ style, MenuComponent, options, onSelect }: TNoChatSelectedProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const OptionComponent = ({ header, icon, id }: TOptionsComponentProps) => {
        const handleOnClick = () => {
            setIsOpen(false);
            onSelect(header);
        };

        return <div className={`${baseClassName}__option`} onClick={handleOnClick} id={id}>
            {icon} {header}
        </div>;
    };
    
    return (
    <div style={style}>
        <div onClick={() => setIsOpen(!isOpen)}>
            <MenuComponent isOpen={isOpen} />
        </div>

        {isOpen && <div className={`${baseClassName}__options-container`}> 
            <div className={`${baseClassName}__header`} >
                <div onClick={() => setIsOpen(!isOpen)}>
                    <MenuComponent isOpen={isOpen} />
                </div>

                {isOpen && <div className={`${baseClassName}__header__logo`}>
                    <TextKitLogo />
                </div>}
            </div>
            {options.map(({ heading, icon, domId }, index) => <OptionComponent header={heading} icon={icon} key={index} id={domId} />)}
        </div>}
    </div>);
};

export default NavigationDropdown;