import "./index.scss";
import "../../locales/i18n";
import "draft-js/dist/Draft.css";
import "emoji-mart/css/emoji-mart.css";

import cx from "classnames";
import { DraftHandleValue, Editor, EditorState, getDefaultKeyBinding, KeyBindingUtil, SelectionState } from "draft-js";
import { BaseEmoji } from "emoji-mart/dist-es";
import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";
import { messageSentAC } from "@/messenger/actions/message";
import EmojiPicker from "@/messenger/components/EmojiPicker";
import SendablesDropdown from "@/messenger/components/SendablesDropdown";
import SendableStandin from "@/messenger/components/SendableStandin";
import { getShownSendableSelector, hasShownSendableSelector } from "@/messenger/ducks/selectors/aggregate";
import { chatsSelectors, upsertOneChat } from "@/messenger/ducks/userInterface/chats";
import { widgetStateSelectorsCustom } from "@/messenger/ducks/userInterface/widgetsState";
import {
    EMessageSendStatus,
    useSendMessage,
} from "@/messenger/hooks/useSendMessage";
import { useVerifyMessageDebounce } from "@/messenger/hooks/useVerifyMessageDebounce";
import { useVerifyMessagePolling } from "@/messenger/hooks/useVerifyMessagePolling";
import { activeConversationSelector } from "@/messenger/selectors";
import { TComplianceAdvice, TDraftMessage } from "@/messenger/types/message";
import { TStore } from "@/messenger/types/store.types";
import { TTwilioChannel } from "@/messenger/types/twilioChatSDK.types";
import { detectDesktop as detectIsDesktop, detectMobile as detectIsMobile } from "@/messenger/utils/deviceType";
import { widgetContainerClient } from "@/messenger/widgets";
import { WidgetState } from "@/messenger/widgets/types";

import MediaPreview from "../../components/MediaPreview";
import PandorasBox from "../../components/PandorasBox";
import SendButton from "../../components/SendButton";
import { additionalFeaturesOptions } from "../../constants/messageInput.settings";
import {
    addComplianceToDraftMessage,
    composeEditorContentFromDraftMessage,
    getEmptyDraftMessage,
    getEmptySelectionState,
} from "../../utils/MessageInput.helper";

const { isOptionKeyCommand } = KeyBindingUtil;

interface TMessageInputProps {
    activeChannel: TTwilioChannel
}

export const MESSAGE_INPUT_HEIGHT = 48;

function MessageInput({ activeChannel }: TMessageInputProps) {
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    const [selectionState, setSelectionState] = useState<SelectionState>(getEmptySelectionState());
    const [messageText, setMessageText] = useState<string>("");
    const [isVerifyingMessage, setIsVerifyingMessage] = useState<boolean>(false);
    const [showMobileComplianceError, setShowMobileComplianceError] = useState<boolean>(false);
    const [shouldDisplayComplianceError, setShouldDisplayComplianceError] = useState<boolean>(false);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const textkitConversation = useSelector(activeConversationSelector);
    const conversationId = textkitConversation.id;

    const { draftMessage, error } = useSelector((s: TStore) => chatsSelectors.selectById(s, conversationId));
    
    const deviceType = useSelector((state: TStore) => state.general.deviceType);

    const sendableWidgetStates = useSelector(widgetStateSelectorsCustom.selectAllByTypeSendable);
    const sendableWidgetOptions = sendableWidgetStates.map((s) => ({
            label: s.id,
            heading: s.label || "",
        }));
    
    const sendableOptions = [...sendableWidgetOptions, ...additionalFeaturesOptions];
    
    const hasShownSendable = useSelector(hasShownSendableSelector);

    const dispatchOnDraftMessageChange = (dm: TDraftMessage): void => {
        dispatch(upsertOneChat({ 
            id: conversationId,
            draftMessage: dm,
            error: null,
        }));
    };
    
    const {
        complianceAdvice: complianceAdviceOnDebounce,
        textDebounced: messageTextDebouncedValue,
        loading: verifyMessageLoading,
        error: verifyMessageError,
        pending: debouncePending,
    } = useVerifyMessageDebounce({
        content: draftMessage.body,
        conversationId: activeChannel?.attributes.conversation_id,
        valueToDebounce: draftMessage.body,
    });
    
    const {
        complianceAdvice: complianceAdviceOnPolling,
    } = useVerifyMessagePolling({
        content: draftMessage.body,
        conversationId: activeChannel?.attributes.conversation_id,
        shouldVerify: (!verifyMessageLoading || debouncePending()) && messageTextDebouncedValue !== editorState.getCurrentContent().getPlainText(),
        cursorOffset: editorState.getSelection().getFocusOffset(),
    });
    
    const { sendMessage, isSending, messageSendStatus, setMessageSendStatus } = useSendMessage({
        error,
        activeChannel,
        draftMessage,
    });
    
    const onVerifyMessage = (complianceAdvice: TComplianceAdvice[]) => {
        const messageDraft = addComplianceToDraftMessage(complianceAdvice, draftMessage);
        const state = composeEditorContentFromDraftMessage(messageDraft, selectionState);
    
        dispatchOnDraftMessageChange(messageDraft);
        setEditorState(state);
        setIsVerifyingMessage(false);
        setShouldDisplayComplianceError(!!messageDraft.metadata.complianceAdvice?.length);
    };
    
    useEffect(() => {
        if (complianceAdviceOnDebounce) {
            onVerifyMessage(complianceAdviceOnDebounce);
        }
    }, [complianceAdviceOnDebounce]);
    
    useEffect(() => {
        if (complianceAdviceOnPolling) {
            onVerifyMessage(complianceAdviceOnPolling);
        }
    }, [complianceAdviceOnPolling]);
    
    useEffect(() => {
        if (!isVerifyingMessage && debouncePending() && (messageText.length > 0 || messageTextDebouncedValue.length > 0)) {
            setIsVerifyingMessage(true);
        } else if (isVerifyingMessage && messageText.length === 0 && messageTextDebouncedValue.length === 0) {
            setIsVerifyingMessage(false);
        }

        if (messageText.length === 0 && messageTextDebouncedValue.length === 0 && shouldDisplayComplianceError) {
            setShouldDisplayComplianceError(false);
        }
    }, [messageText, messageTextDebouncedValue]);

    useEffect(() => {
        if (messageSendStatus === EMessageSendStatus.SUCCESS) {
            const dm = getEmptyDraftMessage();
            const ss = selectionState.merge({
                anchorOffset: 0,
                focusOffset: 0,
                hasFocus: true,
            });
            const state = composeEditorContentFromDraftMessage(dm, ss);
            dispatchOnDraftMessageChange(dm);
            setSelectionState(ss);
            setEditorState(state);
            setMessageText("");

            dispatch(messageSentAC());
            setMessageSendStatus(EMessageSendStatus.NONE);
        }
        if (messageSendStatus === EMessageSendStatus.ERROR) {
            setEditorState(EditorState.createEmpty());
            setMessageSendStatus(EMessageSendStatus.NONE);
        }
    }, [messageSendStatus]);

    useEffect(() => {
        if (draftMessage) {
            const ss = selectionState.merge({
                anchorOffset: draftMessage.body.length,
                focusOffset: draftMessage.body.length,
                hasFocus: true,
            });
            const state = composeEditorContentFromDraftMessage(draftMessage, ss);
            setSelectionState(ss);
            setEditorState(state);
            setShouldDisplayComplianceError(!!draftMessage.metadata.complianceAdvice.length);
        }
    }, [conversationId, draftMessage?.body]);

    const uploadFileRef = useRef<HTMLInputElement>();
    const baseClassName = "с-message-input";

    const addEmoji = (e: BaseEmoji): void => {
        const emoji = e.native;
        const messageCurrentText = draftMessage.body || "";
        const pos = editorState.getSelection().getStartOffset();
        const body = `${messageCurrentText
            .split("")
            .slice(0, pos)
            .join("")}${emoji}${messageCurrentText
                .split("")
                .slice(pos)
                .join("")}`;
        const dm = { body, metadata: draftMessage.metadata };

        const ss = selectionState.merge({
            anchorOffset: body.length,
            focusOffset: body.length,
            hasFocus: true,
        });
        setSelectionState(ss);

        const state = composeEditorContentFromDraftMessage(dm, ss);

        dispatchOnDraftMessageChange(dm);
        setEditorState(state);
        setMessageText(body);
    };

    const hasDraftMessageBody = draftMessage?.body.trim().length > 0 || !!draftMessage?.metadata.mediaFile;
    const hasComplianceAdvice = draftMessage?.metadata.complianceAdvice.length > 0;
    const isDebouncedValueMatchBody = messageTextDebouncedValue.trim() === draftMessage.body.trim();

    const isAllowedToSend = (): boolean => {
        return (
            hasDraftMessageBody &&
            !hasComplianceAdvice &&
            isDebouncedValueMatchBody &&
            !isSending &&
            !isVerifyingMessage &&
            !verifyMessageError
        );
    };

    const handleKeyCommand = (command: string): DraftHandleValue => {
        if (command === "message-send") {
            if (isAllowedToSend()) {
                sendMessage();
            }
            return "handled";
        }
        return "not-handled";
    };

    const editorKeyBindingFn = (e: React.KeyboardEvent) => {
        if (
            e.keyCode === 13 &&
            e.shiftKey === false &&
            e.ctrlKey === false &&
            e.altKey === false &&
            !isOptionKeyCommand(e)
        ) {
            return "message-send";
        }
        return getDefaultKeyBinding(e);
    };

    const onEditorContentChange = (changedEditorState: EditorState): void => {
        setEditorState(changedEditorState);
        setSelectionState(changedEditorState.getSelection());
        const body = changedEditorState.getCurrentContent().getPlainText();
        setMessageText(body);
        let { complianceAdvice } = draftMessage.metadata;
        if (!body) {
            complianceAdvice = [];
        }
        dispatchOnDraftMessageChange({
            body,
            metadata: { ...draftMessage.metadata, complianceAdvice },
        });
    };

    const onMediaSend = (): void => {
        uploadFileRef.current.click();
    };

    const onMediaLoad = async (e): Promise<void> => {
        const mediaFile = e.target.files[0];
        uploadFileRef.current.value = "";
        dispatchOnDraftMessageChange({
            ...draftMessage,
            metadata: {
                ...draftMessage?.metadata,
                mediaFile,
            },
        });
    };

    const removeMediaPreview = (): void => {
        dispatchOnDraftMessageChange({
            ...draftMessage,
            metadata: { ...draftMessage.metadata, mediaFile: null },
        });
    };

    const shownSendableName = useSelector(getShownSendableSelector);
    const closeCurrentSendable = () => {
        if(shownSendableName) {
            widgetContainerClient.setState(shownSendableName, WidgetState.isShown, false);
        }
    };

    const onSelectAction = (option: TDropdownOption): void => {
        switch (option.label) {
            case "media":
                closeCurrentSendable();
                onMediaSend();
                break;
            default:
                closeCurrentSendable();
                removeMediaPreview(); 
                widgetContainerClient.setState(option.label, WidgetState.isShown, true);
        }
    };


    const toggleMobileError = (): void => {
        if (!isAllowedToSend() || showMobileComplianceError) {
            setShowMobileComplianceError(!showMobileComplianceError);
        }
    };

    const renderEditor = (): React.ReactElement => {
        return (
            <Editor
                placeholder={t("Type your message")}
                editorState={editorState}
                onChange={onEditorContentChange}
                handleKeyCommand={handleKeyCommand}
                keyBindingFn={editorKeyBindingFn}
                ariaLabel={t("Message Input")}
            />
        );
    };

    const renderInfo = (): React.ReactElement => {
        const mediaFile = draftMessage?.metadata.mediaFile;
        return (
            <div className={`${baseClassName}__info`}>
                {hasShownSendable && <SendableStandin />}
                {mediaFile && (
                    <MediaPreview file={mediaFile} onError={removeMediaPreview} onRemove={removeMediaPreview} />
                )}
            </div>
        );
    };

    const renderMessageInput = (): React.ReactElement => {
        const isMobile = detectIsMobile(deviceType);
        const isDesktop = detectIsDesktop(deviceType);

        const tooltipMessage = shouldDisplayComplianceError
            ? t("To protect you, we disabled sending, until you change some words")
            : "";
        const tooltipDirection = isDesktop ? "top" : "left";
        const toolTipClassName = cx({
            [`${baseClassName}__tooltip`]: isDesktop,
        });

        return (
            <>
                {!isMobile && (
                    <div className={`${baseClassName}__wrapper ${baseClassName}__wrapper--left`}>
                        <SendablesDropdown
                            isMobileView={false}
                            onSelect={onSelectAction}
                            options={sendableOptions}
                        />

                        <EmojiPicker onSelectEmoji={addEmoji} />
                    </div>
                )}
                <div className={`${baseClassName}__wrapper ${baseClassName}__wrapper--center`}>
                    <Scrollbars autoHeight={true}>
                        {renderEditor()}
                    </Scrollbars>
                </div>            
                <div className={`${baseClassName}__wrapper ${baseClassName}__wrapper--right`}>
                    {isMobile && (
                        <SendablesDropdown
                            isMobileView={true}
                            onSelect={onSelectAction}
                            options={sendableOptions}
                        />
                    )}

                    <div data-tip={tooltipMessage} data-for="send-button" style={{ marginRight: "8px" }} onClick={toggleMobileError}>
                        {isDesktop &&
                            <ReactTooltip
                                place={tooltipDirection}
                                effect="solid"
                                id="send-button"
                                className={toolTipClassName}
                            />
                        }
                        <SendButton
                            disabled={!isAllowedToSend()}
                            onClick={sendMessage}
                            showCompliance={shouldDisplayComplianceError}
                            showLoading={isVerifyingMessage}
                            showDefault={!isVerifyingMessage && !shouldDisplayComplianceError && !isAllowedToSend()}
                        />
                    </div>
                </div>
                <input
                    ref={uploadFileRef}
                    type="file"
                    id="fileElem"
                    accept="image/png, image/jpg, image/jpeg"
                    className={`${baseClassName}__file-upload`}
                    onChange={onMediaLoad}
                />
                {shouldDisplayComplianceError &&
                    <PandorasBox
                        message={tooltipMessage}
                        visible={showMobileComplianceError}
                        onClick={toggleMobileError}
                        onOutsideClick={() => setShowMobileComplianceError(false)}
                    />
                }
            </>
        );
    };

    return (<>
    <div className={cx(baseClassName)} data-testid="messageInput">
            {renderInfo()}
            
            {renderMessageInput()}
        </div>
    </>
        
    );
}

export default MessageInput;
