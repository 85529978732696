import "./DropdownOption.scss";

import cx from "classnames";
import React from "react";

import { Check } from "../../../icons";
import Badge from "../../Badge";
import { OptionIconPosition, OptionVariant } from "../dropdown.enum";
import { TDropdownOption } from "../dropdown.type";

const baseClassName = "c-dropdown-option";

interface DropdownOptionDefaultProps {
    iconPosition?: OptionIconPosition;
}

interface DropdownOptionProps extends DropdownOptionDefaultProps {
    option: TDropdownOption;
    onOptionSelect: (o: TDropdownOption) => void;
    variant?: OptionVariant;
    
    className?: string;
    classNameHeading?: string;
    classNameIcon?: string;
    selected?: boolean;
}

const DropdownOption: React.FC<DropdownOptionProps> = (props: DropdownOptionProps) => {
    const {option, iconPosition, classNameHeading, selected} = props;
    
    /**
     * Select Option Event Handler
     *
     * @param {TDropdownOption} d
     * @return {(e: React.MouseEvent<HTMLElement>) => void}
     */
    const selectOption = (d: TDropdownOption) => (_: React.MouseEvent<HTMLElement>) => props.onOptionSelect(d);
    
    const getOptionClassName = (): string => {
        return cx(
            baseClassName,
            props.className,
            {[`${baseClassName}--icon-${props.iconPosition}`]: props.option.icon},
            {[`${baseClassName}--${props.variant}`]: props.variant},
            {[`${baseClassName}--disabled`]: props.option.isDisabled},
            {[`${baseClassName}--selected`]: props.selected},
        );
    };
    
    const renderIcon = (position: OptionIconPosition) => {
        const { classNameIcon } = props;
        
        const classNames = cx(`${baseClassName}__icon-${position} ${baseClassName}--icon-container`, classNameIcon);
        return <div className={classNames}>{option.icon}</div>;
    };
    
    const headingClassNames = cx(`${baseClassName}__heading`, classNameHeading);
    
    return (
        <button
            aria-label={option.ariaLabel ?? option.heading}
            className={getOptionClassName()}
            onClick={!props.option.isDisabled && selectOption(option)}
            role="option"
        >
            {iconPosition === OptionIconPosition.LEFT && renderIcon(iconPosition)}
            <div className={headingClassNames}>{option.heading}</div>
            {iconPosition === OptionIconPosition.RIGHT && renderIcon(iconPosition)}
            {option.badge && props.option.badge.value > 0 && (
                <Badge style={{marginLeft: "16px"}} color={option.badge.color}>
                    {option.badge.value}
                </Badge>
            )}
            {selected && <Check/>}
        </button>
    );
};

DropdownOption.defaultProps = {
    iconPosition: OptionIconPosition.LEFT,
};

export default DropdownOption;
