import "./index.scss";

import cx from "classnames";
import React, { ReactElement, useEffect, useState } from "react";

import { TMessageTemplatePlaceholder } from "@/messenger/types/entities/messageTemplate";
import {generateUUID, handleOnEnter} from "@/messenger/utils/helpers";

import { DraftDecoratorComponentProps } from "../MessageTemplatePlaceholder";

export interface TComplianceAdviceChunk {
    placeholder?: TMessageTemplatePlaceholder;
    text?: string;
    index?: number;
}

export interface TComplianceAdviceWithPlaceholdersEntityData {
    selectedPlaceholder?: TMessageTemplatePlaceholder;
    chunks?: TComplianceAdviceChunk[];
}

export default function ComplianceAdviceWithPlaceholder(props: DraftDecoratorComponentProps): ReactElement {
    const [chunks, setChunks] = useState<TComplianceAdviceChunk[]>(null);
    const [selectedPlaceholder, setSelectedPlaceholder] = useState<TMessageTemplatePlaceholder>(null);

    const entity = props.contentState.getEntity(props.entityKey);
    useEffect(() => {
        const data = entity.getData() as TComplianceAdviceWithPlaceholdersEntityData;
        const sp = data.selectedPlaceholder;
        if (selectedPlaceholder) {
            const updatedChunks = chunks.map(chunk => {
                if (chunk.placeholder?.placeholder === selectedPlaceholder.placeholder)
                    return { placeholder: selectedPlaceholder };
                return chunk;
            });
            setChunks(updatedChunks);
        } else {
            setChunks(data.chunks);
        }
        setSelectedPlaceholder(sp);
    }, [entity]);

    useEffect(() => {
        if (selectedPlaceholder && chunks) {
            const updatedChunks = chunks.map(chunk => {
                if (chunk.placeholder?.placeholder === selectedPlaceholder.placeholder)
                    return { placeholder: selectedPlaceholder, index: chunk.index };
                return chunk;
            });
            setChunks(updatedChunks);
        }
    }, [selectedPlaceholder]);

    const onClick = (index: number) => {
        props.onClick(index);
    };

    const className = "c-compliance-advice-with-placeholder";
    const getValue = (pl: TMessageTemplatePlaceholder): string => {
        return pl?.draftValue || pl?.placeholder;
    };

    const renderPlaceholder = (placeholder: TMessageTemplatePlaceholder, index: number): ReactElement => {
        const isSelected = placeholder.placeholder === selectedPlaceholder?.placeholder;
        return (
            <span
                key={generateUUID()}
                className={cx(`${className}__placeholder-container`, {
                    [`${className}__placeholder-container--selected`]: isSelected,
                })}
            >
                <span className={`${className}__underline`} />
                <span
                    id={`COMPLIANCE_ADVICE_PLACEHOLDER_${index}`}
                    className={cx(`${className}__placeholder`, {
                        [`${className}__placeholder--selected`]: isSelected,
                    })}
                    onClick={() => onClick(index)}
                    onKeyDown={e => handleOnEnter(e, () => onClick(index))}
                    role="textbox"
                    aria-label={`complianceAdvicePlaceholder${index}`}
                >
                    {getValue(placeholder)}
                </span>
            </span>
        );
    };

    const renderText = (text: string): ReactElement => {
        return (
            <span key={generateUUID()} className={`${className}__text`}>
                <span className={`${className}__underline ${className}__underline--text`} />
                {text}
            </span>
        );
    };

    return (
        <span data-offset-key={props.offsetKey} className={className} data-testid="complianceAdviceWithPlaceholders">
            {chunks?.map(chunk => {
                return chunk.placeholder ? renderPlaceholder(chunk.placeholder, chunk.index) : renderText(chunk.text);
            })}
        </span>
    );
}
