import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} fill="currentColor" {...props}>
      <path d="M11.6667 6.33333C12.6017 6.33318 13.52 6.58093 14.328 7.05133C14.3509 7.06469 14.3766 7.07239 14.403 7.07378C14.4295 7.07517 14.4559 7.07022 14.48 7.05933C14.5042 7.04824 14.5255 7.03159 14.542 7.01074C14.5586 6.98989 14.57 6.96542 14.5753 6.93933C14.6357 6.62986 14.6663 6.31531 14.6667 6C14.6667 2.692 11.3767 0 7.33333 0C3.29 0 0 2.692 0 6C0.0123656 6.73712 0.175963 7.46385 0.480656 8.13516C0.78535 8.80647 1.22465 9.40806 1.77133 9.90267L0.686667 12.886C0.664638 12.9468 0.660778 13.0127 0.675557 13.0757C0.690335 13.1387 0.723114 13.196 0.769896 13.2406C0.816679 13.2853 0.875445 13.3154 0.939031 13.3272C1.00262 13.3391 1.06827 13.3322 1.128 13.3073L5.016 11.688C5.3873 11.7882 5.76478 11.8639 6.146 11.9147C6.16994 11.9178 6.19429 11.9157 6.21732 11.9085C6.24036 11.9012 6.26153 11.889 6.27933 11.8727C6.2978 11.8559 6.3123 11.8353 6.32176 11.8122C6.33121 11.7891 6.3354 11.7642 6.334 11.7393C6.334 11.7147 6.334 11.6893 6.334 11.664C6.33629 10.2506 6.89893 8.89573 7.89856 7.89648C8.89819 6.89722 10.2532 6.3351 11.6667 6.33333Z" fill="hsl(0deg 0% 48%)"/>
      <path d="M11.6673 7.33337C10.8103 7.33337 9.97246 7.58752 9.25985 8.06367C8.54724 8.53983 7.99182 9.2166 7.66384 10.0084C7.33586 10.8002 7.25005 11.6715 7.41725 12.5121C7.58445 13.3527 7.99716 14.1248 8.60319 14.7308C9.20922 15.3369 9.98134 15.7496 10.8219 15.9168C11.6625 16.084 12.5338 15.9982 13.3256 15.6702C14.1174 15.3422 14.7942 14.7868 15.2704 14.0742C15.7465 13.3616 16.0007 12.5238 16.0007 11.6667C15.9992 10.5179 15.5422 9.41649 14.7299 8.60414C13.9175 7.79178 12.8162 7.33479 11.6673 7.33337ZM9.40465 11.7847C9.45109 11.7382 9.50622 11.7014 9.5669 11.6762C9.62759 11.6511 9.69263 11.6381 9.75832 11.6381C9.82401 11.6381 9.88905 11.6511 9.94974 11.6762C10.0104 11.7014 10.0656 11.7382 10.112 11.7847L11.112 12.7847L13.0487 10.202C13.1282 10.096 13.2467 10.0258 13.3779 10.0071C13.5092 9.98831 13.6426 10.0225 13.7487 10.102C13.8547 10.1816 13.9249 10.3001 13.9436 10.4313C13.9624 10.5626 13.9282 10.696 13.8487 10.802L11.912 13.3847C11.8259 13.4994 11.7162 13.5942 11.5903 13.6628C11.4644 13.7314 11.3252 13.7721 11.1822 13.7821C11.0392 13.7922 10.8957 13.7714 10.7614 13.7211C10.6271 13.6708 10.5052 13.5922 10.404 13.4907L9.40399 12.4907C9.31048 12.397 9.25802 12.27 9.25815 12.1376C9.25828 12.0052 9.31097 11.8783 9.40465 11.7847Z" fill="hsl(0deg 0% 48%)"/>
    </svg>
  );
}

export default SvgComponent;
