import "./index.scss";

import React, { ReactNode } from "react";

interface TFormBodyProps {
    children: ReactNode
}

const FormBody = ({ children }: TFormBodyProps) => {
    return (
        <div className="c-form-body__wrapper">{children}</div>
    );
};

export default FormBody;