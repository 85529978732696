import { createEntityAdapter, createSlice,PayloadAction  } from "@reduxjs/toolkit";

import {  TNodeOffsets, TStore  } from "@/messenger/types/store.types";

import { getUserInterfaceSelector } from "../selectors/userInterface";

export const nodeOffsetsAdapter = createEntityAdapter<TNodeOffsets>();

export const initialState = nodeOffsetsAdapter.getInitialState();

const nodeOffsetState = createSlice({
    name: "nodeOffsets",
    initialState,
    reducers: {
        upsertOneNodeOffsets(state, action: PayloadAction<Partial<TNodeOffsets>>) {
            nodeOffsetsAdapter.upsertOne(state as any, action.payload as any);
        },
    },
});


export const DEFAULT_OFFSET: TNodeOffsets = {
    height: 0,
    top: 0,
    width: 0,
    left: 0,
};
export const nodeOffsetsSelectors = nodeOffsetsAdapter.getSelectors((state: TStore) => getUserInterfaceSelector(state).nodeOffsets);

export const {
    upsertOneNodeOffsets,
} = nodeOffsetState.actions;



export default nodeOffsetState.reducer;