import * as React from "react";

function Chat(props) {
    return (<svg width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path d="M10 0.728271C4.50501 0.728271 0.0341797 4.4441 0.0341797 9.01244C0.0465654 10.0602 0.278833 11.0937 0.715947 12.046C1.15306 12.9983 1.78528 13.8483 2.57168 14.5408L0.807513 18.0624C0.729908 18.2171 0.702367 18.3921 0.728712 18.5631C0.755057 18.7341 0.833982 18.8927 0.954533 19.0168C1.07508 19.1409 1.23128 19.2244 1.40146 19.2558C1.57163 19.2871 1.74733 19.2647 1.90418 19.1916L6.86835 16.8799C7.88888 17.1584 8.94218 17.2985 10 17.2966C15.495 17.2966 19.9658 13.5808 19.9658 9.01244C19.9658 4.4441 15.495 0.728271 10 0.728271ZM10 15.6299C8.99865 15.6327 8.00246 15.4863 7.04418 15.1958C6.84724 15.1356 6.63499 15.1504 6.44835 15.2374L3.91001 16.4166C3.87087 16.4347 3.82706 16.4403 3.78463 16.4324C3.74221 16.4246 3.70326 16.4038 3.67317 16.3729C3.64308 16.342 3.62332 16.3025 3.61664 16.2598C3.60995 16.2172 3.61667 16.1736 3.63585 16.1349L4.35251 14.7049C4.43806 14.5344 4.46247 14.3396 4.42167 14.1532C4.38087 13.9668 4.27732 13.8 4.12835 13.6808C3.39521 13.139 2.79544 12.437 2.37476 11.6283C1.95407 10.8195 1.72358 9.92542 1.70085 9.0141C1.70085 5.36494 5.42418 2.3966 10 2.3966C14.5758 2.3966 18.2992 5.36494 18.2992 9.0141C18.2992 12.6633 14.5758 15.6299 10 15.6299Z" />
        <path d="M6.25002 7.81169H11.6667C11.8877 7.81169 12.0997 7.72389 12.2559 7.56761C12.4122 7.41133 12.5 7.19937 12.5 6.97835C12.5 6.75734 12.4122 6.54538 12.2559 6.3891C12.0997 6.23282 11.8877 6.14502 11.6667 6.14502H6.25002C6.02901 6.14502 5.81705 6.23282 5.66076 6.3891C5.50448 6.54538 5.41669 6.75734 5.41669 6.97835C5.41669 7.19937 5.50448 7.41133 5.66076 7.56761C5.81705 7.72389 6.02901 7.81169 6.25002 7.81169Z" />
        <path d="M14.1667 9.89502H6.25002C6.02901 9.89502 5.81705 9.98282 5.66076 10.1391C5.50448 10.2954 5.41669 10.5073 5.41669 10.7284C5.41669 10.9494 5.50448 11.1613 5.66076 11.3176C5.81705 11.4739 6.02901 11.5617 6.25002 11.5617H14.1667C14.3877 11.5617 14.5997 11.4739 14.7559 11.3176C14.9122 11.1613 15 10.9494 15 10.7284C15 10.5073 14.9122 10.2954 14.7559 10.1391C14.5997 9.98282 14.3877 9.89502 14.1667 9.89502Z" />
        </svg>
    );
}

export default Chat;
