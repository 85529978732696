import { WidgetMethods } from "@statflo/widget-sdk/dist/shared";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddAContact from "@/messenger/containers/AddAContact";
import ActionWidget from "@/messenger/containers/Widgets/ActionWidget";
import { setIsAddAContactOpenAC } from "@/messenger/ducks/general/isAddAContactOpen.duck";
import { upsertOneWidgetsState, widgetStateSelectorsCustom } from "@/messenger/ducks/userInterface/widgetsState";
import { updateOneWidgetContextsAC, widgetEventReceivedAC } from "@/messenger/sagas/widget";
import { TStore } from "@/messenger/types/store.types";
import { widgetContainerClient } from "@/messenger/widgets";
import { WidgetState } from "@/messenger/widgets/types";

import ModuleWidget from "../Widgets/ModuleWidget";

interface TRenderWidgetsProps {
    conversationId: string
}
const PersistentWidgetContainerBase = () => {
    const dispatch = useDispatch();

    const actionWidgetStates = useSelector(widgetStateSelectorsCustom.selectAllByTypeAction);
    const moduleWidgetStates = useSelector(widgetStateSelectorsCustom.selectAllByTypeModule);
    const isAddAContactOpen = useSelector((s: TStore) => s.general.isAddAContactOpen);
    
    useEffect(() => {
        widgetContainerClient.on(WidgetMethods.setState, (e) => {
            const { id, payload: { property, value }} = e;
            
            dispatch(upsertOneWidgetsState({id, ...{[property] : value}}));

            if (property === WidgetState.isReady) {
                dispatch(updateOneWidgetContextsAC(id));
            }


        });

        widgetContainerClient.on(WidgetMethods.post, (e) => {
            dispatch(widgetEventReceivedAC(e));
        });
    }, []);


    const actionWidgets = actionWidgetStates.map(w => w.isEnabled && (<ActionWidget 
            key={w.id}
            widgetState={w}
        />));

    const moduleWidgets = moduleWidgetStates.map(w => w.isEnabled && (<ModuleWidget 
            key={w.id}
            widgetState={w}
        />));
    

    const style: React.CSSProperties = {
        position: "fixed",
        zIndex: 99,
        width: "100%",
        left: "48px",
    };

    const onCloseAddAContact = () => {
        dispatch(setIsAddAContactOpenAC(false));
    };

    return (<div style={style}>
        {actionWidgets}
        {isAddAContactOpen && <AddAContact onClose={onCloseAddAContact} />}
        {moduleWidgets}
    </div>);
};

const PersistentWidgetContainer = React.memo(PersistentWidgetContainerBase, (_: TRenderWidgetsProps, { conversationId }: TRenderWidgetsProps) => !conversationId);


export default PersistentWidgetContainer;