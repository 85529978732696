import "./index.scss";
import "../../locales/i18n";

import cx from "classnames";
import { ContentState } from "draft-js";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SELECTED_PLACEHOLDER } from "@/messenger/constants/domID";
import { TMessageTemplatePlaceholder } from "@/messenger/types/entities/messageTemplate";
import { handleOnEnter } from "@/messenger/utils/helpers";

export interface DraftDecoratorComponentProps {
    blockKey: string;
    children?: ReactElement[];
    contentState: ContentState;
    decoratedText: string;
    dir?: any;
    end: number;
    entityKey?: string;
    offsetKey: string;
    start: number;
    onClick: (index: number) => void;
}

export interface TPlaceholderEntityData {
    selectedPlaceholder: TMessageTemplatePlaceholder;
    placeholder: TMessageTemplatePlaceholder;
    index: number;
}

export default function MessageTemplatePlaceholder(props: DraftDecoratorComponentProps): ReactElement {
    const [placeholder, setPlaceholder] = useState<TMessageTemplatePlaceholder>(null);
    const [index, setIndex] = useState<number>();
    const [isSelected, setIsSelected] = useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
        const entity = props.contentState.getEntity(props.entityKey);
        const data = entity.getData() as TPlaceholderEntityData;
        setPlaceholder(data.placeholder);
        setIndex(data.index);
        setIsSelected(data.placeholder.placeholder === data.selectedPlaceholder?.placeholder);
    }, [props.contentState.getEntity(props.entityKey)]);

    const className = "с-message-template-placeholder";
    const value = placeholder?.draftValue || placeholder?.placeholder;

    const onClick = () => {
        props.onClick(index);
    };

    return (
        <div
            id={isSelected ? SELECTED_PLACEHOLDER : null}
            data-offset-key={props.offsetKey}
            className={cx(`${className}`, {
                [`${className}--has-no-value`]: !placeholder?.value,
                [`${className}--has-value`]: !!placeholder?.value,
                [`${className}--selected`]: isSelected,
                [`${className}--selected-has-no-value`]: isSelected && !placeholder?.value,
                [`${className}--selected-has-value`]: isSelected && !!placeholder?.value,
                [`${className}--selected-has-no-draft-value`]: isSelected && !placeholder?.draftValue,
            })}
            tabIndex={0}
            onKeyPress={e => handleOnEnter(e, () => props.onClick(index))}
            onClick={onClick}
            role="textbox"
            aria-label={t("Message Template Placeholder")}
        >
            {value}
        </div>
    );
}
