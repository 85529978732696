import React, { useRef, useState } from "react";

export function useGetDimensions(id: string) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [exists, setExists] = useState(false);

  const nodeRef = useRef<any>();

  const updateSize = (resetNode = false) => {
    if (!nodeRef.current || resetNode === true) {
      nodeRef.current = document.getElementById(id);
      setExists(true);
    }
    
    if (!nodeRef.current) {
      setExists(false);
      return;
    }

    const { offsetWidth, offsetHeight } = nodeRef.current;
    const { left: clientLeft, top: clientTop } = nodeRef.current.getBoundingClientRect();
    
    setWidth(offsetWidth);
    setHeight(offsetHeight);
    setTop(clientTop);
    setLeft(clientLeft);
    
  };

  return [exists, width, height, top, left, updateSize] as [boolean, number, number, number, number, (reset?: boolean) => React.CSSProperties];
}
