import "./index.scss";

import cx from "classnames";
import React from "react";

import Caret from "@/components/icons/Caret";


const baseClassName = "c-chat-status-option-selected";

interface ChatStatusOptionSelectedProps {
    heading: string
    label: string
    value?: string
    showCaret: boolean
    disabled: boolean
    toggleMenu?: () => void
}

function ChatStatusOptionSelected(props: ChatStatusOptionSelectedProps) {
    const { heading, label, value, showCaret, disabled, toggleMenu } = props;

    const chatStatusClassName = cx(
        { [`${baseClassName} ${baseClassName}__${label}`] : true },
        { [`${baseClassName}__${label}__disabled`] : !!disabled},
    );

    return <button id={"CHAT_STATUS_BUTTON"} className={chatStatusClassName} onClick={toggleMenu}>
        <div className={`${baseClassName}__header ${baseClassName}__header__${label}`}>{heading}</div>
        <div className={`${baseClassName}__label ${baseClassName}__label__${label}`}>
            {value}
            {showCaret ? <div className={`${baseClassName}__icon`}>
                <Caret fill={"#6B6C7E"} />
            </div> : null}
        </div>
    </button>;
}

export default ChatStatusOptionSelected;