import "./index.scss";

import React from "react";

import  Error500 from "@/messenger/illustrations/Error500";

const ModuleWidgetNotFound = () => {
    

    return (
    <div className="no-module-found__message-wrapper">
        <Error500 width={500} />
        <span className="no-module-found__message-title">Cannot find module widget</span>
        <span className="no-module-found__message">Please check your widget path</span>
    </div>);
    
};

export default ModuleWidgetNotFound;