import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
        <g clipPath="url(#clip0_14281:43516)">
            <path d="M9 0C7.40925 0.00178658 5.88416 0.634501 4.75933 1.75933C3.6345 2.88416 3.00179 4.40925 3 6C3 8.6325 6.75 15.0187 8.361 17.643C8.42814 17.7521 8.52213 17.8422 8.63399 17.9047C8.74585 17.9672 8.87186 18 9 18C9.12833 18.0006 9.25464 17.968 9.36662 17.9053C9.4786 17.8426 9.57244 17.752 9.639 17.6423C11.25 15.0195 15 8.6355 15 6C14.9982 4.40925 14.3655 2.88416 13.2407 1.75933C12.1158 0.634501 10.5908 0.00178658 9 0V0ZM9 8.625C8.48082 8.625 7.97331 8.47105 7.54163 8.18261C7.10995 7.89417 6.7735 7.4842 6.57482 7.00454C6.37614 6.52489 6.32415 5.99709 6.42544 5.48789C6.52672 4.97869 6.77673 4.51096 7.14384 4.14384C7.51096 3.77673 7.97869 3.52672 8.48789 3.42544C8.99709 3.32415 9.52489 3.37614 10.0045 3.57482C10.4842 3.7735 10.8942 4.10995 11.1826 4.54163C11.471 4.97331 11.625 5.48082 11.625 6C11.625 6.69619 11.3484 7.36387 10.8562 7.85615C10.3639 8.34844 9.69619 8.625 9 8.625Z" fill="#B2B2B2"/>
        </g>
        <defs>
            <clipPath id="clip0_14281:43516">
                <rect width="18" height="18" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  );
}

export default SvgComponent;