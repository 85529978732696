import "./Checkbox.scss";

import cx from "classnames";
import React, { PureComponent } from "react";

import { handleOnEnter } from "@/components/helpers";
import icons from "@/components/icons";

import { CheckboxValue } from "./checkbox.type";

interface CheckboxDefaultProps {
    disabled?: boolean;
}

interface CheckboxProps extends CheckboxDefaultProps {
    /**  CheckboxValue { checked: boolean; label?: string; id?: any; } */
    value: CheckboxValue;
    className?: string;
    onChange?: (cv: CheckboxValue) => void;
    id?: string;
}

interface CheckboxState {
    checked: boolean;
    checkedIcon: React.ReactNode;
}

class Checkbox extends PureComponent<CheckboxProps, CheckboxState> {
    baseClassName = "c-checkbox";

    static defaultProps: CheckboxDefaultProps = {
        disabled: false,
    };

    constructor(props: CheckboxProps) {
        super(props);

        this.state = {
            checked: this.props.value.checked,
            checkedIcon: icons.CHECK,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value.checked !== this.props.value.checked) {
            this.setState({ checked: this.props.value.checked });
        }
    }

    onCheckChange = (): void => {
        if (!this.props.disabled) {
            const checked = !this.state.checked;
            this.setState({ checked });
            if (this.props.onChange)
                this.props.onChange({
                    ...this.props.value,
                    checked,
                });
        }
    };

    changeIcon = (checkedIcon: React.ReactNode) => (): void => {
        if (!this.props.disabled && this.state.checked) this.setState({ checkedIcon });
    };

    getCheckboxClassName = (): string => {
        const c = this.baseClassName;
        return cx(c, this.props.className, { [`${c}--disabled`]: this.props.disabled });
    };

    getCheckboxBoxClassName = (): string => {
        const c = `${this.baseClassName}__box`;
        return cx(c, { [`${c}--checked`]: this.state.checked }, { [`${c}--disabled`]: this.props.disabled });
    };

    getCheckboxLabelClassName = (): string => {
        const c = `${this.baseClassName}__label`;
        return cx(c, { [`${c}--disabled`]: this.props.disabled });
    };

    handleKeyDown = (e: React.KeyboardEvent) => handleOnEnter(e, this.onCheckChange);

    render(): React.ReactNode {
        return (
            <div
                id={this.props.id}
                className={this.getCheckboxClassName()}
                onMouseEnter={this.changeIcon(icons.HR)}
                onMouseLeave={this.changeIcon(icons.CHECK)}
                onKeyDown={this.handleKeyDown}
                onClick={this.onCheckChange}
                tabIndex={0}
            >
                <span className={this.getCheckboxBoxClassName()}>{this.state.checked && this.state.checkedIcon}</span>
                {this.props.value.label && (
                    <span className={this.getCheckboxLabelClassName()}>{this.props.value.label}</span>
                )}
            </div>
        );
    }
}

export default Checkbox;
