import { WidgetViewSize } from "./api";
import { WidgetType } from "./api/events";


export enum DebugLogLevel {
    None = "none",
    Debug = "debug",
}


export enum WidgetState {
    containerDomain = "containerDomain",
    footer = "footer",
    header = "header",
    id = "id",
    isOpen = "isOpen",
    isReady = "isReady",
    isShown = "isShown",
    label = "label",
    maxHeight = "maxHeight",
    size = "size",
    type = "type",
    url = "url",
    widgetDomain = "widgetDomain",
}

export interface TWidgetState {
    containerDomain?: string
    footer: string
    header: string
    id: string
    isOpen: boolean
    isReady: boolean
    isShown: boolean
    label: string
    maxHeight: number
    size: WidgetViewSize
    type: WidgetType
    url: string
    widgetDomain: string
}

