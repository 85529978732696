export interface RequestTracker {
    uri: string
    status: RequestStatus
}

export interface RequestStatus {
    complete: boolean,
    loading: boolean,
    sent: boolean
}

export const createRequestTracker = (uri: string, { complete = false, loading = false, sent = false }: RequestStatus) => {
    return {
        uri,
        status: {
            complete,
            loading,
            sent,
        },
    };
};