import "./index.scss";

import * as React from "react";
import { useParams } from "react-router-dom";

import { Magnify } from "@/components/icons";

function Error404Convo() {
  const { id } = useParams<any>();

  return <div className="c-error-404-convo">
      <div className="c-error-404-convo__icon">
        <Magnify />
      </div>
      <div className="c-error-404-convo__header">
        The conversation ID #{id} cannot be found
      </div>
      <div className="c-error-404-convo__subheader">
        There are several possible reasons for this:<br />
        1. You’ve entered the wrong conversation ID<br />
        2. The conversation has expired<br />
        3. The conversation has been reassigned<br />
      </div>
  </div>;
}

export default Error404Convo;
