import "./index.scss";

import cx from "classnames";
import React, { useRef } from "react";

import Close from "@/components/icons/Close";

import ClickOutside from "../OutsideClick";

export interface PandorasBoxProps {
    message: string;
    visible: boolean;
    onClick: () => void;
    onOutsideClick: () => void;
}

const PandorasBox = ({ message, visible, onClick, onOutsideClick }: PandorasBoxProps) => {
    const baseClassName = "c-pandoras-box";
    const parentRef = useRef<HTMLDivElement>(null);
    return (
        <ClickOutside parentRef={parentRef} onClickOutsideHandler={onOutsideClick}>
            <div ref={parentRef} className={cx(`${baseClassName}__mobile`, {
                [`${baseClassName}__mobile--active`]: visible,
            })}>
                <button className={`${baseClassName}__mobile--close`} onClick={onClick}>
                    <Close width={16} height={16} fill="currentColor" />
                </button>
                <span className={`${baseClassName}__mobile--message`}>
                    {message}
                </span>
            </div>
        </ClickOutside>
    );
};

export default PandorasBox;