import "./index.scss";

import i18next from "i18next";
import React, { useState } from "react";

const baseClassName = "language-toggle";
const LanguageToggle = () => {
    const [activeLanguage, setActiveLanguage] = useState<string>("en");
    const changeLanguage = (value: string): void => {
        if (activeLanguage !== value) {
            setActiveLanguage(value);
            i18next.changeLanguage(value);
        }
    };

    return <div className={`${baseClassName}`}>
            <div onClick={() => changeLanguage("en")} className={`${baseClassName}__language-button ${baseClassName}__${activeLanguage === "en" && "active"}`}>EN</div>
            <div onClick={() => changeLanguage("fr")} className={`${baseClassName}__language-button ${baseClassName}__${activeLanguage === "fr" && "active"}`}>FR</div>
    </div>;
};

export default LanguageToggle;