import "./index.scss";

import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ChatLoading from "@/messenger/components/ChatLoading";
import MessageInputTabs from "@/messenger/components/MessageInputTabs";
import Chat from "@/messenger/containers/Chat";
import ContactNotSMS from "@/messenger/containers/ContactNotSMS";
import DisabledMessageInput from "@/messenger/containers/DisabledMessageInput";
import MessageCooldown from "@/messenger/containers/MessageCooldown";
import MessageInput from "@/messenger/containers/MessageInput";
import MessageTemplates from "@/messenger/containers/MessageTemplates";
import TopBar from "@/messenger/containers/TopBar";
import { getParticipationId, participationsSelectors } from "@/messenger/ducks/entities/participations";
import { twilioChannelsSelectors } from "@/messenger/ducks/entities/twilioChannels";
import { getIsContactSmsCapable,isAppLoadedSelector } from "@/messenger/ducks/selectors/aggregate";
import { upsertOneChat } from "@/messenger/ducks/userInterface/chats";
import { hydrateConversation } from "@/messenger/graphql/mutations";
import { activeChatSelector, activeConversationIsOptOutSms, activeConversationSelector, conversationCooldownSelector } from "@/messenger/selectors";
import { ConversationCategory, MessageInputTabType } from "@/messenger/types/entities/conversation";
import { HydrationEnum } from "@/messenger/types/graphql/autogenerated";
import { TStore } from "@/messenger/types/store.types";
import { getMessageInputTabOptions } from "@/messenger/utils/getMessageInputTabOptions";
import { isSeedEnv } from "@/messenger/utils/helpers";

interface TChatWindowProps {
    style?: React.CSSProperties
}

const ChatWindowBase = ({ style }: TChatWindowProps) => {
    const dispatch = useDispatch();
    const { isCooldownActive, cooldownUntilDatetime } = useSelector(conversationCooldownSelector);
    const conversation = useSelector(activeConversationSelector);
    const {hydration} = conversation;
    const [selectedMessageInputTab, setSelectedMessageInputTab] = useState(MessageInputTabType.STANDARD);

    const chatState = useSelector(activeChatSelector);
    const hasChatHistory = chatState.messages.length > 0;

    const activeChannel = useSelector((s: TStore) => twilioChannelsSelectors.selectById(s, conversation?.id));
    const isSmsCapable = useSelector(getIsContactSmsCapable);
    const isContactToBeContacted = conversation.category === ConversationCategory.TO_BE_CONTACTED;
    const isContactOptOutSms = useSelector(activeConversationIsOptOutSms);
    const isClosedFromTBC = conversation.category === ConversationCategory.CLOSED && conversation.nextCategories?.includes(ConversationCategory.TO_BE_CONTACTED);

    const participationExists = useSelector((state: TStore) => participationsSelectors.selectById(state, getParticipationId(conversation)));
    const messageInputTabOptions = getMessageInputTabOptions();

    const renderChatInputArea = () => {
        if(isContactOptOutSms){
            return <DisabledMessageInput currentCategory={ConversationCategory.OPT_OUT} />;
        }
        if(isClosedFromTBC){
            return <DisabledMessageInput currentCategory={ConversationCategory.CLOSED} />;
        }
        if(isCooldownActive){
            return <MessageCooldown cooldownUntilDatetime={cooldownUntilDatetime}/>;
        }
        if(activeChannel || isSeedEnv){
            return <MessageInput activeChannel={activeChannel}/>;
        }
        return null;
    };

    const onClickMessageInputTab = (tab: MessageInputTabType) => {
        setSelectedMessageInputTab(tab);
    };

    useEffect(() => {
        setSelectedMessageInputTab(hasChatHistory ? MessageInputTabType.STANDARD : MessageInputTabType.CHAT_STARTER);
    }, [conversation, chatState.messageFirstBatchLoaded]);

    const renderChatWindowArea = () => {

        if(!isSmsCapable){
            return <ContactNotSMS />;
        }

        if (!isSeedEnv && ((!activeChannel && !isContactToBeContacted) || (isContactToBeContacted && !participationExists))) {
            return <ChatLoading />;
        }

        if(!isContactOptOutSms && isContactToBeContacted){
            if (conversation) {
                return <>
                    {selectedMessageInputTab === MessageInputTabType.CHAT_STARTER ? 
                        <MessageTemplates isCooldownActive={isCooldownActive} activeConversationId={conversation?.id} /> : 
                        <Chat key={conversation.id} id={conversation.id} />    
                    }
                    {isCooldownActive && <MessageCooldown cooldownUntilDatetime={cooldownUntilDatetime}/>}
                    {!isCooldownActive && hasChatHistory && 
                        <>
                            <MessageInputTabs 
                                selectedTab={selectedMessageInputTab}
                                options={messageInputTabOptions}
                                onClick={onClickMessageInputTab}
                            />
                            <MessageInput activeChannel={activeChannel}/>
                        </>
                    }
                </>;
            }
            return null;
        }

        return <>
            <Chat key={conversation.id} id={conversation.id} />
            {renderChatInputArea()} 
        </>;
    };

    const [hydrateConversationMutation] = useMutation<{
        hydrateConversation: boolean;
    }>(hydrateConversation);
    
    const hydrateConvo = (convoId) => {
        hydrateConversationMutation({
            variables: {conversationId: convoId},
        });
    };

    useEffect(() => {
        if(hydration === HydrationEnum.Dehydrated){
            setTimeout(() => {
                hydrateConvo(conversation.id);
                dispatch(upsertOneChat({id: conversation.id, hydration: HydrationEnum.Hydrated}));
            }, 100); // give time for hydration to run before rendering the Chat component
        }
    }, []);


    return <div className="c-chat-window" id="chat-window" role="application" aria-label="chatWindow" style={style}>
        <TopBar />
        {renderChatWindowArea()}
    </div>;
};

const ChatWindow = (props: TChatWindowProps) => {
    const isAppReady = useSelector(isAppLoadedSelector);
    const conversation = useSelector(activeConversationSelector);

    // handles a race condition that will be caught and handled by the router saga for conversation-not-found redirect.
    return (!isAppReady || !conversation) ? null : <ChatWindowBase {...props} />;

};

export default ChatWindow;