import produce from "immer";

import { localStorageKeys } from "@/messenger/app.settings";
import { TConversationNormalized } from "@/messenger/types/entities/conversation";
import { TConversationRefinements } from "@/messenger/types/store.types";
import { getFromLocalStorage, setToLocalStorage } from "@/messenger/utils/helpers";

export const SET_FILTER_IS_OPEN = "hummingbird/general/setFilterIsOpen";
export const SET_FILTER_PREV_SELECTED_CAMPAIGN_NAME = "hummingbird/general/setFilterPrevSelectedCampaignName";
export const SET_FILTER_SELECTED_CAMPAIGN_NAME = "hummingbird/general/setFilterSelectedCampaignName";
export const SET_FILTER_PREV_SELECTED_MONTH = "hummingbird/general/setFilterPrevSelectedMonth";
export const SET_FILTER_SELECTED_MONTH = "hummingbird/general/setFilterSelectedMonth";
export const SET_FILTER_IS_SMS_CAPABLE = "hummingbird/general/setFilterIsSmsCapable";
export const SET_PREV_FILTER_IS_SMS_CAPABLE = "hummingbird/general/setPrevFilterIsSmsCapable";
export const SET_SEARCH_VALUE = "hummingbird/general/setSearchValue";

export const SET_REFINEMENT_RESULT = "hummingbird/general/setRefinementResult";

const INITIAL_STATE: TConversationRefinements = {
    filter: {
        isOpen: false,
        selectedTopicName: getFromLocalStorage(localStorageKeys.campaignFilter) || null,
        prevSelectedTopicName: getFromLocalStorage(localStorageKeys.prevCampaignFilter) || null,
        selectedMonth: getFromLocalStorage(localStorageKeys.monthFilter) || null,
        prevSelectedMonth: getFromLocalStorage(localStorageKeys.prevMonthFilter) || null,
        isSmsCapable: getFromLocalStorage(localStorageKeys.smsCapableFilter) || false,
        prevIsSmsCapable: getFromLocalStorage(localStorageKeys.prevSmsCapableFilter) || false,
    },
    search: {
        value: null,
    },
    result: [],
};

export const setRefinementResultAC = (conversations: TConversationNormalized[]) => ({
    type: SET_REFINEMENT_RESULT,
    payload: conversations,
});

export const setSearchValueAC = (value: string) => ({
    type: SET_SEARCH_VALUE,
    payload: value,
});

export const setSelectedCampaignNameAC = (campaignName: string) => {
    setToLocalStorage(localStorageKeys.campaignFilter, campaignName);

    return ({
        type: SET_FILTER_SELECTED_CAMPAIGN_NAME,
        payload: campaignName,
    });
};


export const setPrevSelectedCampaignNameAC = (campaignName: string) => {
    setToLocalStorage(localStorageKeys.prevCampaignFilter, campaignName);

    return ({
        type: SET_FILTER_PREV_SELECTED_CAMPAIGN_NAME,
        payload: campaignName,
    });
};

export const setSelectedMonthAC = (month: string) => {
    setToLocalStorage(localStorageKeys.monthFilter, month);

    return ({
        type: SET_FILTER_SELECTED_MONTH,
        payload: month,
    });
};


export const setPrevSelectedMonthAC = (month: string) => {
    setToLocalStorage(localStorageKeys.prevMonthFilter, month);

    return ({
        type: SET_FILTER_PREV_SELECTED_MONTH,
        payload: month,
    });
};

export const setFilterIsSmsCapableAC = (isSmsCapable: boolean) => {
    setToLocalStorage(localStorageKeys.smsCapableFilter, isSmsCapable);

    return ({
        type: SET_FILTER_IS_SMS_CAPABLE,
        payload: isSmsCapable,
    });
};

export const setPrevFilterIsSmsCapableAC = (isSmsCapable: boolean) => {
    setToLocalStorage(localStorageKeys.prevSmsCapableFilter, isSmsCapable);

    return ({
        type: SET_PREV_FILTER_IS_SMS_CAPABLE,
        payload: isSmsCapable,
    });
};

export const setFilterIsOpenAC = (isOpen: boolean) => ({
    type: SET_FILTER_IS_OPEN,
    payload: isOpen,
});

const conversationRefinementsReducer = produce(
    (draft: TConversationRefinements, action) => {
        switch (action.type) {
            case SET_FILTER_IS_OPEN:
                draft.filter.isOpen = action.payload;
                return draft;
            case SET_REFINEMENT_RESULT:
                draft.result = action.payload;
                return draft;
            case SET_FILTER_PREV_SELECTED_CAMPAIGN_NAME:
                draft.filter.prevSelectedTopicName = action.payload;
                return draft;
            case SET_FILTER_SELECTED_CAMPAIGN_NAME:
                draft.filter.selectedTopicName = action.payload;
                return draft;
            case SET_FILTER_PREV_SELECTED_MONTH:
                draft.filter.prevSelectedMonth = action.payload;
                return draft;
            case SET_FILTER_SELECTED_MONTH:
                draft.filter.selectedMonth = action.payload;
                return draft;
            case SET_SEARCH_VALUE:
                draft.search.value = action.payload;
                return draft;
            case SET_FILTER_IS_SMS_CAPABLE:
                draft.filter.isSmsCapable = action.payload;
                return draft;
            case SET_PREV_FILTER_IS_SMS_CAPABLE:
                draft.filter.prevIsSmsCapable = action.payload;
                return draft;
            default:
                return draft;
        }
    }, INITIAL_STATE,
);

export default conversationRefinementsReducer;