import "./index.scss";
import "../../locales/i18n";

import { BaseEmoji, Picker } from "emoji-mart";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@/components/components/Button";
import { ButtonColor, ButtonSize } from "@/components/components/Button/button.enum";
import Close from "@/components/components/Close";
import { Smile } from "@/components/icons";

interface TEmojiPickerProps {
    onSelectEmoji: (e: BaseEmoji) => void
}

const baseClassName = "c-emoji-picker";
export const emojiButtonTestId = "emoji-button";
export const emojiPickerTestId = "emoji-picker";

const EmojiPicker = ({ onSelectEmoji }: TEmojiPickerProps) => {
    const [isOpen, setIsOpen] = useState(false);  
    const [isClosing, setIsClosing] = useState(false);

    const { t } = useTranslation();

    const handleOnSelect = (e: BaseEmoji) => {
        onSelectEmoji(e);
        setIsOpen(false);
    };

    const handleOnClick = () => {
        if (isClosing) {
            setIsClosing(false);
        } else {
            setIsOpen(!isOpen);
        }

    };

    const handleOnClose = () => {
        setIsClosing(true);
        setIsOpen(false);
        setTimeout(() => setIsClosing(false), 100);
    };

    return  <div className={`${baseClassName}__container`}>
        <Button
            id={"EMOJI_PICKER_BUTTON"}
            className={`${baseClassName}__button`}
            color={ButtonColor.SECONDARY}
            icon={<Smile width={16} height={16} />}
            size={ButtonSize.REGULAR}
            onClick={handleOnClick}
            ariaLabel={t("Emoji Picker Button")}
            testId={emojiButtonTestId}
        />
        { isOpen && <div className={`${baseClassName}__menu`} data-testid={emojiPickerTestId}>
            <Close isOpen={isOpen} closeChange={handleOnClose}>
                <Picker
                    onSelect={handleOnSelect}
                    set={"apple"}
                    native={true}
                    sheetSize={16}
                    showPreview={false}
                    showSkinTones={false}
                    emojiSize={18}
                />
            </Close>
        </div>}
    </div>;

};

export default EmojiPicker;