import { TActionV2 } from "@/messenger/types/action.types";

enum CurrentEditableTemplateIdActionTypes {
    SET = "hummingbird/currentEditableTemplateId/SET",
}

export const currentEditableTemplateIdAC = (id: string) => ({
    type: CurrentEditableTemplateIdActionTypes.SET,
    payload: id,
});

const currentEditableTemplateIdReducer = (state = true, action: TActionV2<CurrentEditableTemplateIdActionTypes, string>) => {
    switch (action.type) {
        case CurrentEditableTemplateIdActionTypes.SET:
            return action.payload;
        default:
            return state;
    }
};

export default currentEditableTemplateIdReducer;
