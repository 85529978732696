import { PayloadAction } from "@reduxjs/toolkit";
import { Paginator } from "@twilio/conversations";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { LOAD_MESSAGE_MEDIA, loadMessageMediaAC,MESSAGE_GET_FIRST_BATCH,MESSAGE_GET_PREVIOUS,messageLoadedInitialBatchAC, messageLoadedMoreAC } from "@/messenger/actions/message";
import { twilioChannelsSelectors } from "@/messenger/ducks/entities/twilioChannels";
import { chatsSelectors, updateChatMessage, upsertOneChat } from "@/messenger/ducks/userInterface/chats";
import { createMessage, getMediaGenerator, getMessageIndex } from "@/messenger/models/message";
import { activeConversationSelector } from "@/messenger/selectors";
import { TConversationNormalized } from "@/messenger/types/entities/conversation";
import { TMessage, TMessageOrigin } from "@/messenger/types/message";
import { TChatState, TStore } from "@/messenger/types/store.types";
import { TTwilioChannel, TTwilioMessage } from "@/messenger/types/twilioChatSDK.types";

const DEFAULT_MESSAGES_COUNT = 30;

const addNotDeliveredMessages = (messages: TMessage[], localMessages: TMessage[]): TMessage[] => {
    if (localMessages) {
        return messages.reduce((acc, m) => {
            acc.push(m);
            const index = getMessageIndex(m);
            const notDeliveredMessagesToInsert = localMessages.filter(
                notDeliveredMessage => notDeliveredMessage.local.prevDeliveredMessageIndex === index,
            );
            if (notDeliveredMessagesToInsert.length) acc = acc.concat(messages);
            return acc;
        }, []);
    }

    return messages;
};

function* loadMessagesMedia(newMessages: TMessage[]) {
    const onSuccess = (message: TMessage) => function* (url: string) {
        const updatedMessage = {...message, ...{ computed: {
            ...message.computed,
            mediaUrl: url,
            hasMediaDownloadFailed: false,
        }}};

        yield put(updateChatMessage({
            id: updatedMessage.twilio.conversation.attributes.conversation_id,
            message: updatedMessage,
        }));
    };

    const onFailure = (message: TMessage) => function* (hasMediaDownloadFailed: boolean) {
        const updatedMessage = {...message, ...{ computed: {
            ...message.computed,
            mediaUrl: null,
            hasMediaDownloadFailed,
        }}};

        yield put(updateChatMessage({
            id: updatedMessage.twilio.conversation.attributes.conversation_id,
            message: updatedMessage,
        }));
    };

    const getMediaCalls = newMessages.map(message => call(getMediaGenerator, message, onSuccess(message), onFailure(message)));

    yield all(getMediaCalls);
}

function* loadMessageMedia(action: ReturnType<typeof loadMessageMediaAC>) {
    yield call(loadMessagesMedia, [action.payload]);
}

export function* handleGetFirstMessageBatch(id) {
    const chatState: TChatState = yield select((s: TStore) => chatsSelectors.selectById(s, id));

    if (chatState.messageFirstBatchLoaded) {
        return;
    }

    const activeChannel: TTwilioChannel = yield select((s: TStore) => twilioChannelsSelectors.selectById(s, id));
    
    yield put(upsertOneChat({
        id,
        loadingMoreMessages: true,
    }));

    if (activeChannel) {
        try {
            const paginator: Paginator<TTwilioMessage> = yield activeChannel.getMessages(DEFAULT_MESSAGES_COUNT );

            let items = paginator.items.map(message => createMessage({ twilio: message }));
            items = addNotDeliveredMessages(items, chatState.localMessages);
            
            yield put(upsertOneChat({
                id,
                messages: items,
                paginator,
                messageFirstBatchLoaded: true,
            }));

            yield call(loadMessagesMedia, items);

            yield put(messageLoadedInitialBatchAC(id, true));
        } catch (e) {
            // TODO: use Sentry (or similar) to log errors
            console.log(e);
        } finally {
            yield put(upsertOneChat({
                id,
                loadingMoreMessages: false,
            }));
        }
    }
};

interface TLoadMessagesPayload {
    conversationId: string,
}

export function* handleSneakyLoadMessage(action: PayloadAction<TLoadMessagesPayload>){
    yield call(handleGetFirstMessageBatch,action.payload.conversationId);
}

export function* handleGetPreviousMessage(){
    const activeConversation: TConversationNormalized = yield select(activeConversationSelector);
    const activeChannel: TTwilioChannel = yield select((s: TStore) => twilioChannelsSelectors.selectById(s, activeConversation.id));
    const { messages, paginator }: TChatState = yield select((s: TStore) => chatsSelectors.selectById(s, activeConversation.id));

    yield put(upsertOneChat({
        id: activeConversation.id,
        loadingMoreMessages: true,
    }));
    if (activeChannel) {
        try {
            const updatedPaginator: Paginator<TTwilioMessage> = yield paginator.prevPage();

            const items = updatedPaginator.items.map(message => createMessage({
                twilio: message,
                computed: {
                    messageOrigin: TMessageOrigin.LOAD_MORE,
                },
            }));
            
            yield put(upsertOneChat({
                id: activeConversation.id,
                paginator: updatedPaginator,
                messages: [...items, ...messages],
            }));
            
            yield call(loadMessagesMedia, items);
            yield put(messageLoadedMoreAC());
        } catch(e) {
            // TODO: use Sentry (or similar) to log errors
            console.log(e);
        } finally {
            yield put(upsertOneChat({
                id: activeConversation.id,
                loadingMoreMessages: false,
            }));
        }
    }
}

export default function* messageSaga() {
    yield takeEvery(MESSAGE_GET_PREVIOUS, handleGetPreviousMessage);
    yield takeEvery(MESSAGE_GET_FIRST_BATCH, handleSneakyLoadMessage);
    yield takeEvery(LOAD_MESSAGE_MEDIA, loadMessageMedia);
}
