import { getChatToken } from "@/messenger/services/data.service";
import { TAction } from "@/messenger/types/action.types";
import { TAuth0Credentials,TJWTToken } from "@/messenger/types/auth.types";

import { setUser } from "./user.duck";

enum ActionTypes {
    GET_USER_SESSION = "hummingbird/auth/token/GET_USER_SESSION",
    SET = "hummingbird/auth/token/SET",
}

export const setToken = (token: TJWTToken) => ({
    type: ActionTypes.SET,
    data: token,
});

export const authChat: any = (credentials: TAuth0Credentials) => async dispatch => {
    const { userId, twilioJwt } = await getChatToken(credentials);
    dispatch(setUser({ identity: userId }));
    dispatch(setToken(twilioJwt));
};

const tokenReducer = (state: TJWTToken = "", action: TAction<ActionTypes, TJWTToken>) => {
    switch (action.type) {
        case ActionTypes.SET:
            return action.data;
        default:
            return state;
    }
};

export default tokenReducer;
