import "./AccentedDropdown.scss";

import cx from "classnames";
import React from "react";

import { OptionVariant } from "@/components/components/Dropdown/dropdown.enum";

import { TDropdownOption, TSelectDropDownOption } from "../dropdown.type";
import SimpleDropdown from "../SimpleDropdown";

const baseClassName = "c-accented-dropdown";

interface AccentedDropdownProps {
    heading: string;
    isHighlighted?: boolean;
    isMobileView?: boolean;
    withBackdrop?: boolean;
    options?: TDropdownOption[];
    ariaLabel?: string;
    ariaLabelledBy?: string;
    optionsContainerAriaLabel?: string;
    search?: boolean;
    selectedOption?: TDropdownOption;

    noResultMsg?: string;
    rewordMsg?: string;

    whenEmptyHeader?: string
    whenEmptySubheader?: string
    
    onSelect?: TSelectDropDownOption
    onOpen?: () => void;
    onClose?: () => void;
    
    className?: string;
    headerClassName?: string;
    headerTextClassName?:string;
    
    testid?: string;
}

function AccentedDropdown(props: AccentedDropdownProps) {
    const {
        className,
        headerClassName,
        isHighlighted,
        options,
        selectedOption,
        heading,
        isMobileView,
        withBackdrop,
        onSelect,
        search,
        onOpen,
        onClose,
        ariaLabel,
        ariaLabelledBy,
        optionsContainerAriaLabel,
        headerTextClassName,
        whenEmptyHeader,
        whenEmptySubheader,
        testid,
        noResultMsg,
        rewordMsg,
    } = props;
    
    const headerClassNames = cx(
        `${baseClassName}__header`,
        `${headerClassName}`,
        {[`${baseClassName}__header--highlighted`]: isHighlighted},
    );
    
    const optionsContainerClassNames = {
        container: `${baseClassName}__container-overrides`,
        menu: `${baseClassName}__menu-overrides`,
    };
    return (
        <SimpleDropdown
            className={cx(baseClassName, className)}
            dropdownDefaultRenderClassNames={{header: headerClassNames}}
            headerTextClassName={headerTextClassName}
            heading={heading}
            isMobileView={isMobileView}
            withBackdrop={withBackdrop}
            onSelect={onSelect}
            onOpen={onOpen}
            onClose={onClose}
            options={options}
            optionsContainerClassNames={optionsContainerClassNames}
            optionsVariant={OptionVariant.ACCENTED}
            search={search}
            selectedOption={selectedOption}
            ariaLabel={ariaLabel}
            ariaLabelledBy={ariaLabelledBy}
            optionsContainerAriaLabel={optionsContainerAriaLabel}
            whenEmptyHeader={whenEmptyHeader}
            whenEmptySubheader={whenEmptySubheader}
            testid={testid}
            noResultMsg={noResultMsg}
            rewordMsg={rewordMsg}
        />
    );
}

export default AccentedDropdown;
