import React from "react";

function Support(props) {
    return (<svg viewBox="0 0 20 20" width="20" height="20" fill="none" {...props}>
        <path d="M9.99984 18.3334C14.6022 18.3334 18.3332 14.6025 18.3332 10.0001C18.3332 5.39771 14.6022 1.66675 9.99984 1.66675C5.39746 1.66675 1.6665 5.39771 1.6665 10.0001C1.6665 14.6025 5.39746 18.3334 9.99984 18.3334Z" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.99984 13.3334C11.8408 13.3334 13.3332 11.841 13.3332 10.0001C13.3332 8.15913 11.8408 6.66675 9.99984 6.66675C8.15889 6.66675 6.6665 8.15913 6.6665 10.0001C6.6665 11.841 8.15889 13.3334 9.99984 13.3334Z" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.10791 4.1084L7.64124 7.64173" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.3584 12.3583L15.8917 15.8916" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.3584 7.64168L15.3001 4.70001" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.10791 15.8916L7.64124 12.3583" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>);
}

export default Support;
