import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M4.11783 4.11143C4.83137 3.39533 5.72882 2.89021 6.7112 2.65176C7.69358 2.41331 8.72274 2.4508 9.68517 2.7601C9.89557 2.82728 10.124 2.80814 10.3203 2.70687C10.5166 2.6056 10.6646 2.4305 10.7318 2.2201C10.799 2.00969 10.7799 1.78121 10.6786 1.58493C10.5773 1.38864 10.4022 1.24062 10.1918 1.17343C8.89465 0.758385 7.50606 0.721528 6.18869 1.06718C4.87131 1.41282 3.67969 2.12667 2.75337 3.12511C1.82706 4.12355 1.20439 5.36525 0.958298 6.7048C0.712206 8.04434 0.852875 9.42628 1.36383 10.6888C1.37811 10.7242 1.37966 10.7635 1.36824 10.8C1.35681 10.8364 1.33311 10.8678 1.30117 10.8888L0.367832 11.5034C0.257407 11.5758 0.17097 11.6794 0.119482 11.801C0.0679936 11.9226 0.0537737 12.0567 0.0786253 12.1864C0.103477 12.3161 0.166281 12.4355 0.259072 12.5294C0.351864 12.6234 0.470464 12.6876 0.599832 12.7141L3.53783 13.3194C3.58169 13.3286 3.62636 13.3333 3.67117 13.3334C3.82474 13.3333 3.97356 13.2802 4.09248 13.183C4.2114 13.0858 4.29312 12.9506 4.32383 12.8001L4.93317 9.86276C4.95973 9.73365 4.94742 9.59957 4.8978 9.47745C4.84818 9.35533 4.76347 9.25067 4.65438 9.17668C4.54529 9.10269 4.41671 9.0627 4.2849 9.06177C4.15309 9.06084 4.02396 9.099 3.91383 9.17143L3.02117 9.75876C2.99966 9.77296 2.97516 9.78197 2.94959 9.7851C2.92401 9.78823 2.89806 9.78538 2.87377 9.77679C2.84948 9.7682 2.82752 9.75409 2.8096 9.73558C2.79168 9.71707 2.7783 9.69465 2.7705 9.6701C2.46514 8.70895 2.4293 7.68246 2.66686 6.70236C2.90442 5.72225 3.40629 4.82609 4.11783 4.11143Z"
        />
        <path
            d="M15.9222 3.88778C15.9001 3.75787 15.8399 3.63743 15.7494 3.54168C15.6589 3.44592 15.542 3.37914 15.4135 3.34978L12.4882 2.68311C12.4027 2.66338 12.3142 2.6607 12.2277 2.67523C12.1412 2.68975 12.0585 2.7212 11.9841 2.76777C11.9098 2.81434 11.8454 2.87512 11.7946 2.94662C11.7439 3.01812 11.7077 3.09894 11.6882 3.18445L11.0215 6.10911C10.9922 6.23765 11.0017 6.372 11.0487 6.49516C11.0958 6.61832 11.1783 6.72477 11.2858 6.80104C11.3933 6.87732 11.5211 6.92 11.6528 6.9237C11.7846 6.92739 11.9145 6.89192 12.0262 6.82178L12.9882 6.21778C13.0094 6.20377 13.0335 6.19486 13.0587 6.19174C13.0839 6.18861 13.1095 6.19137 13.1335 6.19978C13.1574 6.20856 13.1791 6.22266 13.1967 6.24102C13.2144 6.25939 13.2277 6.28153 13.2355 6.30578C13.5461 7.26397 13.5875 8.28905 13.3553 9.26919C13.1231 10.2493 12.6262 11.1469 11.9188 11.8639C11.2113 12.5809 10.3205 13.0898 9.34359 13.3351C8.36666 13.5805 7.34112 13.5528 6.37885 13.2551C6.27342 13.2195 6.16193 13.2054 6.05096 13.2136C5.93999 13.2218 5.83179 13.2521 5.73273 13.3028C5.63367 13.3535 5.54577 13.4235 5.47421 13.5087C5.40264 13.5939 5.34887 13.6926 5.31605 13.7989C5.28324 13.9052 5.27205 14.0171 5.28315 14.1278C5.29424 14.2385 5.3274 14.3459 5.38066 14.4436C5.43393 14.5413 5.50622 14.6273 5.59327 14.6966C5.68032 14.7659 5.78037 14.8171 5.88751 14.8471C7.18102 15.2472 8.56139 15.2726 9.86877 14.9205C11.1761 14.5684 12.357 13.8532 13.2747 12.8576C14.1923 11.862 14.8091 10.6269 15.0538 9.29521C15.2984 7.96353 15.1608 6.5898 14.6568 5.33311C14.6429 5.29723 14.642 5.25762 14.6542 5.22111C14.6663 5.1846 14.6909 5.15348 14.7235 5.13311L15.6208 4.56911C15.7332 4.49844 15.8219 4.39585 15.8756 4.27447C15.9292 4.15309 15.9455 4.01844 15.9222 3.88778Z"
        />
    </svg>
  );
}

export default SvgComponent;