import * as React from "react";

function Camera(props) {
  return (
  <svg viewBox="0 0 24 24" fill={"green"} {...props}>
      <path d="M20.131,0H3.869A3.873,3.873,0,0,0,0,3.87V20.13A3.873,3.873,0,0,0,3.869,24H20.131A3.873,3.873,0,0,0,24,20.13V3.87A3.873,3.873,0,0,0,20.131,0ZM22,20.13A1.872,1.872,0,0,1,20.131,22H3.869A1.872,1.872,0,0,1,2,20.13V3.87A1.872,1.872,0,0,1,3.869,2H20.131A1.872,1.872,0,0,1,22,3.87Z"/><path d="M17.239,8.838a.5.5,0,0,0-.51.02l-1.843,1.194a.25.25,0,0,1-.386-.21V8.5A.5.5,0,0,0,14,8H7a.5.5,0,0,0-.5.5v7A.5.5,0,0,0,7,16h7a.5.5,0,0,0,.5-.5V14.158a.25.25,0,0,1,.386-.21l1.843,1.194a.5.5,0,0,0,.771-.42V9.278A.5.5,0,0,0,17.239,8.838Z"/>
    </svg>
  );
}

export default Camera;





