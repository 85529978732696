import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setRequestTrackerAC } from "@/messenger/actions/requestTracker";
import { RequestURIs } from "@/messenger/constants/requests";
import { GraphQLUrl } from "@/messenger/constants/urls";
import { setCustomer } from "@/messenger/ducks/entities/customers";
import { findCustomer } from "@/messenger/graphql/queries";
import { createRequestTracker } from "@/messenger/models/requestTracker";
import { Query } from "@/messenger/types/graphql/autogenerated";
import { normalizeFindCustomerResponse } from "@/messenger/utils/normalize";

const GetCustomer = () => {
    const dispatch = useDispatch();
    const [skipQuery, setSkipQuery] = useState(false);

    useQuery(findCustomer, {
        skip: skipQuery,
        onCompleted: (data: { findCustomer: Query[GraphQLUrl.findCustomer]}) => {
            try {
                const normalizedData = normalizeFindCustomerResponse(data.findCustomer);
                dispatch(setCustomer(normalizedData.entities));
                
                dispatch(setRequestTrackerAC(createRequestTracker(RequestURIs.GET_CUSTOMER, {
                    complete: true,
                    loading: false,
                    sent: true,
                })));
                
                setSkipQuery(true);
            } catch (e) {
                console.error(e);
            }
        },
    });
    
    useEffect(() => {
        dispatch(setRequestTrackerAC(createRequestTracker(RequestURIs.GET_CUSTOMER, {
            complete: false,
            loading: true,
            sent: true,
        })));
    }, []);
    
    return null;
};
export default GetCustomer;