import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import authReducer from "./auth";
import entitiesReducer from "./entities";
import generalReducer from "./general";
import requestTrackerReducer from "./requestTrackers";
import sdkReducer from "./sdk";
import userInterfaceReducer from "./userInterface";
// import widgetClientReducer from "./widgetClient";


const rootReducer = (history) => combineReducers({
    auth: combineReducers({ ...authReducer }),
    general: combineReducers({ ...generalReducer }),
    router: connectRouter(history),
    requests: requestTrackerReducer,
    sdk: combineReducers({ ...sdkReducer }),
    // widgetClient: widgetClientReducer,
    entities: combineReducers({ ...entitiesReducer }),
    userInterface: combineReducers({ ...userInterfaceReducer }),
});

export default rootReducer;
