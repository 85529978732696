import "@/components/styles/screen.scss";
import "./App.scss";

import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import { setTheme } from "@/components/whiteLabel/whiteLabel.service";
import { DEFAULT_THEME } from "@/components/whiteLabel/whiteLabel.settings";
import { getTheme } from "@/components/whiteLabel/whiteLabel.styled.service";
import { DefaultTheme } from "@/components/whiteLabel/whiteLabel.types";
import { useAuth0, useUpdateMetadata } from "@/messenger/providers/Auth0Provider";

import LanguageToggle from "./components/LanguageToggle/LanguageToggle";
import { representativeSettingsSelectors } from "./ducks/entities/representativeSettings";
import { setDeviceType } from "./ducks/general/deviceType.duck";
import { setHasLogoAC } from "./ducks/general/hasLogo.duck";
import { deviceTypeSelector } from "./ducks/selectors/general";
import { useFetchUserMetadata } from "./hooks/useFetchUserMetadata";
import { useMessengerAuthorize } from "./hooks/useMessengerAuthorize";
import GlobalRouter from "./routes";
import { getDeviceType } from "./utils/deviceType";
import { isTestEnv } from "./utils/helpers";
// TODO: remove when loader component is implemented
const loadingStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
};


function App() {
    const [styledTheme, setStyledTheme] = useState<DefaultTheme | null>(null);
    const [isThemeLoaded, setThemeLoaded] = useState<boolean>(false);
    const auth0Client = useAuth0();
    const auth0Credentials = useMessengerAuthorize(auth0Client);
    const [accessToken, userMetadata, setUserMetadata, metadataUrl] = useFetchUserMetadata();

    const deviceType = useSelector(deviceTypeSelector);
    const dispatch = useDispatch();

    const retrievedRepSettings = useSelector(representativeSettingsSelectors.selectAll);
    const representativeSettings = retrievedRepSettings.length >0 && retrievedRepSettings[0];
    const locale = representativeSettings.locale ?? "en_CA";

    useEffect( () => {
        if (i18next.language !== locale.substring(0,2)) {
            i18next.changeLanguage(locale.substring(0,2));
        };
    }, [locale]);

    const onMetadataChange = (metadata: any) => {
        setUserMetadata(metadata);
    };
    const updateMetadata = useUpdateMetadata(metadataUrl, accessToken, onMetadataChange, auth0Client.user, userMetadata);

    const updateDeviceType = () => {
        const currentDeviceType = getDeviceType();
        if (currentDeviceType !== deviceType) {
            dispatch(setDeviceType(currentDeviceType));
        }
    };

    useEffect(() => {
        const delay = 300;
        let timeout = null;

        window.addEventListener("resize", () => {
            // clear the timeout
            if (timeout) {
                clearTimeout(timeout);
            }
            // start timing for event "completion"
            timeout = setTimeout(updateDeviceType, delay);
        });

        dispatch(setDeviceType(getDeviceType()));

        return function updateDeviceTypeCleanup() {
            window.removeEventListener("resize", updateDeviceType);
        };
    }, []);

    useEffect(() => {
        async function themeSetter() {
            setStyledTheme(getTheme(DEFAULT_THEME));
            if (DEFAULT_THEME.logotype !== null) {
                dispatch(setHasLogoAC(true));
            }
            setTheme(DEFAULT_THEME);
            setThemeLoaded(true);
        }

        themeSetter();
    }, []);

    if (!auth0Client.isReady && !isThemeLoaded) {
        return (
            <div style={loadingStyle}>
                <h2>Loading...</h2>
            </div>
        );
    }
    if (auth0Client.isReady && isThemeLoaded) {
        return (
            <ThemeProvider theme={styledTheme}>
                {isTestEnv && <LanguageToggle />}
                <GlobalRouter
                    userMetadata={userMetadata}
                    updateMetadata={updateMetadata}
                    auth0Credentials={auth0Credentials}
                />
            </ThemeProvider>
        );
    }

    return null;
}

export default App;
