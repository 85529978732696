import { cancel, spawn, takeEvery } from "redux-saga/effects";

import { SUBSCRIBE_EVENT_CHANNEL, subscribeEventChannelAC, UNSUBSCRIBE_ALL_EVENT_CHANNEL } from "@/messenger/actions/eventChannel";


interface SubscriptionStore {
    [actionType: string]: {
        [componentId: string]: Subscription
    }
}

interface Subscription {
    saga: ReturnType<typeof spawn>
}

interface SubscriptionIdentifier {
    actionType: string,
}

interface SubscriptionsByID {
    [componentId: string]: SubscriptionIdentifier[]
}

function* handleSubscribe(action: ReturnType<typeof subscribeEventChannelAC>, subscriptionStore, subscribers: SubscriptionsByID) {
    const { actionType, componentId, fn } = action.payload;
    
    if (!subscriptionStore[actionType]) {
        subscriptionStore[actionType] = {};
    }

    const subscriptionSaga = yield spawn(function* () {
        yield takeEvery(actionType, fn);
    });

    subscriptionStore[actionType][componentId] = {
        saga: subscriptionSaga,
    };

    if (!subscribers[componentId]) {
        subscribers[componentId] = [];
    }

    subscribers[componentId].push({
        actionType,
    });
}

function* handleUnsubscribe(action: ReturnType<typeof subscribeEventChannelAC>, subscriptionStore, subscribers: SubscriptionsByID) {
    const { componentId } = action.payload;
    const subscriptions = subscribers[componentId];

    Object.keys(subscriptions).forEach(yield function*(key) {
        const { actionType } = subscriptions[key];

        const subscription = subscriptionStore[actionType][componentId];
            yield cancel(subscription.saga);

            delete subscriptionStore[actionType[componentId]];
    }); 
}

/**
 * Allows subscriptions to redux events to be initiated and terminated. When the given redux event occurs, calls the associated callback function.
 * 
 * For example: `dispatch(subscribeEventChannelAC(MESSAGE_SENT, componentId, scrollToBottom));`
 * 
 * creates a subscription listening for the MESSAGE_SENT event, upon receiving the event the subscription will call scrollToBottom()
*/
export default function* eventChannel() {
    // contains all subscriptions by action type
    const subscriptionStore: SubscriptionStore = {};
    // contains all subscriptions by subscriber id
    const subscribers: SubscriptionsByID = {};

    yield takeEvery(SUBSCRIBE_EVENT_CHANNEL, function*(action: ReturnType<typeof subscribeEventChannelAC>) {
        return yield handleSubscribe(action, subscriptionStore, subscribers);
    });

    yield takeEvery(UNSUBSCRIBE_ALL_EVENT_CHANNEL, function*(action: ReturnType<typeof subscribeEventChannelAC>) {
        return yield handleUnsubscribe(action, subscriptionStore, subscribers);
    });
}


