import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateAllWidgetContextsAC } from "src/sagas/widget";

import { setRequestTrackerAC } from "@/messenger/actions/requestTracker";
import { listenForConvoLoadCompleteAC } from "@/messenger/actions/twilioChannel";
import { localStorageKeys } from "@/messenger/app.settings";
import { RequestURIs } from "@/messenger/constants/requests";
import { GraphQLUrl } from "@/messenger/constants/urls";
import { upsertContacts } from "@/messenger/ducks/entities/contacts";
import { upsertConversations } from "@/messenger/ducks/entities/conversations";
import { upsertTopics } from "@/messenger/ducks/entities/topics";
import { createChatState, upsertManyChats } from "@/messenger/ducks/userInterface/chats";
import { FindAllConversations } from "@/messenger/graphql/queries";
import { createRequestTracker } from "@/messenger/models/requestTracker";
import { Query } from "@/messenger/types/graphql/autogenerated";
import { getFromLocalStorage } from "@/messenger/utils/helpers";
import { normalizeFindAllConversationsResponse } from "@/messenger/utils/normalize";


const GetConversations = () => {
    const dispatch = useDispatch();
    const [skipQuery, setSkipQuery] = useState(false);


    useQuery(FindAllConversations, {
        skip: skipQuery,
        errorPolicy: "ignore",
        onCompleted: (data: { findAllConversations: Query[GraphQLUrl.findAllConversations]}) => {
            try {
                const sanitizedConversations = data.findAllConversations.filter(o => o !== null && o.topic !== null);

                const normalizedData = normalizeFindAllConversationsResponse(sanitizedConversations);
                if(Object.keys(normalizedData.entities.conversations || []).length){
                    dispatch(upsertConversations(normalizedData.entities));
                    
                    const chatStates = Object.entries(normalizedData.entities.conversations).map(([key, conversation]) => {
                        const unreadObj = getFromLocalStorage(localStorageKeys.unreadMessages) || {};
                        const unreadCount = unreadObj[key];
                        return {
                            ...createChatState({ 
                                id: key, 
                                unreadMessagesCount: unreadCount,
                                contact: conversation.contact,
                                hydration: conversation.hydration,
                            }), 
                        };
                    });
                    
                    dispatch(upsertManyChats(chatStates));
                }
                if(Object.keys(normalizedData.entities.contacts || []).length){
                    dispatch(upsertContacts(normalizedData.entities));
                }
                if(Object.keys(normalizedData.entities.topics || []).length){
                    dispatch(upsertTopics(normalizedData.entities));
                }
                dispatch(setRequestTrackerAC(createRequestTracker(RequestURIs.GET_CONVERSATIONS, {
                    complete: true,
                    loading: false,
                    sent: true,
                })));

                dispatch(listenForConvoLoadCompleteAC());

                // accounts for race condition case where widgets load before conversation, they receive context once convos finish loading.
                dispatch(updateAllWidgetContextsAC());

                setSkipQuery(true);
            } catch (e) {
                console.error(e);
            }
        },
    });

    useEffect(() => {
        dispatch(setRequestTrackerAC(createRequestTracker(RequestURIs.GET_CONVERSATIONS, {
            complete: false,
            loading: true,
            sent: true,
        })));
    }, []);

    return null;
};

export default GetConversations;