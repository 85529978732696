import "./index.scss";

import cx from "classnames";
import React from "react";

import icons from "@/components/icons";
import { TMessageDeliveryStatus } from "@/messenger/types/message";
import { handleOnEnter } from "@/messenger/utils/helpers";

interface TVideoViewProps {
    url: string;
    status: TMessageDeliveryStatus;
    onLoad: () => void;
}

export const VideoView = (props: TVideoViewProps) => {
    const openVideo = () => {
        if (props.status !== TMessageDeliveryStatus.NOT_DELIVERED) window.open(props.url);
    };
    return (
        <div
            className={"c-video-view-container"}
            onClick={openVideo}
            onKeyDown={e => handleOnEnter(e, openVideo)}
        >
            <video
                onLoadedData={props.onLoad}
                className={cx("c-video-view", `c-video-view--${props.status}`)}
                src={props.url}
            />
            <div className={"c-video-view__play"}>
                <div className={"c-video-view__play-body"}>{icons.PLAY}</div>
            </div>
        </div>
    );
};

export default VideoView;
