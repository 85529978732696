import conversationRefinementsReducer from "./conversationRefinementsReducer.duck";
import currentEditableTemplateIdReducer from "./currentEditableTemplateId.duck";
import currentSelectedFieldNameReducer from "./currentSelectedFieldName.duck";
import deviceTypeReducer from "./deviceType.duck";
import errorsReducer from "./errors.duck";
import hasLogoReducer from "./hasLogo.duck";
import isAddAContactOpenReducer from "./isAddAContactOpen.duck";
import isOnlineReducer from "./isOnline.duck";
import notificationsReducer from "./notifications.duck";
import promptReducer from "./prompts.duck";
import selectedCategoryReducer from "./selectedCategory.duck";
import tertiaryCategoryReducer from "./tertiaryCategory.duck";
import widgetTabReducer from "./widgetTab.duck";

const generalReducer = {
    conversationRefinements: conversationRefinementsReducer,
    currentEditableTemplateId: currentEditableTemplateIdReducer,
    currentSelectedFieldName: currentSelectedFieldNameReducer,
    deviceType: deviceTypeReducer,
    errors: errorsReducer,
    hasLogo: hasLogoReducer,
    isOnline: isOnlineReducer,
    isAddAContactOpen: isAddAContactOpenReducer,
    notifications: notificationsReducer,
    prompts: promptReducer,
    selectedCategory: selectedCategoryReducer,
    tertiaryCategory: tertiaryCategoryReducer,
    widgetTab: widgetTabReducer,
};

export default generalReducer;
