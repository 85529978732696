import "./Badge.scss";

import cx from "classnames";
import React, { PureComponent } from "react";

import { BadgeColor } from "./badgeColor.enum";

interface BadgeDefaultProps {
    /** BadgeColor { PRIMARY = 'primary', MAIN = 'main', INFO = 'info', SUCCESS = 'success', WARNING = 'warning', ERROR = 'error' } */
    color?: BadgeColor;
}

interface BadgeProps extends BadgeDefaultProps {
    children: string | number | React.ReactElement;
    className?: string;
    style?: any;
}

class Badge extends PureComponent<BadgeProps> {
    baseClassName = "e-badge";

    static defaultProps: BadgeDefaultProps = {
        color: BadgeColor.PRIMARY,
    };

    getClassName(): string {
        const c = this.baseClassName;
        return cx(
            c,
            `${c}--${this.props.color}`,
            this.props.className,
        );
    }

    render(): React.ReactNode {
        return (
            <span style={this.props.style} className={this.getClassName()}>
                {this.props.children}
            </span>
        );
    }
}

export default Badge;
