import "./index.scss";
import "../../../locales/i18n";

import cx from "classnames";
import IframeResizer from "iframe-resizer-react";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import Button from "@/components/components/Button/Button";
import { ButtonColor, ButtonSize } from "@/components/components/Button/button.enum";
import icons from "@/components/icons";
import { onWidgetError, WidgetErrorComponent } from "@/messenger/containers/WidgetContainer";
import { TNodeOffsets, TSendableWidgetState } from "@/messenger/types/store.types";
import { widgetContainerClient } from "@/messenger/widgets";
import { WidgetState } from "@/messenger/widgets/types";

interface TWidgetProps {
    widgetState: TSendableWidgetState
    offsets: TNodeOffsets
}

const baseClassName = "c-widget";

export const getWidgetContainerId = (name: string) => `${name.replace(/ /g,"_")}--container`;

const SendableWidget = ({ widgetState, offsets }: TWidgetProps) => {
    const { id, url, isShown } = widgetState;
    const { width, height, top, left } = offsets;

    const { t } = useTranslation();

    const classNames = cx(
        `${baseClassName}`, `${baseClassName}__sendable`,
    );
    const widgetContainerClassName = cx(
        `${baseClassName}__widget-container`,
    );
    const headerClassName = cx(
        `${baseClassName}__header`, `${baseClassName}__sendable-header`,
    );
    const headerTextClassName = cx(
        `${baseClassName}__header-text`, `${baseClassName}__sendable-header-text`,
    );

    const sendableWidgetStyles: React.CSSProperties = isShown ? {
        width,
        height,
        top,
        left,
        position: "fixed",
    } : {};

    const handleWidgetClose = () => {
        widgetContainerClient.setState(id, WidgetState.isShown, false);
    };

    const hideWidget = !isShown ? { height: 0, overflow: "hidden" } : {};

    return <div style={hideWidget}>
        <div key={id} className={classNames} style={sendableWidgetStyles}>
            <header className={headerClassName}>
                <span className={headerTextClassName}>
                    {id}
                </span>
                <Button
                    id={"CLOSE_SENDABLE_WIDGET_BUTTON"}
                    className={`${headerClassName}__close-button`}
                    color={ButtonColor.SECONDARY}
                    icon={icons.CLOSE}
                    size={ButtonSize.REGULAR}
                    onClick={handleWidgetClose}
                    ariaLabel={t("Close Sendable widget")}
                />
            </header>
            <div className={widgetContainerClassName}  id={getWidgetContainerId(id)}>
            <ErrorBoundary FallbackComponent={WidgetErrorComponent} onError={onWidgetError}>
                <IframeResizer
                    className={`${baseClassName}__iframe`}
                    seamless
                    src={url} 
                    id={id}
                    title={id}
                    name={id}
                    />
            </ErrorBoundary>
            </div>
        </div>
    </div>;
};

export default SendableWidget;