import "./index.scss";
import "../../../locales/i18n";

import cx from "classnames";
import IframeResizer from "iframe-resizer-react";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import Button from "@/components/components/Button";
import { ButtonColor } from "@/components/components/Button/button.enum";
import icons from "@/components/icons";
import { onWidgetError, WidgetErrorComponent } from "@/messenger/containers/WidgetContainer";
import { TNodeOffsets, TStandardWidgetState } from "@/messenger/types/store.types";
import { handleOnEnter } from "@/messenger/utils/helpers";
import { widgetContainerClient } from "@/messenger/widgets";
import { WidgetViewSize } from "@/messenger/widgets/api";
import { WidgetState } from "@/messenger/widgets/types";

interface TWidgetProps {
    widgetState: TStandardWidgetState
    offsets: TNodeOffsets
    widgetWrapperStyle: React.CSSProperties
}

const baseClassName = "c-widget";

export const getWidgetContainerId = (name: string) => `${name.replace(/ /g,"_")}--container`;

const StandardWidget = ({ widgetState, offsets, widgetWrapperStyle }: TWidgetProps) => {
    const { id, url, header, footer, size } = widgetState;
    const { t } = useTranslation();

    const isLargeView = size === WidgetViewSize.Large;
    const isMediumView = size === WidgetViewSize.Medium;
    const isSmallView = size === WidgetViewSize.Small;

    const { width, height, top, left } = offsets;
    
    const onClickFooter = () => {
        const nextSize = size === WidgetViewSize.Large ? WidgetViewSize.Medium : WidgetViewSize.Large;
        widgetContainerClient.setState(id, WidgetState.size, nextSize);
    };

    const onClickHeader = () => {
        const nextSize = size === WidgetViewSize.Small ? WidgetViewSize.Medium : WidgetViewSize.Small;

        widgetContainerClient.setState(id, WidgetState.size, nextSize);
    };


    const classNames = cx(
        {[`${baseClassName}`]: true},
        {[`${baseClassName}__maximized`]: isLargeView},
    );

    const standardWidgetStyles: React.CSSProperties = isLargeView ? {
        width,
        height,
        top,
        left: left + 1,
    } : {};

    const smallViewStyles: React.CSSProperties = isSmallView ? {
        height: 0,
    } : {};

    const headerClassName = cx(
        {[`${baseClassName}__header`]: true},
        {[`${baseClassName}__header__maximized`]: isLargeView},
    );

    const BackButton = (_) => {
        return <Button
            id={"MINIMIZE_STANDARD_WIDGET_BUTTON"}
            icon={icons.ANGLE_LEFT}
            color={ButtonColor.SECONDARY}
            className={`${baseClassName}__back-button`}
            onClick={onClickFooter}
            ariaLabel={t("Minimize Widget")}
        >
            Back
        </Button>;
    };

    const shortenText = (text: string) => {
        if (!text) {
            return "";
        }
        return text.length <= 40 ? text : `${text.substring(0, 37)}...`;
    };

    const shortenedHeader = shortenText(header);

    const headerTextClassName = cx(
        {[`${baseClassName}__header-text`]: true},
        {[`${baseClassName}__header-text__right`]: shortenedHeader.length > 35 && isLargeView},
        {[`${baseClassName}__header-text__not-full`]: !isLargeView},
    );

    const widgetContainerClassName = cx(
        {[`${baseClassName}__widget-container`]: true},
        {[`${baseClassName}__widget-container__compact`]: isMediumView},
    );

    return <div style={widgetWrapperStyle}>
        <div className={classNames} style={standardWidgetStyles} key={id}>
            <div
                id={"STANDARD_WIDGET_HEADER"}
                className={headerClassName} 
                onClick={isMediumView || isSmallView ? onClickHeader : null}
                onKeyDown={e => handleOnEnter(e, () => (isMediumView || isSmallView) ? onClickHeader() : null)}
                tabIndex={0}
            >
                {isLargeView && <BackButton />}
                {<div className={headerTextClassName}>{shortenedHeader}</div>}
                {isMediumView ? <div className={`${baseClassName}__arrow`}>{icons.ANGLE_UP}</div> : null}
                {isSmallView ? <div className={`${baseClassName}__arrow`}>{icons.ANGLE_RIGHT}</div>: null}
            </div>
            <div className={widgetContainerClassName} style={smallViewStyles} id={getWidgetContainerId(id)}>
                <ErrorBoundary FallbackComponent={WidgetErrorComponent} onError={onWidgetError}>
                    <IframeResizer
                        className={`${baseClassName}__iframe`}
                        seamless
                        src={url} 
                        id={id}
                        title={id}
                        name={id}
                        />
                </ErrorBoundary>
            </div>
            {isMediumView && footer && (
                <div
                    id={"STANDARD_WIDGET_FOOTER"}
                    className={`${baseClassName}__footer`}
                    onClick={onClickFooter}
                    onKeyDown={e => handleOnEnter(e, onClickFooter)}
                    tabIndex={0}
                >
                    {footer}
                </div>
            )}
        </div>
    </div>;
};

export default StandardWidget;