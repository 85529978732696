import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { redirectToLogoutAC } from "@/messenger/actions/saga";
import { cookieKeys } from "@/messenger/app.settings";
import { IDLE_TIMEOUT } from "@/messenger/constants/auth";
import { throttle } from "@/messenger/utils/helpers";

const UserActivityEvents = [
    "mousemove",
    "keydown",
    "wheel",
    "DOMMouseScroll",
    "mousewheel",
    "mousedown",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove",
    "visibilitychange",
    ];

const setTimeCookie = () => {
    Cookies.set(cookieKeys.activeTime, Date.now().toString());
};

const userIsIdle = () => {
    const idleCookie = Number(Cookies.get(cookieKeys.activeTime));
    return (Date.now() - idleCookie) > IDLE_TIMEOUT;
};


const UserActivityDetector = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeCookie();

        const resetTime = throttle(setTimeCookie, 60 * 1000);

        UserActivityEvents.forEach((eventActivity) => {
            window.addEventListener(eventActivity, resetTime);
        });

        const idleChecker = () => {
            const isIdle = userIsIdle();

            if (isIdle) {
                dispatch(redirectToLogoutAC());
            }
        };
        const idleCheckerInterval = setInterval(idleChecker, 2000);

        return () => {
            UserActivityEvents.forEach((eventActivity) => {
                window.removeEventListener(eventActivity, resetTime);
            });

            clearInterval(idleCheckerInterval);
        };
    }, []);

    return null;
};

export default UserActivityDetector;