import { TStore } from "@/messenger/types/store.types";

export const generalSelector = (state: TStore) => state.general;
export const deviceTypeSelector = (state: TStore) => generalSelector(state).deviceType;

export const currentEditableTemplateId = (state: TStore) => generalSelector(state).currentEditableTemplateId;
export const currentSelectedFieldName = (state: TStore) => generalSelector(state).currentSelectedFieldName;

export const conversationRefinementSelector = (state: TStore) => generalSelector(state).conversationRefinements;

export const hasLogoSelector = (state: TStore) => generalSelector(state).hasLogo;
export const selectedCategorySelector = (s: TStore) => generalSelector(s).selectedCategory;
export const tertiaryCategorySelector = (s: TStore) => generalSelector(s).tertiaryCategory;

export const widgetTabSelector = (s: TStore) => generalSelector(s).widgetTab;