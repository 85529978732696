import "../locales/i18n";

import i18next from "i18next";
import * as localeHelper from "locale-codes";
import React from "react";
import * as Yup from "yup";

import { TStore } from "../types/store.types";

const { t } = i18next;

export const mapStateToProps = (state: TStore) => ({ state });

export const ellipsis = (text: string, size: number): string =>
    text && text.length > size ? `${text.slice(0, size)  }...` : text;

export const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const setToLocalStorage = (key: string, item: any): void => localStorage.setItem(key, JSON.stringify(item));

export const getFromLocalStorage = (key: string): any => JSON.parse(localStorage.getItem(key));

export const removeFromLocalStorage = (key: string): void => localStorage.removeItem(key);

export const generateUUID = () =>
    // eslint-disable-next-line no-return-assign
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c, r) =>
        // eslint-disable-next-line no-bitwise
        (c === "x" ? (r = (Math.random() * 16) | 0) : (r & 0x3) | 0x8).toString(16),
    );

export const getFileDataUrl = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = (e: ProgressEvent<FileReader>) => {
            resolve(e.target.result as string);
        };
        fr.onerror = (e: ProgressEvent<FileReader>) => {
            reject(e);
        };
        fr.readAsDataURL(file);
    });
};

export const isLocalEnv = process.env.TARGET === "local";
export const isDevEnv = process.env.TARGET === "development";
export const isSeedEnv = process.env.SEED === "seed";
export const isWidgetTestEnv = process.env.WIDGET === "widget";
export const isTestEnv = window.location.href.includes("localhost") || window.location.href.includes(".dev.") || window.location.href.includes(".rc.");

export const isWidgetInstanceOfTextKit = isSeedEnv && window.name !== "";

export const modulesEnabled = true;

export const makeCancelable = (promise: Promise<any>) => {
    let hasCancelled = false;
  
    const wrappedPromise = new Promise((resolve, reject) => {
      promise.then(
        // eslint-disable-next-line prefer-promise-reject-errors
        val => hasCancelled ? reject({isCanceled: true}) : resolve(val),
        // eslint-disable-next-line prefer-promise-reject-errors
        error => hasCancelled ? reject({isCanceled: true}) : reject(error),
      );
    });
  
    return {
      promise: wrappedPromise,
      cancel() {
        hasCancelled = true;
      },
    };
  };


/* eslint-disable  @typescript-eslint/no-implied-eval */
export const executeStringifiedJS = (stringJS: string) => () => setTimeout(stringJS, 0);

export const throttle = (fn: (params: any) => any, throttleDuration: number) => {
    let coolingDown = false;
    
    return (args?: any) => {
        if (!coolingDown) {
            fn(args);
            coolingDown = true;
            setTimeout(() => {coolingDown = false;}, throttleDuration);

        }
    };
};

export const convertToNumbers = (string: string) => {
    return string.replace(/\+|\(|\)|ext:|\s|-/g, "");
};


export const getDomainHost = () => {
    return window.location.host;
};

export const handleOnEnter = (e: React.KeyboardEvent, fn: () => void) => 
    (e.key === "Enter" || e.key === " ") && fn();

interface Actions {
    enter?: () => void,
    esc?: () => void,
    tab?: () => void,
    shiftTab?: () => void,
};

export const handleOnKeyDown = (e: React.KeyboardEvent, actions: Actions) => {
    Object.entries(actions).forEach(([key, value]) => {
        switch (key) {
            case "enter":
                handleOnEnter(e, value);
                break;
            case "esc":
                if(e.key === "Escape") value();
                break;
            case "tab":
                if (e.key === "Tab" && !e.shiftKey) value();
                break;
            case "shiftTab":
                if (e.key === "Tab" && e.shiftKey) value();
                break;
            default: break;
        }
    });
};

export const editContactValidation = Yup.object().shape({
    firstname: Yup.string().max(200).required(t("First name is required.")),
    lastname: Yup.string().max(200).required(t("Last name is required.")),
    language: Yup.string(),
}); 

export const addContactValidation= Yup.object().shape({
    firstname: Yup.string().matches(/^[A-Za-z0-9 ]+$/g, t("Special characters are not permitted for entry.")).required(t("First name is required.")),
    lastname: Yup.string().matches(/^[A-Za-z0-9 ]+$/g, t("Special characters are not permitted for entry.")),
    phoneNumber: Yup.string().matches(/^\+[1-9]\d{10}$/g, t("Phone number must follow the format +1xxxxxxxxxx.")).required(t("Phone number is required.")),
}); ;

export const getLocaleLabelFromCode = (localeCode: string, includeCountry: boolean) => {
    try{
        const localeParts = localeCode.split("_");
        if(localeParts.length < 2){
            return localeCode;
        }
        const localeKey = `${localeParts[0]}-${localeParts[1]}`;
        const locale = localeHelper.getByTag(localeKey);
        if(includeCountry){
            return locale?.location ? `${locale.name} (${locale.location})` : locale.name;
        }
            return locale.name;
        
    }catch (e){
        return localeCode;
    }
};