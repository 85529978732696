import { getColorBrightness } from "./whiteLabel.helper";
import { DEFAULT_THEME } from "./whiteLabel.settings";
import {
    BACKGROUND_COLOR_TYPE, DefaultTheme, TBackgroundTheme, THSL, TPrimaryTheme, TSidebarTheme,
    TTextTheme, TTheme,
} from "./whiteLabel.types";

function getPrimaryColors(hsl: THSL, textHSL: THSL): TPrimaryTheme {
    // Background brightness threshold to switch between black/white text color
    const COLOR_BRIGHTNESS_THRESHOLD = 128;

    const getTextLightness = (lightness: number) => {
        const brightness = getColorBrightness({ ...hsl, lightness });
        return brightness >= COLOR_BRIGHTNESS_THRESHOLD ? 0 : 100;
    };

    const primaryTextLightness = getTextLightness(hsl.lightness);
    const primaryTextUnusedLightness = getTextLightness(hsl.lightness + 5);
    const primaryTextDisabledLightness = getTextLightness(hsl.lightness + 10);

    return {
        active: `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness - 10}$)`,
        base: `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}$)`,
        disabled: `hsla(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}$, 0.6)`,
        hover: `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness - 5}$)`,
        text: {
            base: `hsl(${textHSL.hue}, ${textHSL.saturation}%, ${primaryTextLightness}%)`,
            disabled: `hsl(${textHSL.hue}, ${textHSL.saturation}%, ${primaryTextDisabledLightness}%)`,
            unused: `hsl(${textHSL.hue}, ${textHSL.saturation}%, ${primaryTextUnusedLightness}%)`,
        },
        unused: `hsla(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}$, 0.8)`,
    };
}

function getSidebarColors(hsl: THSL, textHSL: THSL): TSidebarTheme {
    // Sidebar color lightness threshold to switch between black/white text color
    const LIGHTNESS_THRESHOLD = 128;

    const getIconColor = (lightness: number) => {
        const brightness = getColorBrightness({ ...hsl, lightness });
        return brightness >= LIGHTNESS_THRESHOLD ? 0 : 100;
    };

    const sidebarIcon = getIconColor(hsl.lightness + 5);

    return {
        base: `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%)`,
        hover: `hsl(${hsl.hue}, ${hsl.saturation}%, 25%)`,
        icon: `hsl(${hsl.hue}, ${hsl.saturation}%, ${sidebarIcon}%)`,
    };
}

function getTextColors(hsl: THSL, backgroundHSL: THSL): TTextTheme {
    /* eslint-disable */
    const COLOR_LIGHTNESS = {
        BACKGROUND_LIGHTNESS_100_70: { L1: 15, L2: 30, L3: 45 },
        BACKGROUND_LIGHTNESS_70_55: { L1: 10, L2: 20, L3: 30 },
        BACKGROUND_LIGHTNESS_55_45: { L1: 10, L2: 18, L3: 24 },
        BACKGROUND_LIGHTNESS_45_0: { L1: 12, L2: 23, L3: 33 },
    };
    /* eslint-enable */

    let lightnessL1;
    let lightnessL2;
    let lightnessL3;

    if (backgroundHSL.lightness > 70) {
        lightnessL1 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_100_70.L1;
        lightnessL2 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_100_70.L2;
        lightnessL3 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_100_70.L3;
    } else if (backgroundHSL.lightness <= 70 && backgroundHSL.lightness > 55) {
        lightnessL1 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_70_55.L1;
        lightnessL2 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_70_55.L2;
        lightnessL3 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_70_55.L3;
    } else if (backgroundHSL.lightness <= 55 && backgroundHSL.lightness > 45) {
        lightnessL1 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_55_45.L1;
        lightnessL2 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_55_45.L2;
        lightnessL3 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_55_45.L3;
    } else {
        lightnessL1 = hsl.lightness - COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_45_0.L1;
        lightnessL2 = hsl.lightness - COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_45_0.L2;
        lightnessL3 = hsl.lightness - COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_45_0.L3;
    }

    return {
        base: `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%)`,
        l1: `hsl(${hsl.hue}, ${hsl.saturation}%, ${lightnessL1}%)`,
        l2: `hsl(${hsl.hue}, ${hsl.saturation}%, ${lightnessL2}%)`,
        l3: `hsl(${hsl.hue}, ${hsl.saturation}%, ${lightnessL3}%)`,

    };
}

function getBackgroundColors(hsl: THSL, primaryColor: THSL): TBackgroundTheme {
    // Background lightness threshold to switch between dark/light themes
    const BACKGROUND_LIGHTNESS_THRESHOLD = 13;
    const BACKGROUND_LIGHTNESS = {
        DARK_THEME: {
            ACTIVE: 9,
            HOVER: 7,
            SECONDARY: 10,
            SPACER: 13,
        },
        LIGHT_THEME: {
            ACTIVE: 6,
            HOVER: 4,
            SECONDARY: 7,
            SPACER: 10,
        },
    };
    const getLightness = (lightness: number, type: BACKGROUND_COLOR_TYPE): number => {
        return lightness <= BACKGROUND_LIGHTNESS_THRESHOLD
            ? lightness + BACKGROUND_LIGHTNESS.DARK_THEME[type]
            : lightness - BACKGROUND_LIGHTNESS.LIGHT_THEME[type];
    };

    const activeLightness = getLightness(hsl.lightness, "ACTIVE");
    const hoverLightness = getLightness(hsl.lightness, "HOVER");
    const secondaryLightness = getLightness(hsl.lightness, "SECONDARY");
    const spacerLightness = getLightness(hsl.lightness, "SPACER");
    const highlightLightness = hsl.lightness > BACKGROUND_LIGHTNESS_THRESHOLD ? hsl.lightness - 1 : hsl.lightness + 2;

    // Shadow lightness threshold to switch between dark/light shadows
    const SHADOW_LIGHTNESS_THRESHOLD = 56;
    const shadowLightness = hsl.lightness < SHADOW_LIGHTNESS_THRESHOLD ? 100 : 12;

    // Background saturation threshold to adjust spacer saturation
    const SPACER_SATURATION_THRESHOLD = 40;
    const spacerSaturation = hsl.saturation >= SPACER_SATURATION_THRESHOLD ? hsl.saturation - 10 : hsl.saturation;

    return {
        active: `hsl(${primaryColor.hue}, ${primaryColor.saturation}%, ${activeLightness}%)`,
        base: `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%)`,
        highlight: `hsl(${hsl.hue}, ${hsl.saturation}%, ${highlightLightness}%)`,
        hover: `hsl(${primaryColor.hue}, ${primaryColor.saturation}%, ${hoverLightness}%)`,
        opacity: `hsla(${hsl.hue}, ${hsl.saturation}%, ${secondaryLightness}%, 0.5)`,
        secondary: `hsl(${hsl.hue}, ${hsl.saturation}%, ${secondaryLightness}%)`,
        shadow: `hsla(${hsl.hue}, ${hsl.saturation}%, ${shadowLightness}%, 0.15)`,
        spacer: `hsl(${hsl.hue}, ${spacerSaturation}%, ${spacerLightness}%)`,
    };
}

function getCssPropertiesByThemeKey(key: string, theme: TTheme) {
    switch (key) {
        case "backgroundColor":
            return { background: getBackgroundColors(theme.backgroundColor, theme.primaryColor) };
        case "textColor":
            return { text: getTextColors(theme.textColor, theme.backgroundColor) };
        case "primaryColor":
            return { primary: getPrimaryColors(theme.primaryColor, theme.textColor) };
        case "sidebarColor":
            return { sidebar: getSidebarColors(theme.sidebarColor, theme.textColor) };
        case "logotype":
            return { logo: theme.logotype };
        default:
            return {};
    }
}

function getCSSProperties(theme: TTheme): DefaultTheme | null {
    let acc = null;
    Object.keys(theme).forEach((key: any) => {
        const properties = getCssPropertiesByThemeKey(key, theme);
        if (acc === null) { acc = {}; }
        acc = {
            ...acc,
            ...properties,
        };
    }, "");
    return acc;
}

export function getTheme(theme: TTheme): DefaultTheme | null {
    return getCSSProperties(theme);
}

export async function whiteLabelHandler(url: string): Promise<TTheme> {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(JSON.stringify(response));
        }
        const theme = (await response.json()) as TTheme;
        // setTheme(theme);
        return theme;
    } catch (e) {
        // setTheme(DEFAULT_THEME);
        // TODO: make some notification about error to get theme (use Sentry?)
        console.log(e);
        return DEFAULT_THEME;
    }
}

export function getThemeName(): string {
    const url = new URL(window.location.href);
    // TODO: refactor when backend implemented
    const theme = url.searchParams.get("theme");
    return theme ? `${theme}.mock.json` : null;
}