
import { ContainerClient } from "@statflo/widget-sdk";

import { isWidgetInstanceOfTextKit } from "@/messenger/utils/helpers";

import { DebugLogLevel, TWidgetState } from "./types";

export const widgetContainerClient = !isWidgetInstanceOfTextKit && new ContainerClient({
  logs: DebugLogLevel.None,
  window,
});


export const createWidgetState = (id: string): TWidgetState => {
  return ({
      id,
      type: null,
      containerDomain: "*",
      footer: "",
      header: "",
      isOpen: false,
      isReady: false,
      isShown: false,
      label: "",
      maxHeight: null,
      size: null,
      url: "",
      widgetDomain: "*",
  });
};

const ContainerEvents = {
  activeConversationChanged: "active_conversation_changed",
};

const WidgetEvents = {
  postForwardExample: "post_forward_example",
  actionCreationFailed: "action_creation_failed",
  appendTextToMessage: "append_text_to_message",
  replaceTextMessage: "replace_text_message",
};

export const EventNames = {
  widget: WidgetEvents,
  container: ContainerEvents,
};