import * as React from "react";

function SvgComponent() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" data-testid={"filter-bar-default-icon"}>
        <path d="M15.2174 2.38667C15.381 2.163 15.4795 1.89839 15.5019 1.62218C15.5243 1.34596 15.4699 1.06893 15.3445 0.821784C15.2191 0.574638 15.0278 0.367034 14.7916 0.221983C14.5555 0.0769315 14.2838 9.85041e-05 14.0067 1.0342e-07H1.9947C1.71868 -0.000102374 1.44799 0.0759554 1.21242 0.219801C0.976849 0.363646 0.785535 0.569701 0.65954 0.815282C0.533544 1.06086 0.477752 1.33645 0.498306 1.6117C0.518859 1.88695 0.614961 2.15119 0.776031 2.37533L5.50937 9.156V14.4993C5.50934 14.708 5.55267 14.9143 5.63659 15.1053C5.72052 15.2963 5.84321 15.4678 5.9969 15.6089C6.15059 15.75 6.33192 15.8576 6.52939 15.9249C6.72686 15.9923 6.93616 16.0178 7.14403 16C7.51237 16.0111 7.87621 15.9168 8.1927 15.728C10.0147 14.5947 9.8227 14.712 9.84003 14.6993C10.0259 14.5592 10.1768 14.378 10.2809 14.1698C10.385 13.9616 10.4395 13.7321 10.44 13.4993V9.158L15.2174 2.38667ZM8.92603 8.41333C8.82672 8.55394 8.77339 8.72185 8.77337 8.894V13.2147C8.77342 13.2724 8.75846 13.3293 8.72994 13.3795C8.70142 13.4298 8.66033 13.4717 8.6107 13.5013C8.39203 13.6307 7.99536 13.866 7.67403 14.0527C7.62339 14.0821 7.5659 14.0976 7.50735 14.0977C7.4488 14.0978 7.39125 14.0825 7.34049 14.0533C7.28974 14.0241 7.24757 13.9821 7.21823 13.9314C7.18889 13.8807 7.17342 13.8232 7.17336 13.7647V8.894C7.17365 8.72345 7.12126 8.55698 7.02336 8.41733L2.6787 2.19133C2.64385 2.14136 2.62337 2.0828 2.61947 2.02201C2.61557 1.96122 2.6284 1.90052 2.65657 1.8465C2.68475 1.79249 2.72719 1.74723 2.77927 1.71565C2.83136 1.68406 2.89111 1.66735 2.95203 1.66733H13.042C13.1031 1.6673 13.163 1.68405 13.2152 1.71575C13.2674 1.74746 13.3099 1.7929 13.3381 1.84711C13.3662 1.90132 13.3789 1.96222 13.3748 2.02316C13.3707 2.0841 13.3499 2.14274 13.3147 2.19267L8.92603 8.41333Z" fill="#757575"/>
    </svg>
  );
}

export default SvgComponent;
