import "./index.scss";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

// import Button from "@/components/components/Button";
import Close from "@/components/components/Close";
import { Filter, FilterAppliedV2 } from "@/components/icons";
import ChatFilterContent from "@/messenger/components/ChatFilterContent";
import { conversationsSelectors } from "@/messenger/ducks/entities/conversations";
import { topicsSelectors } from "@/messenger/ducks/entities/topics";
import { setFilterIsOpenAC, setFilterIsSmsCapableAC, setPrevFilterIsSmsCapableAC, setPrevSelectedCampaignNameAC, setPrevSelectedMonthAC, setSelectedCampaignNameAC, setSelectedMonthAC } from "@/messenger/ducks/general/conversationRefinementsReducer.duck";
import { deviceTypeSelector } from "@/messenger/ducks/selectors/general";
import { conversationRefinementsSelector, filterCounterSelector } from "@/messenger/selectors";
import { detectMobile } from "@/messenger/utils/deviceType";

const ChatFilter = (): React.ReactElement => {
    const isMobile = detectMobile(useSelector(deviceTypeSelector));

    const { selectedTopicName, prevSelectedTopicName, selectedMonth, prevSelectedMonth, isOpen, isSmsCapable, prevIsSmsCapable } = useSelector(conversationRefinementsSelector).filter;
    const textkitConversations = useSelector(conversationRefinementsSelector).result;

    const filterCount = useSelector(filterCounterSelector);

    const topics = useSelector(topicsSelectors.selectAll);
    const conversations = useSelector(conversationsSelectors.selectAll);

    const dispatch = useDispatch();

    const clearFilters = (e?): void => {
        if (e){
            e.preventDefault();
            e.stopPropagation();
        }

        dispatch(setFilterIsOpenAC(false));
        dispatch(setSelectedCampaignNameAC(null));
        dispatch(setPrevSelectedCampaignNameAC(null));
        dispatch(setSelectedMonthAC(null));
        dispatch(setPrevSelectedMonthAC(null));
        dispatch(setPrevFilterIsSmsCapableAC(false));
        dispatch(setFilterIsSmsCapableAC(false));
    };

    const onSelectTopic = (name: string): void => {
        dispatch(setPrevSelectedCampaignNameAC(selectedTopicName));
        dispatch(setSelectedCampaignNameAC(name));
    };

    const onSelectMonth = (month: string): void => {
        dispatch(setPrevSelectedMonthAC(selectedMonth));
        dispatch(setSelectedMonthAC(month));
    };

    const onFilterSmsCapable = (value: boolean): void => {
        dispatch(setPrevFilterIsSmsCapableAC(isSmsCapable));
        dispatch(setFilterIsSmsCapableAC(value));
    };

    const onChatFilterOpen = (): void => {
        dispatch(setFilterIsOpenAC(true));
    };

    const onApplyButtonClick = (): void => {
        dispatch(setFilterIsOpenAC(false));
        dispatch(setPrevSelectedCampaignNameAC(selectedTopicName));
        dispatch(setPrevSelectedMonthAC(selectedMonth));
        dispatch(setPrevFilterIsSmsCapableAC(isSmsCapable));
    };

    const onCancelButtonClick = (): void => {
        if (!prevSelectedTopicName) {
            dispatch(setSelectedCampaignNameAC(null));
            dispatch(setPrevSelectedCampaignNameAC(null));
        }
        if (prevSelectedTopicName && prevSelectedTopicName !== selectedTopicName) {
            dispatch(setSelectedCampaignNameAC(prevSelectedTopicName));
        }
        if (!prevSelectedMonth) {
            dispatch(setSelectedMonthAC(null));
            dispatch(setPrevSelectedMonthAC(null));
        }

        if (prevSelectedMonth && prevSelectedMonth !== selectedMonth) {
            dispatch(setSelectedMonthAC(prevSelectedMonth));
        }
        if (prevIsSmsCapable !== isSmsCapable) {
            dispatch(setFilterIsSmsCapableAC(prevIsSmsCapable));
        }
        dispatch(setFilterIsOpenAC(false));
    };

    const onClose = () => {
        dispatch(setFilterIsOpenAC(false));
    };

    const renderFilterCounter = () => {
        return <div className={"c-chat-filter__counter"} data-testid="chat-filter-counter">
            {filterCount}
        </div>;
    };

    // https://statflo.atlassian.net/browse/TECH-435
    const filteredCampaigns = topics.filter(c => c.name !== "All");

    return (<>
        <button id={"CHAT_FILTER_BUTTON"} aria-label="Chat Filter" className={`c-chat-filter__icon ${filterCount > 0 ? "c-chat-filter__icon-background" : ""}`} onClick={onChatFilterOpen} data-testid="chat-filter">
            {filterCount > 0 ? renderFilterCounter() : null}
            {selectedTopicName ? <FilterAppliedV2 /> :  <Filter width={20} height={20} />}
        </button>
        {isOpen && <Close isOpen={isOpen} closeChange={onClose}>
            <ChatFilterContent
                topics={filteredCampaigns}
                conversations={conversations}
                conversationCount={textkitConversations.length}
                selectedCampaignName={selectedTopicName}
                selectedMonth={selectedMonth}
                isSmsCapable={isSmsCapable}
                isMobile={isMobile}
                onSelectCampaign={onSelectTopic}
                onSelectMonth={onSelectMonth}
                onFilterSmsCapable={onFilterSmsCapable}
                onApplyButtonClick={onApplyButtonClick}
                onResetButtonClick={clearFilters}
                onCancelButtonClick={onCancelButtonClick}
            />;
        </Close>}
    </>);
};

export default ChatFilter;
