import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { WidgetNames } from "@/messenger/constants/misc";
import { deviceTypeSelector } from "@/messenger/ducks/selectors/general";
import { widgetsStateSelectors } from "@/messenger/ducks/userInterface/widgetsState";
import { saveConversationCall,updateConversationCategory } from "@/messenger/graphql/mutations";
import { activeConversationIdSelector, conversationIdByPhoneNumber } from "@/messenger/selectors";
import { ConversationCategory } from "@/messenger/types/entities/conversation";
import { CallOutcome } from "@/messenger/types/graphql/autogenerated";
import { TStore } from "@/messenger/types/store.types";
import { detectMobile } from "@/messenger/utils/deviceType";
import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from "@/messenger/utils/helpers";
import { widgetContainerClient } from "@/messenger/widgets";
import { WidgetState } from "@/messenger/widgets/types";


// import RingNotes from "./CallDetails";

const noteAreaClass = "note-area";
const ringCentralLocalstorageKey = "rc-logged-in-user";
const embeddedAppSource = new URL("https://apps.ringcentral.com/integration/ringcentral-embeddable/1.8.0/adapter.telus.js");
const redirectUri = new URL("https://ringcentral.github.io/ringcentral-embeddable/redirect.html");
const ndiv = document.createElement("div");
ndiv.className = noteAreaClass;

const HIDE_RING_CENTRAL_KEY = "hideRingCentral";

export const getRingCentralHideState = () => {
  return Boolean(getFromLocalStorage(HIDE_RING_CENTRAL_KEY)) || false;
};

export const setHideRingCentral = (isHidden: boolean) => {
  const isCurrentlyHidden = getRingCentralHideState();

  (window as any).RCAdapter.setClosed(isHidden);
  setToLocalStorage(HIDE_RING_CENTRAL_KEY, isHidden);
  if(isCurrentlyHidden){
      (window as any).RCAdapter.setMinimized(false); // force widget to always be maximized
  }
};

export const toggleHideRingCentral = () => {
  const isCurrentlyHidden = getRingCentralHideState();

  setHideRingCentral(!isCurrentlyHidden);
};

function ringCentralLogoutPromise() {
  return new Promise((resolve,reject) => {
      const rcWidgetComponent = document.getElementById("rc-widget-adapter-frame") as HTMLIFrameElement;
      try{
          rcWidgetComponent.contentWindow.postMessage({
              type: "rc-adapter-logout",
          }, "*");
      }
      catch(e){
          reject(e);
      }
      resolve("successfully logged out of RingCentral");
  });
}

function rcLogout(){
  ringCentralLogoutPromise().then(successMessage => {
    console.log(successMessage);
    removeFromLocalStorage(ringCentralLocalstorageKey);
  }).catch(e => {
    console.log(e);
  });
}

const handleResize = (isMobile: boolean) => {
  const hideRingCentralConfig = Boolean(getFromLocalStorage(HIDE_RING_CENTRAL_KEY));
  if (!hideRingCentralConfig && (window as any).RCAdapter) {
    if (isMobile) {
      (window as any).RCAdapter.setClosed(true);
    } else {
      (window as any).RCAdapter.setClosed(false);
    }
  }
};

function injectDialer(clientId: string, disableSMS: boolean, disableMinimize: boolean) {
  window.addEventListener("message", (e) => {
    // omit non RingCentral logs
    if (e.data?.type && (e.data.type as string).startsWith("rc-")) {
          console.log(e.data);
    }
    if ( e.data?.type === "rc-dialer-status-notify" &&  e.data?.ready === true) {
      // attachNotesWidget(widgetId);
    }
  });
  const rcs = document.createElement("script");
  const srcRoot = `${embeddedAppSource}?clientId=${clientId}&redirectUri=${redirectUri}&disableReadText=${disableSMS}`;
  rcs.src = disableMinimize ? `${srcRoot}&disableMinimize=true` : srcRoot;
  const rcs0 = document.getElementsByTagName("script")[0];
  rcs0.parentNode.insertBefore(rcs, rcs0);
}

// function attachNotesWidget(widgetId) {
//   const dialerWin = document.getElementById(widgetId);
//   // document.body.appendChild(ndiv);
//   // dialerWin.appendChild(ndiv);
//   // dialerWin.prepend(ndiv);
//   const frame = dialerWin.getElementsByClassName("Adapter_frameContainer")[0];
//   if (frame) {
//     dialerWin.insertBefore(ndiv, frame);
//   } else {
//     dialerWin.prepend(ndiv);
//   }
// }

interface TRingCentralProps {
  userEmail: string,
  clientId: string,
  disableSMS: boolean,
  disableMinimize: boolean,
}
const RingCentral = ({userEmail, clientId, disableSMS, disableMinimize }: TRingCentralProps) => {
  const activeConversationId = useSelector(activeConversationIdSelector);
  const isMobile = detectMobile(useSelector(deviceTypeSelector));

  const [inCallPhoneNumber, setInCallPhoneNumber] = useState<string>();
  const [callOutcome, setCallOutcome] = useState<CallOutcome>();
  const [callStartDatetime, setCallStartDatetime] = useState<Date>(); 
  const conversationIdGivenPhoneNumber = useSelector(conversationIdByPhoneNumber(inCallPhoneNumber));

  const laaWidgetState = useSelector((s: TStore) => widgetsStateSelectors.selectById(s, WidgetNames.LogAnActivity));
  const history = useHistory();

  const [message, setMessage] = useState(null);

  const [
    updateConversationCategoryMutation,
  ] = useMutation<{
      updateConversationCategory: boolean;
  }>(updateConversationCategory);

  const setConversationCategoryToOpen = (convoId) => {
    updateConversationCategoryMutation({
      variables: {
        UpdateConversationCategoryInput: {
          conversationId: convoId,
          conversationCategory: ConversationCategory.OPEN,
        },
      },
    });
  };

  const [
    sendConversationCallMutation,
  ] = useMutation<{
    saveConversationCall: boolean;
  }>(saveConversationCall);

  const publishConversationCallEvent = (convoId, callStart, callDuration, callEndStatus, callExternalId) => {
    sendConversationCallMutation({
      variables: {
        SaveConversationCallInput: {
          conversationId: convoId,
          callStartDatetime: callStart,
          callDuration,
          callOutcome: callEndStatus, 
          callExternalId,
        },
      },
    });
  };
  
  useEffect(() => {
    handleResize(isMobile);
  }, [isMobile]);

  const openLogAnActivityWidget = () => {
    if (laaWidgetState) {
      if (activeConversationId !== conversationIdGivenPhoneNumber) {
        history.push(`/chat/${conversationIdGivenPhoneNumber}`);
      }
      
      widgetContainerClient.setState(WidgetNames.LogAnActivity, WidgetState.isOpen, true );
    }
  };

  const startCallTimer = () => {
    setCallStartDatetime(new Date());
  };

  const getCallTime = () => {
    const now = new Date();
    return Math.round((now.getTime() - callStartDatetime.getTime())/1000);
  };

  const handleRingCentralEvent = (e) => {
    const {data} = e;
    if (data) {
      switch (data.type) {
        case "rc-login-status-notify": {
          // get login status from widget
          // console.log('rc-login-status-notify:', data.loggedIn, data.loginNumber);
          const existingLoggedInUser = getFromLocalStorage(ringCentralLocalstorageKey);
          if(data.loggedIn){
            if(!existingLoggedInUser){
              setToLocalStorage("rc-logged-in-user",userEmail);
            }else if(existingLoggedInUser === userEmail){
              console.log("ringcentral: user already logged in");
            }else{
              rcLogout();
            }
          }else if(existingLoggedInUser){
            removeFromLocalStorage(ringCentralLocalstorageKey);
          }
          break;
        }
        case "rc-telephony-session-notify": {
          // get telephony session on telephony session event
          const {parties} = data.telephonySession;
          console.log("rcdebug", data.telephonySession);
          if(parties.length === 1){
            const party = parties[0];
            if(party.direction === "Outbound" && (party.status.code === "Setup" || party.status.code === "Proceeding")){
              setInCallPhoneNumber(party.to.phoneNumber);
            }
            if(party.direction === "Outbound" && party.status.code === "Disconnected"){
              publishConversationCallEvent(conversationIdGivenPhoneNumber, new Date(), 0, CallOutcome.HangUp, data.telephonySession.id);
            }
          }
          if(parties.length === 2){
            if(parties[0].status.code==="Answered" && parties[1].status.code==="Answered"){
              setConversationCategoryToOpen(conversationIdGivenPhoneNumber);
              setCallOutcome(CallOutcome.Accepted);
              startCallTimer();
            }
            parties.forEach(party => {
              if(party.direction === "Outbound" && party.status.code === "Disconnected"){
                publishConversationCallEvent(conversationIdGivenPhoneNumber, callStartDatetime, getCallTime(), callOutcome, data.telephonySession.id);
              }
            });
          }
          break;
        }
        case "rc-call-ring-notify":
          setHideRingCentral(false);
          // setInCall(true);
          // setContact(data.call?.from || "");
          break;
        case "rc-call-init-notify":
          // setInCall(true);
          // setContact(data.call?.to || "");
          break;
        case "rc-call-start-notify":
          // setInCall(true);
          break;
        case "rc-call-end-notify":
          // setInCall(false);
          // setContact('');
          openLogAnActivityWidget();
          break;
        case "rc-call-hold-notify": break;
        case "rc-call-resume-notify": break;
        case "rc-call-mute-notify": break;
        default: break;
      }
    }
  };

  const ringCentralEventListener = (e) => {
    setMessage(e);
  };

  useEffect(() => {
    if (message) {
      handleRingCentralEvent(message);
    }
  }, [message]);

  useEffect(() => {
    injectDialer(clientId, disableSMS, disableMinimize);  

    window.addEventListener("message", ringCentralEventListener);

    return () => {
      window.removeEventListener("message", ringCentralEventListener);
    };
  }, []);

  // const NotesPortal = () => ReactDOM.createPortal(
  //   <RingNotes
  //     inCall={inCall}
  //     contact={contact}
  //     onResolve={() => {setContact("");}}
  //   />,
  //   ndiv,
  // );

  return null;
};

export default RingCentral;
