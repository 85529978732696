import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";
import icons from "@/components/icons";

import { Action, MessageSource } from "./messageInput.enum";

export const COMPLIANCE_ERROR_ANIMATION_TIMEOUT = 300;

export const additionalFeaturesOptions: TDropdownOption[] = [{
        label: Action.MEDIA,
        heading: "Image",
    },
];

export const messageSourceOptions: TDropdownOption[] = [
    {
        label: MessageSource.MESSENGER,
        heading: "Messenger",
        icon: icons.FACEBOOK_MESSENGER,
    },
    {
        label: MessageSource.WHATSAPP,
        heading: "WhatsApp",
        icon: icons.WHATSAPP,
    },
    {
        label: MessageSource.SMS,
        heading: "SMS",
        icon: icons.SMS,
    },
    {
        label: MessageSource.EMAIL,
        heading: "Email",
        icon: icons.EMAIL,
    },
];
