import "./index.scss";
import "../../locales/i18n";

import { useLazyQuery ,useMutation} from "@apollo/client";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "@/components/components/Checkbox";
import { MessageType } from "@/components/enums";
import FormBody from "@/messenger/components/FormBody";
import FormFooter from "@/messenger/components/FormFooter";
import FormHeader from "@/messenger/components/FormHeader";
import TextInput from "@/messenger/components/TextInput";
import { CHAT } from "@/messenger/constants/routing";
import { addNotification, removeNotification } from "@/messenger/ducks/general/notifications.duck";
import { deviceTypeSelector } from "@/messenger/ducks/selectors/general";
import { createContact } from "@/messenger/graphql/mutations";
import { isPhoneUnique } from "@/messenger/graphql/queries";
import { detectMobile } from "@/messenger/utils/deviceType";
import { addContactValidation } from "@/messenger/utils/helpers";


interface TAddAContactProps {
    onClose: () => void;
}

interface TPhoneStatus {
    isDuplicate?: boolean;
    exisitingConversationId?: string;
}


const AddAContact = ({ onClose }: TAddAContactProps) => {
    const deviceType = useSelector(deviceTypeSelector);
    const isMobile = detectMobile(deviceType);
    const dispatch = useDispatch();
    
    const height = "calc(100vh - 25px)";
    const hardWidth = isMobile ? "100%" : "380px";
    const componentStyles: React.CSSProperties =  {
        position: "fixed",
        marginTop: 0,
        top: 25,
        right: 0,
        height,
        width: hardWidth,
        marginRight: 0,
    } ;
    const { t } = useTranslation();

    const [hasConsent, setHasConsent] = useState(false);
    const [checkPhoneUnique, { data, error, loading }] = useLazyQuery(isPhoneUnique);
    const [phoneStatus, setPhoneStatus] = useState<TPhoneStatus>({});
    

    const renderPhoneMessage = () => {
        if (phoneStatus?.exisitingConversationId) {
            return (
                <span>{t("Duplicate Entry: Contact already exists for you.")} <a href={`/${CHAT}/${phoneStatus.exisitingConversationId}`}>{t("Open")}</a></span>
            );
        } 
            return (
                <span>{t("Duplicate Entry: This Contact exists and is assigned to another Rep.")}</span>
            );
    };

    useEffect(() => {
        if(data?.isPhoneUnique) {
            const phoneUniqueData = data.isPhoneUnique;
            if(phoneUniqueData.existingConversationId) {
                setPhoneStatus({isDuplicate: true, exisitingConversationId: phoneUniqueData.existingConversationId});
            } else if(!phoneUniqueData.isUnique) {
                setPhoneStatus({isDuplicate: true, exisitingConversationId: null});
            } else if(phoneUniqueData.isUnique) {
                setPhoneStatus({isDuplicate: false, exisitingConversationId: null});
            }
        }
        renderPhoneMessage();
    }, [data, error, loading]);

    

    const [createContactMutation] = useMutation(createContact);


    const { values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, dirty, isValid, resetForm, setFieldError } = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            phoneNumber: "",
        },
        validationSchema: addContactValidation,
        onSubmit: () => {
            if (isValid && hasConsent) {
                createContactMutation({
                    variables: {
                        CreateContactInput: {
                            firstName: values.firstname,
                            lastName: values.lastname,
                            phoneNumber: values.phoneNumber,
                        },
                    },
                });
                onClose();
                const notification = {
                    id: `${values.phoneNumber}`,
                    type: MessageType.NEUTRAL,
                    message: "Sending request, please wait for contact to be added",
                };
    
                dispatch(addNotification(notification));
                setTimeout(() => dispatch(removeNotification(`${values.phoneNumber}`)), 3000);
            }
        },
    });

    const clearForm = () => {
        resetForm();
        setFieldValue("firstname", "");
        setFieldValue("lastname", "");
        setFieldValue("phoneNumber", "");

        setFieldError("firstname", undefined);
        setFieldError("lastname", undefined);
        setFieldError("phoneNumber", undefined);
    };

    const handleCancel = () => {
        clearForm();
        onClose();
    };

    const handleSubmitForm = () => {
        handleSubmit();
    };

    const onPhoneNumberChange = (e) => {
        handleChange(e);
        
        const currentPhoneNumber = e.target.value;
        const phoneNumberValid = /^\+[1-9]\d{10}$/g.test(currentPhoneNumber);
        
        if(phoneNumberValid && currentPhoneNumber) {
            checkPhoneUnique({
                variables: {
                    phoneNumber: currentPhoneNumber,
                },
            });
        }
    };

    return (
        <div>
        <div className="c-add-a-contact" style={componentStyles}>
            <FormHeader name={"ADD_CONTACT"} title={"Add A Contact"} onClose={onClose}/>
            <FormBody>
                <TextInput
                    inputId={"ADD_CONTACT_FIRSTNAME"}
                    required
                    name="firstname"
                    label={t("First Name")}
                    onBlur={handleBlur}
                    value={values.firstname}
                    onChange={handleChange}
                    error={ errors.firstname ? errors.firstname : undefined}
                />
                <div className="c-add-a-contact__space"></div>
                <TextInput 
                    inputId={"ADD_CONTACT_LASTNAME"}
                    name="lastname"
                    label={t("Last Name")}
                    value={values.lastname}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={ errors.lastname ? errors.firstname : undefined}
                />
                <div className="c-add-a-contact__space"></div>
                <TextInput
                    inputId={"ADD_CONTACT_PHONE"}
                    required
                    name="phoneNumber"
                    label={t("Phone Number")}
                    value={values.phoneNumber}
                    onBlur={handleBlur}
                    onChange={(e) => onPhoneNumberChange(e)}
                    error={ errors.phoneNumber ? errors.phoneNumber : undefined}
                    customMessage={(phoneStatus?.isDuplicate && !errors.phoneNumber) ? renderPhoneMessage(): null}
                />
                <div className="c-add-a-contact__space"></div>
                <Checkbox 
                    id={"ADD_CONTACT_CONSENT_CHECKBOX"}
                    value={{checked: hasConsent, label: t("Contact has given verbal consent to be a part of future marketing & sales opportunities.")}}
                    onChange={(box) => {
                        setHasConsent(box.checked);
                    }}
                />
                <div className="c-add-a-contact__space"></div>
            </FormBody>
            <FormFooter 
                name={"ADD_CONTACT"}
                onCancel={handleCancel} 
                submitTitle={t("Create Contact")}
                submitDisabled={!isValid || !dirty || !hasConsent || phoneStatus?.isDuplicate}
                onSubmit={handleSubmitForm} />
        </div>
        </div>
    );
};

export default AddAContact;