import chatsReducer from "./chats";
import nodeOffsetsReducer from "./nodeOffsets";
import widgetsStateReducer from "./widgetsState";

const userInterfaceReducer = {
    chats: chatsReducer,
    widgetsState: widgetsStateReducer,
    nodeOffsets: nodeOffsetsReducer,
};

export default userInterfaceReducer;
