import "./index.scss";
import "../../locales/i18n";

import cx from "classnames";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { usePrevious, useUnmount } from "react-use";

import Alert from "@/components/components/Alert";
import { AlertView } from "@/components/components/Alert/alert.enum";
import { AccentedDropdown } from "@/components/components/Dropdown";
import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";
import { MessageType } from "@/components/enums";
import BackButton
    from "@/messenger/components/BackButton";
import MessageTemplate from "@/messenger/containers/MessageTemplate";
import { selectActiveConversationContact } from "@/messenger/ducks/entities/contacts";
import { chatsSelectors, updateChatMessageTemplate, updateChatStarter } from "@/messenger/ducks/userInterface/chats";
import { useSendChatStarter } from "@/messenger/hooks/useSendChatStarter";
import { formatContactName } from "@/messenger/models/contact";
import { TContact } from "@/messenger/types/entities/conversation";
import { EMessageTemplateSelectedPlaceholderType, TMessageTemplate } from "@/messenger/types/entities/messageTemplate";
import { TChatStarterState, TStore } from "@/messenger/types/store.types";
import { detectMobile } from "@/messenger/utils/deviceType";
import { getLocaleLabelFromCode, handleOnEnter } from "@/messenger/utils/helpers";
import {
    getFirstEmptyPlaceholder,
    getMessageTemplateWithUpdatedPlaceholders,
} from "@/messenger/utils/messageTemplates.helper";

interface TMessageTemplatesProps {
    isCooldownActive: boolean;
    activeConversationId: string
}

/**
 * Returns the placeholder's default value when there is a compliance complaint. Returns the placeholder's draft value otherwise.
 * @param  placeholder The placeholder
 * @param  complianceErrorCount The count of compliance errors for this placeholder
 */
const resetPlaceholderValue = (placeholder, complianceErrorCount: number) => {
    return complianceErrorCount > 0
        ? placeholder.placeholder.value || null
        : placeholder.placeholder.draftValue;
};

export default function MessageTemplates({ isCooldownActive, activeConversationId }: TMessageTemplatesProps): ReactElement {
    const [scrollTop, setScrollTop] = useState<number>(0);
    const [prevScrollTop, setPrevScrollTop] = useState<number>(0);
    const [shouldDisplaySendError, setShouldDisplaySendError] = useState<boolean>(false);
    const [isSent, setIsSent] = useState<boolean>(false);
    const scrollbarRef = useRef<Scrollbars>();
    const { t } = useTranslation();
    const toggleDefaultLanguage = "English";

    const chatState = useSelector((s: TStore) => chatsSelectors.selectById(s, activeConversationId));
    const { chatStarter: { messageTemplates, selectedMessageTemplateId, selectedPlaceholder, inputComplianceAdvice } } = chatState;
    const locales = [];

    messageTemplates.map(mt => {
        return (locales.indexOf(getLocaleLabelFromCode(mt.locale, false)) < 0) ? locales.push(getLocaleLabelFromCode(mt.locale, false)) : null;
    });
    
    const {
        startConversationData,
        startConversationError,
        startConversationLoading,
        sendChatStarter,
        handleSendChatStarterSuccess,
    } = useSendChatStarter({ activeConversationId });
    
    useEffect(() => {
        if (startConversationData) {
            setIsSent(true);
        }
    }, [startConversationData]);
    
    const activeContact: TContact = useSelector(selectActiveConversationContact);
    const prevActiveContact: TContact = usePrevious(activeContact);
    const currentActiveContactLanguage = getLocaleLabelFromCode(activeContact.locale, false);
    const [activeContactLanguage, setActiveContactLanguage] = useState(currentActiveContactLanguage);
    const [selectedLanguage, setSelectedLanguage] = useState<string>();

    const deviceType = useSelector((state: TStore) => state.general.deviceType);
    
    const dispatch = useDispatch();
    const isMobile = detectMobile(deviceType);

    useEffect(() => {
        setActiveContactLanguage(currentActiveContactLanguage);
    }, [activeContact]);

    const getDefaultLanguage = () => {
        if (locales.includes(activeContactLanguage)) {
            return activeContactLanguage;
        } if (locales.includes(toggleDefaultLanguage)) {
            return toggleDefaultLanguage;
        } return "";
    };

    useEffect(() => {
        if (!selectedLanguage) {
            const defaultLanguage = getDefaultLanguage();
            setSelectedLanguage(defaultLanguage);
        }
    }, [locales, activeContact]);
    
    const unselectPlaceholder = () => {
        const mt = messageTemplates.find(item => item.id === selectedMessageTemplateId);
        const value = resetPlaceholderValue(selectedPlaceholder, inputComplianceAdvice.length);
        const sp = {...selectedPlaceholder.placeholder, value, draftValue: value};
        const messageTemplate = getMessageTemplateWithUpdatedPlaceholders(mt, [sp]);

        dispatch(updateChatMessageTemplate({
            id: activeConversationId,
            messageTemplate,
        }));

        dispatch(updateChatStarter({
            id: activeConversationId,
            chatStarter: { 
                selectedPlaceholder: null,
            },
        }));
    };
    
    useUnmount(() => {
        if (selectedPlaceholder) {
            unselectPlaceholder();
        }

        dispatch(updateChatStarter({
            id: activeConversationId,
            chatStarter: {
                selectedMessageTemplateId: null,
                selectedPlaceholder: null,
            },
        }));
    });
    
    useEffect(() => {
        let scrollPosition = prevScrollTop;
        if (prevActiveContact?.id !== activeContact.id) {
            scrollPosition = 0;
            setShouldDisplaySendError(false);
        }
        setPrevScrollTop(scrollPosition);
        scrollbarRef.current.scrollTop(scrollPosition);
    }, [messageTemplates, activeConversationId, prevActiveContact]);
    
    useEffect(() => {
        if (
            prevActiveContact &&
            selectedMessageTemplateId &&
            selectedPlaceholder &&
            prevActiveContact.id !== activeContact.id
        ) {
            unselectPlaceholder();
        }
    }, [activeContact.id, selectedMessageTemplateId]);
    
    useEffect(() => {
        if (startConversationError) {
            setShouldDisplaySendError(true);
        }
    }, [startConversationError]);
    
    const handleMessageTemplateClick = (messageTemplate: TMessageTemplate): void => {
        if (!selectedMessageTemplateId) {
            setPrevScrollTop(scrollTop);
            scrollbarRef.current.scrollToTop();

            const chatStarterUpdate: Partial<TChatStarterState> = {
                selectedMessageTemplateId: messageTemplate.id,
            };

            if (!selectedPlaceholder) {
                const sp = getFirstEmptyPlaceholder(messageTemplate, 0);
                if (sp) {
                    const placeholder = {
                        placeholder: sp,
                        index: sp.indices[0],
                        type: EMessageTemplateSelectedPlaceholderType.AUTO_SELECTED,
                    };

                    chatStarterUpdate.selectedPlaceholder = placeholder;
                }
            }

            dispatch(updateChatStarter({
                id: activeConversationId,
                chatStarter: chatStarterUpdate,
            }));
        }
    };
    
    const handleOnScroll = (): void => {
        setScrollTop(scrollbarRef.current.getScrollTop());
    };
    
    const handleClickBackToAllButton = (): void => {
        if (selectedPlaceholder) {
            unselectPlaceholder();
        }

        dispatch(updateChatStarter({
            id: activeConversationId,
            chatStarter: {
                selectedMessageTemplateId: null,
            },
        }));

        setShouldDisplaySendError(false);
    };
    
    const handleSendMessageTemplateErrorAlertClose = () => {
        setShouldDisplaySendError(false);
    };
    
    const shouldRenderMessageTemplateListHeader = (): boolean => {
        return !!activeContact;
    };
    
    const handleSendChatStarterFinish = () => {
        handleSendChatStarterSuccess();
    };

    const renderMessageTemplate = (messageTemplate: TMessageTemplate): ReactElement => {
        return (
            <div
                id={`MESSAGE_TEMPLATE_ITEM_${messageTemplate.id}`}
                className={cx("с-message-templates__list-item", {
                    "с-message-templates__list-item--disabled": isCooldownActive,
                })}
                role="listitem"
                aria-label="messageTemplate"
                onKeyPress={e => handleOnEnter(e, () => !isCooldownActive
                    && handleMessageTemplateClick(messageTemplate))}
                onClick={() => !isCooldownActive && handleMessageTemplateClick(messageTemplate)}
                tabIndex={0}
            >
                <MessageTemplate
                    messageTemplate={messageTemplate}
                    isSending={startConversationLoading}
                    isSent={isSent}
                    handleSendChatStarterStart={sendChatStarter}
                    handleSendChatStarterFinish={handleSendChatStarterFinish}
                    activeConversationId={activeConversationId}
                />
            </div>
        );
    };


    function filterMessageTemplates(messageTemplate: TMessageTemplate) {
        if(selectedLanguage === null){
            return true;
        }
        if (!selectedLanguage && !locales.includes(activeContactLanguage) && !locales.includes(toggleDefaultLanguage)) {
            return true;
        }
            return (getLocaleLabelFromCode(messageTemplate.locale, false) === selectedLanguage);
    };

    function sortMessageTemplates(t1: TMessageTemplate,t2: TMessageTemplate) {
        const preferredLocale = activeContact.locale;
        if(t1.locale === preferredLocale){
            return -1;
        }
        if(t2.locale === preferredLocale){
            return 1;
        }
        return 0;
    };
    
    const renderMessageTemplateList = (): ReactElement => {
        return (
            <div className="с-message-templates__list" role="list">
                <TransitionGroup>
                    {messageTemplates.slice().filter(filterMessageTemplates).sort(sortMessageTemplates).map(messageTemplate => (!selectedMessageTemplateId || messageTemplate.id === selectedMessageTemplateId ?
                        <CSSTransition
                            key={`${activeContact.id}-${messageTemplate.id}`}
                            timeout={500}
                            exit={false}
                            classNames="с-message-templates__list-item--animation"
                        >
                            {renderMessageTemplate(messageTemplate)}
                        </CSSTransition> : null
                    ))}
                </TransitionGroup>
            </div>
        );
    };
    
    const renderMessageTemplateListHeader = (): ReactElement => {
        return (
            <div
                className={cx("с-message-templates__header", {
                    "c-message-templates__header--scrolled-list": scrollTop > 0,
                })}
            >
                <div className="с-message-templates__header-title">
                    {startConversationLoading || startConversationData?.startConversation
                        ? t("Sending message to:")
                        : t("Begin conversation with:")}
                </div>
                
                <div
                    className="с-message-templates__header-name">{`${formatContactName({
                        firstName: activeContact.firstName,
                        lastName: activeContact.lastName,
                    })}`}</div>
            </div>
        );
    };
    
    const renderSendError = (): ReactElement => {
        return (
            <Alert
                className={"с-message-templates__header-error"}
                type={MessageType.ERROR}
                view={AlertView.TOAST}
                onClose={handleSendMessageTemplateErrorAlertClose}
            >
                <span className={"с-message-templates__header-error-text"}>
                    {t("Our service partners experience some technical issues. We are aware of this problem and actively working on it. Try to resend a message in a couple of minutes.")}
                </span>
            </Alert>
        );
    };

    const renderLanguageMenu = (): ReactElement => {
        
        if ([2,3].includes(locales.length)){
            const optionsTabs = locales.map(locale => ({
                label: locale,
                ariaLabel: locale,
                heading: locale,
                selected: false,
            })).sort((a,b) => a.heading.localeCompare(b.heading));
            return (
                <div>
                    <div className="с-message-templates__widget-selector">
                        <div className="с-message-templates__widget-selector__tabs">
                            {optionsTabs.map(option => 
                                <>
                                    <button id={`SELECT_LOCALE_${option.label.toUpperCase()}_BUTTON`} className={`с-message-templates__widget-selector__tabs-${(option.label === selectedLanguage) ? "active" : "inactive"} с-message-templates__widget-selector__tabs-tab${locales.length}`} 
                                    onClick={() => setSelectedLanguage(option.label)}>{option.heading}</button>
                                    <span className="с-message-templates__widget-selector__tabs-divider"></span>
                                </>,
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        if(locales.length > 3) {
            const options = locales.map(locale => ({
                label: locale,
                ariaLabel: locale,
                heading: locale,
            })) as TDropdownOption[];
            return (
                <div className="с-message-templates__dropdown-list">
                    <AccentedDropdown
                        options={options}
                        heading={t("Filter By Language")}
                        isMobileView={false}
                        onSelect={(option) => setSelectedLanguage(option.label)}
                        selectedOption={options.filter(option => option.label === selectedLanguage).pop()}
                        headerClassName={"с-message-templates__dropdown-header"}
                        headerTextClassName={"с-message-templates__dropdown-text"}
                        ariaLabel={t("Language Filter")}
                        data-testid={"Language-filter"}
                    />
                </div>
            );
        }
        return null;
    };
    
    return (
        <div className="с-message-templates">
            {shouldDisplaySendError && renderSendError()}
            {shouldRenderMessageTemplateListHeader() && renderMessageTemplateListHeader()}
            {selectedMessageTemplateId && !isMobile && <BackButton
                buttonId={"MESSAGE_TEPLATE_BACK_BUTTON"}
                hideButton={isSent || startConversationLoading}
                onClick={handleClickBackToAllButton}
            />}
            {selectedMessageTemplateId === null ? renderLanguageMenu() : null}
            {scrollTop > 0 && <div className="с-message-templates__divider"/>}
            <Scrollbars ref={scrollbarRef} onScroll={handleOnScroll}>
                {renderMessageTemplateList()}
            </Scrollbars>
        </div>
    );
}
