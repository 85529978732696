import { TAction } from "@/messenger/types/action.types";
import { ConversationCategory } from "@/messenger/types/entities/conversation";

enum ActionType {
    SET = "hummingbird/conversations/selectedCategory/SET",
}

export const setSelectedCategory = (data: ConversationCategory) => ({
    type: ActionType.SET,
    data,
});

const initialState: ConversationCategory = null;

const selectedCategoryReducer = (
    state: ConversationCategory = initialState,
    action: TAction<ActionType, ConversationCategory>,
) => {
    switch (action.type) {
        case ActionType.SET:
            return action.data;
        default:
            return state;
    }
};

export default selectedCategoryReducer;
