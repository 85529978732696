export enum DropdownMenu {
    REGULAR = "regular",
    COMPLEX = "complex",
}

export enum OptionIconPosition {
    LEFT = "left",
    RIGHT = "right",
}

export enum OptionVariant {
    NORMAL = "normal",
    ACCENTED = "accented",
}
