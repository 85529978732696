import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

import {
    VERIFY_MESSAGE_DEBOUNCE_TIME_VALUE,
} from "@/messenger/constants/compliance.settings";
import { useVerifyMessage } from "@/messenger/hooks/useVerifyMessage";
import { TComplianceAdvice } from "@/messenger/types/message";


interface TUseVerifyMessageDebouncedProps {
    content: string; // whole content text to verify
    valueToDebounce: string; // debounced text
    conversationId: string;
 }

export const useVerifyMessageDebounce = ({ content, conversationId, valueToDebounce }: TUseVerifyMessageDebouncedProps) => {
    const [textDebounced, { pending }] = useDebounce(valueToDebounce, VERIFY_MESSAGE_DEBOUNCE_TIME_VALUE);
    const [complianceAdvice, setComplianceAdvice] = useState<TComplianceAdvice[]>();
    
    const { data, error, loading } = useVerifyMessage({
        content,
        conversationId,
        shouldVerify: !!content && valueToDebounce === textDebounced,
    });
    
    useEffect(() => {
        if (data?.verifyMessage.content === content) {
            setComplianceAdvice(data.verifyMessage.complianceAdvices);
        }
    }, [data]);

    return { complianceAdvice, error, loading, textDebounced, pending };
};
