import { gql } from "@apollo/client";


export const startConversation = gql`
    mutation startConversation($StartConversationInput: StartConversationInput!) {
        startConversation(input: $StartConversationInput)
    }
`;

export const saveOptOut = gql`
    mutation SaveOptOut($SaveOptOutInput: SaveOptOutInput!) {
        saveOptOut(input: $SaveOptOutInput)
    }
`;

export const updateConversationStatus = gql`
    mutation updateConversationStatus($UpdateConversationStatusInput: UpdateConversationStatusInput!) {
        updateConversationStatus(input: $UpdateConversationStatusInput)
    }
`;

export const updateConversationCategory = gql`
    mutation updateConversationCategory($UpdateConversationCategoryInput: UpdateConversationCategoryInput!) {
        updateConversationCategory(input: $UpdateConversationCategoryInput)
    }
`;

export const saveConversationCall = gql`
    mutation saveConversationCall($SaveConversationCallInput: SaveConversationCallInput!) {
        saveConversationCall(input: $SaveConversationCallInput)
    }
`;

export const assignRepresentativeToContact = gql`
    mutation assignRepresentativeToContact($AssignRepresentativeToContact: AssignRepresentativeToContact!) {
        assignRepresentativeToContact(input: $AssignRepresentativeToContact)
    }
`;

export const hydrateConversation = gql`
    mutation hydrateConversation($conversationId: ID!) {
        hydrateConversation(conversationId: $conversationId)
    }
`;

export const updateContact = gql`
    mutation updateContact($UpdateContactInput: UpdateContactInput!) {
        updateContact(input: $UpdateContactInput)
    }
`;

export const saveRepresentativeIncomingAvailable = gql`
    mutation saveRepresentativeIncomingAvailable($availability: Boolean!) {
        saveRepresentativeIncomingAvailable(availability: $availability)
    }
`;

export const manageRepresentativeIncomingAvailable = gql`
    mutation manageRepresentativeIncomingAvailable($ManageRepresentativeIncomingAvailableInput: ManageRepresentativeIncomingAvailableInput) {
        manageRepresentativeIncomingAvailable(input: $ManageRepresentativeIncomingAvailableInput)
    }
`;

export const createContact = gql`
    mutation createContact($CreateContactInput: CreateContactInput!) {
        createContact(input: $CreateContactInput)
    }
`;