import { createMessage, getMessageIndex } from "@/messenger/models/message";
import { TMessage, TMessageDeliveryStatus } from "@/messenger/types/message";
import { generateUUID, getFileDataUrl } from "@/messenger/utils/helpers";

interface TComposeNotDeliveredMessage {
    body?: string;
    media?: File;
    deliveredMessages: TMessage[]
}

export const composeNotDeliveredMessage = async ({body, media, deliveredMessages}: TComposeNotDeliveredMessage): Promise<TMessage> => {
    const lastDeliveredMessage = !!deliveredMessages.length && deliveredMessages.pop();
    const notDeliveredMessage = createMessage({
        local: {
            status: TMessageDeliveryStatus.NOT_DELIVERED,
            prevDeliveredMessageIndex: getMessageIndex(lastDeliveredMessage),
            sid: generateUUID(),
        },
    });
    if (body) {
        return {
            ...notDeliveredMessage, local: {
                ...notDeliveredMessage.local,
                body,
            },
        };
    }
    if (media) {
        const mediaUrl = await getFileDataUrl(media);
        return {
            ...notDeliveredMessage, local: {
                ...notDeliveredMessage.local,
                media,
                mediaUrl,
            },
        };
    }
    return notDeliveredMessage;
    
};