import "./index.scss";

import cx from "classnames";
import React from "react";

import { SimpleDropdown } from "@/components/components/Dropdown";
import { OptionVariant } from "@/components/components/Dropdown/dropdown.enum";
import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";
import { Ellipse } from "@/components/icons";

const baseClassName = "c-chategories-dropdown";

interface TChategoriesDropdownProps {
    isMobileView: boolean;
    options: TDropdownOption[];
    label?: string;

    onSelect: (o: TDropdownOption) => void;
}

function ChatCategoriesDropdown(props: TChategoriesDropdownProps) {
    const { options, onSelect, isMobileView, label } = props;

    const renderOverride = (_, toggleMenu: () => void) => 
    <button className={cx(
        `${baseClassName}__card`,
        `${baseClassName}__card__OTHERS`,
        )}
        onClick={toggleMenu}
        id={"OTHERS_CATEGORY_CARD"}
        >
        <div className={`${baseClassName}__icon`}>{<Ellipse/>}</div>
        <div className={`${baseClassName}__label`}>{label || "Others"}</div>
    </button>;

    const optionClassNames = {
        icon: `${baseClassName}__dd-options`,
    };

    return <SimpleDropdown
        isMobileView={isMobileView}
        onSelect={onSelect}
        options={options}
        optionsVariant={isMobileView ? OptionVariant.ACCENTED : OptionVariant.NORMAL}
        optionClassNames={optionClassNames}
        optionsContainerClassNames={{ menu: `${baseClassName}__menu`, container: `${baseClassName}__container` }}
        renderOverride={renderOverride}
        search={false}
    />;
}
export default ChatCategoriesDropdown;