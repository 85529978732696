import {
    CompositeDecorator,
    ContentState,
    convertFromRaw,
    EditorState,
    EntityInstance,
    RawDraftContentState,
    SelectionState,
} from "draft-js";

import { ComplianceErrorItem } from "@/components/components/ComplianceError/ComplianceError";
import {
    TMessageTemplate,
    TMessageTemplatePlaceholder,
    TMessageTemplatePlaceholderInPlace,
} from "@/messenger/types/entities/messageTemplate";
import { TComplianceAdvice, TComposedComplianceAdvice } from "@/messenger/types/message";

import ComplianceAdvice from "../components/ComplianceAdvice";
import ComplianceAdviceWithPlaceholder, { TComplianceAdviceWithPlaceholdersEntityData } from "../components/ComplianceAdviceWithPlaceholder";
import MessageTemplatePlaceholder, { TPlaceholderEntityData } from "../components/MessageTemplatePlaceholder";
import {
    COMPLIANCE_ADVICE_KEY,
    COMPLIANCE_ADVICE_WITH_PLACEHOLDER_KEY,
    EditorContentBlockKeys,
    EntityType,
    MutabilityType,
    PLACEHOLDER_KEY,
} from "../constants/compliance.settings";
import { EMessageTemplateCommand } from "../containers/MessageTemplate";
import {
    composeComplianceAdvice,
    createEditorStateWithComplianceAdvice,
    getEditorRawContentWithComplianceAdvice,
    getEntityStrategyFactory,
} from "./compliance.helper";

const getPlaceholderValue = (
    placeholder: TMessageTemplatePlaceholder,
    selectedPlaceholder: TMessageTemplatePlaceholder,
): string => {
    let value;
    const isSelected = placeholder.placeholder === selectedPlaceholder?.placeholder;
    if (isSelected) {
        value = placeholder?.draftValue || placeholder?.placeholder;
    } else {
        value = placeholder?.value || placeholder?.placeholder;
    }
    return value;
};


const createEditorState = (
    content: ContentState,
    placeholderClickHandler: (index: number) => void,
): EditorState => {
    return EditorState.createWithContent(
        content,
        new CompositeDecorator([
            {
                strategy: getEntityStrategyFactory(EntityType.PLACEHOLDER),
                component: MessageTemplatePlaceholder,
                props: {
                    onClick: placeholderClickHandler,
                },
            },
            {
                strategy: getEntityStrategyFactory(EntityType.COMPLIANCE_ADVICE_WITH_PLACEHOLDER),
                component: ComplianceAdviceWithPlaceholder,
                props: {
                    onClick: placeholderClickHandler,
                },
            },
            {
                strategy: getEntityStrategyFactory(EntityType.COMPLIANCE_ADVICE),
                component: ComplianceAdvice,
                props: {
                    shouldShowPopover: false,
                },
            },
        ]),
    );
};

interface GetMessageTemplateContentProps {
    messageTemplate: TMessageTemplate;
    selectedPlaceholder?: TMessageTemplatePlaceholder;
    lastIndex?: number; // Needed to get message template partial content
    excludeEmptyPlaceholders?: boolean; // Message template content without empty placeholders (for compliance verification)
}

export const getMessageTemplateContent = ({
    excludeEmptyPlaceholders,
    lastIndex,
    messageTemplate,
    selectedPlaceholder,
}: GetMessageTemplateContentProps): string => {
    return messageTemplate.template.reduce((acc, chunk, index) => {
        if (lastIndex !== undefined && index > lastIndex) return acc;
        const placeholder = messageTemplate.placeholders.find(p => {
            return p.indices.includes(index);
        });
        if (placeholder) {
            if (excludeEmptyPlaceholders && !placeholder.draftValue) return acc;
            const v = getPlaceholderValue(placeholder, selectedPlaceholder);
            return acc + v;
        }
        return acc + chunk;
    }, "");
};

const getUpdatedDataForEntity = ({
    entity,
    placeholder,
    selectedPlaceholder,
}: TGetUpdatedDataForEntity): TGetUpdatedDataForEntityResult => {
    const type = entity.getType();
    if (type === EntityType.PLACEHOLDER)
        return {
            placeholder,
            selectedPlaceholder,
        };
    if (type === EntityType.COMPLIANCE_ADVICE_WITH_PLACEHOLDER) {
        return {
            selectedPlaceholder,
        };
    }
    if (type === EntityType.COMPLIANCE_ADVICE) return null;
    throw new Error(`Unknown entity type provided: "${type}"`);
};

const recalculateComplianceAdviceOffsets = (
    composedComplianceAdvice: TComposedComplianceAdvice[],
    messageTemplate: TMessageTemplate,
): TComposedComplianceAdvice[] => {
    return messageTemplate.placeholders.reduce((res, placeholder) => {
        if (!placeholder.draftValue) {
            return res.map(ca => {
                let delta = 0;
                placeholder.offsets.forEach(offset => {
                    if (offset <= ca.start) delta += placeholder.placeholder.length;
                });
                return {...ca, start: ca.start + delta, end: ca.end + delta};
            });
        }
        return res;
    }, composedComplianceAdvice);
};

const getPlaceholdersSequence = (
    placeholders: TMessageTemplatePlaceholder[],
    text: string,
): TMessageTemplatePlaceholderInPlace[] => {
    return (
        placeholders
            .reduce((acc, placeholder) => {
                placeholder.indices.forEach(index => {
                    const o = placeholder.offsets.get(index);
                    if (o >= 0 && o <= text.length) acc.push({placeholder, offset: o, index});
                });
                return acc;
            }, [] as TMessageTemplatePlaceholderInPlace[])
            // place placeholders in correct order
            .sort((a, b) => a.offset - b.offset)
    );
};

const getChunksOfComplianceAdviceWithPlaceholders = ({
    composedComplianceAdvice,
    placeholders,
    messageTemplateText,
}: TGetChunksOfComplianceAdviceWithPlaceholders): string[] => {
    const complianceAdviceText = messageTemplateText.substring(
        composedComplianceAdvice.start,
        composedComplianceAdvice.end,
    );
    
    const pls = placeholders
        // recalculate placeholders offsets
        .map(pl => {
            const offsets = new Map();
            pl.offsets.forEach((offset, index) => {
                offsets.set(index, offset - composedComplianceAdvice.start);
            });
            return {...pl, offsets};
        });
    const placeholdersSequence = getPlaceholdersSequence(pls, complianceAdviceText);
    const chunks = [];
    let textToRetrieveChunks = complianceAdviceText;
    let d = 0;
    placeholdersSequence.forEach(item => {
        const correctedOffset = item.offset - d;
        if (correctedOffset > 0) {
            // add text chunk (before placeholder)
            const text = textToRetrieveChunks.slice(0, correctedOffset);
            chunks.push({text});
            textToRetrieveChunks = textToRetrieveChunks.slice(correctedOffset);
            d += text.length;
        }
        chunks.push(item);
        const v = item.placeholder.draftValue ? item.placeholder.draftValue : item.placeholder.placeholder;
        textToRetrieveChunks = textToRetrieveChunks.slice(v.length);
        d += v.length;
    });
    // add text chunk at the end of compliance advice
    if (textToRetrieveChunks) chunks.push({text: textToRetrieveChunks});
    return chunks;
};

export const getEntityKeys = (messageTemplate: TMessageTemplate, editorState: EditorState): Map<string, string[]> => {
    return messageTemplate.placeholders.reduce((acc, placeholder) => {
        const ek = Array.from(placeholder.offsets.values()).map(offset => {
            return editorState
                .getCurrentContent()
                .getFirstBlock()
                .getEntityAt(offset);
        });
        return acc.set(
            placeholder.placeholder,
            ek.filter(item => !!item),
        );
    }, new Map());
};

const checkNextIndex = ({
    nextIndex,
    command,
    hasEmptyPlaceholders,
    placeholdersCount,
}): number => {
    // Highlight 'Send Message' button in case calculated index is out of boundaries
    // there are no empty placeholders
    if ((nextIndex === placeholdersCount || nextIndex < 0) && !hasEmptyPlaceholders)
        return null;
    
    if (command === EMessageTemplateCommand.SET_NEXT_PLACEHOLDER && nextIndex === placeholdersCount) {
        nextIndex = 0;
    } else if (command === EMessageTemplateCommand.SET_PREV_PLACEHOLDER && nextIndex < 0) {
        nextIndex = placeholdersCount - 1;
    }
    return nextIndex;
};

interface TGetMessageTemplateRawContentProps {
    messageTemplate: TMessageTemplate;
    composedComplianceAdvice: TComposedComplianceAdvice[];
    selectedPlaceholder: TMessageTemplatePlaceholder;
}

const getMessageTemplateRawContent = ({
    messageTemplate,
    composedComplianceAdvice,
    selectedPlaceholder,
}: TGetMessageTemplateRawContentProps): RawDraftContentState => {
    const complianceAdviceWithPlaceholdersInside: Map<TComposedComplianceAdvice,
        TMessageTemplatePlaceholder[]> = new Map();
    const entityRanges = [];
    const entityMap = {};
    
    if (messageTemplate.placeholders.length) {
        messageTemplate.placeholders.forEach(placeholder => {
            placeholder.offsets.forEach((offset, index) => {
                const ca = composedComplianceAdvice.find(item => {
                    return offset >= item.start && offset <= item.end;
                });
                
                if (ca) {
                    let placeholders = complianceAdviceWithPlaceholdersInside.get(ca);
                    if (!placeholders) {
                        placeholders = [placeholder];
                    } else if (!placeholders.includes(placeholder)) {
                        placeholders = [...placeholders, placeholder];
                    }
                    complianceAdviceWithPlaceholdersInside.set(ca, placeholders);
                } else {
                    const value = getPlaceholderValue(placeholder, selectedPlaceholder);
                    const key = PLACEHOLDER_KEY + index;
                    const entityRange = {
                        offset,
                        length: value.length,
                        key,
                    };
                    const entity = {
                        type: EntityType.PLACEHOLDER,
                        mutability: MutabilityType.MAPPED_FIELD,
                        data: {placeholder, index, selectedPlaceholder},
                    };
                    if (entityRange) entityRanges.push(entityRange);
                    if (entity) entityMap[key] = entity;
                }
            });
        });
    }
    
    const text = getMessageTemplateContent({messageTemplate, selectedPlaceholder});
    complianceAdviceWithPlaceholdersInside.forEach((placeholders, ca) => {
        const key = `${COMPLIANCE_ADVICE_WITH_PLACEHOLDER_KEY}_${ca.start}`;
        const entityRange = {
            offset: ca.start,
            length: ca.end - ca.start,
            key,
        };
        const chunks = getChunksOfComplianceAdviceWithPlaceholders({
            placeholders,
            composedComplianceAdvice: ca,
            messageTemplateText: text,
        });
        const entity = {
            type: EntityType.COMPLIANCE_ADVICE_WITH_PLACEHOLDER,
            mutability: MutabilityType.MAPPED_FIELD,
            data: {chunks, selectedPlaceholder},
        };
        if (entityRange) entityRanges.push(entityRange);
        if (entity) entityMap[key] = entity;
    });
    const complianceAdviceWithoutPlaceholdersInside = composedComplianceAdvice.filter(item => {
        return !Array.from(complianceAdviceWithPlaceholdersInside.keys()).find(ca => ca.start === item.start);
    });
    
    if (complianceAdviceWithoutPlaceholdersInside.length) {
        complianceAdviceWithoutPlaceholdersInside.forEach((item, index) => {
            const key = COMPLIANCE_ADVICE_KEY + index;
            entityRanges.push({
                offset: item.start,
                length: item.end - item.start,
                key,
            });
            entityMap[key] = {
                type: EntityType.COMPLIANCE_ADVICE,
                mutability: MutabilityType.COMPLIANCE_ADVICE,
                data: item.complianceAdvice,
            };
        });
    }
    
    return {
        blocks: [
            {
                key: EditorContentBlockKeys.MESSAGE_TEMPLATE_CONTENT,
                depth: 1,
                inlineStyleRanges: [],
                text,
                type: "unstyled",
                entityRanges,
            },
        ],
        entityMap,
    };
};

export const calculatePlaceholdersOffsets = (messageTemplate: TMessageTemplate): TMessageTemplate => {
    const placeholders = messageTemplate.template.reduce((acc, _, index) => {
        // check if chunk is placeholder
        const placeholder = messageTemplate.placeholders.find(p => {
            return p.indices.includes(index);
        });
        if (placeholder) {
            // check if placeholder already has offsets
            const p = acc.find(pl => {
                return pl.indices.includes(index);
            });
            const offsetsMap = p ? p.offsets : new Map();
            
            // Get partial message template content to get placeholder offset
            const v = index > 0 ? getMessageTemplateContent({messageTemplate, lastIndex: index - 1}) : "";
            offsetsMap.set(index, v.length);
            if (!p)
                acc.push({
                    ...placeholder,
                    offsets: offsetsMap,
                });
        }
        return acc;
    }, []);
    return {...messageTemplate, placeholders};
};

export const getComplianceErrors = (complianceAdvice: TComplianceAdvice[]): ComplianceErrorItem[] => {
    return complianceAdvice.map(ca => {
        return {
            value: ca.content,
            message: ca.reasonDescription,
            title: ca.reasonMessage,
            content: ca.content,
            count: 1,
        };
    });
};

interface GetComposedComplianceAdviceForInputFieldTextProps {
    inputText: string;
    selectedPlaceholderOffsets: Map<number, number>;
    complianceAdvice: TComplianceAdvice[];
}

export const getComposedComplianceAdviceForInputText = ({ inputText, selectedPlaceholderOffsets, complianceAdvice }: GetComposedComplianceAdviceForInputFieldTextProps): TComposedComplianceAdvice[] => {
    const complianceAdviceForSelectedPlaceholder = [];
    
    // Filter compliance advice, find those that relates to currently selected placeholder
    complianceAdvice?.forEach(item => {
        selectedPlaceholderOffsets?.forEach(offset => {
            if (item.contentArea.start <= offset + inputText.length && item.contentArea.end >= offset)
                complianceAdviceForSelectedPlaceholder.push(item);
        });
    });
    const composedComplianceAdvice = composeComplianceAdvice(complianceAdviceForSelectedPlaceholder);
    const composedComplianceAdviceWithCorrectedParams = [];
    
    // Correct compliance advice length and offsets,
    // set those needed for correct displaying in input field
    composedComplianceAdvice.forEach(item => {
        selectedPlaceholderOffsets.forEach(selectedPlaceholderOffset => {
            const start = item.start > selectedPlaceholderOffset ? item.start - selectedPlaceholderOffset : 0;
            const end = item.end - selectedPlaceholderOffset;
            if (end - start > 0 && start <= inputText.length)
                composedComplianceAdviceWithCorrectedParams.push({
                    ...item,
                    end,
                    start,
                });
        });
    });
    
    // Filter duplicated values
    // TODO: Could be good to get rid from this filtering (need to change function that corrects compliance advice params)
    return composedComplianceAdviceWithCorrectedParams.reduce(
        (acc: TComposedComplianceAdvice[], item: TComposedComplianceAdvice) => {
            if (!acc.find(ca => ca.end === item.end && ca.start === item.start)) acc.push(item);
            return acc;
        },
        [],
    );
};

interface TComposeInputEditorContentProps {
    text: string;
    complianceAdvice: TComposedComplianceAdvice[];
    selectionState: SelectionState;
}

export const composeInputEditorState = ({ text, complianceAdvice, selectionState }: TComposeInputEditorContentProps): EditorState => {
    const rawContent = getEditorRawContentWithComplianceAdvice(text, complianceAdvice);
    const content = convertFromRaw(rawContent);
    const state = createEditorStateWithComplianceAdvice(content);
    return EditorState.forceSelection(state, selectionState);
};

interface TGetChunksOfComplianceAdviceWithPlaceholders {
    composedComplianceAdvice: TComposedComplianceAdvice;
    placeholders: TMessageTemplatePlaceholder[];
    messageTemplateText: string;
}

interface TComposeMessageTemplateEditorContentProps {
    messageTemplate: TMessageTemplate;
    complianceAdvice: TComplianceAdvice[];
    selectedPlaceholder: TMessageTemplatePlaceholder;
    placeholderClickHandler: (index) => void;
}

export const composeMessageTemplateEditorState = ({
    messageTemplate,
    complianceAdvice,
    selectedPlaceholder,
    placeholderClickHandler,
}: TComposeMessageTemplateEditorContentProps): EditorState => {
    const messageTemplateWithPlaceholderOffsets = calculatePlaceholdersOffsets(messageTemplate);
    const composedComplianceAdvice = composeComplianceAdvice(complianceAdvice);
    
    /*
     * Message template content that is sent for verification does not include empty placeholders,
     * so offsets of compliance advice in verification response should be corrected,
     * because message template is rendered with empty placeholders
     */
    const ca = recalculateComplianceAdviceOffsets(composedComplianceAdvice, messageTemplateWithPlaceholderOffsets);
    
    const rawContent = getMessageTemplateRawContent({
        messageTemplate: messageTemplateWithPlaceholderOffsets,
        composedComplianceAdvice: ca,
        selectedPlaceholder,
    });
    const content = convertFromRaw(rawContent);
    return createEditorState(content, placeholderClickHandler);
};

export const getMessageTemplateWithUpdatedPlaceholders = (
    messageTemplate: TMessageTemplate,
    placeholdersToUpdate: TMessageTemplatePlaceholder[],
): TMessageTemplate => {
    const placeholders = messageTemplate.placeholders.map(pl => {
        const placeholderToUpdate = placeholdersToUpdate.find(item => item.placeholder === pl.placeholder);
        if (placeholderToUpdate) return {...placeholderToUpdate};
        return pl;
    });
    return {
        ...messageTemplate,
        placeholders,
    };
};

interface TGetUpdatedDataForEntity {
    entity: EntityInstance;
    placeholder: TMessageTemplatePlaceholder;
    selectedPlaceholder: TMessageTemplatePlaceholder;
}

type TGetUpdatedDataForEntityResult = TPlaceholderEntityData | TComplianceAdviceWithPlaceholdersEntityData;

interface TGetEditorStateWithUpdatedPlaceholderDataProps {
    editorState: EditorState;
    messageTemplate: TMessageTemplate;
    selectedPlaceholder: TMessageTemplatePlaceholder;
    placeholderClickHandler: (index: number) => void;
}

export const getEditorStateWithUpdatedPlaceholders = ({
    editorState,
    messageTemplate,
    selectedPlaceholder,
    placeholderClickHandler,
}: TGetEditorStateWithUpdatedPlaceholderDataProps): EditorState => {
    const currentMessageTemplateContentState = editorState.getCurrentContent();
    
    // Offsets of placeholders should be recalculated on each key stroke
    const mt = calculatePlaceholdersOffsets(messageTemplate);
    
    // Entities are placeholders at specific offsets (placeholder can be placed on several offsets)
    // and every entity has unique key that can be used to get access to its data
    const entityKeys = getEntityKeys(mt, editorState);
    
    const updatedMessageTemplateContentState = mt.placeholders.reduce((contentState, placeholder) => {
        const entityKeysForPlaceholder = entityKeys.get(placeholder.placeholder);
        if (!entityKeysForPlaceholder) return contentState;
        return entityKeysForPlaceholder.reduce((acc, entityKey) => {
            const entity = acc.getEntity(entityKey);
            const data = getUpdatedDataForEntity({
                entity,
                placeholder,
                selectedPlaceholder,
            });
            acc = acc.mergeEntityData(entityKey, data);
            return acc;
        }, contentState);
    }, currentMessageTemplateContentState);
    return createEditorState(updatedMessageTemplateContentState, placeholderClickHandler);
};

export const getPlaceholderOffsets = (messageTemplate: TMessageTemplate, placeholder: TMessageTemplatePlaceholder): Map<number, number> => {
    return placeholder?.indices.reduce((map: Map<number, number>, index) => {
        const v = getMessageTemplateContent({
            messageTemplate,
            lastIndex: index - 1,
            selectedPlaceholder: placeholder,
            excludeEmptyPlaceholders: true,
        });
        map.set(index, v.length);
        return map;
    }, new Map());
};

export const getPlaceholderOrderedList = (messageTemplate: TMessageTemplate): TMessageTemplatePlaceholderInPlace[] => {
    return messageTemplate.placeholders
        .reduce((acc, placeholder) => {
            placeholder.indices.forEach(index => {
                acc.push({index, placeholder});
            });
            return acc;
        }, [] as TMessageTemplatePlaceholderInPlace[])
        .sort((a, b) => a.index - b.index);
};

export const getFirstEmptyPlaceholder = (
    messageTemplate: TMessageTemplate,
    currentPlaceholderIndex: number,
): TMessageTemplatePlaceholder => {
    const placeholders = getPlaceholderOrderedList(messageTemplate);
    
    // Find first empty placeholder after current placeholder index
    const placeholder = placeholders
        .filter(item => {
            return item.index >= currentPlaceholderIndex;
        })
        .find(item => {
            return !item.placeholder.value;
        });
    
    // if there is no empty placeholder after current placeholder index
    // find first empty placeholder from the message template beginning
    if (!placeholder && placeholders.some(item => !item.placeholder.value)) {
        return placeholders.find(item => !item.placeholder.value).placeholder;
    }
    return placeholder?.placeholder;
};

export const getEmptySelectionState = (): SelectionState => {
    return new SelectionState({
        anchorKey: EditorContentBlockKeys.INPUT,
        focusKey: EditorContentBlockKeys.INPUT,
        hasFocus: true,
    });
};

export const checkIsPlaceholderValueChanged = (
    prevMessageTemplate: TMessageTemplate,
    currentMessageTemplate: TMessageTemplate,
): boolean => {
    return prevMessageTemplate.placeholders.some((prevMessageTemplatePlaceholder, index) => {
        const currentMessageTemplatePlaceholder = currentMessageTemplate.placeholders[index];
        return prevMessageTemplatePlaceholder.value !== currentMessageTemplatePlaceholder.value;
    });
};

interface TGetNextPlaceholder {
    currentPlaceholderIndex: number;
    placeholderOrderedList: TMessageTemplatePlaceholderInPlace[];
    command: EMessageTemplateCommand;
}

export const getNextPlaceholderData = ({
    currentPlaceholderIndex,
    placeholderOrderedList,
    command,
}: TGetNextPlaceholder): TMessageTemplatePlaceholderInPlace => {
    const currentIndex = placeholderOrderedList.findIndex(item => item.index === currentPlaceholderIndex);
    let nextIndex = command === EMessageTemplateCommand.SET_NEXT_PLACEHOLDER ? currentIndex + 1 : currentIndex - 1;
    const hasEmptyPlaceholders = placeholderOrderedList.some(item => !item.placeholder.draftValue);
    
    nextIndex = checkNextIndex({
        nextIndex,
        hasEmptyPlaceholders,
        command,
        placeholdersCount: placeholderOrderedList.length,
    });
    
    if (!Number.isInteger(nextIndex)) return null;
    
    // Skip placeholders with the same "placeholder"
    while (
        placeholderOrderedList[nextIndex]?.placeholder.placeholder ===
        placeholderOrderedList[currentIndex]?.placeholder.placeholder
        ) {
        nextIndex = command === EMessageTemplateCommand.SET_NEXT_PLACEHOLDER ? nextIndex + 1 : nextIndex - 1;
    }
    
    nextIndex = checkNextIndex({
        nextIndex,
        hasEmptyPlaceholders,
        command,
        placeholdersCount: placeholderOrderedList.length,
    });
    
    if (!Number.isInteger(nextIndex)) return null;
    
    return placeholderOrderedList[nextIndex];
};
