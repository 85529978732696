import React from "react";

function Home(props) {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <path d="M2.00012 7.33331L10.0001 1.11108L18.0001 7.33331V17.1111C18.0001 17.5826 17.8128 18.0348 17.4794 18.3682C17.146 18.7016 16.6938 18.8889 16.2223 18.8889H3.7779C3.3064 18.8889 2.85422 18.7016 2.52082 18.3682C2.18742 18.0348 2.00012 17.5826 2.00012 17.1111V7.33331Z" strokeWidth="1.77778" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.33337 18.8889V10H12.6667V18.8889" strokeWidth="1.77778" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>);
}

export default Home;
