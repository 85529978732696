import { TMessage } from "@/messenger/types/message";

export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";
export const messageReceivedAC = () => ({
    type: MESSAGE_RECEIVED,
});

export const MESSAGE_SENT = "MESSAGE_SENT";
export const messageSentAC = () => ({
    type: MESSAGE_SENT,
});

export const MESSAGE_NOT_DELIVERED = "MESSAGE_NOT_DELIVERED";
export const messageNotDeliveredAC = () => ({
    type: MESSAGE_NOT_DELIVERED,
});

export const MESSAGE_LOADED_MORE = "MESSAGE_LOADED_MORE";
export const messageLoadedMoreAC = () => ({
    type: MESSAGE_LOADED_MORE,
});

export const MESSAGE_LOADED_INITIAL_BATCH = "MESSAGE_LOADED_INITIAL_BATCH";
export const messageLoadedInitialBatchAC = (conversationId: string, isFirstBatch: boolean) => ({
    type: MESSAGE_LOADED_INITIAL_BATCH,
    payload: {conversationId, isFirstBatch},
});

export const MESSAGE_GET_FIRST_BATCH = "MESSAGE_GET_FIRST_BATCH";
export const messageGetFirstBatchAC = (conversationId: string) => ({
    type: MESSAGE_GET_FIRST_BATCH,
    payload: {conversationId},
});

export const MESSAGE_GET_PREVIOUS = "MESSAGE_GET_PREVIOUS";
export const messageGetPreviousAC = () => ({
    type: MESSAGE_GET_PREVIOUS,
});

export const LOAD_MESSAGE_MEDIA = "LOAD_MESSAGE_MEDIA";
export const loadMessageMediaAC = (message: TMessage) => ({
    type: LOAD_MESSAGE_MEDIA,
    payload: message,
});