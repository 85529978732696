import "./Popover.scss";

import cx from "classnames";
import React, { ReactElement, useEffect, useState } from "react";
import TinyPopover, { ArrowContainer } from "react-tiny-popover";

import { handleOnEnter } from "@/components/helpers";
import { ArrowLeft, ArrowRight } from "@/components/icons";

interface TPopoverProps {
    id: string;
    children: ReactElement;
    count: number | null;
    message: string | ReactElement;
    phrase: string;
    position?: "top" | "bottom" | "left" | "right";
    title: string | ReactElement;
    testId?: string;
    trigger?: "click" | "hover";
    onNext?: () => void;
    onPrev?: () => void;
}

const Popover= ({
    id,
    children,
    count = 0,
    message,
    phrase,
    position = "top",
    title,
    testId = "popover-test",
    trigger = "click",
    onNext,
    onPrev,
}: TPopoverProps) => {
    const [visible, setVisible] = useState<boolean>(false);

    const onOpenPopover = (e: CustomEvent) => {
        if (e.detail === id && trigger === "hover") {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("open:popover", onOpenPopover);

        return () => {
            document.removeEventListener("open:popover", onOpenPopover);
        };
    }, []);

    const onNextClass = cx({
        "c-popover__icon": true,
        "c-popover__icon-click": onNext,
        "c-popover__icon--hidden": count === 1,
    });
    const onPrevClass = cx({
        "c-popover__icon": true,
        "c-popover__icon-click": onNext,
        "c-popover__icon--hidden": count === 1,
    });

    const handleMouseToggle = () => {
        document.dispatchEvent(new CustomEvent("open:popover", { bubbles: true, detail: id }));
        setVisible(true);
    };

    const handleOnClick = () => {
        if (trigger === "click") {
            setVisible(true);
        }
    };

    const getLabel = () => {
        return count > 1 ? "Errors" : "Error";
    };

    const renderContent = ({ position: contentPosition, nudgedLeft, nudgedTop, targetRect, popoverRect }) => (
        <ArrowContainer 
            position={contentPosition}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor={"white"}
            arrowSize={6}
            arrowStyle={{ opacity: 1 }}
            >
            <div className={"c-popover__overlay"} data-testid={`${testId}-overlay`}>
                {count > 0 && (
                    <div className="c-popover__count">
                        {count} {getLabel()}
                    </div>
                )}
                <div className="c-popover__title-wrapper">
                    <div
                        className={onNextClass}
                        onClick={onPrev}
                        onKeyDown={e => handleOnEnter(e, onPrev)}
                        tabIndex={0}
                    >
                        {onPrev && <ArrowLeft width={10} height={10} />}
                    </div>
                    <div className="c-popover__title">{title}</div>
                    <div
                        className={onPrevClass}
                        onClick={onNext}
                        onKeyDown={e => handleOnEnter(e, onNext)}
                        tabIndex={0}
                    >
                        {onNext && <ArrowRight width={10} height={10} />}
                    </div>
                </div>
                <div className="c-popover__content">
                    <div className="c-popover__phrase">{phrase}</div>
                    <div className="c-popover__message">{message}</div>
                </div>
            </div>
        </ArrowContainer>
    );

    return (
        <TinyPopover
            isOpen={visible}
            position={position}
            onClickOutside={() => setVisible(false)}
            content={renderContent}
            containerClassName="c-popover__container"
        >
            <div
                id={id}
                style={{ display: "inline-block" }}
                data-testid={testId}
                onMouseOver={handleMouseToggle}
                onFocus={handleMouseToggle}
                onKeyDown={e => handleOnEnter(e, handleOnClick)}
                onClick={handleOnClick}
            >
                {children}
            </div>
        </TinyPopover>
    );
};

export default Popover;
