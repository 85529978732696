import "./index.scss";
import "../../locales/i18n";

import React from "react";
import { useTranslation } from "react-i18next";

import { Loading } from "@/components/icons";

const ChatLoading = () => {
    const { t } = useTranslation();
    
    return (
        <div className="c-chat-loading">
            <div className="c-chat-loading__info-card">
                <span className="c-chat-loading__info-card-icon">
                    <Loading width={16} height={16}/>
                </span>
                <span>{t("Loading conversation...")}</span>
            </div>
        </div>
    );
};

export default ChatLoading;
