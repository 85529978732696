import "./SimpleDropdown.scss";

import React, { PureComponent } from "react";
import Scrollbars from "react-custom-scrollbars";

import { OptionVariant } from "@/components/components/Dropdown/dropdown.enum";

import Search from "../../Search";
import Dropdown from "../Dropdown";
import { TDropdownOption } from "../dropdown.type";
import { DropdownDefaultRenderSelectedClassNames } from "../DropdownDefaultRenderSelected";
import DropdownOptions from "../DropdownOptions";
import { OptionClassNamesProps } from "../DropdownOptions/DropdownOptions";
import DropdownOptionsContainer, {
    OptionsContainerClassNames,
} from "../DropdownOptions/DropdownOptionsContainer";
import DropdownOptionsFooter from "./DropdownOptionsFooter";
import DropdownOptionsHeader from "./DropdownOptionsHeader";
import SimpleDropdownHeading from "./SimpleDropdownHeader";

export const dropdownOptionsBaseClassName = "c-simple-dropdown";

interface SimpleDropdownProps {
    disabled?: boolean;
    headerIcons?: {up: React.ReactNode, down: React.ReactNode};
    heading?: string;
    actionButton?: JSX.Element;
    ariaLabel?: string;
    ariaLabelledBy?: string;
    isMobileView?: boolean;
    withBackdrop?: boolean;
    isOpen?: boolean;
    options?: TDropdownOption[];
    optionsVariant?: OptionVariant;
    search?: boolean;
    selectedOption?: TDropdownOption;
    noResultMsg?: string
    rewordMsg?: string

    whenEmptyHeader?: string
    whenEmptySubheader?: string

    onSelect?: (o: TDropdownOption) => void;
    onOpen?: () => void;
    onClose?: () => void;
    renderOverride?: (isOpen?: boolean, toggleMenu?: () => void) => React.ReactNode;

    className?: string;
    dropdownDefaultRenderClassNames?: DropdownDefaultRenderSelectedClassNames;
    headerTextClassName?: string;
    optionClassNames?: OptionClassNamesProps;
    optionsContainerClassNames?: OptionsContainerClassNames;
    optionsContainerAriaLabel?: string;

    testid?: string;
}

interface SimpleDropdownState {
    filter: string;
}

class SimpleDropdown extends PureComponent<SimpleDropdownProps, SimpleDropdownState> {
    state: SimpleDropdownState = {
        filter: "",
    };

    onFilterChange = (v: string): void => this.setState({ filter: v });

    onSelect = (option: TDropdownOption) => {
        const { onSelect } = this.props;
        this.setState({ filter: "" });

        if (onSelect) {
            onSelect(option);
        }
    };

    onClose = () => {
        const { onClose } = this.props;
        this.setState({ filter: "" });
        if (onClose) onClose();
    };

    render() {
        const {
            renderOverride,
            isMobileView = false,
            withBackdrop = true,
            heading,
            actionButton,
            selectedOption,
            options,
            optionsVariant,
            optionsContainerClassNames,
            optionsContainerAriaLabel,
            optionClassNames,
            search,
            disabled,
            className,
            headerIcons,
            dropdownDefaultRenderClassNames,
            headerTextClassName,
            onOpen,
            ariaLabel,
            ariaLabelledBy,
            whenEmptyHeader,
            whenEmptySubheader,
            testid,
            noResultMsg,
            rewordMsg,
        } = this.props;

        if(options?.length > 0 && selectedOption) {
            const selectedOptionIndex = options.findIndex(o => o.label === selectedOption.label);
            if(selectedOptionIndex) {
                options.splice(selectedOptionIndex, 1);
                options.unshift(selectedOption);
            } 
        }

        const filteredOptions = options?.filter(o => o.heading.toLowerCase().includes(this.state.filter.toLowerCase()));

        const searchClassNames = {
            formContainer: `${dropdownOptionsBaseClassName}__search`,
            searchInput: `${dropdownOptionsBaseClassName}__search__input`,
        };

        const searchInHeading = () => {
            const getPlaceholder = () => {
                if (!selectedOption) {
                    return heading;
                }
                if (!selectedOption.label) {
                    return null;
                }
                return selectedOption.label;
            };
            return <Search
            hideIcons={true}
            classNames={searchClassNames}
            focusOnMount={true}
            onChange={this.onFilterChange}
            placeholder={getPlaceholder()}
            value={this.state.filter}
        />;
        };

        return (
            <Dropdown
                classNames={{ container: className }}
                defaultRenderClassNames={dropdownDefaultRenderClassNames}
                headerTextClassName={headerTextClassName}
                disabled={disabled}
                headerIcons={headerIcons}
                heading={
                    search ? searchInHeading()
                    : <SimpleDropdownHeading isMobileView={isMobileView} option={selectedOption} heading={heading} />
                }
                actionButton={actionButton}
                onClose={this.onClose}
                onOpen={onOpen}
                onSelect={this.onSelect}
                renderOverride={renderOverride}
                selectedOption={selectedOption}
                ariaLabel={ariaLabel}
                ariaLabelledBy={ariaLabelledBy}
                testid={testid}
                search={search}
            >
                {(onSelect, onClose) => (
                    <>
                        {isMobileView && withBackdrop && <div className={`${dropdownOptionsBaseClassName}__backdrop`} />}
                        {filteredOptions && <DropdownOptionsContainer classNames={optionsContainerClassNames} ariaLabel={optionsContainerAriaLabel}>

                            <Scrollbars autoHeight={true} autoHeightMax={306}>
                                {isMobileView && (
                                    <DropdownOptionsHeader
                                        heading={heading}
                                        className={`${dropdownOptionsBaseClassName}__heading`}
                                    />
                                )}
                                <DropdownOptions
                                    classNames={optionClassNames}
                                    onOptionSelect={onSelect}
                                    options={filteredOptions}
                                    hasOptions={options?.length > 0}
                                    whenEmptyHeader={whenEmptyHeader}
                                    whenEmptySubheader={whenEmptySubheader}
                                    variant={optionsVariant || OptionVariant.NORMAL}
                                    noResultMsg={noResultMsg}
                                    rewordMsg={rewordMsg}
                                    selectedOption={search ? selectedOption : null}
                                />
                                {isMobileView && (
                                    <DropdownOptionsFooter
                                        onClick={onClose}
                                        className={`${dropdownOptionsBaseClassName}__cancel-btn`}
                                    />
                                )}
                            </Scrollbars>
                        </DropdownOptionsContainer>}
                    </>
                )}
            </Dropdown>
        );
    }
}

export default SimpleDropdown;
