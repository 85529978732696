import { TAction } from "@/messenger/types/action.types";
import { TNotification, TNotificationId } from "@/messenger/types/notification.types";

enum ActionType {
    ADD = "hummingbird/general/notifications/ADD",
    REMOVE = "hummingbird/general/notifications/REMOVE",
}

export const addNotification = (notification: TNotification) => ({
    type: ActionType.ADD,
    data: notification,
});

export const removeNotification = (id: TNotificationId) => ({
    type: ActionType.REMOVE,
    data: id,
});

type TData = TNotification | TNotificationId;

const notificationsReducer = (state: TData[] = [], action: TAction<ActionType, TData>) => {
    switch (action.type) {
        case ActionType.ADD:
            return [...state, action.data as TNotification];
        case ActionType.REMOVE:
            return [...state.filter((n: TNotification) => n.id !== (action.data as TNotificationId))];
        default:
            return state;
    }
};

export default notificationsReducer;
