import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={13}
    height={14}
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.88804 7.77413C2.82971 7.7473 2.69788 7.74613 2.69788 7.8698V10.1903C2.69883 10.2125 2.70511 10.2341 2.71619 10.2534C2.72728 10.2726 2.74283 10.2889 2.76155 10.3009C2.78026 10.3128 2.80158 10.3201 2.8237 10.3221C2.84582 10.3241 2.86809 10.3207 2.88863 10.3122C3.13548 10.2044 3.34553 10.0269 3.49304 9.80149C3.64055 9.57609 3.71912 9.31255 3.71912 9.04317C3.71912 8.77379 3.64055 8.51026 3.49304 8.28486C3.34553 8.05945 3.13548 7.88196 2.88863 7.77413H2.88804Z" fill="#B2B2B2"/>
<path d="M9.33337 3.50001H11.6084C11.6373 3.50006 11.6655 3.49154 11.6895 3.47551C11.7136 3.45949 11.7323 3.4367 11.7434 3.41002C11.7544 3.38334 11.7573 3.35398 11.7517 3.32565C11.746 3.29733 11.7321 3.27132 11.7116 3.25093L8.70746 0.247925C8.68713 0.227547 8.66123 0.213639 8.63302 0.207952C8.6048 0.202266 8.57553 0.205056 8.5489 0.215971C8.52227 0.226886 8.49946 0.245438 8.48335 0.26929C8.46724 0.293141 8.45855 0.321227 8.45837 0.350008V2.62501C8.45837 2.85707 8.55056 3.07963 8.71466 3.24373C8.87875 3.40782 9.10131 3.50001 9.33337 3.50001Z" fill="#DBDBDB"/>
<path d="M6.12504 7.65511C5.98976 7.65541 5.86012 7.70929 5.76446 7.80494C5.66881 7.9006 5.61493 8.03025 5.61462 8.16552V9.91552C5.61462 10.0509 5.6684 10.1807 5.76412 10.2764C5.85984 10.3722 5.98967 10.4259 6.12504 10.4259C6.26041 10.4259 6.39024 10.3722 6.48596 10.2764C6.58168 10.1807 6.63546 10.0509 6.63546 9.91552V8.16669C6.63546 8.03121 6.58172 7.90126 6.48603 7.80536C6.39034 7.70945 6.26052 7.65541 6.12504 7.65511Z" fill="#B2B2B2"/>
<path d="M9.33329 4.08333H11.8125C11.8509 4.08333 11.8879 4.09854 11.9152 4.12563C11.9425 4.15273 11.958 4.18953 11.9583 4.228V12.8333C11.9583 13.1428 11.8354 13.4395 11.6166 13.6583C11.3978 13.8771 11.101 14 10.7916 14H1.45829C1.14887 14 0.852127 13.8771 0.633335 13.6583C0.414542 13.4395 0.291626 13.1428 0.291626 12.8333V1.16667C0.291626 0.857247 0.414542 0.560501 0.633335 0.341709C0.852127 0.122916 1.14887 0 1.45829 0H7.72912C7.7676 0 7.80452 0.0152031 7.83183 0.0423003C7.85915 0.0693975 7.87465 0.106192 7.87496 0.144667V2.625C7.87496 3.01177 8.0286 3.38271 8.30209 3.6562C8.57558 3.92969 8.94652 4.08333 9.33329 4.08333Z" fill="#DBDBDB"/>

  </svg>
);

export default SvgComponent;
