import "./index.scss";
import "../../locales/i18n";

import { useMutation } from "@apollo/client";
import cx from "classnames";
import i18next from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import Button from "@/components/components/Button";
import { ButtonColor, ButtonIconPosition } from "@/components/components/Button/button.enum";
import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";
import { H2 } from "@/components/components/Headings/H";
import { MessageType } from "@/components/enums";
import icons from "@/components/icons";
import ChatStatusDropdown from "@/messenger/components/ChatStatusDropdown";
import MobileChatHeader from "@/messenger/components/MobileChatHeader";
import RepDropdown from "@/messenger/components/RepDropdown";
import { CHAT } from "@/messenger/constants/routing";
import { representativesSelectors } from "@/messenger/ducks/entities/representatives";
import { representativeSettingsSelectors } from "@/messenger/ducks/entities/representativeSettings";
import { addNotification } from "@/messenger/ducks/general/notifications.duck";
import { getContactName } from "@/messenger/ducks/selectors/aggregate";
import { chatsSelectors } from "@/messenger/ducks/userInterface/chats";
import { assignRepresentativeToContact, updateConversationCategory, updateConversationStatus } from "@/messenger/graphql/mutations";
import { conversationById } from "@/messenger/selectors";
import { ConversationCategory } from "@/messenger/types/entities/conversation";
import { TRepresentative } from "@/messenger/types/entities/representative";
import { TStore } from "@/messenger/types/store.types";
import { detectMobile as detectIsMobile } from "@/messenger/utils/deviceType";


type ChatCategoryOption = TDropdownOption<ConversationCategory>
type ChatCategoryOptions = {
    [key in ConversationCategory]: ChatCategoryOption
}

export const CHAT_CATEGORY_OPTIONS: ChatCategoryOptions = {
    [ConversationCategory.OPEN] : {
        label: ConversationCategory.OPEN,
        heading: i18next.t("Open"),
    },
    [ConversationCategory.TO_ANSWER] : {
        label: ConversationCategory.TO_ANSWER,
        heading: i18next.t("To Answer"),
    },
    [ConversationCategory.TO_BE_CONTACTED]: {
        label: ConversationCategory.TO_BE_CONTACTED,
        heading: i18next.t("To Contact"),
    },
    [ConversationCategory.CLOSED]: {
        label: ConversationCategory.CLOSED,
        heading: i18next.t("Closed"),
    },
    [ConversationCategory.OPT_OUT]: {
        label: ConversationCategory.OPT_OUT,
        heading: i18next.t("Opt-out"),
    },
};

const getNextOptionsFromNextCategories = (nextCategories: ConversationCategory[]) : TDropdownOption[] => {
    if(!nextCategories || !nextCategories.length){
        return [];
    }
    const nextOptions = nextCategories.map(nextCategory => {
        switch(nextCategory) {
            case ConversationCategory.TO_ANSWER: 
                return {
                    label: ConversationCategory.TO_ANSWER,
                    status: ConversationCategory.TO_ANSWER,
                    heading: i18next.t("Move to To Answer"),
                };
            case ConversationCategory.TO_BE_CONTACTED: 
                return {
                    label: ConversationCategory.TO_BE_CONTACTED,
                    status: ConversationCategory.TO_BE_CONTACTED,
                    heading: i18next.t("Move to To Contact"),
                };
            case ConversationCategory.OPEN: 
                return {
                    label: ConversationCategory.OPEN,
                    status: ConversationCategory.OPEN,
                    heading: i18next.t("Move to Open"),
                };
            case ConversationCategory.CLOSED: 
                return {
                    label: ConversationCategory.CLOSED,
                    status: ConversationCategory.CLOSED,
                    heading: i18next.t("Close conversation"),
                };
            case ConversationCategory.OPT_OUT: 
                return {
                    label: ConversationCategory.OPT_OUT,
                    status: ConversationCategory.OPT_OUT,
                    heading: i18next.t("Move to Opt Out"),
                };
            default:
                return null;
        }
    });
    return nextOptions;
}; 

const BUTTONS_FULL_WIDTH = 156;
const BUTTONS_SHORT_WIDTH = 106;

export const TOPBAR_HEIGHT = 65;

const TopBar = () => {
    const upperRowRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const { id:conversationId } = useParams<any>();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [headerWidth, setHeaderWidth] = useState<number>(0);
    const [rowWidth, setRowWidth] = useState<number>(0);

    const [showOptionsForCategory, setShowOptionsForCategory] = useState<ConversationCategory>(null);

    const contactName = useSelector(getContactName);
    const deviceType = useSelector((s: TStore) => s.general.deviceType);
    
    const chatState = useSelector((s: TStore) => chatsSelectors.selectById(s, conversationId));
    const isEditingMessageTemplate = !!chatState?.chatStarter.selectedMessageTemplateId;

    const conversation = useSelector((s: TStore) => conversationById(conversationId)(s));
    const currentCategory = conversation?.category;
    const allowedNextCategories = conversation?.nextCategories;
    const nextOptions = getNextOptionsFromNextCategories(allowedNextCategories);

    const isInbound = conversation?.isInbound;
    
    const contactId = conversation?.contact;
    const representatives = useSelector(representativesSelectors.selectAll);

    const representativeSettings: TRepresentative[] = useSelector(representativeSettingsSelectors.selectAll);
    const currentUser  = representativeSettings[0]?.identifier;

    const [
        updateConversationStatusMutation,
    ] = useMutation<{
        updateConversationStatus: boolean;
    }>(updateConversationStatus);

    const [
        updateConversationCategoryMutation,
    ] = useMutation<{
        updateConversationCategory: boolean;
    }>(updateConversationCategory);

    const [assignRepresentativeToContactMutation] = useMutation(assignRepresentativeToContact, {
        onCompleted: () => {
            const notification = {
                id: `${contactId}_reassigned`,
                type: MessageType.NEUTRAL,
                message: "Contact Reassigned",
            };

            dispatch(addNotification(notification));
        },
    });

    const onSelect = (o: ChatCategoryOption) => {
        const {status: nextCategory} = o;
        if(!allowedNextCategories.map(category => category.toString()).includes(nextCategory)){
            console.error(`Category ${nextCategory} is not in list of allowed next categories: ${allowedNextCategories}`);
            return;
        }
        // if we are moving from CLOSED to TO_BE_CONTACTED, or <anything> to CLOSED, it needs to be done as a Status update since it's tied to Activate/Deactivate
        if((nextCategory === "TO_BE_CONTACTED" && currentCategory === "CLOSED") || nextCategory === "CLOSED"){
            updateConversationStatusMutation({
                variables: {
                    UpdateConversationStatusInput: {
                        conversationId,
                        conversationStatus: nextCategory === "TO_BE_CONTACTED" ? "OPEN" : "CLOSED", // status updates can only be OPEN or CLOSED
                    },
                },
            });
        // Any other category change needs to be handled as a Category override change
        }else{
            updateConversationCategoryMutation({
                variables: {
                    UpdateConversationCategoryInput: {
                        conversationId,
                        conversationCategory: nextCategory,
                    },
                },
            });
        }
    };

    const onSelectRepresentative = (representativeId : string) => {
        assignRepresentativeToContactMutation({
            variables: {
                AssignRepresentativeToContact: {
                    contact: contactId,
                    representative: representativeId,
                },
            },
        });
       
    };

    const baseClassName = "c-top-bar";
    const isMobile = detectIsMobile(deviceType);
    const isMobileMessageTemplateEditSection = isMobile && isEditingMessageTemplate;

    const shouldShowBackButtonText = (): boolean => {
        return headerWidth < rowWidth - BUTTONS_FULL_WIDTH;
    };

    const onChatsButtonClick = () => {        
        history.push(`/${CHAT}/`);
    };

    const renderBackButton = (): React.ReactElement => {
        return (
            <Button
                id={"TOP_BAR_BACK_BUTTON"}
                icon={icons.ANGLE_LEFT}
                color={ButtonColor.SECONDARY}
                className={cx(`${baseClassName}__back-button`, {
                    [`${baseClassName}__back-button--only-icon`]: !shouldShowBackButtonText(),
                })}
                onClick={onChatsButtonClick}
                ariaLabel={t("Top Bar Back Button")}
            >
                <div className={`${baseClassName}__back-button-text`}>
                    {!isMobileMessageTemplateEditSection && shouldShowBackButtonText() ? t("Chats") : ""}
                </div>
            </Button>
        );
    };

    const onClickInfoButton = () => {
        history.push(`/${CHAT}/${conversationId}/right`);
    };

    const renderInfoButton = (): React.ReactElement => {
        return (
            <Button
                id={"TOP_BAR_INFO_BUTTON"}
                icon={icons.INFO_CIRCLE_FULL}
                iconPosition={ButtonIconPosition.RIGHT}
                color={ButtonColor.SECONDARY}
                onClick={onClickInfoButton}
                className={`${baseClassName}__info-button`}
                ariaLabel={t("Top Bar Info Button")}
            >
                {" "}
            </Button>
        );
    };

    const getOptionsForReps = (reps: TRepresentative[]) => {

        return reps.map(rep => ({
                label:rep.participantId,
                heading:rep.identifier,
            }),
        );
    };

    const renderHeader = (): React.ReactElement => {
        const maxWidth = rowWidth && `${rowWidth - BUTTONS_SHORT_WIDTH}px`;
        return (
            <div ref={headerRef} className={`${baseClassName}__header`}>
                {!isMobile &&
                    <>
                        <div className={`${baseClassName}__header-title`}>
                            <H2 className={`${baseClassName}__contact`} style={{ maxWidth }}>
                                {contactName}
                            </H2>
                        </div>
                        
                    </>
                }
                {isMobile &&
                    <MobileChatHeader
                        category={currentCategory}
                        nextCategories={allowedNextCategories}
                        name={contactName}
                        options={nextOptions}
                        onSelect={onSelect}
                        isInbound={isInbound}
                    />
                }
                {isInbound && <RepDropdown options={getOptionsForReps(representatives)} currentUser={currentUser} onSelectRepresentative={onSelectRepresentative}/>}
            </div>
        );
    };

    const renderStatusMenu = (): React.ReactElement => {
        const isDropdownVisible = Object.values(ConversationCategory).includes(showOptionsForCategory);
        const isDisabled = showOptionsForCategory === ConversationCategory.OPT_OUT || allowedNextCategories?.length === 0;

        return (
            isDropdownVisible && (
                <div id="chat-status">
                    <ChatStatusDropdown
                        options={nextOptions}
                        selectedOption={CHAT_CATEGORY_OPTIONS[showOptionsForCategory]}
                        disabled={isDisabled}
                        isMobileView={isMobile}
                        onSelect={onSelect}
                        heading={t("Status")}
                        optionsContainerAriaLabel={t("Status Dropdown Options")}
                    />
                </div>
            )
        );
    };

    useEffect(() => {
        if (upperRowRef) setRowWidth(upperRowRef.current.getBoundingClientRect().width);
        if (headerRef) setHeaderWidth(headerRef.current.getBoundingClientRect().width);
    }, [upperRowRef, headerRef]);

    useEffect(() => {
        setShowOptionsForCategory(currentCategory);
    }, [currentCategory]);

    return (
        <div className={baseClassName} data-testid="topBar">
            <div
                ref={upperRowRef}
                className={cx(`${baseClassName}__wrapper ${baseClassName}__wrapper--left`)}
            >
                {isMobile && renderBackButton()}
                {renderHeader()}
                {isMobile && renderInfoButton()}
            </div>
            {!isMobile && !isMobileMessageTemplateEditSection && (
                <div className={`${baseClassName}__wrapper ${baseClassName}__wrapper--right`}>
                    {renderStatusMenu()}
                </div>
            )}
        </div>
    );
};

export default TopBar;
