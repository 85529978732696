import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 17 17" width={17} height={17} fill="currentColor" {...props}>
        <path d="M8.98775 0.998856C6.85015 1.03385 4.81219 1.90867 3.31442 3.43419C2.565 4.18031 1.97371 5.06987 1.5759 6.04969C1.17808 7.02952 0.9819 8.07949 0.999086 9.13686C0.997856 10.17 1.20053 11.1933 1.59549 12.148C1.99045 13.1028 2.56993 13.9702 3.30072 14.7005C4.03151 15.4309 4.89925 16.0098 5.85421 16.4042C6.80916 16.7986 7.83256 17.0007 8.86575 16.9989H9.00842C11.1467 16.9769 13.1889 16.1075 14.6869 14.5816C16.185 13.0557 17.0165 10.9978 16.9991 8.85952C17.0015 7.8138 16.7949 6.77814 16.3913 5.81345C15.9876 4.84876 15.3952 3.97451 14.6488 3.24213C13.9023 2.50974 13.017 1.93399 12.0448 1.54875C11.0726 1.16351 10.0332 0.976545 8.98775 0.998856ZM7.99909 12.0269C7.99428 11.8961 8.01558 11.7658 8.06175 11.6434C8.10792 11.521 8.17803 11.4091 8.26797 11.3141C8.35792 11.2191 8.4659 11.143 8.58559 11.0903C8.70529 11.0375 8.8343 11.0092 8.96509 11.0069H8.98309C9.24616 11.0074 9.49882 11.1097 9.6881 11.2924C9.87739 11.4751 9.9886 11.724 9.99842 11.9869C10.0033 12.1176 9.98207 12.248 9.93594 12.3704C9.8898 12.4928 9.8197 12.6048 9.72974 12.6998C9.63978 12.7948 9.53176 12.8709 9.41202 12.9236C9.29229 12.9763 9.16323 13.0046 9.03242 13.0069H9.01442C8.75145 13.006 8.49897 12.9036 8.30976 12.721C8.12054 12.5383 8.00923 12.2896 7.99909 12.0269ZM8.33242 9.33219V5.33219C8.33242 5.15538 8.40266 4.98581 8.52768 4.86079C8.65271 4.73576 8.82227 4.66552 8.99909 4.66552C9.1759 4.66552 9.34547 4.73576 9.47049 4.86079C9.59551 4.98581 9.66575 5.15538 9.66575 5.33219V9.33219C9.66575 9.509 9.59551 9.67857 9.47049 9.80359C9.34547 9.92862 9.1759 9.99886 8.99909 9.99886C8.82227 9.99886 8.65271 9.92862 8.52768 9.80359C8.40266 9.67857 8.33242 9.509 8.33242 9.33219Z" fill="#C92F24"/>
    </svg>
  );
}

export default SvgComponent;
