import * as React from "react";

function ChatInActive(props) {
    return (<svg width="20" height="20" viewBox="0 0 20 20" {...props}>
                <path d="M7.16667 14.44C6.98479 14.3877 6.79052 14.3989 6.61583 14.4717L3.57583 15.7383C3.5385 15.7538 3.49747 15.7582 3.45773 15.7508C3.41799 15.7434 3.38126 15.7246 3.35202 15.6966C3.32278 15.6687 3.30229 15.6329 3.29306 15.5936C3.28382 15.5542 3.28623 15.513 3.3 15.475L4.00917 13.5258C4.06613 13.3689 4.07466 13.1985 4.03365 13.0367C3.99264 12.8749 3.90398 12.7292 3.77917 12.6183C3.13596 12.0829 2.6147 11.4161 2.25034 10.6627C1.88599 9.90928 1.68699 9.08665 1.66667 8.25C1.66667 4.62167 5.405 1.66667 10 1.66667C14.595 1.66667 18.3333 4.62167 18.3333 8.25C18.3326 8.45701 18.3193 8.66378 18.2933 8.86917C18.2881 8.90642 18.2931 8.9444 18.3078 8.97905C18.3224 9.01369 18.3463 9.04369 18.3767 9.06583C18.7601 9.33341 19.1143 9.64067 19.4333 9.9825C19.4591 10.0096 19.4916 10.0292 19.5275 10.0395C19.5634 10.0498 19.6014 10.0502 19.6375 10.0408C19.6738 10.0316 19.7069 10.0127 19.7333 9.9861C19.7597 9.95955 19.7784 9.92634 19.7875 9.89C19.9256 9.35417 19.997 8.80333 20 8.25C20 3.7025 15.5142 0 10 0C4.48583 0 0 3.7025 0 8.25C0.0136243 9.22538 0.220023 10.1884 0.607315 11.0837C0.994608 11.979 1.55515 12.7889 2.25667 13.4667L0.923333 17.1333C0.867908 17.2855 0.858021 17.4505 0.894882 17.6082C0.931744 17.7659 1.01376 17.9095 1.13089 18.0213C1.24803 18.1331 1.39521 18.2084 1.55444 18.2379C1.71368 18.2674 1.87806 18.2499 2.0275 18.1875L6.99333 16.1183C7.27917 16.1933 7.56667 16.2542 7.85667 16.305C7.89035 16.3109 7.92496 16.3083 7.95744 16.2977C7.98992 16.287 8.01927 16.2685 8.0429 16.2438C8.06654 16.2191 8.08373 16.1889 8.09296 16.156C8.10219 16.1231 8.10318 16.0884 8.09583 16.055C7.99762 15.6388 7.9404 15.214 7.925 14.7867C7.92296 14.7402 7.90544 14.6958 7.87524 14.6605C7.84504 14.6251 7.80389 14.6009 7.75833 14.5917C7.56111 14.5467 7.36389 14.4961 7.16667 14.44Z" fill="#4B64D7"/>
                <path d="M11.9999 10.7584C12.74 10.2639 13.61 10 14.5 10C15.693 10.0015 16.8368 10.476 17.6804 11.3196C18.524 12.1632 18.9985 13.307 19 14.5C19 15.39 18.7361 16.26 18.2416 17.0001C17.7471 17.7401 17.0443 18.3169 16.2221 18.6575C15.3998 18.998 14.495 19.0872 13.6221 18.9135C12.7492 18.7399 11.9474 18.3113 11.318 17.682C10.6887 17.0526 10.2601 16.2508 10.0865 15.3779C9.91283 14.505 10.0019 13.6002 10.3425 12.7779C10.6831 11.9557 11.2599 11.2529 11.9999 10.7584Z" fill="#B2B2B2"/>
            </svg>
    );
}

export default ChatInActive;
