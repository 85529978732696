import { CompositeDecorator, ContentBlock, ContentState, EditorState, RawDraftContentState } from "draft-js";

import { TComplianceAdvice, TComposedComplianceAdvice } from "@/messenger/types/message";

import ComplianceAdvice from "../components/ComplianceAdvice";
import {
    COMPLIANCE_ADVICE_KEY,
    EditorContentBlockKeys,
    EntityType,
    MutabilityType,
} from "../constants/compliance.settings";


/**
 * Groups compliance advice with overlapping start/end coordinates. These overlapping groups are returned as a list of composed compliance advice.
 * @param ca compliance advice list
 * @returns A new list of composed compliance advice
 */
export const composeComplianceAdvice = (ca: TComplianceAdvice[]): TComposedComplianceAdvice[] => {
    const overlaps = (item, rangeStart, rangeEnd) => {
        const { start, end } = item.contentArea;
        return (start >= rangeStart && start <= rangeEnd) || (end >= rangeStart && end <= rangeEnd);
    }; 

    return ca
        .sort((a, b) => a.contentArea.start - b.contentArea.start)
        .reduce((acc: TComposedComplianceAdvice[], item) => {
            if (acc.length > 0) {
                const prev = acc[acc.length - 1];
                const { start, end, complianceAdvice } = prev;
                
                if (overlaps(item, start, end)) {
                    complianceAdvice.push(item);
                    prev.start = Math.min(item.contentArea.start, start);
                    prev.end = Math.max(item.contentArea.end, end);
                    return acc;
                }
            }

            acc.push({
                complianceAdvice: [item],
                start: item.contentArea.start,
                end: item.contentArea.end,
            });

            return acc;
        }, []);
};


export interface ComplianceAdviceByType {
    content: TComplianceAdvice[],
    other: TComplianceAdvice[]
}
/**
 * Categorizes compliance advice into two groups: advice referring to message content, and advice referring to anything else.
 * @param ca compliance advice list
 * @returns ComplianceAdviceByType
 */
export const categorizeComplianceAdviceByType = (ca: TComplianceAdvice[] = []): ComplianceAdviceByType => {
    return ca.reduce((acc, item) => {
        if (item.contentArea) {
            acc.content.push(item);
        } else {
            acc.other.push(item);
        }
        return acc;
    }, {content: [], other: []});
};

/**
 * Creates an editor content block for the draft-js editor using the provided text with the provided composed compliance advice.
 * @param text string text that this block encompasses
 * @param ca composed compliance advice list pertaining to provided text
 * @returns RawDraftContentState
 */
export const getEditorRawContentWithComplianceAdvice = (
    text: string,
    ca: TComposedComplianceAdvice[],
): RawDraftContentState => {
    const rawDraftContentState: RawDraftContentState = {
        blocks: [{
            key: EditorContentBlockKeys.INPUT,
            depth: 1,
            inlineStyleRanges: [],
            text,
            type: "unstyled",
            entityRanges: [],
        }],
        entityMap: {},
    };

    return ca.reduce((acc, item, index) => {
        const key = COMPLIANCE_ADVICE_KEY + index;
        acc.blocks[0].entityRanges.push({
            offset: item.start,
            length: item.end - item.start,
            key,
        });
        acc.entityMap[key] = {
            type: EntityType.COMPLIANCE_ADVICE,
            mutability: MutabilityType.COMPLIANCE_ADVICE,
            data: item.complianceAdvice,
        };
        return acc;
    }, rawDraftContentState);
};


export const getEntityStrategyFactory = (entityType: EntityType) => {
    return (contentBlock: ContentBlock, callback: (...args: any) => any, contentState: ContentState) => {
        contentBlock.findEntityRanges(character => {
            const entityKey = character.getEntity();
            if (entityKey === null) {
                return false;
            }
            return contentState.getEntity(entityKey).getType() === entityType;
        }, callback);
    };
};

export const COMPOSITE_DECORATOR_COMPLIANCE_ADVICE = [
    {
        strategy: getEntityStrategyFactory(EntityType.COMPLIANCE_ADVICE),
        component: ComplianceAdvice,
        props: {
            shouldShowPopover: true,
        },
    },
];

export const createEditorStateWithComplianceAdvice = (content: ContentState) => {
    return EditorState.createWithContent(
        content,
        new CompositeDecorator(COMPOSITE_DECORATOR_COMPLIANCE_ADVICE),
    );
};


