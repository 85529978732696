import "./index.scss";
import "../../locales/i18n";

import React from "react";
import { useTranslation } from "react-i18next";

import Button from "@/components/components/Button/Button";
import { ButtonColor, ButtonSize } from "@/components/components/Button/button.enum";
import icons from "@/components/icons";

interface TFormHeaderProps {
    name: string,
    title: string,
    onClose: () => void
}

export const closeButtonTestId = "closeButtonTestId";

const FormHeader = ({ name, title, onClose }: TFormHeaderProps) => {
    const { t } = useTranslation();
    return (
        <header className="c-form__header">
            <span className="c-form__header-text">
                {title}
            </span>
            <Button
                id={`${name}_CLOSE_BUTTON`}
                testId={closeButtonTestId}
                className="c-form__header__close-button"
                color={ButtonColor.SECONDARY}
                icon={icons.CLOSE}
                size={ButtonSize.REGULAR}
                onClick={onClose}
                ariaLabel={t("Close Form")}
            />
        </header>
    );
};

export default FormHeader;