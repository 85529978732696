import "./index.scss";

import React from "react";

interface TChatLabelProps {
    label: string
}

const ChatLabel = ({ label }: TChatLabelProps) => {
    return <div className="c-chat-label__container">
        <div className="c-chat-label__text">    
            {label}
        </div>
        <hr />
    </div>;
};

export default ChatLabel;