import { useMutation, useSubscription } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { CHAT } from "@/messenger/constants/routing";
import { addConversationAC, detatchConversationAC, removeConversationAC, setConversationCategoryAC, setConversationOptOutAC, updateConversationContactAC } from "@/messenger/ducks/entities/conversations";
import { updateRepresentativeSettingsAC } from "@/messenger/ducks/entities/representativeSettings";
import { setSelectedCategory } from "@/messenger/ducks/general/selectedCategory.duck";
import { selectedCategorySelector } from "@/messenger/ducks/selectors/general";
import { upsertOneChat } from "@/messenger/ducks/userInterface/chats";
import { hydrateConversation } from "@/messenger/graphql/mutations";
import { subscribeToConversations } from "@/messenger/graphql/subscriptions";
import { activeConversationIdSelector } from "@/messenger/selectors";
import { ConversationCategory } from "@/messenger/types/entities/conversation";
import { HydrationEnum } from "@/messenger/types/graphql/autogenerated";
import { normalizeOneConversation } from "@/messenger/utils/normalize";



export enum ConversationSubscriptionEvents {
    CategoryChanged = "ConversationCategoryChangedEvent",
    OptOutChanged = "ConversationOptOutChangedEvent",
    ConversationRemoved = "ParticipantRemovedFromConversationEvent",
    ConversationAdded = "ParticipantAddedToConversationEvent",
    ContactChanged = "ConversationContactChangedEvent",
    RepresentativeIncomingAvailableChanged = "RepresentativeIncomingAvailableChangedEvent",
}

// Hack to ensure that any conversations that are rehydrated more than once in a single session do not cause sync errors with Twilio.
const rehydratedConversations = {};

const GetConversationsSubscription =  () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const currentCategory = useSelector(selectedCategorySelector);
    const activeConversationId = useSelector(activeConversationIdSelector);

    const removeConversationCallback = (id: string, activeConvoId: string, nextConvoId: string) => {
        if (id === activeConvoId) {
            if (nextConvoId) {
                history.push(`/${CHAT}/${nextConvoId}`);
            }  else {
                history.push(`/${CHAT}`);
            }
        }
    };

    const addHighlightConversationCallback = (conversationId: string, category: ConversationCategory) => {
        history.push(`/${CHAT}/${conversationId}`);
        if (!currentCategory || currentCategory !== category) {
            dispatch(setSelectedCategory(category));
        }
    };

    const [hydrateConversationMutation] = useMutation<{
        hydrateConversation: boolean;
    }>(hydrateConversation);
    
    const hydrateConvo = (convoId) => {
        hydrateConversationMutation({
            variables: {conversationId: convoId},
        });
    };
    
    useSubscription(subscribeToConversations, { 
        onSubscriptionData: ({ subscriptionData }: any) => {
            if (subscriptionData) {
                const eventPayload = subscriptionData.data.subscribeToConversations;
                try {
                    // eslint-disable-next-line no-underscore-dangle
                    switch (eventPayload.__typename){
                        case(ConversationSubscriptionEvents.CategoryChanged):
                            dispatch(setConversationCategoryAC(eventPayload));
                            break;
                        case(ConversationSubscriptionEvents.OptOutChanged):
                            dispatch(setConversationOptOutAC(eventPayload));
                            break;
                        case(ConversationSubscriptionEvents.ConversationRemoved):
                            if (eventPayload.fromHydrationEvent){
                                dispatch(detatchConversationAC(eventPayload, removeConversationCallback));
                                if(activeConversationId === eventPayload.conversationId){
                                    hydrateConvo(eventPayload.conversationId);
                                    dispatch(upsertOneChat({id: eventPayload.conversationId, hydration: HydrationEnum.Hydrated}));
                                    setTimeout(() => {
                                        history.push(`/${CHAT}/${activeConversationId}`);
                                    }, 600);
                                }
                            }else{
                                dispatch(removeConversationAC(eventPayload, removeConversationCallback));
                            }
                            break;
                        case(ConversationSubscriptionEvents.ConversationAdded):
                            if (eventPayload.conversation.campaign !== null && eventPayload.conversation.hydration === HydrationEnum.Hydrated) {
                                if(eventPayload.fromHydrationEvent){
                                    if(rehydratedConversations[eventPayload.conversation.id]){
                                        history.go(0); // Forces app to reload if a conversation is rehydrated more than once in a single session
                                    }else{
                                        rehydratedConversations[eventPayload.conversation.id] = 1;
                                    }
                                }
                                const normalizedConversationData = normalizeOneConversation(eventPayload.conversation);
                                const isHighlight = eventPayload.highlight && !eventPayload.fromHydrationEvent;
                                dispatch(addConversationAC(normalizedConversationData, eventPayload.conversation.id, isHighlight, addHighlightConversationCallback));
                            }
                            break;
                        case(ConversationSubscriptionEvents.ContactChanged):
                            dispatch(updateConversationContactAC(eventPayload));
                            break;
                        case(ConversationSubscriptionEvents.RepresentativeIncomingAvailableChanged):
                            dispatch(updateRepresentativeSettingsAC(eventPayload));
                            break;
                        default:
                            break;
                    }
                } catch (e) {
                    console.error(e);
                }
                
                
            }
        },
    });

    return null;
};

export default GetConversationsSubscription;
