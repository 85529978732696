import "./Switch.scss";

import cx from "classnames";
import React, { PureComponent } from "react";

import { handleOnEnter } from "@/components/helpers";

import { SwitchValue } from "./switch.type";

interface SwitchDefaultProps {
    disabled?: boolean;
}

interface SwitchProps extends SwitchDefaultProps {
    /** SwitchValue { on: boolean; label?: string;} */
    value: SwitchValue;
    onChange?: (sv: SwitchValue) => void;
    iconOn?: React.ReactNode;
    iconOff?: React.ReactNode;
    className?: string;
    testId?: string;
    id?: string;
}

interface SwitchState {
    isOn: boolean;
}

class Switch extends PureComponent<SwitchProps, SwitchState> {
    baseClassName = "c-switch";

    static defaultProps: SwitchDefaultProps = {
        disabled: false,
    };

    constructor(props: SwitchProps) {
        super(props);

        this.state = {
            isOn: this.props.value.on,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle(): void {
        if (!this.props.disabled) {
            const isOn = !this.state.isOn;
            this.setState({ isOn });
            if (this.props.onChange) this.props.onChange({ ...this.props.value, on: isOn });
        }
    }

    getIcon(): React.ReactNode {
        return this.state.isOn ? this.props.iconOn : this.props.iconOff;
    }

    getSwitchClassName = (): string => {
        const c = `${this.baseClassName}`;
        return cx(c, this.props.className, { [`${c}--disabled`]: this.props.disabled });
    };

    getSwitchSwitcherClassName = (): string => {
        const c = `${this.baseClassName}__switcher`;
        return cx(
            c,
            { [`${c}--disabled`]: this.props.disabled },
            { [`${c}--on`]: this.state.isOn },
            { [`${c}--off`]: !this.state.isOn },
        );
    };

    render(): React.ReactNode {
        return (
            <div
                id={this.props.id}
                className={this.getSwitchClassName()} 
                onClick={this.toggle}
                onKeyDown={e => handleOnEnter(e, this.toggle)}
                tabIndex={0}
                data-testid={this.props.testId}
            >
                <div className={this.getSwitchSwitcherClassName()}>
                    <span className={"c-switch__circle"}>{this.getIcon()}</span>
                </div>
                {this.props.value.label && <span className="c-switch__label">{this.props.value.label}</span>}
            </div>
        );
    }
}

export default Switch;
