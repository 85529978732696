import "./index.scss";
import "../../locales/i18n";

import { DraftHandleValue, Editor, EditorState, getDefaultKeyBinding, SelectionState } from "draft-js";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { usePrevious } from "react-use";

import MessageTemplateComplianceErrorBox from "@/messenger/components/MessageTemplateComplianceErrorBox";
import MessageTemplateContent from "@/messenger/components/MessageTemplateContent";
import MessageTemplateEditForm from "@/messenger/components/MessageTemplateEditForm";
import MessageTemplateLoading from "@/messenger/components/MessageTemplateLoading";
import MessageTemplateSendButton from "@/messenger/components/MessageTemplateSendButton";
import MessageTemplateValidateHint from "@/messenger/components/MessageTemplateValidateHint";
import { SELECTED_PLACEHOLDER } from "@/messenger/constants/domID";
import { topicsSelectors } from "@/messenger/ducks/entities/topics";
import { chatsSelectors, updateChatMessageTemplate, updateChatStarter } from "@/messenger/ducks/userInterface/chats";
import { useVerifyMessage } from "@/messenger/hooks/useVerifyMessage";
import { useVerifyMessageDebounce } from "@/messenger/hooks/useVerifyMessageDebounce";
import { useVerifyMessagePolling } from "@/messenger/hooks/useVerifyMessagePolling";
import { activeConversationIsOptOutSms } from "@/messenger/selectors";
import {
    EMessageTemplateSelectedPlaceholderType,
    TMessageTemplate,
    TMessageTemplatePlaceholder,
    TMessageTemplatePlaceholderInPlace,
} from "@/messenger/types/entities/messageTemplate";
import { TComplianceAdvice } from "@/messenger/types/message";
import { TChatStarterState, TStore } from "@/messenger/types/store.types";
import { categorizeComplianceAdviceByType } from "@/messenger/utils/compliance.helper";
import { getEmptySelectionState } from "@/messenger/utils/MessageInput.helper";
import {
    checkIsPlaceholderValueChanged,
    composeInputEditorState,
    composeMessageTemplateEditorState,
    getComposedComplianceAdviceForInputText,
    getEditorStateWithUpdatedPlaceholders,
    getFirstEmptyPlaceholder,
    getMessageTemplateContent,
    getMessageTemplateWithUpdatedPlaceholders,
    getPlaceholderOffsets,
} from "@/messenger/utils/messageTemplates.helper";

export enum EMessageTemplateCommand {
    SAVE_PLACEHOLDER_VALUE = "save-placeholder-value",
    SET_NEXT_PLACEHOLDER = "set-next-placeholder",
    SET_PREV_PLACEHOLDER = "set-prev-placeholder",
}

interface TMessageTemplateProps {
    messageTemplate: TMessageTemplate;
    isSending: boolean;
    isSent: boolean;
    handleSendChatStarterStart: (message: string) => void;
    handleSendChatStarterFinish: () => void;
    activeConversationId: string;
}

export default function MessageTemplate({
    messageTemplate,
    isSending,
    isSent,
    handleSendChatStarterStart,
    handleSendChatStarterFinish,
    activeConversationId,
}: TMessageTemplateProps): ReactElement {
    const [contentEditorState, setContentEditorState] = useState<EditorState>(EditorState.createEmpty());
    const [inputEditorState, setInputEditorState] = useState<EditorState>(EditorState.createEmpty());
    const [inputSelectionState, setInputSelectionState] = useState<SelectionState>(getEmptySelectionState());

    const { t } = useTranslation();

    const isOptOutSms = useSelector(activeConversationIsOptOutSms);

    const [isJustOpened, setIsJustOpened] = useState<boolean>(true);
    const [shouldVerifyMessage, setShouldVerifyMessage] = useState<boolean>(false);


    const topicName = useSelector((state: TStore) => topicsSelectors.selectById(state, messageTemplate?.topicId))?.name;
    const prevActiveConversationId = usePrevious(activeConversationId);

    const chatState = useSelector((s: TStore) => chatsSelectors.selectById(s, activeConversationId));
    const { chatStarter: { selectedMessageTemplateId, selectedPlaceholder, inputComplianceAdvice }} = chatState;

    const dispatch = useDispatch();

    const prevMessageTemplate: TMessageTemplate = usePrevious(messageTemplate);
    const prevSelectedPlaceholder: TMessageTemplatePlaceholderInPlace = usePrevious(selectedPlaceholder);

    const placeholderRef = useRef<any>();
    placeholderRef.current = document.getElementById(SELECTED_PLACEHOLDER);

    const {
        data: verifyMessageData,
        error: verifyMessageError,
        loading: verifyMessageLoading,
    } = useVerifyMessage({
        content: getMessageTemplateContent({
            messageTemplate,
            selectedPlaceholder: selectedPlaceholder?.placeholder,
            excludeEmptyPlaceholders: true,
        }),
        conversationId: activeConversationId,
        shouldVerify: shouldVerifyMessage,
    });

    const {
        complianceAdvice: complianceAdviceOnDebounce,
        textDebounced: inputTextDebounced,
        loading: verifyInputLoading,
        error: verifyInputError,
        pending: inputTextDebouncePending,
    } = useVerifyMessageDebounce({
        content: getMessageTemplateContent({
            messageTemplate,
            selectedPlaceholder: selectedPlaceholder?.placeholder,
            excludeEmptyPlaceholders: true,
        }),
        conversationId: activeConversationId,
        valueToDebounce: selectedPlaceholder?.placeholder.draftValue,
    });

    const isSelected = selectedMessageTemplateId === messageTemplate.id;
    const hasSelectedPlaceholder = Boolean(selectedPlaceholder);
    const shouldDisplayEditForm = isSelected && hasSelectedPlaceholder;

    const shouldRenderSendButton = isSelected && !shouldDisplayEditForm && !isSent;

    const shouldRenderLoading = !isSelected && verifyMessageLoading;

    const emptyPlaceholdersCount = messageTemplate.placeholders?.filter(item => !item.value).length;

    const {
        complianceAdvice: complianceAdviceOnPolling,
    } = useVerifyMessagePolling({
            content: getMessageTemplateContent({
                messageTemplate,
                selectedPlaceholder: selectedPlaceholder?.placeholder,
                excludeEmptyPlaceholders: true,
            }),
            conversationId: activeConversationId,
            // run verification only if user is typing or there is no pending request
            shouldVerify: (!verifyInputLoading || inputTextDebouncePending()) && inputTextDebounced !== selectedPlaceholder?.placeholder.draftValue,
            cursorOffset: inputEditorState.getSelection().getFocusOffset(),
            textOffset: selectedPlaceholder?.placeholder.offsets?.get(selectedPlaceholder?.placeholder.indices[0]),
    });

    const getContentRelatedComplianceAdvice = (complianceAdvice: TComplianceAdvice[]): TComplianceAdvice[] => {
        const { content } = categorizeComplianceAdviceByType(complianceAdvice);

        return content;
    };

    const onVerifyInput = (complianceAdvice: TComplianceAdvice[]): void => {
        const inputText = inputEditorState.getCurrentContent().getPlainText();
        const selectedPlaceholderOffsets = getPlaceholderOffsets(messageTemplate, selectedPlaceholder?.placeholder);
        const ca = getContentRelatedComplianceAdvice(complianceAdvice);
        const composedComplianceAdvice = getComposedComplianceAdviceForInputText({
            inputText,
            selectedPlaceholderOffsets,
            complianceAdvice: ca,
        });

        dispatch(updateChatStarter({
            id: activeConversationId,
            chatStarter: {
                inputComplianceAdvice: composedComplianceAdvice,
            },
        }));

        const es = composeInputEditorState({
            complianceAdvice: composedComplianceAdvice,
            selectionState: inputSelectionState,
            text: inputText,
        });

        setInputEditorState(es);
    };

    useEffect(() => {
        if (complianceAdviceOnDebounce) {
            onVerifyInput(complianceAdviceOnDebounce);
        }
    }, [complianceAdviceOnDebounce]);

    useEffect(() => {
        if (complianceAdviceOnPolling) {
            onVerifyInput(complianceAdviceOnPolling);
        }
    }, [complianceAdviceOnPolling]);


    useEffect(() => {
        console.log("contentEditorState change: ", contentEditorState);
    }, [contentEditorState]);


    const isSavingDisabled =
        hasSelectedPlaceholder === false ||
        Boolean(selectedPlaceholder?.placeholder.draftValue) === false ||
        verifyInputLoading ||
        Boolean(verifyInputError) === true ||
        inputTextDebounced !== selectedPlaceholder?.placeholder.draftValue ||
        inputComplianceAdvice?.length > 0;

    const isAllowedToSend =
        emptyPlaceholdersCount === 0 &&
        !verifyMessageLoading &&
        !verifyMessageError &&
        !isOptOutSms &&
        !isSent &&
        !isSending &&
        messageTemplate.complianceAdvice.length === 0;

    const savePlaceholderValue = (): TMessageTemplate => {
        if (selectedPlaceholder) {
            const value = inputEditorState.getCurrentContent().getPlainText();
            const sp = {...selectedPlaceholder.placeholder, value, draftValue: value};
            const updatedMessageTemplate = getMessageTemplateWithUpdatedPlaceholders(messageTemplate, [sp]);

            dispatch(updateChatMessageTemplate({
                id: activeConversationId,
                messageTemplate: updatedMessageTemplate,
            }));

            return updatedMessageTemplate;
        }
        return null;
    };

    const placeholderClickHandler = (index: number): void => {
        placeholderRef.current = document.getElementById(SELECTED_PLACEHOLDER);
        const canSelect =
            selectedMessageTemplateId &&
            (!selectedPlaceholder?.placeholder.draftValue ||
                (inputComplianceAdvice.length === 0 && !verifyInputLoading && !verifyInputError));
        if (!canSelect) return;

        setIsJustOpened(false);
        const placeholder = messageTemplate.placeholders.find(item => item.indices.includes(index));
        const text = placeholder.value || "";

        // Put cursor position to the end of newly selected field text
        const updatedInputSelectionState = inputSelectionState.merge({
            anchorOffset: text.length,
            focusOffset: text.length,
            hasFocus: true,
        });
        setInputSelectionState(updatedInputSelectionState);


        const chatStarterUpdates: Partial<TChatStarterState> = {};
        // Extract input compliance advice from content compliance advice
        const selectedPlaceholderOffsets = getPlaceholderOffsets(messageTemplate, placeholder);
        const complianceAdvice = getComposedComplianceAdviceForInputText({
            inputText: text,
            selectedPlaceholderOffsets,
            complianceAdvice: messageTemplate.complianceAdvice,
        });

        chatStarterUpdates.inputComplianceAdvice = complianceAdvice;

        const es = composeInputEditorState({
            complianceAdvice: inputComplianceAdvice,
            selectionState: updatedInputSelectionState,
            text,
        });
        setInputEditorState(es);

        const currentlySelectedPlaceholder = {...placeholder, draftValue: placeholder.value};
        const nextPlaceholder = {
            placeholder: currentlySelectedPlaceholder,
            index,
            type: EMessageTemplateSelectedPlaceholderType.SELECTED_ON_CLICK,
        };

        chatStarterUpdates.selectedPlaceholder = nextPlaceholder;

        const placeholdersToUpdate: TMessageTemplatePlaceholder[] = [currentlySelectedPlaceholder];

        // Save previously selected placeholder value
        const value = selectedPlaceholder?.placeholder.draftValue;
        const updatedSelectedPlaceholder: TMessageTemplatePlaceholder = {
            ...selectedPlaceholder?.placeholder,
            value,
            draftValue: value,
        };
        placeholdersToUpdate.push(updatedSelectedPlaceholder);

        const updatedMessageTemplate = getMessageTemplateWithUpdatedPlaceholders(messageTemplate, placeholdersToUpdate);


        dispatch(updateChatStarter({
            id: activeConversationId,
            chatStarter: chatStarterUpdates,
        }));

        dispatch(updateChatMessageTemplate({
            id: activeConversationId,
            messageTemplate: updatedMessageTemplate,
        }));
    };

    // Invoke message template verification when message templates are not yet verified
    // or when placeholder value is changed
    useEffect(() => {
        let isPlaceholderValueChanged = false;
        if (
            prevMessageTemplate &&
            prevMessageTemplate.id === messageTemplate.id &&
            prevActiveConversationId === activeConversationId
        ) {
            isPlaceholderValueChanged = checkIsPlaceholderValueChanged(prevMessageTemplate, messageTemplate);
        }

        // if (isPlaceholderValueChanged || !messageTemplate.complianceAdvice) {
        if (isPlaceholderValueChanged || !inputComplianceAdvice) {
            setShouldVerifyMessage(true);
        }
    }, [messageTemplate]);

    // Set message template content state when one is (un)selected
    useEffect(() => {
        const state = composeMessageTemplateEditorState({
            complianceAdvice: messageTemplate.complianceAdvice,
            messageTemplate,
            selectedPlaceholder: null,
            placeholderClickHandler,
        });
        setContentEditorState(state);
    }, [selectedMessageTemplateId]);

    // Update message template content state with updated placeholders data
    useEffect(() => {
        if (selectedMessageTemplateId === messageTemplate.id) {
            const updatedMessageTemplateEditorState = getEditorStateWithUpdatedPlaceholders({
                editorState: contentEditorState,
                messageTemplate,
                selectedPlaceholder: selectedPlaceholder?.placeholder,
                placeholderClickHandler,
            });
            setContentEditorState(updatedMessageTemplateEditorState);
        }
    }, [messageTemplate.placeholders, selectedPlaceholder]);

    useEffect(() => {
        if (selectedPlaceholder && selectedPlaceholder?.placeholder.placeholder !== prevSelectedPlaceholder?.placeholder.placeholder) {
            const offset = selectedPlaceholder.placeholder.draftValue? selectedPlaceholder.placeholder.draftValue.length : 0;
            const updatedInputSelectionState = inputSelectionState.merge({
                anchorOffset: offset,
                focusOffset: offset,
                hasFocus: true,
            });
            setInputSelectionState(updatedInputSelectionState);

            const es = composeInputEditorState({
                complianceAdvice: inputComplianceAdvice || [],
                selectionState: updatedInputSelectionState,
                text: selectedPlaceholder.placeholder.draftValue || "",
            });
            setInputEditorState(es);
        }
    }, [prevSelectedPlaceholder, selectedPlaceholder]);

    // Set message template content compliance advice
    // Update message template content state with latest compliance advice
    useEffect(() => {
        const content = getMessageTemplateContent({
            messageTemplate,
            selectedPlaceholder: selectedPlaceholder?.placeholder,
            excludeEmptyPlaceholders: true,
        });
        const verifyMessageContent = verifyMessageData?.verifyMessage.content;
        if (verifyMessageContent === content) {
            setShouldVerifyMessage(false);
            const contentRelatedComplianceAdvice = getContentRelatedComplianceAdvice(
                verifyMessageData.verifyMessage.complianceAdvices,
            );
            const contentWithEmptyPlaceholders = getMessageTemplateContent({
                messageTemplate,
                selectedPlaceholder: selectedPlaceholder?.placeholder,
                excludeEmptyPlaceholders: false,
            });

            const ca = contentRelatedComplianceAdvice.filter(item => contentWithEmptyPlaceholders.includes(item.content));

            const updatedMessageTemplate = {...messageTemplate, complianceAdvice: ca};
            console.log("MT COMPLIANCE: ", ca, updatedMessageTemplate);
            dispatch(updateChatMessageTemplate({
                id: activeConversationId,
                messageTemplate: updatedMessageTemplate,
            }));

            let sp = selectedPlaceholder?.placeholder;
            if (selectedMessageTemplateId === messageTemplate.id && !selectedPlaceholder) {
                sp = getFirstEmptyPlaceholder(messageTemplate, 0);
                if (sp) {
                    const updateSelectedPlaceholder = {
                        placeholder: sp,
                        type: EMessageTemplateSelectedPlaceholderType.AUTO_SELECTED,
                        index: sp.indices[0],
                    };
                    dispatch(updateChatStarter({
                        id: activeConversationId,
                        chatStarter: {
                            selectedPlaceholder: updateSelectedPlaceholder,
                        },
                    }));
                }
            }

            const state = composeMessageTemplateEditorState({
                complianceAdvice: ca,
                messageTemplate,
                selectedPlaceholder: sp,
                placeholderClickHandler,
            });
            setContentEditorState(state);
        }
    }, [verifyMessageData]);

    const saveInputField = (): void => {
        const mt = savePlaceholderValue();

        const firstEmptyPlaceholder = getFirstEmptyPlaceholder(mt, selectedPlaceholder.index);
        let sp = null;
        if (
            firstEmptyPlaceholder &&
            firstEmptyPlaceholder.placeholder !== selectedPlaceholder.placeholder.placeholder
        ) {
            const index = firstEmptyPlaceholder.indices.find(i => i > selectedPlaceholder.index);
            sp = {
                placeholder: firstEmptyPlaceholder,
                index,
                type: EMessageTemplateSelectedPlaceholderType.AUTO_SELECTED,
            };
        }
        dispatch(updateChatStarter({
            id: activeConversationId,
            chatStarter: {
                selectedPlaceholder: sp,
                inputComplianceAdvice: [],
            },
        }));

        setIsJustOpened(false);
    };

    const onInputEditorContentChange = (changedEditorState: EditorState): void => {
        const chatStarterUpdates: Partial<TChatStarterState> = {};

        // how is it possible to register a change, if a message template is not selected and a placeholder is not selected?????/??????
        if (selectedMessageTemplateId && selectedPlaceholder) {
            setInputSelectionState(changedEditorState.getSelection());

            const draftValue = changedEditorState.getCurrentContent().getPlainText();
            setInputEditorState(changedEditorState);

            if (!draftValue) {
                chatStarterUpdates.inputComplianceAdvice = [];
            }

            const updatedSelectedPlaceholder = {...selectedPlaceholder.placeholder, draftValue};
            const updatedMessageTemplate = getMessageTemplateWithUpdatedPlaceholders(messageTemplate, [
                updatedSelectedPlaceholder,
            ]);
            dispatch(updateChatMessageTemplate({
                id: activeConversationId,
                messageTemplate: updatedMessageTemplate,
            }));

            chatStarterUpdates.selectedPlaceholder = { ... selectedPlaceholder, placeholder: updatedSelectedPlaceholder };

            dispatch(updateChatStarter({
                id: activeConversationId,
                chatStarter: chatStarterUpdates,
            }));

        }
    };

    const resetSelectedPlaceholder = (): void => {
        const updatedSelectedPlaceholder = {
            ...selectedPlaceholder.placeholder,
            draftValue: selectedPlaceholder?.placeholder.value || selectedPlaceholder?.placeholder.placeholder,
        };
        const updatedMessageTemplate = getMessageTemplateWithUpdatedPlaceholders(messageTemplate, [
            updatedSelectedPlaceholder,
        ]);
        dispatch(updateChatMessageTemplate({
            id: activeConversationId,
            messageTemplate: updatedMessageTemplate,
        }));

        dispatch(updateChatStarter({
            id: activeConversationId,
            chatStarter: {
                selectedPlaceholder: null,
            },
        }));

        const updatedMessageTemplateEditorState = getEditorStateWithUpdatedPlaceholders({
            editorState: contentEditorState,
            messageTemplate,
            selectedPlaceholder: null,
            placeholderClickHandler,
        });
        setContentEditorState(updatedMessageTemplateEditorState);
    };

    const onCancelButtonClick = (): void => {
        resetSelectedPlaceholder();
        dispatch(updateChatStarter({
            id: activeConversationId,
            chatStarter: {
                inputComplianceAdvice: [],
            },
        }));
    };

    const onSendButtonClick = (): void => {
        const message = getMessageTemplateContent({messageTemplate});
        handleSendChatStarterStart(message);
    };

    const handleInputKeyCommand = (command: EMessageTemplateCommand): DraftHandleValue => {
        if (command === EMessageTemplateCommand.SAVE_PLACEHOLDER_VALUE) {
            if (!isSavingDisabled) saveInputField();
            return "handled";
        }
        return "not-handled";
    };

    const inputEditorKeyBindingFn = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13) {
            return EMessageTemplateCommand.SAVE_PLACEHOLDER_VALUE;
        }
        return getDefaultKeyBinding(e);
    };

    const shouldDisplayComplianceError = isSelected && messageTemplate.complianceAdvice.length > 0 && !selectedPlaceholder;

    const onEditorFormUnmount = () => {
        if (placeholderRef.current) {
            placeholderRef.current.focus();
        }
    };

    return (
        <>
            {shouldRenderLoading && (
                <MessageTemplateLoading topicName={topicName} messageTemplate={messageTemplate}/>
            )}
            <MessageTemplateContent
                isSent={isSent}
                isPlaceholderSelected={hasSelectedPlaceholder}
                topicName={!isSelected && topicName}
                handleSendChatStarterFinish={handleSendChatStarterFinish}
            >
                <>
                    <Editor readOnly={true} editorState={contentEditorState} onChange={setContentEditorState}/>
                    <MessageTemplateComplianceErrorBox
                        contentComplianceAdvice={messageTemplate.complianceAdvice}
                        shouldDisplayComplianceError={shouldDisplayComplianceError}
                        isJustOpened={isJustOpened}
                    />
                </>
            </MessageTemplateContent>

            <CSSTransition
                in={shouldDisplayEditForm}
                unmountOnExit={true}
                exit={false}
                timeout={300}
                classNames={"с-message-template__edit-form--animation"}
            >
                <MessageTemplateEditForm
                    selectedPlaceholder={selectedPlaceholder}
                    isJustOpened={isJustOpened}
                    isSavingDisabled={isSavingDisabled}
                    hasMoreEmptyPlaceholders={emptyPlaceholdersCount > 1}
                    onCancelButtonClick={onCancelButtonClick}
                    onSaveButtonClick={saveInputField}
                    onUnmount={onEditorFormUnmount}
                >
                    <Editor
                        placeholder={selectedPlaceholder?.placeholder.placeholder}
                        editorState={inputEditorState}
                        onChange={onInputEditorContentChange}
                        keyBindingFn={inputEditorKeyBindingFn}
                        handleKeyCommand={handleInputKeyCommand}
                        ariaLabel={t("Message Template Placeholder")}
                    />
                </MessageTemplateEditForm>
            </CSSTransition>

            {isSelected && (
                <MessageTemplateValidateHint
                    hintText={emptyPlaceholdersCount > 0
                        ? t("You must edit the fields in yellow before you can send this Chat Starter.")
                        : t("Double check the information before sending, to be sure everything is correct.")}
                />
            )}

            {shouldRenderSendButton && (
                <MessageTemplateSendButton
                    isSending={isSending}
                    isDisabled={!isAllowedToSend}
                    onButtonClick={onSendButtonClick}
                />
            )}
        </>
    );
}
