/**
 * Close Component Class
 * Wrapper for Closing Element by Clicking Outside or Escape Button
 */
import React, { PureComponent } from "react";

interface CloseComponentProps {
    isOpen: boolean;
    closeChange: () => void;
}

class Close extends PureComponent<CloseComponentProps> {
    private wrapperRef: React.RefObject<HTMLDivElement>;

    /**
     * Constructor
     *
     * @param {CloseComponentProps} props
     */
    constructor(props: CloseComponentProps) {
        super(props);

        this.wrapperRef = React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    /**
     * Component Did Mount Lifecycle hook
     */
    componentDidMount(): void {
        document.addEventListener("mousedown", this.handleClickOutside);
        document.addEventListener("touchstart", this.handleClickOutside, { passive: false });
        document.addEventListener("keydown", this.handleKeyDown);
    }

    /**
     * Component Will Unmount Lifecycle hook
     */
    componentWillUnmount(): void {
        document.removeEventListener("mousedown", this.handleClickOutside);
        document.removeEventListener("touchstart", this.handleClickOutside);
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    /**
     * Handle Click Outside
     *
     * @param e
     */
    handleClickOutside(e: any): void {
        if (this.props.isOpen && this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            e.preventDefault();
            this.props.closeChange();
        }
    }

    /**
     * Handle Keyboard Events
     *
     * @param e
     */
    handleKeyDown(e: any): void {
        if (e.key === "Escape" && this.props.isOpen) {
            this.props.closeChange();
        }
        if ((e.key === "Enter" || e.key === " ") && this.props.isOpen && this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            this.props.closeChange();
        }
    }

    /**
     * Render
     *
     * @return {React.ReactNode}
     */
    render(): React.ReactNode {
        return (
            <div className="c-close" ref={this.wrapperRef}>
                {this.props.children}
            </div>
        );
    }
}

export default Close;
