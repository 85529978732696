import { TPhoneNumber } from "src/types/entities/conversation";

export const formatContactName = ({ firstName, lastName } = { firstName: "", lastName: null }) => {
    if (!lastName) {
        return firstName;
    }
    
    return `${firstName} ${lastName}`;
};

export const valueExists = (value) => {
    return value && value.trim().length > 0;
};

export const formatContactAddress = (address) => {
    const addressKeys = ["streetName", "city", "province", "country", "postalCode"];

    const formattedAddress = [];

    if (valueExists(address.streetNumber) && valueExists(address.streetName)) {
        formattedAddress.push(`${address.streetNumber} `);
    } else if (valueExists(address.streetNumber)) {
        formattedAddress.push(`${address.streetNumber}, `);
    }

    addressKeys.forEach((addressKey) => {
        if (valueExists(address[addressKey])) {
            formattedAddress.push(`${address[addressKey]}, `);
        }
    });

    const formattedAddressResult = formattedAddress.join("").replace(/,\s*$/, "");
    return formattedAddressResult;
};

export const formatNAPhoneNumber = (phoneNumber: TPhoneNumber) => {
    let { number } = phoneNumber;

    if (number.length === 12 && number.startsWith("+1")) {
        number = `${number.substring(0,2)} (${number.substring(2,5)}) ${number.substring(5,8)}-${number.substring(8,12)}`;
    }
    if (phoneNumber.extension) {
        number += ` (ext:${phoneNumber.extension})`;
    }

    return number;
};
