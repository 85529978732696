import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 14 14" width={14} height={14} fill="currentColor" {...props}>
      <path d="M7 0.487793C3.14008 0.487793 0 3.09763 0 6.30654C0.00864677 7.0427 0.171855 7.76883 0.479051 8.43789C0.786246 9.10695 1.23058 9.704 1.78325 10.1904L0.543083 12.666C0.48876 12.7743 0.469481 12.8968 0.487922 13.0165C0.506364 13.1362 0.561611 13.2472 0.645998 13.3341C0.730384 13.421 0.839723 13.4794 0.958843 13.5014C1.07796 13.5233 1.20095 13.5076 1.31075 13.4565L4.79967 11.8325C5.51676 12.0277 6.25681 12.1258 7 12.1241C10.8599 12.1241 14 9.51371 14 6.30538C14 3.09704 10.8599 0.487793 7 0.487793ZM7 10.9586C6.29608 10.9602 5.59583 10.8571 4.92217 10.653C4.78449 10.611 4.63615 10.6214 4.50567 10.6821L2.71483 11.5157C2.68737 11.5285 2.65659 11.5325 2.62679 11.527C2.59698 11.5215 2.56962 11.5069 2.54851 11.4851C2.5274 11.4634 2.5136 11.4356 2.50901 11.4056C2.50442 11.3757 2.50928 11.345 2.52292 11.318L3.02983 10.3059C3.08956 10.1865 3.10657 10.0503 3.07801 9.91997C3.04945 9.78961 2.97707 9.67298 2.87292 9.58954C2.35718 9.20849 1.93534 8.71461 1.63963 8.14562C1.34391 7.57664 1.18214 6.9476 1.16667 6.30654C1.16667 3.73988 3.7835 1.65446 7 1.65446C10.2165 1.65446 12.8333 3.74104 12.8333 6.30654C12.8333 8.87204 10.2165 10.9586 7 10.9586Z" fill="#757575"/>
      <path d="M10.0545 13.6822C10.6335 13.6822 11.1994 13.5105 11.6808 13.1888C12.1622 12.8672 12.5374 12.41 12.759 11.8751C12.9805 11.3402 13.0385 10.7517 12.9256 10.1838C12.8126 9.61599 12.5338 9.0944 12.1244 8.68502C11.715 8.27563 11.1934 7.99683 10.6256 7.88388C10.0578 7.77094 9.4692 7.82891 8.93431 8.05046C8.39942 8.27202 7.94225 8.64722 7.62059 9.12861C7.29894 9.60999 7.12726 10.176 7.12726 10.7549C7.12813 11.531 7.43682 12.2751 7.9856 12.8238C8.53438 13.3726 9.27844 13.6813 10.0545 13.6822ZM8.52726 10.5004H9.73635C9.75323 10.5004 9.76941 10.4937 9.78135 10.4817C9.79328 10.4698 9.79999 10.4536 9.79999 10.4367V9.22764C9.79999 9.16013 9.8268 9.09538 9.87454 9.04765C9.92228 8.99991 9.98702 8.97309 10.0545 8.97309C10.122 8.97309 10.1868 8.99991 10.2345 9.04765C10.2823 9.09538 10.3091 9.16013 10.3091 9.22764V10.4367C10.3091 10.4536 10.3158 10.4698 10.3277 10.4817C10.3396 10.4937 10.3558 10.5004 10.3727 10.5004H11.5818C11.6493 10.5004 11.7141 10.5272 11.7618 10.5749C11.8095 10.6227 11.8363 10.6874 11.8363 10.7549C11.8363 10.8224 11.8095 10.8872 11.7618 10.9349C11.7141 10.9826 11.6493 11.0095 11.5818 11.0095H10.3727C10.3558 11.0095 10.3396 11.0162 10.3277 11.0281C10.3158 11.04 10.3091 11.0562 10.3091 11.0731V12.2822C10.3091 12.3497 10.2823 12.4144 10.2345 12.4622C10.1868 12.5099 10.122 12.5367 10.0545 12.5367C9.98702 12.5367 9.92228 12.5099 9.87454 12.4622C9.8268 12.4144 9.79999 12.3497 9.79999 12.2822V11.0731C9.79999 11.0562 9.79328 11.04 9.78135 11.0281C9.76941 11.0162 9.75323 11.0095 9.73635 11.0095H8.52726C8.45975 11.0095 8.395 10.9826 8.34727 10.9349C8.29953 10.8872 8.27271 10.8224 8.27271 10.7549C8.27271 10.6874 8.29953 10.6227 8.34727 10.5749C8.395 10.5272 8.45975 10.5004 8.52726 10.5004Z" fill="#757575"/>
    </svg>
  );
}

export default SvgComponent;
