
import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="403" height="408" viewBox="0 0 403 408" fill="currentColor" {...props}>
        <path opacity="0.15" d="M335.336 287.443C293.913 179.936 214.651 99.9111 244.893 63.964C275.136 28.0169 -49.0652 3.87042 58.2826 172.147C165.63 340.424 376.751 394.951 335.336 287.443Z" fill="#ADB5C1"/>
        <path d="M125.518 216.705C125.518 216.705 124.297 220.855 122.717 222.637C121.138 224.42 117.017 225.527 118.988 229.758L135.632 221.881L131.78 213.742L125.518 216.705Z" fill="#4F305C"/>
        <path d="M123.515 210.353L127.601 208.422L130.838 215.264C130.982 215.569 131.001 215.918 130.891 216.236C130.78 216.554 130.549 216.816 130.247 216.966L128.495 217.794C128.342 217.866 128.177 217.908 128.008 217.916C127.84 217.924 127.671 217.899 127.512 217.842C127.353 217.786 127.206 217.698 127.081 217.584C126.956 217.471 126.854 217.334 126.782 217.181L123.545 210.339L123.515 210.353Z" fill="#DF3871"/>
        <path d="M138.148 80.8836C138.148 80.8836 123.768 84.6679 125.486 96.9243C126.414 103.541 129.256 106.755 132.456 107.895C135.184 108.855 135.803 105.706 138.099 104.469C143.099 101.799 150.639 82.7798 147.871 81.5347C145.102 80.2895 138.148 80.8836 138.148 80.8836Z" fill="#191847"/>
        <path d="M138.897 80.8836C137.038 80.5599 135.309 79.7183 133.907 78.4557C132.505 77.193 131.489 75.5607 130.974 73.7463C128.873 67.2356 135.119 63.4024 138.238 63.671C138.238 63.671 146.983 54.5805 156.079 60.6435C165.174 66.7066 154.076 78.0352 154.076 78.0352L138.897 80.8836Z" fill="#191847"/>
        <path d="M146.169 90.2347C153.041 91.7809 142.806 112.607 137.13 117.693C132.652 121.706 118.581 101.091 117.115 100.505C115.649 99.9193 131.137 107.708 131.137 107.708C131.137 107.708 132.725 87.1991 146.169 90.2347Z" fill="#DF3871"/>
        <path d="M154.344 129.014C154.344 129.014 155.867 134.971 155.264 146.78C150.639 155.944 122.131 152.021 120.665 157.36C119.778 175.402 129.769 207.956 129.769 207.956L121.936 212.025C121.936 212.025 104.379 178.283 105.153 155.968C105.739 139.268 133.572 135.923 134.158 132.578C134.932 128.119 135.542 125.677 135.542 125.677L154.344 129.014Z" fill="#4F305C"/>
        <path d="M105.324 212.465C105.324 212.465 103.24 216.249 101.318 217.657C99.3961 219.065 95.0966 219.284 96.147 223.818L114.061 219.683L112.034 210.91L105.324 212.465Z" fill="#4F305C"/>
        <path d="M104.749 205.836L109.153 204.823L110.851 212.198C110.89 212.365 110.895 212.538 110.865 212.706C110.836 212.874 110.774 213.035 110.682 213.179C110.59 213.324 110.47 213.448 110.329 213.545C110.188 213.642 110.03 213.71 109.862 213.745L107.974 214.18C107.641 214.256 107.292 214.198 107.003 214.017C106.713 213.836 106.508 213.547 106.431 213.215L104.733 205.839L104.749 205.836Z" fill="#DF3871"/>
        <path d="M155.81 129.201C155.81 129.201 160.631 141.555 153.367 150.051C146.714 157.881 115.82 148.546 113.247 153.453C108.532 170.853 111.317 204.806 111.317 204.806L102.808 207.142C102.808 207.142 92.8817 170.43 98.4108 148.798C102.547 132.603 130.461 135.289 131.74 132.147C133.45 127.956 134.573 125.702 134.573 125.702L155.81 129.201Z" fill="#69467F"/>
        <path d="M142.586 86.1329L138.629 86.8979C138.629 86.8979 127.994 98.5521 134.948 103.671C134.948 103.671 132.35 115.024 131.015 126.458C131.015 126.458 129.574 131.984 144.402 131.553C156.616 131.203 156.934 130.104 157.43 127.696C157.927 125.287 159.987 86.5073 142.586 86.1329Z" fill="#437CC8"/>
        <path d="M123.32 107.748L119.623 91.4635L117.245 92.0902L121.195 108.106L123.32 107.748Z" fill="#69467F"/>
        <path d="M144.614 83.2925C158.318 84.1471 154.19 69.5306 153.31 67.5042C149.928 70.681 145.778 72.9246 141.267 74.0148C141.422 74.552 140.982 70.8409 138.734 70.8897C136.487 70.9385 135.681 75.3007 139.068 77.083L138.629 86.849C138.612 87.2127 138.669 87.576 138.796 87.9173C138.923 88.2586 139.117 88.571 139.366 88.8359C139.616 89.1008 139.917 89.3129 140.25 89.4595C140.584 89.6061 140.943 89.6842 141.308 89.6893C141.974 89.7013 142.621 89.4685 143.127 89.0349C143.633 88.6014 143.962 87.9973 144.052 87.3373L144.614 83.2925Z" fill="#DF3871"/>
        <path d="M146.299 91.7159C145.399 92.0451 144.654 92.6979 144.21 93.5466C143.766 94.3954 143.654 95.3792 143.897 96.3059C145.094 100.912 147.016 108.872 146.633 111.191C146.21 113.73 123.019 103.386 123.019 103.386C123.073 102.641 122.897 101.896 122.514 101.254C121.186 99.1055 120.885 101.71 120.885 101.71C120.885 101.71 120.226 99.6101 119.004 99.2682C118.239 99.0241 116.863 99.7565 116.944 101.816C117.025 103.875 119.664 105.014 122.147 105.413C122.147 105.413 135.095 115.724 142.252 118.06C156.543 122.682 155.566 112.102 151.25 94.019C150.761 91.9437 148.278 90.9997 146.275 91.7403L146.299 91.7159Z" fill="#DF3871"/>
        <path d="M144.842 107.447L155.427 106.552C155.427 106.552 153.986 92.6435 149.402 90.7473C144.817 88.851 140.347 93.9538 144.842 107.447Z" fill="#4595D4"/>
        <path d="M125.396 302.052C122.051 305.394 119.773 309.653 118.849 314.29C117.926 318.927 118.399 323.733 120.209 328.101C122.019 332.469 125.084 336.202 129.018 338.829C132.951 341.456 137.575 342.858 142.305 342.858C147.035 342.858 151.659 341.456 155.593 338.829C159.526 336.202 162.591 332.469 164.401 328.101C166.211 323.733 166.684 318.927 165.761 314.29C164.837 309.653 162.559 305.394 159.214 302.052C156.994 299.832 154.358 298.071 151.456 296.869C148.555 295.668 145.445 295.05 142.305 295.05C139.165 295.05 136.055 295.668 133.154 296.869C130.252 298.071 127.616 299.832 125.396 302.052Z" fill="#7D9ED3"/>
        <path d="M142.293 245.221C132.609 245.199 123.016 247.097 114.071 250.804C105.126 254.512 97.0047 259.955 90.1783 266.821C88.834 268.109 87.7607 269.654 87.0212 271.362C86.2818 273.071 85.891 274.91 85.8719 276.772C85.8528 278.634 86.2057 280.481 86.9099 282.204C87.6141 283.928 88.6555 285.494 89.973 286.81C91.2905 288.126 92.8577 289.167 94.5826 289.87C96.3075 290.573 98.1555 290.925 100.018 290.905C101.881 290.885 103.721 290.494 105.431 289.754C107.14 289.014 108.685 287.941 109.974 286.597C118.557 278.018 130.199 273.199 142.338 273.199C154.476 273.199 166.118 278.018 174.702 286.597C176.001 287.895 177.543 288.925 179.241 289.628C180.938 290.331 182.758 290.693 184.595 290.693C186.433 290.693 188.252 290.331 189.95 289.628C191.647 288.925 193.19 287.895 194.489 286.597C195.788 285.298 196.819 283.757 197.522 282.06C198.225 280.363 198.587 278.545 198.587 276.709C198.587 274.872 198.225 273.054 197.522 271.357C196.819 269.661 195.788 268.119 194.489 266.821C187.653 259.946 179.519 254.496 170.558 250.789C161.598 247.081 151.991 245.188 142.293 245.221Z" fill="#7D9ED3"/>
        <path d="M275.918 337.624C290.357 343.901 305.898 347.254 321.642 347.487C337.386 347.72 353.02 344.83 367.639 338.983C371.005 337.557 373.678 334.872 375.089 331.501C376.499 328.131 376.534 324.343 375.187 320.946C373.839 317.55 371.216 314.816 367.878 313.327C364.539 311.839 360.751 311.715 357.322 312.981C346.11 317.427 334.132 319.623 322.071 319.442C310.01 319.261 298.103 316.708 287.029 311.928C275.955 307.148 265.932 300.235 257.532 291.584C249.131 282.932 242.518 272.712 238.07 261.506C237.392 259.796 236.384 258.236 235.104 256.916C233.823 255.595 232.295 254.539 230.606 253.809C228.917 253.078 227.101 252.688 225.261 252.659C223.421 252.63 221.593 252.963 219.882 253.641C218.171 254.318 216.611 255.325 215.289 256.605C213.968 257.885 212.911 259.412 212.181 261.1C210.705 264.509 210.644 268.364 212.012 271.818C217.779 286.466 226.397 299.825 237.367 311.121C248.337 322.417 261.44 331.425 275.918 337.624Z" fill="#7D9ED3"/>
        <path d="M259.713 201.608C244.331 186.146 226.032 173.888 205.877 165.544C185.721 157.2 164.109 152.935 142.293 152.998C136.967 152.998 131.685 153.244 126.447 153.738L123.898 153.999C86.419 158.079 51.4742 174.884 24.8967 201.608C22.3618 204.249 20.9632 207.777 21.0007 211.437C21.0383 215.096 22.5091 218.595 25.0978 221.183C27.6864 223.772 31.1868 225.243 34.8482 225.282C38.5096 225.321 42.0406 223.925 44.6839 221.392C57.5016 208.579 72.7193 198.415 89.4679 191.48C106.217 184.545 124.168 180.976 142.297 180.976C160.426 180.976 178.377 184.545 195.126 191.48C211.874 198.415 227.092 208.579 239.91 221.392C241.21 222.692 242.753 223.723 244.451 224.426C246.15 225.13 247.97 225.492 249.809 225.493C251.647 225.493 253.468 225.132 255.167 224.429C256.865 223.726 258.409 222.695 259.709 221.396C261.01 220.097 262.041 218.555 262.745 216.858C263.449 215.16 263.812 213.341 263.812 211.503C263.812 209.666 263.451 207.846 262.747 206.148C262.044 204.45 261.013 202.908 259.713 201.608Z" fill="#7D9ED3"/>
    </svg>
  );
}

export default SvgComponent;

