import { put } from "@redux-saga/core/effects";
import { push } from "connected-react-router";
import isOnline from "is-online";
import Cookies from "js-cookie";
import { call, delay, select, spawn, takeEvery } from "redux-saga/effects";

import { REDIRECT_TO_LOGOUT_ACTION } from "@/messenger/actions/saga";
import { cookieKeys } from "@/messenger/app.settings";
import { LOGOUT, LOGOUT_REDIRECT_PARAM } from "@/messenger/constants/routing";
import { customerSelector } from "@/messenger/ducks/entities/customers";
import { setIsOnlineAC } from "@/messenger/ducks/general/isOnline.duck";
import { setHideRingCentral } from "@/messenger/integration/RingCentral";

function* listenForOffline() {
    while (true) {
        yield delay(4000);

        const result = yield isOnline();

        if (!result) {
            let failuresToConnect = 0;
            let connectionResult = yield isOnline();
            while (!connectionResult && failuresToConnect < 3) {
                yield delay(1000);
                connectionResult = yield isOnline(); 
                    
                if (!connectionResult) {
                    failuresToConnect += 1;
                }
            }

            if (failuresToConnect === 3) {

                yield put(setIsOnlineAC(false));
                setHideRingCentral(true);
                return;
            }
        }
    }
}

function* redirectToLogout() {
    const customers = yield select(customerSelector.selectAll);
    const hasCustomLogout = customers[0]?.config?.webapp?.logoutUrl;

    if (hasCustomLogout) {
        yield put(push(`/${LOGOUT}?${LOGOUT_REDIRECT_PARAM}=${customers[0].config.webapp.logoutUrl}`));
    } else {
        yield put(push(`/${LOGOUT}`));
    }  
}

function* redirectToLogoutListener(_: any){
    yield call(redirectToLogout);
}

function* listenForLogout() {
    while(true) {
        yield delay(2000);
        const authCookie = Cookies.get(cookieKeys.auth0IsAuthenticated);
        
        if(!authCookie) {
            yield redirectToLogout();
            return;
        }
    }
}

export default function* intervalListenerSaga() {
    yield spawn(listenForOffline);
    yield spawn(listenForLogout);
    yield takeEvery(REDIRECT_TO_LOGOUT_ACTION, redirectToLogoutListener);
}


