import React from "react";

function Chart(props) {
    return (<svg width="20" height="18" viewBox="0 0 20 18" fill="none" {...props}>
        <path d="M4.99984 10H1.6665V16.6667H4.99984V10Z" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.6668 1.66675H8.3335V16.6667H11.6668V1.66675Z" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.3333 5.83325H15V16.6666H18.3333V5.83325Z" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>);
}

export default Chart;
