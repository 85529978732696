import "./index.scss";
import "../../locales/i18n";

import { useMutation } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import Close from "@/components/components/Close";
import { SwitchValue } from "@/components/components/Switch/switch.type";
import { ChatActive, ChatInActive } from "@/components/icons";
import AvailabilityToggle from "@/messenger/components/AvailabilityToggle";
import { representativeSettingsSelectors } from "@/messenger/ducks/entities/representativeSettings";
import { saveRepresentativeIncomingAvailable } from "@/messenger/graphql/mutations";
import { TRepresentative } from "@/messenger/types/entities/representative";

const AvailabilityButton = (): React.ReactElement => {
    const { t } = useTranslation();
    const representativeSettings: TRepresentative[] = useSelector(representativeSettingsSelectors.selectAll);
    const inboundAvailability = representativeSettings[0]?.incomingEnabled;
    const [isToggleOpen, setIsToggleOpen] = useState(false);
    const tooltipRef = useRef(null);
    const firstRender = useRef(true);

    useEffect(() => {
        if (tooltipRef?.current && !inboundAvailability && firstRender.current) {
            ReactTooltip.show(tooltipRef.current);
            setTimeout(() => {
                ReactTooltip.hide(tooltipRef.current);
            }, 2000);
        }
    }, [firstRender.current, inboundAvailability, tooltipRef]);
    
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        }
    }, [isToggleOpen]);

    const [updateInboundAvailability] = useMutation<{
        saveRepresentativeIncomingAvailable: boolean;
    }>(saveRepresentativeIncomingAvailable);

    const onOpen = () => {
        setIsToggleOpen(true);
    };
    const onClose = () => {
        setIsToggleOpen(false);
    };
    
    const onAvailabilityToggle = (value: SwitchValue) => {
        updateInboundAvailability({
            variables: {
                availability: value.on,
            },
        });
    };

    return (<>
        <button 
            id={"AVAILABILITY_BUTTON"}
            aria-label={t("inbound availability button")}
            className={"c-availability-button__icon"} 
            onClick={onOpen} 
            data-testid="availability-button"
            ref={tooltipRef}
            data-tip
            data-for={"availability-reminder"}>
            {inboundAvailability ? <ChatActive /> : <ChatInActive />}
        </button>
        {!inboundAvailability && 
        <ReactTooltip id={"availability-reminder"} place="top" effect="solid" className="c-availability-button__tooltip">
            {t("Turning Availability on lets you receive incoming messages")}
        </ReactTooltip>}
        {isToggleOpen && <Close isOpen={isToggleOpen} closeChange={onClose}>
            <AvailabilityToggle onClose={onClose} onToggle={onAvailabilityToggle} isOn={inboundAvailability} />
        </Close>}
    </>);
};

export default AvailabilityButton;