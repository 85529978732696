import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} fill="currentColor" {...props}>
        <path d="M7.1662 11.3113C7.1662 8.75 9.58486 6.66667 12.5582 6.66667C14.0071 6.64971 15.41 7.17498 16.4915 8.13933C16.5125 8.15896 16.5382 8.17274 16.5662 8.17929C16.5942 8.18585 16.6234 8.18494 16.6509 8.17667C16.6785 8.16866 16.7035 8.15364 16.7236 8.13309C16.7437 8.11253 16.7582 8.08712 16.7655 8.05933C16.8864 7.60492 16.9485 7.13688 16.9502 6.66667C16.9502 2.99067 13.3615 0 8.9502 0C4.53886 0 0.950195 2.99067 0.950195 6.66667C0.961811 7.46182 1.13073 8.24681 1.44722 8.97636C1.76371 9.70591 2.2215 10.3656 2.7942 10.9173L1.8482 14.0713C1.83006 14.1323 1.82976 14.1972 1.84733 14.2584C1.8649 14.3195 1.8996 14.3744 1.94735 14.4164C1.99509 14.4585 2.05387 14.486 2.11675 14.4958C2.17963 14.5055 2.24398 14.497 2.3022 14.4713L5.9502 12.8473C6.40486 12.9996 6.87103 13.1151 7.3442 13.1927C7.37393 13.1975 7.40442 13.1942 7.43245 13.1832C7.46047 13.1721 7.485 13.1537 7.50343 13.1299C7.52187 13.1061 7.53353 13.0777 7.53718 13.0478C7.54084 13.0179 7.53635 12.9876 7.5242 12.96C7.28992 12.4418 7.16791 11.88 7.1662 11.3113ZM7.28353 8.16667H4.9502C4.81759 8.16667 4.69041 8.11399 4.59664 8.02022C4.50287 7.92645 4.4502 7.79927 4.4502 7.66667C4.4502 7.53406 4.50287 7.40688 4.59664 7.31311C4.69041 7.21935 4.81759 7.16667 4.9502 7.16667H7.28353C7.41614 7.16667 7.54331 7.21935 7.63708 7.31311C7.73085 7.40688 7.78353 7.53406 7.78353 7.66667C7.78353 7.79927 7.73085 7.92645 7.63708 8.02022C7.54331 8.11399 7.41614 8.16667 7.28353 8.16667ZM11.9502 5.16667H4.9502C4.81759 5.16667 4.69041 5.11399 4.59664 5.02022C4.50287 4.92645 4.4502 4.79927 4.4502 4.66667C4.4502 4.53406 4.50287 4.40688 4.59664 4.31311C4.69041 4.21934 4.81759 4.16667 4.9502 4.16667H11.9502C12.0828 4.16667 12.21 4.21934 12.3037 4.31311C12.3975 4.40688 12.4502 4.53406 12.4502 4.66667C12.4502 4.79927 12.3975 4.92645 12.3037 5.02022C12.21 5.11399 12.0828 5.16667 11.9502 5.16667Z" fill="#757575"/>
        <path d="M12.558 7.66666C10.136 7.66666 8.16602 9.30132 8.16602 11.3113C8.16602 13.6587 10.8847 15.526 13.8033 14.7993L16.1367 15.9647C16.1948 15.9933 16.2601 16.0043 16.3244 15.9964C16.3887 15.9885 16.4493 15.9621 16.4988 15.9202C16.5484 15.8784 16.5846 15.8231 16.6032 15.761C16.6217 15.6989 16.6218 15.6328 16.6033 15.5707L16.0033 13.5707C16.5996 12.9685 16.9389 12.1586 16.95 11.3113C16.95 9.30132 14.98 7.66666 12.558 7.66666Z" fill="#757575"/>
    </svg>

  );
}

export default SvgComponent;
