import "./index.scss";
import "../../locales/i18n";

import i18next from "i18next";
import React from "react";

import { BadgeColor } from "@/components/components/Badge/badgeColor.enum";
import { Contact,Conversation,MessageBubbleV2,MessageCheck,NoEntry } from "@/components/icons";
import { ConversationCategory } from "@/messenger/types/entities/conversation";
import { ChatCategoryCounts } from "@/messenger/types/store.types";

export interface CategoryOption {
    label: ConversationCategory,
    heading: string,
    icon: JSX.Element,
    badge: {
        value: number;
        color: BadgeColor;
    }
}

export const hiddenCategories = [ConversationCategory.OPEN, ConversationCategory.CLOSED, ConversationCategory.OPT_OUT];

export const getChatCategoryOptions = (counts: ChatCategoryCounts) => {
    return [
        {
            label: ConversationCategory.TO_ANSWER,
            heading: i18next.t("To Answer"),
            icon: <MessageBubbleV2 />,
            badge: { value: counts[ConversationCategory.TO_ANSWER], color: BadgeColor.TARGARYEN },
        },
        {
            label: ConversationCategory.TO_BE_CONTACTED,
            heading: i18next.t("To Contact"),
            icon: <Contact />,
            badge: { value: counts[ConversationCategory.TO_BE_CONTACTED], color: BadgeColor.DOTHRAKI },
        },
        {
            label: ConversationCategory.OPEN,
            heading: i18next.t("Open"),
            icon: <Conversation />,
            badge: { value: counts[ConversationCategory.OPEN], color: BadgeColor.PRIMARY },
        },
        {
            label: ConversationCategory.CLOSED,
            heading: i18next.t("Closed"),
            icon: <MessageCheck />,
            badge: { value: counts[ConversationCategory.CLOSED], color: BadgeColor.MAIN },
        },
        {
            label: ConversationCategory.OPT_OUT,
            heading: i18next.t("Opt-out"),
            icon: <NoEntry />,
            badge: { value: counts[ConversationCategory.OPT_OUT], color: BadgeColor.ERROR },
        },
    ];
};

export const getChatCategoryCounts = (allConvos): ChatCategoryCounts => {
    return {
        [ConversationCategory.TO_ANSWER]: allConvos.filter(c => c.category === ConversationCategory.TO_ANSWER).length,
        [ConversationCategory.TO_BE_CONTACTED]: allConvos.filter(c => c.category === ConversationCategory.TO_BE_CONTACTED).length,
        [ConversationCategory.OPEN]: allConvos.filter(c => c.category === ConversationCategory.OPEN).length,
        [ConversationCategory.CLOSED]: allConvos.filter(c => c.category === ConversationCategory.CLOSED).length,
        [ConversationCategory.OPT_OUT]: allConvos.filter(c => c.category === ConversationCategory.OPT_OUT).length,
    };
};