import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { config } from "@/messenger/config";
import { setPromptsWereShown } from "@/messenger/ducks/general/prompts.duck";
import { useMessengerAuthorize } from "@/messenger/hooks/useMessengerAuthorize";
import { useAuth0 } from "@/messenger/providers/Auth0Provider";

export function useFetchUserMetadata() {
    const auth0Client = useAuth0();
    const [userMetadata, setUserMetadata] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [metadataUrl, setMetadataUrl] = useState(null);
    const dispatch = useDispatch();
    const auth0Credentials = useMessengerAuthorize(auth0Client);

    function getUserMetadata() {
        const options: RequestInit = {
            headers: { Authorization: `Bearer ${auth0Credentials.token}` },
        };
        return fetch(`${config.api.messenger.rest().origin}/auth0/token`, options)
            .then(tokenBlob => tokenBlob.json())
            .then(({ token, apiUrl }) => {
                setAccessToken(token);
                setMetadataUrl(apiUrl);
                return fetch(`${apiUrl}users/${auth0Client.user.sub}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                    .then(metadataBlob => metadataBlob.json())
                    .then(metadata => setUserMetadata({ ...metadata.user_metadata }))
                    .catch(e => {
                        // TODO: use Sentry (or similar) to log errors
                        console.log(e);
                    });
            });
    }

    useEffect(() => {
        if (!accessToken && auth0Credentials) {
            getUserMetadata();
        }
    }, [auth0Credentials]);

    useEffect(() => {
        if (userMetadata?.werePromptsShown) {
            dispatch(setPromptsWereShown);
        }
    }, [userMetadata]);

    return [accessToken, userMetadata, setUserMetadata, metadataUrl];
}