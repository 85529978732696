import { Client as ConversationClient, Paginator } from "@twilio/conversations";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Notification from "react-web-notification";
import { TMessageOrigin } from "src/types/message";

import { messageReceivedAC } from "@/messenger/actions/message";
import { TMessageDirection } from "@/messenger/components/MessageBubble";
import { DISABLE_NOTIFICATION_FLAG } from "@/messenger/constants/featureFlags";
import { RequestURIs } from "@/messenger/constants/requests";
import { authChat } from "@/messenger/ducks/auth/token.duck";
import { conversationsSelectors } from "@/messenger/ducks/entities/conversations";
import { addOneTwilioChannel, twilioChannelsSelectors } from "@/messenger/ducks/entities/twilioChannels";
import { setLoadingStatus } from "@/messenger/ducks/sdk/loadingStatus.duck";
import { setSession } from "@/messenger/ducks/sdk/session.duck";
import { getRequestTrackerById } from "@/messenger/ducks/selectors/requestTrackers";
import { chatsSelectors, incrementUnreadMessageCount, upsertOneChat } from "@/messenger/ducks/userInterface/chats";
import { createMessage, getMessageDirection } from "@/messenger/models/message";
import { activeConversationIdSelector } from "@/messenger/selectors";
import { TAuth0Credentials } from "@/messenger/types/auth.types";
import { SDKLoadingStatus, TContainer, TStore } from "@/messenger/types/store.types";
import { EChatSDKEvents, TTwilioChannel, TTwilioMessage } from "@/messenger/types/twilioChatSDK.types";
import { generateUUID, mapStateToProps, throttle } from "@/messenger/utils/helpers";

import history from "../utils/history";

interface TWithChatSDKProps extends TContainer {
    sessionId?: string;
    auth0Credentials: TAuth0Credentials;
}

interface TWithChatSDKState {
    connectionStatus: EConnectionStatus;
    showNotification: boolean
    notificationTitle: string
    windowHasFocus: boolean
    twilioChannelsCount: number
    ids: any
}

enum EConnectionStatus {
    CONNECTING = "Connecting to SDK",
    CONNECTED = "You are connected",
    DISCONNECTING = "Disconnecting from SDK",
    DISCONNECTED = "Disconnected",
    FAILED = "Failed to connect",
}

enum EConnectionState {
    CONNECTING = "connecting",
    CONNECTED = "connected",
    DISCONNECTING = "disconnecting",
    DISCONNECTED = "disconnected",
    DENIED = "denied",
}

@(connect(mapStateToProps) as any)
class WithTwilioChatSDK extends PureComponent<TWithChatSDKProps, TWithChatSDKState> {
    conversationClient: ConversationClient;

    constructor(props) {
        super(props);

        this.state = {
            connectionStatus: null,
            showNotification: true,
            notificationTitle: "hello",
            windowHasFocus: true,
            twilioChannelsCount: Number.MAX_SAFE_INTEGER,
            ids: [],
        };
    }

    componentDidMount(): void {
        const { dispatch, auth0Credentials } = this.props;
        dispatch(authChat(auth0Credentials));
        this.initializeFocusListeners();

        this.props.dispatch(setLoadingStatus(SDKLoadingStatus.LOADED_CONVERSATIONS));
    }
    
    componentDidUpdate(prevProps: TWithChatSDKProps) {
        if (this.props.state.auth.token && this.props.state.auth.token !== (prevProps.state as TStore).auth.token) {
            this.chatInitialization();
        }

        const prevGetConversationsComplete = getRequestTrackerById(RequestURIs.GET_CONVERSATIONS)(prevProps.state).status.complete;
        const getConversationsComplete = getRequestTrackerById(RequestURIs.GET_CONVERSATIONS)(this.props.state).status.complete;

        if (getConversationsComplete && !prevGetConversationsComplete) {
            const convoCount = conversationsSelectors.selectIds(this.props.state).length;
            const textKitIds = conversationsSelectors.selectIds(this.props.state);

            this.setState({ 
                twilioChannelsCount: convoCount,
                ids: textKitIds,
            });
        }

        const currChannelCount = twilioChannelsSelectors.selectAll(this.props.state).length;
        const prevChannelCount = twilioChannelsSelectors.selectAll(prevProps.state).length;
        
        if (currChannelCount !== prevChannelCount) {
            // DO NOT REMOVE, this logs a list of missing data between Twilio/TextKit in order for us to be able to sanitize the data in any env quickly.
            console.log("Incomplete Twilio Convos: ", currChannelCount, this.state.twilioChannelsCount, this.state);
        }
    }

    onBlur = () => {
        this.setState({ windowHasFocus: false });
    };

    onFocus = () => {
        this.setState({ windowHasFocus: true});
    };

    initializeFocusListeners = () => {
        window.onfocus = this.onFocus;
        window.onblur = this.onBlur;
    }

    chatInitialization = async () => {
        this.conversationClient = await new ConversationClient(this.props.state.auth.token);

        this.conversationClient.on(EChatSDKEvents.TOKEN_EXPIRED, this.updateToken );
        this.conversationClient.on(EChatSDKEvents.TOKEN_ABOUT_TO_EXPIRE, this.updateToken );

        this.props.dispatch(setSession(generateUUID()));

        this.setState({ connectionStatus: EConnectionStatus.CONNECTING });
        this.conversationClient.on(EChatSDKEvents.CONNECTION_STATE_CHANGED, this.connectionStateChangedEventHandler);
        this.conversationClient.on(EChatSDKEvents.CONVERSATION_JOINED, this.channelJoinedEventHandler);
        this.conversationClient.on(EChatSDKEvents.CONVERSATION_UPDATED, this.channelUpdatedEventHandler);
        
        // Event handler for the case when conversation category becomes "ENDED" and need to be removed from UI
        this.conversationClient.on(EChatSDKEvents.CONVERSATION_LEFT, this.channelLeftEventHandler);
    };

    updateToken = () => {
        this.props.dispatch(authChat(this.props.auth0Credentials));
    };

    connectionStateChangedEventHandler = (state: EConnectionState) => {
        if (state === EConnectionState.CONNECTING) {
            this.setState({ connectionStatus: EConnectionStatus.CONNECTING });
        }
        if (state === EConnectionState.CONNECTED) {
            this.setState({ connectionStatus: EConnectionStatus.CONNECTED });
        }
        if (state === EConnectionState.DISCONNECTING) {
            this.setState({connectionStatus: EConnectionStatus.DISCONNECTING });
        }
        if (state === EConnectionState.DISCONNECTED) {
            this.setState({ connectionStatus: EConnectionStatus.DISCONNECTED });
        }
        if (state === EConnectionState.DENIED) {
            this.setState({ connectionStatus: EConnectionStatus.FAILED });
        }
    };

    channelJoinedEventHandler = (channel: TTwilioChannel) => {
        // Reinitialization of Twilio client (after updating of Twilio JWT token) leads to repeated event triggering
        // with existing channel as payload, so no need to process in this case

        const channelExists = twilioChannelsSelectors.selectById(this.props.state, channel.attributes.conversation_id);

        this.setState({
            ids: this.state.ids.filter(id => id !== channel.attributes.conversation_id),
        });

        if (!channelExists) {    
            this.props.dispatch(addOneTwilioChannel(channel));
            this.getLastMessage(channel);
            channel.on(EChatSDKEvents.MESSAGE_ADDED, (m: TTwilioMessage) => this.messageAddedEventHandler(m, channel));
        }
    };

    getLastMessage = (channel: TTwilioChannel) => {
        channel.getMessages(1)
            .then((paginator: Paginator<TTwilioMessage>) => {
                const chat = chatsSelectors.selectById(this.props.state, channel.attributes.conversation_id);
                if ((!chat || chat.messages.length === 0) && paginator.items.length) {
                    const lastMessage = paginator.items.pop();
                    this.props.dispatch(upsertOneChat({
                        id: channel.attributes.conversation_id,
                        messages: [createMessage({ twilio: lastMessage })],
                    }));
                }
            });
    }

    throttledShowNotification = throttle((title: string) => this.setState({ showNotification: true, notificationTitle: title}), 5000)

    messageAddedEventHandler = (message: TTwilioMessage, channel: TTwilioChannel) => {
        const { dispatch, state } = this.props;

        const conversationId = message.conversation.attributes.conversation_id;

        // Notifications for incoming messages
        if (!this.state.windowHasFocus && !DISABLE_NOTIFICATION_FLAG) {
            this.throttledShowNotification("new message received");
        }

        // Has an error and the session is the same
        if (message.body === "" && message.attributes?.session === this.props.state.sdk.session) {
            dispatch(upsertOneChat({
                id: conversationId,
                error: message.attributes?.error_message,
            }));
        } else {
            const { messages } = chatsSelectors.selectById(state, conversationId);
            const universalMessage = createMessage({ 
                twilio: message,
                computed: {
                    messageOrigin: TMessageOrigin.NEW,
                },
            });
               
            this.props.dispatch(upsertOneChat({
                id: conversationId,
                messages: [...messages, universalMessage],
                error: null,
            }));

            if (channel.attributes.conversation_id === activeConversationIdSelector(state)) {
                this.props.dispatch(messageReceivedAC());
            }else{
                getMessageDirection(message).then(d => {
                    if(d === TMessageDirection.INCOMING){
                        this.props.dispatch(incrementUnreadMessageCount({
                            conversationId,
                        }));
                    };
                });
            }
        }
    };


    channelUpdatedEventHandler = (_: { conversation: TTwilioChannel; updateReasons: any[]}) => {
        return null;
    };
    
    channelLeftEventHandler = (channel: TTwilioChannel) => {
        const { state } = this.props;

        const activeConversationId = activeConversationIdSelector(state);
        const { sid } = twilioChannelsSelectors.selectById(state, activeConversationId);
        if (channel.sid === sid) {
            history.push("/");
        }
    };

    render(): React.ReactNode {
        return <>
            {!DISABLE_NOTIFICATION_FLAG && this.state.showNotification && <Notification
                timeout={5000}
                onClose={() => this.setState({ showNotification: false })}
                title={this.state.notificationTitle}
            /> }
        </>;
    }
}

export default WithTwilioChatSDK;