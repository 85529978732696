import "./index.scss";

import React, { ReactElement, useEffect, useRef, useState } from "react";

import { TMessageTemplate } from "@/messenger/types/entities/messageTemplate";

import { getMessageTemplateContent } from "../../utils/messageTemplates.helper";

interface TMessageTemplateLoadingProps {
    messageTemplate: TMessageTemplate;
    topicName: string;
}

const MIN_HEIGHT = 100;

export default function MessageTemplateLoading({
    messageTemplate,
    topicName,
}: TMessageTemplateLoadingProps): ReactElement {
    const [strokeCount, setStrokeCount] = useState<number>(1);
    const [content, setContent] = useState<string>("");
    const contentRef = useRef<HTMLDivElement>();

    useEffect(() => {
        setContent(getMessageTemplateContent({ messageTemplate }));
    }, []);

    useEffect(() => {
        if (content) {
            const r = contentRef.current.getBoundingClientRect();
            const c = Math.round(r.height / MIN_HEIGHT);
            setStrokeCount(c);
        }
    }, [content]);

    const renderStrokes = () => {
        const rows = [];
        for (let i = 0; i < strokeCount; i++) {
            rows.push(
                <div key={i} className="с-message-template-loading__stroke-container" data-testid="messageTemplateLoadingStroke">
                    <div className="с-message-template-loading__stroke-wrapper">
                        <span className="с-message-template-loading__stroke с-message-template-loading__stroke--28" />
                        <span className="с-message-template-loading__stroke с-message-template-loading__stroke--13" />
                    </div>
                    <span className="с-message-template-loading__stroke с-message-template-loading__stroke--91" />
                </div>,
            );
        }
        return <div className="с-message-template-loading__loading">{rows}</div>;
    };

    return (
        <div className="с-message-template-loading" ref={contentRef} data-testid="messageTemplateLoading">
            {renderStrokes()}
            <div className="с-message-template-loading__text"> {content}</div>
            <div className="с-message-template-loading__information">
                <span className="с-message-template-loading__information-campaign">{topicName}</span>
            </div>
        </div>
    );
}
