// need to import generic, and later on widget-specific styles
import "../Widgets/index.scss";

import React from "react";
import { useSelector } from "react-redux";

import { RIGHT_SIDEBAR_STANDIN, SENDABLE_STANDIN } from "@/messenger/constants/domID";
import SendableWidget from "@/messenger/containers/Widgets/SendableWidget";
import StandardWidget from "@/messenger/containers/Widgets/StandardWidget";
import TimelineWidget from "@/messenger/containers/Widgets/TimelineWidget";
import { widgetsSelectors } from "@/messenger/ducks/entities/widgets";
import { widgetsHiddenSelector } from "@/messenger/ducks/selectors/aggregate";
import { DEFAULT_OFFSET, nodeOffsetsSelectors } from "@/messenger/ducks/userInterface/nodeOffsets";
import { widgetStateSelectorsCustom } from "@/messenger/ducks/userInterface/widgetsState";
import { safeSelect } from "@/messenger/selectors";
import { TStore } from "@/messenger/types/store.types";
import { WidgetType } from "@/messenger/widgets/api/events";

interface TRenderWidgetsProps {
    conversationId: string
}
const WidgetContainerBase = (_: TRenderWidgetsProps) => {
    const widgets = useSelector(widgetsSelectors.selectAll);
    const standardWidgetStates = useSelector(widgetStateSelectorsCustom.selectAllByTypeStandard);
    const timelineWidgetStates = useSelector(widgetStateSelectorsCustom.selectAllByTypeTimeline);
    const sendableWidgetStates = useSelector(widgetStateSelectorsCustom.selectAllByTypeSendable);

    const rightSidebarOffsets = safeSelect(useSelector((s: TStore) => nodeOffsetsSelectors.selectById(s, RIGHT_SIDEBAR_STANDIN)), DEFAULT_OFFSET); 
    const sendableOffsets = safeSelect(useSelector((s: TStore) => nodeOffsetsSelectors.selectById(s, SENDABLE_STANDIN)), DEFAULT_OFFSET);

    const standardWidgetsHidden = useSelector((s: TStore) => widgetsHiddenSelector(s, WidgetType.Standard));
    const timelineWidgetsHidden = useSelector((s: TStore) => widgetsHiddenSelector(s, WidgetType.Timeline));

    const hideWidgetStyle = { height: 0, overflow: "hidden" };

    if (!widgets || widgets.length === 0) {
        return null;
    }

    const standardWidgets = standardWidgetStates.map(w => {
        const widgetIsHidden = !w.isReady || standardWidgetsHidden;
        const widgetWrapperStyle = widgetIsHidden ? hideWidgetStyle : {};
        return w.isEnabled && <StandardWidget  
            key={w.id}
            widgetState={w}
            widgetWrapperStyle={widgetWrapperStyle}
            offsets={rightSidebarOffsets}
        />;
    });

    const timelineWidget = timelineWidgetStates.map(w => {
        const widgetIsHidden = !w.isReady || timelineWidgetsHidden;
        const widgetWrapperStyle = widgetIsHidden ? hideWidgetStyle : {};
        return w.isEnabled && <TimelineWidget 
            key={w.id}
            widgetWrapperStyle={widgetWrapperStyle}
            widgetState={w}
        />;
    });

    const sendableWidgets = sendableWidgetStates.map(w => w.isEnabled && (<SendableWidget 
            key={w.id}
            widgetState={w}
            offsets={sendableOffsets}
        />));

    return <>
        {standardWidgets}
        {timelineWidget}
        {sendableWidgets}
    </>;
};

const WidgetContainer = React.memo(WidgetContainerBase, (_: TRenderWidgetsProps, { conversationId }: TRenderWidgetsProps) => !conversationId);

export const onWidgetError = (e) => {
    console.log("unexpected widget error: ", e);
};

export const WidgetErrorComponent = () => <>unexpected widget error</>;


export default WidgetContainer;