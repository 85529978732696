import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M17.653 11.06 9.26 2.667A2.613 2.613 0 0 0 7.653 2h-4.32a1.333 1.333 0 0 0-.94.393c-.25.25-.392.587-.393.94V7.66a2.5 2.5 0 0 0 .667 1.607l8.393 8.386a1.1 1.1 0 0 0 .78.347c.08.01.16.01.24 0a1.093 1.093 0 0 0 .753-.767L13.8 14a.346.346 0 0 1 .2-.2l3.18-.967a1.025 1.025 0 0 0 .447-1.773h.026ZM6 6.993a1 1 0 1 1-.013-2 1 1 0 0 1 .013 2Z"
        fill="#DBDBDB"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(2 2)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;