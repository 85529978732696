import { TActionV2 } from "@/messenger/types/action.types";

enum IsAddAContactOpenActionTypes {
    SET = "hummingbird/isAddAContactOpen/SET",
}

export const setIsAddAContactOpenAC = (isAddAContactOpen: boolean) => ({
    type: IsAddAContactOpenActionTypes.SET,
    payload: isAddAContactOpen,
});

const isAddAContactOpenReducer = (state = false, action: TActionV2<IsAddAContactOpenActionTypes, boolean>) => {
    switch (action.type) {
        case IsAddAContactOpenActionTypes.SET:
            return action.payload;
        default:
            return state;
    }
};

export default isAddAContactOpenReducer;