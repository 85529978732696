import * as React from "react";

function SvgComponent(props) {
    return (
        <svg viewBox="0 0 16 16" width={16} height={16} fill="currentColor" {...props}>
            <path d="M2.626 5.261A2.605 2.605 0 005.22 2.646 2.605 2.605 0 002.626.031 2.604 2.604 0 00.032 2.646a2.604 2.604 0 002.594 2.615zM10.516 6.256l5.403 8.927a.517.517 0 01-.438.786H.518a.517.517 0 01-.438-.785l4.076-6.732a.51.51 0 01.798-.099l1.28 1.274 3.485-3.47a.51.51 0 01.797.099z" />
        </svg>
    );
}

export default SvgComponent;
