import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setRequestTrackerAC } from "@/messenger/actions/requestTracker";
import { RequestURIs } from "@/messenger/constants/requests";
import { GraphQLUrl } from "@/messenger/constants/urls";
import { getTopics } from "@/messenger/graphql/queries";
import { createRequestTracker } from "@/messenger/models/requestTracker";
import { Query,TopicType } from "@/messenger/types/graphql/autogenerated";
import { normalizeTopics } from "@/messenger/utils/normalize";


export const sanitizeTopics = (topics: TopicType[]) => {
    const result = topics?.filter(topic => topic?.contactCount >= 0);

    return result;
};

const GetTopics = () => {
    const dispatch = useDispatch();
    const [skipQuery, setSkipQuery] = useState(false); 

    useQuery(getTopics, {
        skip: skipQuery,
        errorPolicy: "ignore",
        // disables cache so Apollo does not merge records by typename & id for campaignMembers because they are non-unique here.
        fetchPolicy: "no-cache",
        onCompleted: (data: { topics: Query[GraphQLUrl.topics]}) => {
            try {
                const sanitizedTopics = sanitizeTopics(data.topics);
                dispatch(normalizeTopics(sanitizedTopics));
    
                dispatch(setRequestTrackerAC(createRequestTracker(RequestURIs.GET_TOPICS, {
                    complete: true,
                    loading: false,
                    sent: true,
                })));
    
                setSkipQuery(true);
            } catch (e) {
                console.error(e);
            }
        },
    });

    useEffect(() => {
        dispatch(setRequestTrackerAC(createRequestTracker(RequestURIs.GET_TOPICS, {
            complete: false,
            loading: true,
            sent: true,
        })));
    }, []);

    return null;
};

export default GetTopics;