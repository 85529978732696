import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

import { VERIFY_MESSAGE } from "@/messenger/graphql/queries";
import { TVerifyMessageResult } from "@/messenger/types/message";

interface TUseVerifyMessageProps {
    content: string;
    conversationId: string;
    shouldVerify: boolean;
}

export const useVerifyMessage = ({ content, conversationId, shouldVerify }: TUseVerifyMessageProps) => {
    const [verifyMessage, { data, error, loading }] = useLazyQuery<TVerifyMessageResult>(VERIFY_MESSAGE);
    
    useEffect(() => {
        if (shouldVerify)
            verifyMessage({
                variables: {
                    VerifyMessageInput: {
                        conversationId,
                        content,
                    },
                },
            });
    }, [shouldVerify]);

    return { data, error, loading };
};
