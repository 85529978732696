import "./DropdownDefaultRenderSelected.scss";
import "./Dropdown.scss";

import cx from "classnames";
import React, { PureComponent } from "react";

import Close from "@/components/components/Close";
import { handleOnEnter } from "@/components/helpers";
import icons from "@/components/icons";

import { TDropdownOption } from "./dropdown.type";
import {
    DropdownDefaultRenderSelected,
    DropdownDefaultRenderSelectedClassNames,
} from "./DropdownDefaultRenderSelected";

const baseClassName = "c-dropdown";


interface DropdownClassNames {
    container?: string
}

interface DropdownProps {
    disabled?: boolean;
    headerIcons?: {up: React.ReactNode, down: React.ReactNode};
    heading: string | JSX.Element;
    actionButton?: JSX.Element;
    ariaLabel?: string;
    ariaLabelledBy?:string;
    selectedOption?: TDropdownOption;
    
    children: (onSelect: (o: TDropdownOption) => void, onClose: () => void) => React.ReactNode;
    onClose?: () => void
    onOpen?: () => void
    onSelect: (option: TDropdownOption) => void
    renderOverride?: (isOpen?: boolean, toggleMenu?: () => void) => React.ReactNode
    
    className?: string;
    classNames?: DropdownClassNames
    defaultRenderClassNames?: DropdownDefaultRenderSelectedClassNames
    headerTextClassName?: string

    testid?: string
    search?: boolean
}

interface DropdownState {
    isOpen: boolean;
}

class Dropdown extends PureComponent<DropdownProps, DropdownState> {
    state: DropdownState = {
        isOpen: false,
    };
    
    toggleMenu = (): void => {
        const {onOpen, onClose} = this.props;
        if (this.props.disabled !== true) {
            this.setState({isOpen: !this.state.isOpen});
            if (onOpen && !this.state.isOpen) onOpen();
            else if (onClose && this.state.isOpen) onClose();
        }
    };
    
    open = (): void => {
        this.setState({isOpen: true});
    };
    
    close = (): void => {
        this.setState({isOpen: false});
    };
    
    onSelect = (o: TDropdownOption): void => {
        this.props.onSelect(o);
        
        this.onClose();
    }
    
    onClose = (): void => {
        const {onClose} = this.props;
        
        this.setState({isOpen: false});
        
        if (onClose) {
            onClose();
        }
    }
    
    renderDropdown() {
        const {
            renderOverride,
            headerIcons = {up: icons.CARET_TOP, down: icons.CARET_BOTTOM},
            heading,
            selectedOption,
            disabled,
            defaultRenderClassNames,
            actionButton,
            headerTextClassName,
            testid,
            search,
        } = this.props;
        const {isOpen} = this.state;
        
        if (renderOverride) {
            return renderOverride(isOpen, this.toggleMenu);
        }
        
        return (
            <div
                data-testid={testid}
                onClick={this.toggleMenu} 
                onKeyDown={e => handleOnEnter(e, this.toggleMenu)} 
                tabIndex={0}
            >
                <DropdownDefaultRenderSelected
                    classNames={defaultRenderClassNames}
                    headerTextClassName={headerTextClassName}
                    disabled={disabled}
                    headerIcons={headerIcons}
                    heading={heading}
                    actionButton={actionButton}
                    isOpen={isOpen}
                    selectedOption={selectedOption}
                    search={search}
                />
            </div>
        );
    }
    
    render(): React.ReactNode {
        const {classNames = {}, children, ariaLabel, ariaLabelledBy} = this.props;
        const {isOpen} = this.state;
        const {container} = classNames;
        
        return (
            <Close isOpen={isOpen} closeChange={this.onClose}>
                <div aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} className={cx(baseClassName, container)} role="group" >
                    {this.renderDropdown()}
                    {isOpen && children(this.onSelect, this.onClose)}
                </div>
            </Close>
        );
    }
}

export default Dropdown;
