import development from "./development";
import { Config } from "./interface";
import local from "./local";
import production from "./production";

const get = (): Config => {
    if (process.env.TARGET === "production") {
        return production;
    } if (process.env.TARGET === "local") {
        return local;
    }
        return development;
    
};

export const config = get();
export default get();
