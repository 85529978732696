import * as React from "react";

function SvgComponent(props) {
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" {...props}>
            <path
                d="M36 0C28.8799 0 21.9197 2.11136 15.9995 6.06709C10.0793 10.0228 5.46511 15.6453 2.74035 22.2234C0.0155983 28.8015 -0.697322 36.0399 0.691746 43.0233C2.08081 50.0066 5.50948 56.4212 10.5442 61.4558C15.5789 66.4905 21.9935 69.9192 28.9768 71.3083C35.9601 72.6973 43.1985 71.9844 49.7766 69.2597C56.3548 66.5349 61.9772 61.9207 65.9329 56.0005C69.8886 50.0804 72 43.1201 72 36C71.9889 26.4556 68.1925 17.3053 61.4436 10.5564C54.6947 3.80753 45.5444 0.0111157 36 0V0ZM56.781 24.6L36.246 52.467C36.0036 52.7887 35.6995 53.0588 35.3515 53.2617C35.0035 53.4646 34.6186 53.5961 34.2193 53.6486C33.8199 53.7011 33.4141 53.6735 33.0255 53.5675C32.6369 53.4614 32.2734 53.2791 31.956 53.031L17.292 41.307C16.9843 41.0608 16.7282 40.7564 16.5381 40.4111C16.3481 40.0659 16.2279 39.6866 16.1844 39.2949C16.0967 38.5039 16.3267 37.7104 16.824 37.089C17.3213 36.4676 18.0451 36.0692 18.8361 35.9814C19.6271 35.8937 20.4206 36.1237 21.042 36.621L33.27 46.404L51.951 21.051C52.1759 20.7135 52.4669 20.4251 52.8064 20.2033C53.1459 19.9815 53.5268 19.8307 53.9262 19.7603C54.3256 19.6898 54.7351 19.701 55.13 19.7933C55.5249 19.8856 55.897 20.057 56.2238 20.2971C56.5507 20.5372 56.8254 20.8411 57.0314 21.1904C57.2375 21.5397 57.3706 21.9272 57.4226 22.3294C57.4747 22.7316 57.4447 23.1402 57.3344 23.5304C57.2241 23.9207 57.0359 24.2845 56.781 24.6Z"
                fill="#58D07E"
            />
        </svg>
    );
}

export default SvgComponent;
