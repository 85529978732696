import React from "react";

function NoPhone(props) {
    return (<svg viewBox="0 0 16 16" width={16} height={16} fill="currentColor" {...props} >
        <path d="M13.1185 9.8775C12.7613 9.5364 12.2864 9.34607 11.7925 9.34607C11.2986 9.34607 10.8237 9.5364 10.4665 9.8775L10.1778 10.1663C9.931 9.95925 9.68425 9.74625 9.4405 9.5295C9.36893 9.46598 9.27578 9.43223 9.18012 9.43516C9.08447 9.43809 8.99356 9.47747 8.926 9.54525L6.65875 11.8117C6.62251 11.8479 6.59405 11.891 6.57512 11.9386C6.55618 11.9861 6.54717 12.037 6.54865 12.0882C6.55012 12.1393 6.56205 12.1896 6.58369 12.236C6.60534 12.2823 6.63624 12.3238 6.6745 12.3577C7.89026 13.4356 9.18018 14.4268 10.5348 15.324C11.0407 15.6518 11.6431 15.7976 12.2429 15.7373C12.8427 15.6771 13.4042 15.4144 13.8348 14.9925L14.7078 14.1195C15.0593 13.7679 15.2567 13.2911 15.2567 12.7939C15.2567 12.2967 15.0593 11.8199 14.7078 11.4683L13.1185 9.8775Z" fill="#C92F24"/>
        <path d="M3.64375 9.32692C3.67776 9.36526 3.71926 9.39622 3.7657 9.41789C3.81214 9.43956 3.86253 9.45148 3.91375 9.45292H3.925C3.97433 9.45291 4.02318 9.44317 4.06874 9.42425C4.1143 9.40533 4.15568 9.37761 4.1905 9.34267L6.457 7.07542C6.52478 7.00786 6.56417 6.91695 6.56709 6.8213C6.57002 6.72565 6.53627 6.6325 6.47275 6.56092C6.25675 6.31742 6.04475 6.07192 5.83675 5.82442L6.12625 5.53492C6.47776 5.18331 6.67523 4.70648 6.67523 4.2093C6.67523 3.71211 6.47776 3.23529 6.12625 2.88367L4.534 1.29292C4.17711 0.951528 3.70226 0.760986 3.20838 0.760986C2.71449 0.760986 2.23965 0.951528 1.88275 1.29292L1.00975 2.16592C0.584276 2.59445 0.319117 3.15627 0.258758 3.75713C0.198399 4.35799 0.346514 4.96132 0.678253 5.46592C1.57552 6.82049 2.56644 8.11064 3.64375 9.32692Z" fill="#C92F24"/>
        <path d="M13.5302 2.47508C13.3896 2.33448 13.1989 2.25549 13 2.25549C12.8011 2.25549 12.6104 2.33448 12.4697 2.47508L0.469742 14.4751C0.329139 14.6157 0.250153 14.8065 0.250153 15.0053C0.250153 15.2042 0.329139 15.3949 0.469742 15.5356C0.611341 15.6744 0.801715 15.7521 0.999992 15.7521C1.19827 15.7521 1.38864 15.6744 1.53024 15.5356L13.5302 3.53558C13.6708 3.39494 13.7498 3.20421 13.7498 3.00533C13.7498 2.80646 13.6708 2.61573 13.5302 2.47508Z" fill="#C92F24"/>
    </svg>

    );
}

export default NoPhone;
