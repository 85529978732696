import "./index.scss";
import "../../locales/i18n";

import cx from "classnames";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import { AccentedDropdown } from "@/components/components/Dropdown";
import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";

interface TRepDropdownProps {
    options: TDropdownOption[];
    currentUser: string;
    onSelectRepresentative: (representativeId: string) => void
}

export default function RepDropdown({options, currentUser, onSelectRepresentative}: TRepDropdownProps): ReactElement {
    const [isActive, setIsActive] = useState<boolean>(false);

    const headerText = `Assigned To ${currentUser}`;

    const { t } = useTranslation();

    const onOpen = () => {
        setIsActive(true);
    };
    
    const onClose = () => {
        setIsActive(false);
    };
    
    const onSelect = (o: TDropdownOption) => {
        onSelectRepresentative(o.label);
    };

    return (
        <div className="c-rep-dropdown" role="form">
            <>
                
                <AccentedDropdown
                    options={options}
                    heading={headerText}
                    isMobileView={false}
                    onSelect={onSelect}
                    onOpen={onOpen}
                    onClose={onClose}
                    headerClassName={cx(
                        "c-rep-dropdown__header",
                        {"c-rep-dropdown__header--active": isActive,
                        })}
                    headerTextClassName={"c-rep-dropdown__dropdown-header-text"}
                    search={true}
                    ariaLabel={t("Representative Filter")}
                    testid={"Rep-filter"}
                    className={"c-rep-dropdown__dropdown"}
                />
            </>
        </div>
    );
}