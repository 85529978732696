import * as React from "react";

function SvgComponent() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
        <path d="M17 4V1.5H1.5L8 11V17.5L9 19L11.5 17.5V11.5L17 4Z" fill="#4B64D7"/>
        <path d="M18.6529 2.98231C18.8491 2.70282 18.9672 2.37217 18.9941 2.02702C19.021 1.68187 18.9557 1.3357 18.8054 1.02688C18.655 0.718051 18.4256 0.458635 18.1425 0.277383C17.8594 0.0961313 17.5336 0.000123088 17.2014 1.2923e-07H2.79915C2.46821 -0.000127924 2.14365 0.0949116 1.86121 0.274656C1.57876 0.454401 1.34938 0.711881 1.19831 1.01875C1.04724 1.32562 0.980351 1.66998 1.00499 2.01393C1.02964 2.35787 1.14486 2.68806 1.33798 2.96815L7.01318 11.4411V18.1179C7.01315 18.3786 7.0651 18.6365 7.16572 18.8752C7.26635 19.1138 7.41346 19.3281 7.59773 19.5044C7.782 19.6807 7.99941 19.8152 8.23618 19.8993C8.47294 19.9835 8.72389 20.0154 8.97312 19.9931C9.41475 20.007 9.85099 19.8891 10.2305 19.6532C12.415 18.2371 12.1848 18.3837 12.2056 18.3678C12.4284 18.1928 12.6094 17.9663 12.7342 17.7061C12.859 17.446 12.9243 17.1592 12.925 16.8684V11.4436L18.6529 2.98231ZM11.1097 10.513C10.9906 10.6887 10.9267 10.8986 10.9267 11.1137V16.5126C10.9267 16.5849 10.9088 16.6558 10.8746 16.7186C10.8404 16.7814 10.7911 16.8339 10.7316 16.8709C10.4695 17.0325 9.99386 17.3265 9.60858 17.5598C9.54787 17.5965 9.47894 17.6159 9.40873 17.6161C9.33853 17.6162 9.26953 17.5971 9.20867 17.5606C9.14782 17.5241 9.09726 17.4716 9.06208 17.4083C9.02691 17.3449 9.00835 17.2731 9.00829 17.1999V11.1137C9.00863 10.9006 8.94582 10.6925 8.82845 10.518L3.61925 2.73822C3.57747 2.67579 3.55291 2.60261 3.54824 2.52664C3.54356 2.45068 3.55895 2.37483 3.59273 2.30734C3.62651 2.23984 3.67739 2.18329 3.73984 2.14382C3.8023 2.10435 3.87394 2.08347 3.94698 2.08345H16.0447C16.118 2.08341 16.1898 2.10434 16.2524 2.14395C16.315 2.18357 16.366 2.24035 16.3997 2.30809C16.4334 2.37583 16.4487 2.45193 16.4437 2.52808C16.4388 2.60423 16.4138 2.6775 16.3717 2.73989L11.1097 10.513Z" fill="#4B64D7"/>
    </svg>
  );
}

export default SvgComponent;