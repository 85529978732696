import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { entitiesSelector } from "@/messenger/selectors";
import { TTopicNormalized } from "@/messenger/types/entities/topic";
import { NormalizedEntities, TStore } from "@/messenger/types/store.types";


const topicsAdapter = createEntityAdapter<TTopicNormalized>();

export const initialState = topicsAdapter.getInitialState({});

export const topicsSelectors = topicsAdapter.getSelectors((state: TStore) => entitiesSelector(state).topics);

const topics = createSlice({
    name: "topics",
    initialState,
    reducers: {
        upsertTopics(state, action: PayloadAction<NormalizedEntities>) {
            topicsAdapter.upsertMany(state, action.payload.topics);
        },
    },
});

export const {
    upsertTopics,
} = topics.actions;

export default topics.reducer;
