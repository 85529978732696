import "./index.scss";
import "../../locales/i18n";

import { useMutation } from "@apollo/client";
import cx from "classnames";
import i18next from "i18next";
import moment from "moment-timezone";
import React, { createRef, useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { usePrevious } from "react-use";

import Badge from "@/components/components/Badge";
import { BadgeColor } from "@/components/components/Badge/badgeColor.enum";
import { Camera, Exclamation, Landscape } from "@/components/icons";
import { messageGetFirstBatchAC } from "@/messenger/actions/message";
import ChatCardNew from "@/messenger/components/ChatCard";
import LoadedChatCard from "@/messenger/components/ChatCard/LoadedChatCard";
import { ACTIVE_CHAT_CARD } from "@/messenger/constants/domID";
import { CHAT } from "@/messenger/constants/routing";
import { contactsSelectors } from "@/messenger/ducks/entities/contacts";
import { conversationsSelectors } from "@/messenger/ducks/entities/conversations";
import { topicsSelectors } from "@/messenger/ducks/entities/topics";
import { setSelectedCategory } from "@/messenger/ducks/general/selectedCategory.duck";
import { setTertiaryCategory } from "@/messenger/ducks/general/tertiaryCategory.duck";
import { isAppLoadedSelector } from "@/messenger/ducks/selectors/aggregate";
import { selectedCategorySelector, tertiaryCategorySelector } from "@/messenger/ducks/selectors/general";
import { chatsSelectors, upsertOneChat } from "@/messenger/ducks/userInterface/chats";
import { hydrateConversation } from "@/messenger/graphql/mutations";
import Mailbox from "@/messenger/illustrations/Mailbox";
import NoSearchResult from "@/messenger/illustrations/NoSearchResult";
import { formatContactName } from "@/messenger/models/contact";
import { getBody, getMediaContentType, isNotDelivered } from "@/messenger/models/message";
import { activeConversationSelector, conversationRefinementsSelector } from "@/messenger/selectors";
import { ConversationCategory,TConversationNormalized  } from "@/messenger/types/entities/conversation";
import { ChannelTypeEnum, HydrationEnum } from "@/messenger/types/graphql/autogenerated";
import { TMessage, TMessageMediaType } from "@/messenger/types/message";
import { TStore } from "@/messenger/types/store.types";
import { EDeviceType } from "@/messenger/utils/deviceType";
import { getChatCategoryCounts , getChatCategoryOptions, hiddenCategories } from "@/messenger/utils/getChatCategoryOptions";
import { ellipsis } from "@/messenger/utils/helpers";

import { CHAT_CATEGORY_OPTIONS } from "../TopBar";

const baseClassName = "c-chat-card-list";

const getMessageContentType = (message: TMessage) => {
    const messageBody = message.local ?? message.twilio; 
    
    if (!messageBody){
        return null;
    }

    if (messageBody.body && messageBody.body.length > 0) {
        return TMessageMediaType.TEXT;
    }

    const contentType = getMediaContentType(message);

    if (!contentType) {
        return TMessageMediaType.TEXT;
    }

    if (contentType.startsWith("image")) {
        return TMessageMediaType.IMAGE;
    }
    if (contentType.startsWith("video")) {
        return TMessageMediaType.VIDEO;
    }

    return null;
};

const getMessageSnippet = (message: TMessage, isMobileView: boolean, isActive: boolean): React.ReactNode => {
    if (!message) {
        return null;
    }

    const maxTextLength = isMobileView ? 50 : 75;
    const contentType = getMessageContentType(message);

    if (!contentType) {
        return null;
    }

     if (contentType === TMessageMediaType.TEXT) {
        return ellipsis(getBody(message), maxTextLength);
    } 

    if (contentType === TMessageMediaType.IMAGE) {
        return (<div className={cx(`${baseClassName}__subheader`, isActive ? `${baseClassName}__subheader--active` : "")}>
            <Landscape /> image
        </div>);
    } 

    if (contentType === TMessageMediaType.VIDEO) {
        return (<div className={cx(`${baseClassName}_subheader`, isActive ? `${baseClassName}__subheader--active` : "")}>
            <Camera /> video
        </div>);
    }
    return null;
};

const renderRightLabel = (message: any, isActive: boolean, isSearchMode: boolean, category: ConversationCategory): React.ReactNode => {
    const c = `${baseClassName}__label`;

    if(!isSearchMode){
        const lastTimestampLabel = message ? moment(message.timestamp).fromNow() : "";
        return <span className={cx(`${c} ${c}-open`, { [`${c}-open--active`]: isActive })}>
            {lastTimestampLabel === i18next.t("just now ago") ? i18next.t("just now") : lastTimestampLabel}
        </span>;
    }
        
    const readableCategoryHeading = CHAT_CATEGORY_OPTIONS[category]?.heading;
    return <span className={cx(`${c} ${c}-search`, { [`${c}-search__${category}`]: !isActive })}>
        {readableCategoryHeading}
    </span>;
};

const ChatCardList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const state = useSelector((s: TStore) => s);
    const { id: activeConversationId } = useParams<any>();

    const tertiaryCategory = useSelector(tertiaryCategorySelector);
    const isAppLoaded = useSelector(isAppLoadedSelector);
    const scrollbarRef = useRef<any>(null);
    const [elRefs, setElRefs] = useState<Array<any | null>>([]);
    const [headers, setHeaders] = useState({});
    const selectedCategory = useSelector(selectedCategorySelector);

    const convoCounts = getChatCategoryCounts(useSelector(conversationsSelectors.selectAll));
    const chatCategoryOptions = getChatCategoryOptions(convoCounts);
    const textkitConversations = useSelector(conversationRefinementsSelector).result;
    const hasSearch = useSelector(conversationRefinementsSelector).search.value?.length > 0;
    const hasSearchResult = hasSearch && textkitConversations?.length > 0;

    const { selectedTopicName, isSmsCapable, selectedMonth } = useSelector(conversationRefinementsSelector).filter;
    const hasFilter = selectedTopicName?.length > 0 || selectedMonth?.length > 0 || isSmsCapable;
    
    const prevHasSearch = usePrevious(hasSearch);

    const getResultsByCategory = (conversations: TConversationNormalized[], category: ConversationCategory) => {
        const filteredList = conversations.filter(c => c?.category === category);
        if (category === ConversationCategory.TO_ANSWER || category === ConversationCategory.TO_BE_CONTACTED) {
            const newList = [...filteredList].sort((a, b) => {
                const chatA = chatsSelectors.selectById(state, a.id)?.hasPriority;
                const chatB = chatsSelectors.selectById(state, b.id)?.hasPriority;
                if ((chatA && chatB) || chatB) {
                    return 1;
                }
                if (chatA) {
                    return -1;
                }

                return 0;
            });
            return newList;
        }
        return filteredList;
    };

    const activeConversation = useSelector(activeConversationSelector);

    useEffect(() => {
        if (!hasSearch && prevHasSearch && activeConversation) {
            const element = document.getElementById(ACTIVE_CHAT_CARD);
            const elementIsOffScreen = element && element.getBoundingClientRect().bottom > (window.innerHeight || document.documentElement.clientHeight);
            if (elementIsOffScreen) {
                element.scrollIntoView(false);
            }
        }
    });

    useEffect(() => {
        const list = {};
        chatCategoryOptions.forEach((_, i) => {
            list[i] = false;
        });
        
        setHeaders(list);
    }, []);

    useEffect(() => {
        setElRefs([1, 2, 3].map((_, i) => elRefs[i] || createRef()));
    }, []);

    const onChatCardSelect = (conversationId: string, cardCategory: ConversationCategory) => {
        if (hasSearch && selectedCategory !== cardCategory) {
            dispatch(setSelectedCategory(cardCategory));
            
            if (hiddenCategories.includes(cardCategory) && cardCategory !== tertiaryCategory) {
                dispatch(setTertiaryCategory(cardCategory));
            }
        }
        history.push(`/${CHAT}/${conversationId}`);
    };

    const [hydrateConversationMutation] = useMutation<{
        hydrateConversation: boolean;
    }>(hydrateConversation);
    
    const hydrateConvo = (convoId) => {
        hydrateConversationMutation({
            variables: {conversationId: convoId},
        });
    };

    const onChatCardHover = (conversationId: string) => {
        const chatState = chatsSelectors.selectById(state, conversationId); 
        if(chatState.hydration === HydrationEnum.Dehydrated){
            hydrateConvo(conversationId);
            dispatch(upsertOneChat({id: conversationId, hydration: HydrationEnum.Hydrated}));
        }
        else if(!chatState.messageFirstBatchLoaded){
            dispatch(messageGetFirstBatchAC(conversationId));
        }
    };
    
    const onToBeContactedCardSelect = (id: string) => {
        if (hasSearch && selectedCategory !== ConversationCategory.TO_BE_CONTACTED) {
            dispatch(setSelectedCategory(ConversationCategory.TO_BE_CONTACTED));
        }
        history.push(`/${CHAT}/${id}`);
    };

    const renderLoading = (): React.ReactNode => {
        const result = [];
        for (let i = 0; i < 8; i++) {
            result.push(<LoadedChatCard key={i} />);
        }
        return result;
    };

    const renderNoChatsMessage = () => {
        const title = ((category: ConversationCategory) => {
            if (hasSearch) {
                return "No Contacts Found";
            }
            switch (category) {
                case ConversationCategory.OPT_OUT:
                    return t("No Opt-out Contacts");
                case ConversationCategory.TO_BE_CONTACTED:
                    return t("No Contacts Yet");
                default:
                    return t("No Conversations Yet");
            }
        })(selectedCategory);


        const message = ((category: ConversationCategory) => {
            if (hasFilter) {
                return t("Try adjusting your filter settings");
            }
            if (hasSearch) {
                return "Try searching for another contact name or phone number";
            }
            
            switch (category) {
                case ConversationCategory.TO_ANSWER:
                    return t("You will see conversations in this category once someone sends you a message");
                case ConversationCategory.TO_BE_CONTACTED:
                    return t("You will see all contacts here as soon as they are assigned to you");
                case ConversationCategory.OPEN:
                    return t("You will see conversations here after you send a message");
                case ConversationCategory.CLOSED:
                    return t("You will see conversations here when they are marked as closed");
                case ConversationCategory.OPT_OUT:
                    return t("You will see conversations here if they are marked as Opt-out");
                default:
                    return "";
            }
        })(selectedCategory);

        const illustration = (() => {
            if (hasSearch) {
                return <NoSearchResult width={200} />;
            } 
                return <Mailbox width={200} />;
        })();

        return (
            <div className={`${baseClassName}__message-wrapper`}>
                {illustration}
                <span className={`${baseClassName}__message-title`}>{title}</span>
                <span className={`${baseClassName}__message`}>{message}</span>
            </div>
        );
    };

    const isMobile = state.general.deviceType === EDeviceType.MOBILE;

    const renderToBeContactedList = () => {
        return getResultsByCategory(textkitConversations, ConversationCategory.TO_BE_CONTACTED)
            .map(c => {
                const contact = contactsSelectors.selectById(state, c.contact);
                const topic = topicsSelectors.selectById(state, c.topic);
                const isContactSmsCapable = contact?.capabilities?.includes(ChannelTypeEnum.Sms);
                const isActive = activeConversationId === c.id;

                const topicName = topic?.name !== "All" ? topic?.name : "";
                return <ChatCardNew
                    key={c.id}
                    domId={isActive ? ACTIVE_CHAT_CARD : `CHAT_CARD_${c.id}`}
                    header={formatContactName(contact)}
                    subheader={topicName}
                    rightLabel={hasSearch && (renderRightLabel(null, isActive, true, ConversationCategory.TO_BE_CONTACTED))}
                    isActive={c.id === activeConversationId}
                    onClick={() => onToBeContactedCardSelect(c.id)}
                    onHover={() => onChatCardHover(c.id)}
                    isSmsCapable={isContactSmsCapable}
                />;
            });
    };

    const renderRightContent = (count: number, isActive: boolean, notDelivered: boolean) => {
        const renderUnreadMessagesCount = (): React.ReactNode => {
            return count && !isActive ? (
                <Badge className={`${baseClassName}__badge`} color={BadgeColor.PRIMARY}>
                    {count < 10 ? (
                        count
                    ) : (
                            <>
                                9<span className={`${baseClassName}__badge-plus`}>+</span>
                            </>
                        )}
                </Badge>
            ) : (
                    ""
                );
        };

        return <>
            {notDelivered && <Exclamation className={cx(`${baseClassName}__error`, { [`${baseClassName}__error--active`]: isActive })} />}
            {!notDelivered && renderUnreadMessagesCount()}
        </>;    
    };

    const renderChatList = (category): React.ReactNode => {
        if (category === ConversationCategory.TO_BE_CONTACTED) {
            return renderToBeContactedList();
        }
        return getResultsByCategory(textkitConversations, category).map(c => {
            const chatState = chatsSelectors.selectById(state, c.id); 
            const lastMessage = chatState.messages[chatState.messages.length - 1];
            const isActive = activeConversationId === c.id;
            const notDelivered = lastMessage ? isNotDelivered(lastMessage) : false;
            const contact = contactsSelectors.selectById(state, c.contact);
            const hasRightContent = category === ConversationCategory.TO_ANSWER;
            const isContactSmsCapable = contact?.capabilities?.includes(ChannelTypeEnum.Sms);

            return <ChatCardNew
                key={c.id}
                domId={isActive ? ACTIVE_CHAT_CARD : `CHAT_CARD_${c.id}`}
                header={formatContactName(contact)}
                subheader={getMessageSnippet(lastMessage, isMobile, isActive)}
                rightLabel={renderRightLabel(lastMessage?.twilio?.conversation.lastMessage, isActive, hasSearch, category)}
                rightContent={hasRightContent && renderRightContent(chatState.unreadMessagesCount, isActive, notDelivered)}
                isActive={isActive}
                onClick={() => onChatCardSelect(c.id, category)}
                onHover={() => onChatCardHover(c.id)}
                isSmsCapable={isContactSmsCapable}
            />;
        });
    };

    const renderChatListSearchResult = (): React.ReactNode => {
        return (
            <div style={{ position: "relative", maxWidth: scrollbarRef.current?.getClientWidth(), overflow: "hidden" }}>
                {Object.keys(convoCounts).map((category: ConversationCategory, i) => {
                    return (
                        <div style={{ position: "relative" }} ref={elRefs[i]} key={category}>
                            {renderChatList(category)}
                        </div>
                    );
                })}
            </div>
        );
    };
    
    const shouldNoChatsMessageBeRendered = getResultsByCategory(textkitConversations, selectedCategory).length === 0;

    const handleOnScroll = () => {
        if (hasSearch) {
            const scrollTop = scrollbarRef.current.getScrollTop();
            let total = 0;
            const newHeaders = { ...headers };

            elRefs.forEach((ref, i) => {
                const { clientHeight } = ref.current;
                newHeaders[i] = scrollTop >= total;
                total += clientHeight;
            });

            setHeaders(newHeaders);
        }
    };

    let childComponent: JSX.Element | React.ReactNode = null;

    if (!isAppLoaded) {
        childComponent = renderLoading();
    } else if (hasSearchResult) {
        childComponent = renderChatListSearchResult();
    } else if (shouldNoChatsMessageBeRendered) {
        childComponent = renderNoChatsMessage();
    } else {
        childComponent = renderChatList(selectedCategory);
    }

    const classNames = cx({
        [`${baseClassName}__wrapper`]: true,
    });
    return (
        <div className={baseClassName} data-testid="chatCardList">
            <div className={classNames}>
                <Scrollbars ref={scrollbarRef} hideTracksWhenNotNeeded={true} onScroll={handleOnScroll}>
                    {childComponent}
                </Scrollbars>
            </div>
        </div>
    );
};

export default ChatCardList;