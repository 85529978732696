import NoChatSelected from "@/messenger/components/NoChatsSelected";
import ChatWindow from "@/messenger/containers/ChatWindow";
import Error404 from "@/messenger/illustrations/Error404";
import Error404Convo from "@/messenger/illustrations/Error404Convo";
import LeftSidebar from "@/messenger/modules/LeftSidebar";
import RightSidebarStandin from "@/messenger/modules/RightSidebarStandin";

export const CHAT = "chat";
export const CONVERSATION_NOT_FOUND = "conversation-not-found";
export const NOT_FOUND = "not-found";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const MODULE = "module";
export const MODULE_NOT_FOUND = "module-not-found";

export const LOGOUT_REDIRECT_PARAM = "logoutRedirectUrl";


export enum LayoutComponentKeys {
    "ChatWindow" = "ChatWindow",
    "Error404" = "Error404",
    "Error404Convo" = "Error404Convo",
    "LeftSidebar" = "LeftSidebar",
    "NoChatSelected" = "NoChatSelected",
    "RightSidebarStandin" = "RightSidebarStandin",
}

export const LayoutComponents = {
    [LayoutComponentKeys.ChatWindow]: ChatWindow,
    [LayoutComponentKeys.Error404]: Error404,
    [LayoutComponentKeys.Error404Convo]: Error404Convo,
    [LayoutComponentKeys.LeftSidebar]: LeftSidebar,
    [LayoutComponentKeys.NoChatSelected]: NoChatSelected,
    [LayoutComponentKeys.RightSidebarStandin]: RightSidebarStandin,
};