export const localStorageKeys = {
    activeChatCardId: "activeChatCardId",
    campaignFilter: "campaignFilter",
    prevCampaignFilter: "prevCampaignFilter",
    monthFilter: "monthFilter",
    prevMonthFilter: "prevMonthFilter",
    unreadMessages: "unreadMessages",
    smsCapableFilter: "smsCapableFilter",
    prevSmsCapableFilter: "prevSmsCapableFilter",
};

export const windowBreakpoints = {
    tablet: 768,
    desktop: 1024,
    desktopLarge: 1200,
};

export const cookieKeys = {
    auth0IsAuthenticated: "auth0.is.authenticated",
    activeTime: "active_time",
};
