import * as React from "react";

function SvgComponent(props) {
    return (
        <svg fill="currentColor" {...props}>
            <path d="M17.3923 2.50879L10.0887 0.528788C9.70307 0.4253 9.29693 0.4253 8.91125 0.528788L1.60775 2.50879C1.29141 2.59808 1.01251 2.78744 0.812809 3.04852C0.61311 3.30961 0.503362 3.62836 0.5 3.95704L0.5 13.3635C0.500001 13.675 0.596982 13.9788 0.777479 14.2327C0.957976 14.4866 1.21304 14.678 1.50725 14.7803L9.113 17.4263C9.35643 17.511 9.61277 17.5526 9.8705 17.5493C10.1381 17.5504 10.4035 17.501 10.6528 17.4038L17.534 14.7788C17.8129 14.6638 18.0525 14.4705 18.2239 14.2223C18.3953 13.9741 18.4912 13.6816 18.5 13.38V3.95704C18.4974 3.62817 18.3879 3.30907 18.1881 3.04784C17.9883 2.78661 17.709 2.59742 17.3923 2.50879ZM9.3035 1.97704C9.43223 1.94254 9.56777 1.94254 9.6965 1.97704L14.3143 3.22879C14.3538 3.2397 14.3887 3.26329 14.4136 3.29594C14.4384 3.32859 14.4519 3.3685 14.4519 3.40954C14.4519 3.45058 14.4384 3.49048 14.4136 3.52313C14.3887 3.55579 14.3538 3.57938 14.3143 3.59029L9.91625 4.85479C9.88361 4.8638 9.84914 4.8638 9.8165 4.85479L4.8395 3.54904C4.79962 3.53836 4.76438 3.51483 4.73924 3.48208C4.7141 3.44933 4.70048 3.4092 4.70048 3.36791C4.70048 3.32663 4.7141 3.2865 4.73924 3.25375C4.76438 3.221 4.79962 3.19746 4.8395 3.18679L9.3035 1.97704ZM2.126 13.407C2.08922 13.3943 2.05733 13.3704 2.03475 13.3386C2.01217 13.3069 2.00003 13.269 2 13.23V4.59829C2.00006 4.56919 2.00686 4.5405 2.01987 4.51448C2.03289 4.48845 2.05176 4.46579 2.075 4.44829C2.09779 4.43097 2.12419 4.41901 2.15223 4.4133C2.18028 4.40759 2.20925 4.40827 2.237 4.41529L8.987 6.18679C9.02717 6.19725 9.06273 6.22074 9.08812 6.25359C9.1135 6.28644 9.12726 6.32678 9.12725 6.36829V15.5783C9.127 15.6078 9.11972 15.6367 9.10603 15.6628C9.09233 15.6889 9.07261 15.7114 9.0485 15.7283C9.0241 15.7456 8.99595 15.7569 8.96635 15.7612C8.93676 15.7655 8.90656 15.7627 8.87825 15.753L2.126 13.407Z" />
        </svg>
    );
}

export default SvgComponent;
