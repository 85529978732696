import "./index.scss";
import "../../locales/i18n";

import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-spinners/FadeLoader";

import Button from "@/components/components/Button";
import { ButtonColor } from "@/components/components/Button/button.enum";

interface TMessageTemplateSendButtonProps {
    isDisabled: boolean;
    isSending: boolean;
    onButtonClick: () => void;
}

export default function MessageTemplateSendButton({
    isDisabled,
    isSending,
    onButtonClick,
}: TMessageTemplateSendButtonProps): ReactElement {
    const { t } = useTranslation();
    return (
        <div className="с-message-template-send-button__button-wrapper">
            <Button
                id={"MESSAGE_TEMPLATE_SEND_BUTTON"}
                color={ButtonColor.PRIMARY}
                className="с-message-template-send-button__button"
                disabled={isDisabled}
                onClick={onButtonClick}
                ariaLabel={t("Message Template Send Button")}
            >
                <Loader loading={isSending} css={"top: 9px;transform: scale(0.4);"} color={"#fff"} />
                {!isSending && t("Send Message")}
            </Button>
        </div>
    );
}
