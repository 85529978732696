import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setRequestTrackerAC } from "@/messenger/actions/requestTracker";
import { RequestURIs } from "@/messenger/constants/requests";
import { GraphQLUrl } from "@/messenger/constants/urls";
import { upsertWidgets } from "@/messenger/ducks/entities/widgets";
import { setWidgetTab } from "@/messenger/ducks/general/widgetTab.duck";
import { DEFAULT_WIDGET_STATE, upsertOneWidgetsState } from "@/messenger/ducks/userInterface/widgetsState";
import { findAllWidgets } from "@/messenger/graphql/queries";
import { createRequestTracker } from "@/messenger/models/requestTracker";
import { toTitleCase } from "@/messenger/sagas/util";
import { TWidget } from "@/messenger/types/entities/conversation";
import { Query } from "@/messenger/types/graphql/autogenerated";
import { normalizeFindAllWidgetsResponse } from "@/messenger/utils/normalize";
import { widgetContainerClient } from "@/messenger/widgets";
import { WidgetViewSize } from "@/messenger/widgets/api";
import { WidgetType } from "@/messenger/widgets/api/events";
import { TWidgetState } from "@/messenger/widgets/types";


const sanitizeWidgets = (widgets: Query[GraphQLUrl.findAllWidgets]) => {
    return widgets.map(w => {
        const scope = w.scope.toLowerCase();
        const domains = w.domains ? w.domains : [];
        const type = toTitleCase(w.type);

        return {
            ...w,
            scope,
            domains,
            type,
        } as TWidget;
    });
};

const createWidgetState = (w: TWidget): TWidgetState => ({
    ...DEFAULT_WIDGET_STATE, 
    ...{ 
        type: w.type,
        id: w.name,
        url: w.url,
        scope: w.scope,
        widgetDomain: w.domains[0],
        label: "",
        footer: "",
        isOpen: false,
        isReady: false,
        maxHeight: null,
        path: w.path,
        isEnabled: w.isEnabled,
    },
    ...{ header: w.type === WidgetType.Standard ? w.name : null },
    ...{ size: w.type === WidgetType.Standard ? WidgetViewSize.Medium : null },
    ...{ isShown: w.type === WidgetType.Sendable ? false : null },
});

const GetWidgets = () => {
    const [skipQuery, setSkipQuery] = useState(false);
    const dispatch = useDispatch();

    useQuery(findAllWidgets, {
        skip: skipQuery,
        onCompleted: (data: { findAllWidgets: Query[GraphQLUrl.findAllWidgets]}) => {
            try {
                const widgets = sanitizeWidgets([...data.findAllWidgets]);
                
                const normalizedData = normalizeFindAllWidgetsResponse(widgets);

                let hasStandard = false;
                let hasTimeline = false;
                
                if(Object.keys(normalizedData.entities.widgets || []).length){
                    dispatch(upsertWidgets(normalizedData.entities));
                    

                    widgets.forEach(w => {
                        if (!w.domains) {
                            console.error("widget registration is missing domains for widget: ", w.name);
                        }
                        
                        // todo remove widgets from redux entirely
                        const widgetState = createWidgetState(w);
                        dispatch(upsertOneWidgetsState(widgetState));
                        widgetContainerClient.createWidget(widgetState);


                        if(w.type === WidgetType.Standard) {
                            hasStandard = true;
                        }
                        if(w.type === WidgetType.Timeline) {
                            hasTimeline = true;
                        }
                    });
                    
                    if (hasStandard && hasTimeline) {
                        dispatch(setWidgetTab({
                            hasTab: true,
                            selectedTab: WidgetType.Standard,
                        }));
                    } else {
                        dispatch(setWidgetTab({
                            hasTab: false,
                            selectedTab: null,
                        }));
                    }
                }

                dispatch(setRequestTrackerAC(createRequestTracker(RequestURIs.GET_WIDGETS, {
                    complete: true,
                    loading: false,
                    sent: true,
                })));
                
                setSkipQuery(true);
            } catch (e) {
                console.error(e);
            }
        },
    });

    useEffect(() => {
        dispatch(setRequestTrackerAC(createRequestTracker(RequestURIs.GET_WIDGETS, {
            complete: false,
            loading: true,
            sent: true,
        })));
    }, []);

    return null;
};

export default GetWidgets;