import "./index.scss";
import "../../locales/i18n";

import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { usePrevious, useUnmount } from "react-use";

import Button from "@/components/components/Button";
import { ButtonColor } from "@/components/components/Button/button.enum";
import {
    EMessageTemplateSelectedPlaceholderType,
    TMessageTemplatePlaceholderInPlace,
} from "@/messenger/types/entities/messageTemplate";

interface TMessageTemplateEditFormProps {
    children: ReactElement;
    selectedPlaceholder: TMessageTemplatePlaceholderInPlace;
    isSavingDisabled: boolean;
    isJustOpened: boolean;
    hasMoreEmptyPlaceholders: boolean;
    onCancelButtonClick: () => void;
    onSaveButtonClick: () => void;
    onUnmount: () => void;
}

export default function MessageTemplateEditForm({
    selectedPlaceholder,
    isJustOpened,
    hasMoreEmptyPlaceholders,
    children,
    onSaveButtonClick,
    isSavingDisabled,
    onCancelButtonClick,
    onUnmount,
}: TMessageTemplateEditFormProps): ReactElement {
    const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);
    const prevPlaceholder = usePrevious(selectedPlaceholder);

    const { t } = useTranslation();

    useUnmount(onUnmount);

    useEffect(() => {
        if (selectedPlaceholder && prevPlaceholder) {
            const shouldAnimateValue =
                selectedPlaceholder.type !== EMessageTemplateSelectedPlaceholderType.SELECTED_ON_CLICK &&
                selectedPlaceholder.placeholder.placeholder !== prevPlaceholder?.placeholder.placeholder;
            if (shouldAnimateValue) setShouldAnimate(shouldAnimateValue);
        }
    }, [prevPlaceholder, selectedPlaceholder, isJustOpened]);

    const onEntered = () => {
        setShouldAnimate(false);
    };

    const shouldShowLabel =
        prevPlaceholder && selectedPlaceholder?.placeholder.placeholder === prevPlaceholder?.placeholder.placeholder;

    return (
        <div className="с-message-template-edit-form__wrapper">
            <div className="с-message-template-edit-form" role="form" aria-label="messageTemplateEditForm">
                <CSSTransition
                    onEntered={onEntered}
                    exit={false}
                    in={shouldAnimate}
                    timeout={300}
                    classNames={"с-message-template-edit-form--animation"}
                >
                    <div>
                        <div className="с-message-template-edit-form__title">
                            {shouldShowLabel && selectedPlaceholder?.placeholder.placeholder}
                        </div>

                        <div className="с-message-template-edit-form__input">{children}</div>

                        <div className="с-message-template-edit-form__buttons">
                            <Button
                                id={"MESSAGE_TEMPLATE_EDIT_CANCEL_BUTTON"}
                                color={ButtonColor.SECONDARY}
                                className="с-message-template-edit-form__button-cancel"
                                onClick={onCancelButtonClick}
                                ariaLabel={t("Message Template Edit Form Cancel Button")}
                            >
                                {t("Cancel")}
                            </Button>
                            <Button
                                id={"MESSAGE_TEMPLATE_EDIT_SAVE_BUTTON"}
                                color={ButtonColor.PRIMARY}
                                className="с-message-template-edit-form__button-save"
                                onClick={onSaveButtonClick}
                                disabled={isSavingDisabled}
                                ariaLabel={t("Message Template Edit Form Save Button")}
                            >
                                {hasMoreEmptyPlaceholders ? t("Save & Edit Next" ): t("Save")}
                            </Button>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
}
