import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { entitiesSelector } from "@/messenger/selectors";
import { TRepresentative } from "@/messenger/types/entities/representative";
import { NormalizedEntities, TStore  } from "@/messenger/types/store.types";


export const representativesAdapter = createEntityAdapter<TRepresentative>({
    selectId: (representative) => representative.participantId,
});

export const initialState = representativesAdapter.getInitialState({});


const representatives = createSlice({
    name: "representatives",
    initialState,
    reducers: {
        upsertRepresentatives(state, action: PayloadAction<NormalizedEntities>) {
            representativesAdapter.upsertMany(state, action.payload.representatives);
        },
    },
});

export const representativesSelectors = representativesAdapter.getSelectors((state: TStore) => entitiesSelector(state).representatives);

export const {
    upsertRepresentatives,
} = representatives.actions;



export default representatives.reducer;