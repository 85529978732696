import "./index.scss";
import "../../locales/i18n";

import cx from "classnames";
import React, { ReactElement, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";

import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";
import Search from "@/components/components/Search";
import { ArrowRight, Check, Search as SearchIcon } from "@/components/icons";

const HEADER_HEIGHT = 48;
const SEARCH_BAR_HEIGHT = 34;
const BUTTONS_BAR_HEIGHT = 63;

interface TChatFilterMobileProps {
    name: string;
    options: TDropdownOption[];
    selectedFilterContent: string;
    onSelectFilter: (name: string) => void;
    isSelected: boolean;
    onHeaderClick?: () => void;
}

export default function ChatFilterMobile({
    name,
    options,
    selectedFilterContent,
    onSelectFilter,
    isSelected,
    onHeaderClick,
}: TChatFilterMobileProps): ReactElement {
    const [searchValue, setSearchValue] = useState<string>();
    const [filteredChats, setFilteredChats] = useState<TDropdownOption[]>([]);
    
    const { t } = useTranslation();
    
    useEffect(() => {
        setFilteredChats(options.filter(item => searchValue ? item.heading.includes(searchValue) : true));
    }, [searchValue]);
    
    const onChatFilterHeaderClick = () => {
        onHeaderClick();
    };
    
    const renderHeader = () => {
        return <div id={`${name?.toUpperCase()}_FILTER`} className={"c-chat-filter-mobile__title"} onClick={onChatFilterHeaderClick}>
            <span>{name}</span>
                <span className={"c-chat-filter-mobile__title-chat"}>{selectedFilterContent}</span>
                <ArrowRight fill={"#B2B2B2"} height="13"/>
        </div>;
    };
    
    const onChange = (v) => {
        setSearchValue(v);
    };
    
    const onClick = (item) => {
        onSelectFilter(item.label);
    };
    
    const renderOptions = () => {
        const searchStyle: React.CSSProperties = { border: "none" };
        const height = window.innerHeight - HEADER_HEIGHT - SEARCH_BAR_HEIGHT - BUTTONS_BAR_HEIGHT;
        return <div className={"c-chat-filter-mobile__list-container"}>
            <div className="c-chat-filter-mobile__search">
                <Search
                    inputId={"CHAT_FILTER_MOBILE_INPUT"}
                    buttonId={"CHAT_FILTER_MOBILE_CANCEL_BUTTON"}
                    style={searchStyle}
                    onChange={onChange}
                    placeholder={t("Search")}
                    airaLabel={t("search box")}
                />
            </div>
            <div className="c-chat-filter-mobile__list">
                {filteredChats.length > 0 && <Scrollbars hideTracksWhenNotNeeded={true} style={{ height }}>
                        {filteredChats
                            .map(item => {
                            return <div key={item.label}
                                        id={`${item.label.toUpperCase()}_OPTION`}
                                        onClick={() => onClick(item)}
                                        className={cx(
                                            "c-chat-filter-mobile__list-item",
                                            {"c-chat-filter-mobile__list-item--selected": item.label === selectedFilterContent})}>
                                {item.heading}
                                {item.label === selectedFilterContent && <span className={"c-chat-filter-mobile__list-item-icon"}><Check/></span>}
                            </div>;
                        })}
                </Scrollbars>}
                {options.length > 0 && filteredChats.length === 0 && <div className={"c-chat-filter-mobile__list-not-found"} style={{ height }}>
                    <SearchIcon height={31} width={31}/>
                    <span className={"c-chat-filter-mobile__list-not-found-text1"}>{t("No Results Found")}</span>
                    <span className={"c-chat-filter-mobile__list-not-found-text2"}>{t("Try rewording your search")}</span>
                </div>}

                {options.length === 0 && <div className={"c-chat-filter-mobile__list-not-found"} style={{ height }}>
                    <span className={"c-chat-filter-mobile__list-not-found-text1"}>{t("No Campaigns have been added yet.")}</span>
                    <span className={"c-chat-filter-mobile__list-not-found-text2"}>{t("To start using Textkit please add campaigns now")}</span>
                </div>}
            </div>
            
        </div>;
    };

    const mainClasses = cx(
        "c-chat-filter-mobile__main",
        {"c-chat-filter-mobile__main--selected": isSelected,
    });
    
    return (
        <div className="c-chat-filter-mobile" role="form">
            <main className={mainClasses}>
                {isSelected ? renderOptions() : renderHeader()}
            </main>
        </div>
    );
}