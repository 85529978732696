import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="237" height="107" viewBox="0 0 237 107" fill="none" {...props}>
        <path d="M72.2048 107C112.082 107 144.41 103.616 144.41 99.4422C144.41 95.2681 112.082 91.8844 72.2048 91.8844C32.3272 91.8844 0 95.2681 0 99.4422C0 103.616 32.3272 107 72.2048 107Z" fill="#F5F5F5"/>
        <path d="M122.632 92.8854C185.796 92.8854 237 89.8835 237 86.1805C237 82.4774 185.796 79.4756 122.632 79.4756C59.4685 79.4756 8.26416 82.4774 8.26416 86.1805C8.26416 89.8835 59.4685 92.8854 122.632 92.8854Z" fill="#F5F5F5"/>
        <path d="M159.522 21.5481C162.937 16.7149 183.706 10.3713 195.209 5.86975C206.711 1.36823 208.38 1.89538 208.38 1.89538C208.38 1.89538 214.279 3.90922 216.172 4.66145C218.066 5.41367 216.762 10.2883 216.762 10.2883C216.762 10.2883 219.564 7.02474 215.264 18.1897C224.702 35.1711 198.612 28.7683 184.591 28.8512C170.57 28.9341 159.522 21.5481 159.522 21.5481Z" fill="#F8FBFF"/>
        <path d="M218.467 19.2618C222.767 7.62889 211.648 2.19745 211.648 2.19745L210.975 2.7246C215.411 6.36728 216.231 10.9754 212.067 15.0623C209.898 17.1501 207.271 18.698 204.398 19.5816C201.29 20.4642 197.91 19.937 194.79 20.9617C190.608 22.3418 186.638 24.9834 181.972 25.3625L171.284 33.714C185.346 33.6252 227.904 36.948 218.467 19.2618Z" fill="#F8FBFF"/>
        <path d="M193.108 12.8471C185.405 13.2736 176.457 16.6793 174.823 23.3131L193.108 12.8471Z" fill="#F8FBFF"/>
        <path d="M188.738 13.2676C188.407 8.64766 195.226 3.68414 202.936 6.53906" stroke="#ADB3BC" strokeMiterlimit="10"/>
        <path d="M218.071 3.17475C211.76 1.39784 198.647 3.17476 202.234 15.7257" stroke="#ADB3BC" strokeMiterlimit="10"/>
        <path d="M197.804 11.0287C190.1 11.4552 181.152 14.8609 179.518 21.4948" stroke="#ADB3BC" strokeMiterlimit="10"/>
        <path d="M221.569 48.3321H16.5454V58.7567H221.569V48.3321Z" fill="#DDE6F2"/>
        <path d="M221.569 24.1365H16.5454V48.3321H221.569V24.1365Z" fill="#EFF4F9"/>
        <path d="M229.603 0H218.508V87.4776H229.603V0Z" fill="#DDE6F2"/>
        <path d="M22.3145 0H11.2192V87.4776H22.3145V0Z" fill="#DDE6F2"/>
        <path d="M178.793 11.8994C168.175 12.853 170.877 15.6843 149.347 16.2943C136.305 16.6675 98.961 18.1423 88.78 17.396C83.3651 16.9992 78.953 17.396 72.9954 17.6152C65.5395 17.8936 61.3987 17.9469 54.3086 17.7277C49.867 17.5915 22.1494 17.1769 22.1494 17.1769C22.3795 20.5589 22.7393 34.567 22.6154 39.7911C22.4738 45.5898 47.5075 43.9905 55.7361 42.7526C63.9646 41.5147 80.988 41.9234 97.5159 41.568C114.044 41.2126 129.905 43.0784 164.843 41.5325C172.782 41.183 179.465 41.6628 184.196 41.8464C181.034 35.8582 174.811 19.9844 178.793 11.8994Z" fill="#F2F2F2"/>
        <path d="M189.811 40.644C189.534 39.3232 181.789 18.1719 184.473 11.0939C184.78 9.76712 181.818 10.6023 179.205 10.9043L178.303 11.0169C175.389 19.0959 179.931 34.9756 182.261 40.952C184.039 41.0083 185.812 41.1764 187.57 41.4555C189.392 41.8819 190.088 41.9885 189.811 40.644Z" fill="#E5E5E5"/>
        <path d="M132.795 34.5433L137.072 33.1987L139.585 32.8493C140.287 32.7558 141 32.9153 141.596 33.2994L143.189 34.33C143.602 34.6002 143.928 34.985 144.127 35.4376L144.451 36.184C144.63 36.5928 144.661 37.0516 144.54 37.4811L143.283 41.8286C143.25 41.9391 143.195 42.0416 143.121 42.13C143.047 42.2184 142.956 42.2908 142.853 42.3428C142.751 42.3949 142.639 42.4255 142.524 42.4328C142.409 42.4401 142.294 42.424 142.186 42.3854L139.013 41.3489C138.581 41.2076 138.122 41.171 137.674 41.2422L134.683 41.7457L133.816 36.5275L132.795 34.5433Z" fill="#DBDCDE"/>
        <path d="M89.9004 91.2565L91.552 95.4323L92.0888 97.9259C92.2346 98.6206 92.1301 99.3448 91.7938 99.9693L90.8854 101.651C90.6529 102.086 90.298 102.443 89.865 102.676L89.1454 103.055C88.7538 103.266 88.301 103.333 87.8654 103.245L83.4237 102.303C83.3118 102.277 83.2063 102.228 83.1134 102.16C83.0204 102.092 82.942 102.007 82.8826 101.908C82.8232 101.809 82.7842 101.699 82.7677 101.585C82.7512 101.471 82.7577 101.354 82.7867 101.243L83.583 97.991C83.6951 97.5479 83.6951 97.0837 83.583 96.6406L82.8633 93.679L87.9774 92.4174L89.9004 91.2565Z" fill="#DBDCDE"/>
        <path d="M12.9238 93.1104C13.1374 92.2689 13.4586 91.4586 13.8793 90.6998C12.7468 86.7491 13.0712 82.5259 13.1892 78.368C13.0802 78.3775 12.9719 78.3933 12.8648 78.4153C10.6115 77.8704 7.49114 77.9178 6.45889 79.0787C5.5446 80.1093 3.77502 84.753 4.68931 87.1637C5.95161 90.4036 2.99641 90.9841 4.42387 95.61C5.81594 100.129 17.2769 100.763 17.6426 98.7492C17.8019 98.0503 12.5344 94.7689 12.9238 93.1104Z" fill="#AEB5C1"/>
        <path d="M57.1632 93.7027C53.2288 92.7373 49.0939 93.3 45.0357 93.4954C44.7879 94.0403 42.4226 99.2526 42.6762 100.864C42.7765 101.509 48.0675 101.829 50.4978 101.723C52.928 101.616 58.396 103.328 64.6544 102.179C69.332 101.314 70.5884 100.283 69.8039 98.7551C68.8483 96.9663 60.1951 96.5991 57.1632 93.7027Z" fill="#AEB5C1"/>
        <path d="M78.3985 60.2612C75.6734 57.6254 52.0259 54.7823 48.3156 54.3381C40.3053 54.4388 32.295 54.4566 24.2788 54.4566C23.8777 56.891 22.5977 65.7104 24.096 70.8161C25.1772 74.2852 26.044 77.8181 26.6914 81.3946C23.5592 80.2811 17.0943 78.83 12.9535 78.9721C12.8669 79.4736 12.7386 79.9669 12.5701 80.4469C11.8446 82.4904 10.4348 85.5645 10.3994 88.2476C11.5759 88.4604 12.7047 88.8837 13.7321 89.4974L13.797 89.5448C13.8316 89.5342 13.8686 89.5342 13.9032 89.5448C19.6425 90.8301 27.4405 96.99 30.9501 98.5597C34.4598 100.129 39.0489 98.8144 39.6919 98.1984C41.4025 96.5695 43.3903 91.683 44.281 84.6819C44.9298 79.5703 45.5787 75.442 45.5787 75.442C45.5787 75.442 58.2666 73.9375 61.2926 72.6285C61.2926 72.6285 56.6799 75.59 54.3145 79.9731C53.1348 82.0817 51.259 84.4687 49.7018 86.8202C47.1359 90.6998 43.0069 93.2881 45.1304 94.5734C45.5885 94.454 46.0736 94.4958 46.5048 94.6919C48.41 95.4737 50.3212 96.1312 52.4034 96.0424C54.173 95.9654 55.7833 95.3967 57.4703 95.0236L57.1871 94.7571C58.3646 93.6628 59.4516 92.4743 60.4373 91.2032C61.9945 89.1598 72.0693 79.8902 77.5432 73.434C83.0171 66.9779 81.3478 63.1516 78.3985 60.2612Z" fill="#91959B"/>
        <path d="M110.009 76.052C107.649 74.4765 106.31 74.4647 106.31 74.4647L105.172 74.6838L103.992 73.1379C103.992 73.1379 98.9843 69.3235 98.654 69.0925C98.4122 68.8847 98.2394 68.6078 98.1585 68.2988C97.979 67.806 97.9604 67.2687 98.1054 66.7647C98.2647 66.2909 100.247 58.4191 104.317 55.4694C105.477 54.6306 106.732 53.9327 108.056 53.3904C112.056 51.7497 116.503 51.5543 119.806 53.118C122.579 54.4824 124.814 56.7418 126.156 59.5341C127.497 62.3264 127.866 65.49 127.203 68.5179C126.366 72.8477 123.015 77.2722 118.904 77.8467C114.793 78.4213 112.356 77.6217 110.009 76.052Z" fill="#DBDCDE"/>
        <path opacity="0.2" d="M98.0759 66.7647C98.2351 66.2908 100.217 58.4191 104.287 55.4694C105.448 54.6306 106.703 53.9327 108.027 53.3904C108.211 53.492 108.38 53.6197 108.528 53.7695C108.988 54.3057 109.254 54.9818 109.283 55.6886C109.311 56.3896 109.256 57.0915 109.118 57.7794C108.935 59.1496 108.641 60.5026 108.239 61.8249C107.803 63.122 107.425 64.8634 106.263 65.3254C105.101 65.7874 101.515 63.3708 99.5859 67.1971C99.0432 68.3047 97.9107 67.2504 98.0759 66.7647Z" fill="black"/>
        <path d="M110.735 52.4901C108.463 52.6565 106.179 52.4362 103.981 51.8386C102.488 51.418 98.8726 50.6244 98.2532 49.4694C97.8167 48.6401 98.3181 47.7635 97.3213 51.4714C96.3244 55.1792 94.7495 59.8347 93.729 61.4399C93.729 61.4399 99.486 63.0983 106.264 65.3195C107.98 65.8881 113.283 53.0824 110.735 52.4901Z" fill="#DBDCDE"/>
        <path d="M106.387 53.734C105.721 59.1003 104.081 61.4695 105.125 63.507C106.169 65.5445 112.793 64.1822 113.188 68.9562C113.354 70.8871 111.248 69.9098 111.248 69.9098C111.248 69.9098 109.714 69.1872 109.071 68.7963C108.428 68.4054 108.558 70.0934 109.425 70.5732C110.292 71.053 112.722 72.3501 113.059 72.8358C113.395 73.3215 112.139 75.0392 113.283 77.6276C114.427 80.2159 118.073 80.826 118.073 80.826C117.958 80.6039 117.896 80.3582 117.892 80.1082C117.888 79.8582 117.941 79.6106 118.048 79.3847C118.155 79.1587 118.312 78.9607 118.508 78.8059C118.703 78.6511 118.932 78.5438 119.176 78.4923C121.972 78.1606 126.702 76.514 128.077 69.4123C129.675 61.12 125.876 54.3263 119.417 52.0044C114.699 50.2986 108.717 52.6797 106.405 52.3657C106.187 52.3657 106.098 52.4783 106.269 52.8396C106.392 53.1206 106.433 53.4306 106.387 53.734Z" fill="#AEB5C1"/>
        <path d="M118.072 80.826C118.072 80.826 114.427 80.2337 113.283 77.6276C112.138 75.0214 113.389 73.3097 113.052 72.8358C112.716 72.362 110.298 71.0589 109.425 70.5732C108.552 70.0875 108.428 68.3994 109.071 68.7963C109.714 69.1931 111.247 69.9098 111.247 69.9098C111.247 69.9098 113.353 70.8871 113.188 68.9562C112.775 64.1822 106.21 65.6097 105.125 63.507C104.039 61.4043 105.715 59.1003 106.387 53.734C106.432 53.4306 106.391 53.1206 106.269 52.8396C106.098 52.4783 106.186 52.3361 106.405 52.3657C108.717 52.6797 114.663 50.2986 119.417 52.0044C120.361 52.3411 121.263 52.7842 122.107 53.3253C121.789 53.9521 121.393 54.5354 120.927 55.0607C118.568 58.0223 118.161 62.7607 119.901 66.3619C120.491 67.5465 121.287 68.7726 121.08 70.0697C120.927 71.0115 120.266 71.7045 119.736 72.439L119.535 72.7351C118.964 73.6152 118.547 74.5867 118.302 75.6078C118.046 76.6613 117.944 77.7469 118.001 78.8299C118.001 79.0254 118.001 79.2268 118.049 79.4223C117.931 79.7213 117.896 80.0468 117.948 80.364C117.968 80.5231 118.009 80.6787 118.072 80.826Z" fill="#AEB5C1"/>
        <path d="M86.4556 45.033L103.426 37.8483L109.507 35.0171C111.21 34.2226 113.057 33.7853 114.934 33.7318L134.989 33.098L137.237 35.6331C137.33 37.5986 136.859 39.5497 135.88 41.2541L134.464 43.7062H116.597C115.441 43.706 114.306 44.0257 113.318 44.6302L101.833 51.6905L85.1284 45.033H86.4556Z" fill="#9CA3AF"/>
        <path d="M100.813 50.3341L90.4493 42.8888L80.4216 39.5305L69.9103 37.5285L61.0624 37.1376L53.7423 37.8069L45.9561 39.8326L36.7366 42.7289L26.5615 49.5286L22.8218 53.4556C25.482 54.6876 27.5879 56.8139 29.9532 58.8456C33.396 61.7681 37.1862 64.2511 41.2372 66.2375C45.7379 68.4824 51.4359 69.9158 55.6239 72.972L60.449 70.0934L66.731 70.4903L68.8958 71.207L69.2025 75.7974L71.7861 83.0294L78.2156 92.5892L80.6576 98.8084L85.7422 96.6939L91.6408 94.3247L84.1201 79.0491L86.6506 74.3699L93.1449 66.7055L97.3388 64.0934L98.1646 56.9857C98.2769 56.0253 98.548 55.0904 98.9669 54.2197L100.813 50.3341Z" fill="#AEB5C1"/>
        <path d="M76.1273 68.8911L79.2712 62.3106C79.3704 62.1024 79.4889 61.9041 79.6252 61.7182L85.9072 53.426L84.462 54.1427C81.088 57.6076 74.8001 59.0884 70.6357 60.35C64.9907 62.0618 59.0213 62.8969 53.2584 64.1645C49.2854 65.0897 45.2586 65.7642 41.2017 66.1842L55.4114 73.2919L57.995 71.6631C59.4525 70.7428 61.1699 70.3268 62.8849 70.4784L68.465 70.9641L68.9723 75.0747C69.0383 75.624 69.171 76.163 69.3675 76.6799L71.7269 82.8576C71.8328 83.1246 71.957 83.3838 72.0985 83.6336L80.7341 98.7255L86.1844 96.5754L76.8882 82.9524C76.3863 82.2166 76.0262 81.3929 75.8265 80.524L75.1658 77.6039C74.4996 74.6738 74.8384 71.6036 76.1273 68.8911Z" fill="#9CA3AF"/>
    </svg>
  );
}

export default SvgComponent;