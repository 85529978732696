import "./index.scss";

import React from "react";

import { MODULE_STANDIN } from "@/messenger/constants/domID";

const baseClassName = "c-module-standin";

const ModuleStandin = () => {
    const moduleStandinHeight = "100%";

    return <div 
        style={{height: moduleStandinHeight}}
        className={baseClassName} 
        id={MODULE_STANDIN} 
    />;
    
};

export default ModuleStandin;