import "./Search.scss";

import cx from "classnames";
import React, { FormEvent, PureComponent, RefObject } from "react";

import { Close, Search as SearchIcon } from "@/components/icons";

import { SearchSize } from "./search.enum";

export interface SearchClassNames {
    formContainer?: string,
    searchInput?: string
}
interface SearchDefaultProps {
    disabled?: boolean;
    /** SearchSize { REGULAR = 'regular', BIG = 'big' } */
    size?: SearchSize;
    style?: any;
    value?: string;
    onClear?: () => void;
    classNames?: SearchClassNames;
    placeholder?: string;
    airaLabel?: string;
    inputId?: string;
    buttonId?: string;
    hideIcons?: boolean;
}

interface SearchProps extends SearchDefaultProps {
    onChange?: (value: string) => void;
    focusOnMount?: boolean
    onBlur?: () => void;
}

interface SearchState {
    isActive: boolean;
}

class Search extends PureComponent<SearchProps, SearchState> {
    baseClassName = "c-search";

    searchInput: RefObject<HTMLInputElement>;

    static defaultProps: SearchDefaultProps = {
        disabled: false,
        size: SearchSize.REGULAR,
        onClear: () => {},
        classNames: {},
    };

    constructor(props: SearchProps) {
        super(props);

        this.state = {
            isActive: false,
        };

        this.searchInput = React.createRef();
    }

    componentDidMount() {
        const { focusOnMount } = this.props;

        if (focusOnMount === true) {
            this.searchInput.current.focus();
        }
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (this.props.onChange) this.props.onChange(e.target.value);
    };

    onFocus = (): void => this.setState({ isActive: true });

    onBlur = (): void => {
        const { onBlur } = this.props;

        this.setState({ isActive: false });

        if (onBlur) {
            onBlur();
        }
    };

    onSubmit = (e: FormEvent): void => {
        e.preventDefault();
    };

    handleClear = (): void => {
        this.props.onClear();
        this.searchInput.current.focus();
    };

    getClassName = (): string => {
        const { classNames: { formContainer } } = this.props;

        const c = `${this.baseClassName}`;
        return cx(
            c,
            formContainer,
            { [`${c}--active`]: this.state.isActive },
            { [`${c}--disabled`]: this.props.disabled },
        );
    }; 

    render(): React.ReactNode {
        const { value, classNames: { searchInput }, hideIcons } = this.props;
        
        const searchInputClassNames = cx(
            "c-search__input",
            `c-search__input--size-${this.props.size}`,
            searchInput,
        );

        const isClean = !value || value.length === 0;
        
        return (
            <form
                className={this.getClassName()}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                style={this.props.style}
                action={"."}
                onSubmit={this.onSubmit}
            >
                {!hideIcons && <div className="c-search__icon">
                    <SearchIcon width={14} height={14} />
                </div>}
                
                <input
                    id={this.props.inputId}
                    ref={this.searchInput}
                    type="search"
                    className={searchInputClassNames}
                    value={value}
                    onChange={this.onChange}
                    placeholder={this.props.placeholder || "Search"}
                    disabled={this.props.disabled}
                    name={"search"}
                    aria-label={this.props.airaLabel || "search box"}
                    autoComplete="off"
                />
                {(!isClean && !hideIcons) && (
                    <button id={this.props.buttonId} className="c-search__close-button" onClick={this.handleClear}>
                        <Close width={14} height={14} />
                    </button>
                )}
            </form>
        );
    }
}

export default Search;
