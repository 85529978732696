import { config } from "@/messenger/config";
import { TAuth0Credentials, TUserSession } from "@/messenger/types/auth.types";

export const getChatToken = (credentials: TAuth0Credentials) => {
    const { token, customer: customerName, email } = credentials;
    
    const request = new Request(`${config.api.messenger.rest().origin}/messenger/start`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ customerName, email }),
    });

    return fetch(request)
        .then((response): Promise<TUserSession> => response.json())
        .then(data => data);
};

export const getTermsAndConditionsTemplate = (): Promise<any> =>
    fetch("https://terms-and-conditions-template.s3.amazonaws.com/termsAndConditionsTemplate.html")
        .then(response => response.text())
        .catch(err => console.log(err));
