import "./index.scss";

import cx from "classnames";
import React from "react";
import ReactTooltip from "react-tooltip";

import { MessageInputTabType } from "@/messenger/types/entities/conversation";
import { MessageInputTabOption } from "@/messenger/utils/getMessageInputTabOptions";


interface TMessageInputTabProps {
    selectedTab: MessageInputTabType
    options: MessageInputTabOption[]
    onClick: (option: MessageInputTabType) => void
}

const baseClassName = "c-message-input-tabs";

const MessageInputTabs = ({ selectedTab, options, onClick}: TMessageInputTabProps) => {

    const getContainerClassNames = (option: MessageInputTabType) => {
        const classNames = cx(
            `${baseClassName}__tab`,
            `${baseClassName}__tab__${option}`,
            {[`${baseClassName}__tab__${option}__selected`]: selectedTab === option},
            );

        return classNames;
    };

    const toolTipClassName = `${baseClassName}__tooltip`;

    return <div className={baseClassName}>
        {options.map(o => (
            <div key={`message-input-tabs-${o.label}`}>
                <button
                    data-tip data-for={`registerTip-${o.label}`}
                    className={getContainerClassNames(o.label)}
                    key={o.label}
                    onClick={() => onClick(o.label)}
                    id={`${o.label}_TAB`}
                >
                    {o.icon}
                </button>
                <ReactTooltip id={`registerTip-${o.label}`} place="top" effect="solid" className={toolTipClassName}>
                    {o.heading}
                </ReactTooltip>
            </div>
        ))}
    </div>;

};

export default MessageInputTabs;
