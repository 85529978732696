import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { chatsSelectors, upsertOneChat } from "@/messenger/ducks/userInterface/chats";
import { getMessageIndex } from "@/messenger/models/message";
import { TTwilioError } from "@/messenger/types/error.types";
import { TStore } from "@/messenger/types/store.types";
import { composeNotDeliveredMessage } from "@/messenger/utils/chatFrame.helper";

export enum EMessageSendStatus {
    NONE = "NONE",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
}

export const useSendMessage = ({draftMessage, error, activeChannel}) => {
    const [isSending, setIsSending] = useState<boolean>(false);
    const [messageSendStatus, setMessageSendStatus] = useState<EMessageSendStatus>(EMessageSendStatus.NONE);
    const dispatch = useDispatch();
    const session = useSelector((state: TStore) => state.sdk.session);
    const { id } = useParams<any>();
    const chat = useSelector((s:TStore) => chatsSelectors.selectById(s, id));
    const localMessages = chat?.localMessages;
    const messages = chat?.messages;
    const deliveredMessages = messages?.filter(m => getMessageIndex(m) !== undefined);
    
    const onMessageSendError = async (body: string, media: File, e: TTwilioError) => {
        setMessageSendStatus(EMessageSendStatus.ERROR);
 
        const notDeliveredMessage = await composeNotDeliveredMessage({body, media, deliveredMessages});
        
        dispatch(upsertOneChat({
            id, 
            localMessages: [...localMessages, notDeliveredMessage],
            messages: [...messages, notDeliveredMessage],
            draftMessage: null,
        }));
        
        // TODO: use Sentry (or similar) to log errors
        console.log(e);
    };
    
    const sendMessage = (): void => {
        setIsSending(true);
        const {
            body,
            metadata: {mediaFile},
        } = draftMessage;
        
        const sendTextMessagePromise = activeChannel.sendMessage(body, {
            session,
        });
        
        let sendMessagePromise;
        if (body && mediaFile) {
            sendMessagePromise = sendTextMessagePromise.then(() => {
                // Send media file only if text message is compliant
                if (!error) {
                    activeChannel.sendMessage({
                        contentType: mediaFile.type,
                        media: mediaFile,
                    });
                }
            });
        } else if (body) {
            sendMessagePromise = sendTextMessagePromise;
        } else if (mediaFile) {
            sendMessagePromise = activeChannel.sendMessage({
                contentType: mediaFile.type,
                media: mediaFile,
            });
        }
    
        if (sendMessagePromise) {
            sendMessagePromise
                .then(() => {
                    if (!error) {
                        setMessageSendStatus(EMessageSendStatus.SUCCESS);
                    }
                })
                .catch(async (e: TTwilioError) => {
                    await onMessageSendError(body, mediaFile, e);
                })
                .finally(() => setIsSending(false));
        }
    };
    
    return {sendMessage, messageSendStatus, isSending, setMessageSendStatus};
};