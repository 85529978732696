import React from "react";

import { MessageBubbleArrow,MessageBubblePlus } from "@/components/icons";
import { MessageInputTabType } from "@/messenger/types/entities/conversation";

export interface MessageInputTabOption {
    label: MessageInputTabType,
    heading: string,
    icon: JSX.Element,
}

export const getMessageInputTabOptions = () => {
    return [
        {
            label: MessageInputTabType.STANDARD,
            heading: "Messages",
            icon: <MessageBubblePlus />,
        },
        {
            label: MessageInputTabType.CHAT_STARTER,
            heading: "Chat Starters",
            icon: <MessageBubbleArrow />,
        },
    ];
};