import { RequestURIs } from "@/messenger/constants/requests";
import { formatContactName } from "@/messenger/models/contact";
import { activeConversationSelector } from "@/messenger/selectors";
import { ChannelTypeEnum} from "@/messenger/types/graphql/autogenerated";
import { SDKLoadingStatus, TStore } from "@/messenger/types/store.types";
import { WidgetType } from "@/messenger/widgets/api/events";

import { contactsSelectors } from "../entities/contacts";
import { widgetStateSelectorsCustom } from "../userInterface/widgetsState";
import { widgetTabSelector } from "./general";
import { getRequestTrackerById } from "./requestTrackers";

export const getContactName = (state: TStore) => {
    const convo = activeConversationSelector(state);
    const contact = contactsSelectors.selectById(state, convo?.contact);

    return formatContactName(contact);
};

export const getContactPhoneNumber = (state: TStore) => {
    const convo = activeConversationSelector(state);
    const contact = contactsSelectors.selectById(state, convo?.contact);

    return contact?.phoneNumber;
};

export const isAppLoadedSelector = (state: TStore) => {
    const getConversationsRequestStatus = getRequestTrackerById(RequestURIs.GET_CONVERSATIONS)(state);
    const getConversationsComplete = getConversationsRequestStatus?.status?.complete;

    const getTwilioChannelsComplete = state.sdk.loadingStatus === SDKLoadingStatus.LOADED_CONVERSATIONS;

    return getTwilioChannelsComplete && getConversationsComplete;
};

export const getIsContactSmsCapable = (state: TStore) => {
    const convo = activeConversationSelector(state);
    const contact = contactsSelectors.selectById(state, convo?.contact);
    return contact?.capabilities?.includes(ChannelTypeEnum.Sms);
};

export const getShownSendableSelector = (state: TStore) => {
    const sendableWidgetStates= widgetStateSelectorsCustom.selectAllByTypeSendable(state);
    const shownSendable = sendableWidgetStates.find((s) => s.isShown);
    if (shownSendable) {
        return shownSendable.id;
    } 
        return null;
    
};

export const hasShownSendableSelector = (state: TStore) => {
    return !!getShownSendableSelector(state);
};

export const widgetsHiddenSelector = (state: TStore, widgetType: WidgetType) => {
    const { hasTab, selectedTab } = widgetTabSelector(state); 

    return hasTab && widgetType !== selectedTab;
};