import snippet from "@segment/snippet";
import {Option} from "prelude-ts";

import {config} from "@/messenger/config";
import { SegmentUser } from "@/messenger/containers/Analytics";


let segmentService: Option<SegmentAnalytics.AnalyticsJS> = Option.none();
let segmentElement: Option<Element> = Option.none();

export const segment = {

  init: () =>
    segmentElement.isNone &&
    segmentService.ifNone(
      () =>
        config.analyticsConfig().segmentKey.ifSome(
          apiKey => {
            const script = document.createElement("script");
            script.innerHTML = snippet.min({apiKey});
            segmentElement = Option.of(document.head.appendChild(script));
            window.analytics.ready(() => { segmentService = Option.of(window.analytics); });
          })),

  page: () => segmentService.ifSome(a => a.page()),

  identify: (user: SegmentUser) => segmentService.ifSome(a => a.identify(user.UUID, user)),
};



