import contactsReducer from "./contacts";
import conversationsReducer from "./conversations";
import customersReducer from "./customers";
import incomingsReducer from "./incomings";
import messageTemplatesReducer from "./messageTemplates";
import participationsReducer from "./participations";
import representativesReducer from "./representatives";
import representativeSettingsReducer from "./representativeSettings";
import topicsReducer from "./topics";
import twilioChannelsReducer from "./twilioChannels";
import widgetsReducer from "./widgets";

const entitiesReducer = {
    topics: topicsReducer,
    contacts: contactsReducer,
    conversations: conversationsReducer,
    customers: customersReducer,
    incomings: incomingsReducer,
    messageTemplates: messageTemplatesReducer,
    participations: participationsReducer,
    twilioChannels: twilioChannelsReducer,
    widgets: widgetsReducer,
    representatives: representativesReducer,
    representativeSettings: representativeSettingsReducer,
};

export default entitiesReducer;
