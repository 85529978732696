import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setRequestTrackerAC } from "@/messenger/actions/requestTracker";
import { RequestURIs } from "@/messenger/constants/requests";
import { GraphQLUrl } from "@/messenger/constants/urls";
import { findAllIncoming } from "@/messenger/graphql/queries";
import { createRequestTracker } from "@/messenger/models/requestTracker";
import { Query } from "@/messenger/types/graphql/autogenerated";
import { normalizeIncomings } from "@/messenger/utils/normalize";

const GetIncomings = () => {
    const [skipQuery, setSkipQuery] = useState(false);
    const dispatch = useDispatch();

    useQuery(findAllIncoming, {
        skip: skipQuery,
        onCompleted: (data: { findAllIncoming: Query[GraphQLUrl.findAllIncoming]}) => {
            try {
                const incomings: Query[GraphQLUrl.findAllIncoming] = data.findAllIncoming;
                dispatch(normalizeIncomings(incomings));
                dispatch(setRequestTrackerAC(createRequestTracker(RequestURIs.GET_INCOMINGS, {
                    complete: true,
                    loading: false,
                    sent: true,
                })));

                setSkipQuery(true);
            } catch (e) {
                console.error(e);
            }
        },
    });

    useEffect(() => {
        dispatch(setRequestTrackerAC(createRequestTracker(RequestURIs.GET_INCOMINGS, {
            complete: false,
            loading: true,
            sent: true,
        })));
    }, []);

    return null;
};

export default GetIncomings;