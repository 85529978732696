import "./index.scss";
import "../../locales/i18n";

import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "@/components/components/Button";
import { ButtonColor } from "@/components/components/Button/button.enum";
import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";
import { H1 } from "@/components/components/Headings/H";
import { Chart, Close, Home, Menu, Plus,Support, Users   } from "@/components/icons";
import Logout from "@/components/icons/LogOutTextKit";
import NavigationDropdown from "@/messenger/components/NavigationDropdown";
import { NAV_ACCOUNTS, NAV_LOG_OUT, NAV_REPORTS, NAV_SUPPORT, NAV_WELCOME } from "@/messenger/constants/domID";
import { WidgetNames } from "@/messenger/constants/misc";
import { LOGOUT, LOGOUT_REDIRECT_PARAM } from "@/messenger/constants/routing";
import AvailabilityButton from "@/messenger/containers/AvailabilityButton";
import ChatFilter from "@/messenger/containers/ChatFilter";
import { customerSelector } from "@/messenger/ducks/entities/customers";
import { representativeSettingsSelectors } from "@/messenger/ducks/entities/representativeSettings";
import { setIsAddAContactOpenAC } from "@/messenger/ducks/general/isAddAContactOpen.duck";
import { deviceTypeSelector } from "@/messenger/ducks/selectors/general";
import { widgetsStateSelectors } from "@/messenger/ducks/userInterface/widgetsState";
import { textkitEnvSelector } from "@/messenger/selectors";
import { TCustomer } from "@/messenger/types/entities/conversation";
import { TextkitEnvironmentEnum } from "@/messenger/types/graphql/autogenerated";
import { TStore } from "@/messenger/types/store.types";
import { detectMobile } from "@/messenger/utils/deviceType";
import { executeStringifiedJS } from "@/messenger/utils/helpers";
import { widgetContainerClient } from "@/messenger/widgets";
import { WidgetState } from "@/messenger/widgets/types";

const getNavigationOptions = (customer: TCustomer) => {
    const links = customer?.config?.webapp;

    const options: TDropdownOption[] = [];

    const size = 20;
    const stroke = "white";

    if (links?.welcomeUrl) {
        options.push({ icon: <Home width={size} height={size} stroke={stroke} />, heading: i18next.t("Welcome"), label: i18next.t("Welcome"), domId: NAV_WELCOME });
    }
    if (links?.accountsUrl) {
        options.push({ icon: <Users width={size} height={size} stroke={stroke} />, heading: i18next.t("Accounts"), label: i18next.t("Accounts"), domId: NAV_ACCOUNTS});
    }
    if (links?.reportsUrl){
        options.push({ icon: <Chart width={size} height={size} stroke={stroke} />, heading: i18next.t("Reports"), label: i18next.t("Reports"), domId: NAV_REPORTS});
    }
    if (links?.supportJsFunction) {
        options.push({ icon: <Support width={size} height={size} stroke={stroke} />, heading: i18next.t("Support"), label: i18next.t("Support"), domId: NAV_SUPPORT});
    }

    options.push({ heading: i18next.t("Log Out"), label: i18next.t("Log Out"), icon: <Logout width={size} height={size} fill={stroke} />, domId: NAV_LOG_OUT });

    return options;
};


const baseClassName = "c-header-control";
function HeaderControl() {
    const isMobile = detectMobile(useSelector(deviceTypeSelector));
    const customers = useSelector(customerSelector.selectAll);
    const history = useHistory();
    const dispatch = useDispatch();
    const options = customers && customers.length > 0 ? getNavigationOptions(customers[0]) : [];

    const addALeadWidgetState = useSelector((s: TStore) => widgetsStateSelectors.selectById(s, WidgetNames.AddaLead));
    const isTextkitStandalone = useSelector(textkitEnvSelector) === TextkitEnvironmentEnum.Textkit;
    const isAddAContactOpen = useSelector((s: TStore) => s.general.isAddAContactOpen);
    const isIncomingVisible = useSelector(representativeSettingsSelectors.selectAll)[0]?.incomingVisible;
    const { t } = useTranslation(); 

    const MenuIcon = ({ isOpen = false }) => {

        if (!isOpen) {
            return <div className={`${baseClassName}__navigation-dropdown-open`}><Menu /></div>;
        }

        return <div className={`${baseClassName}__navigation-dropdown-closed`}><Close /></div>;
    };

    const handleOnSelect = (value: string) => {
        const links = customers[0]?.config?.webapp;
        
        if (value === "Log Out") {
            if (links?.logoutUrl) {
                history.push(`/${LOGOUT}?${LOGOUT_REDIRECT_PARAM}=${links.logoutUrl}`);
            } else {
                history.push(`/${LOGOUT}`);
            }
        }

        const handleNavigation = (url) => {
            window.location.href = url; 
        };

        if (value === "Welcome") {
            handleNavigation(links.welcomeUrl);
        }
        if (value === "Accounts") {
            handleNavigation(links.accountsUrl);
        }
        if (value === "Reports") {
            handleNavigation(links.reportsUrl);
        }
        if (value === "Support") {
            executeStringifiedJS(links.supportJsFunction);
        }
    };

    const handleOnAddALeadClick = () => {
        widgetContainerClient.setState(WidgetNames.AddaLead, WidgetState.isOpen, true);
    };

    const handleOnAddAContactClick = () => {
        dispatch(setIsAddAContactOpenAC(true));
    };
    
    return (
        <div className={`${baseClassName}`} data-testid="headingControl">
            <div className={`${baseClassName}__container`}>
                { isMobile && <NavigationDropdown 
                    options={options} 
                    MenuComponent={MenuIcon}
                    onSelect={handleOnSelect}
                    />}
            </div>


            <H1>{t("Conversations")}</H1>
            <div className={`${baseClassName}__filter-container`}>
                {isTextkitStandalone && <Button
                    id={"ADD_CONTACT_BUTTON"}
                    ariaLabel="Add A Contact"
                    className={`${baseClassName}__icon-button`}
                    disabled={!!isAddAContactOpen}
                    color={ButtonColor.SECONDARY}
                    icon={(<Plus width={20} height={20} />)}
                    onClick={handleOnAddAContactClick}
                />}
                {isIncomingVisible &&
                    <AvailabilityButton />
                }
                {addALeadWidgetState?.isReady && <Button
                    id={"ADD_A_LEAD_BUTTON"}
                    ariaLabel="Add A Lead"
                    className={`${baseClassName}__icon-button`}
                    color={ButtonColor.SECONDARY}
                    icon={(<Plus width={20} height={20} />)}
                    onClick={handleOnAddALeadClick}
                />}
                <ChatFilter />
            </div>
            
            {/* <div className="c-heading-control__buttons">
                <Link to="/admin" className="c-heading-control__icon">
                    <Filter width={20} height={20} />
                </Link>
            </div> */}
        </div>
    );
}

export default HeaderControl;
