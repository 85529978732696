import * as React from "react";

function PhoneBoxed(props) {
  return (
    <svg viewBox="0 0 20 20" width={20} height={20} fill="currentColor" {...props}>
      <path d="M0 8C0 5.19974 0 3.79961 0.544967 2.73005C1.02433 1.78924 1.78924 1.02433 2.73005 0.544967C3.79961 0 5.19974 0 8 0H12C14.8003 0 16.2004 0 17.27 0.544967C18.2108 1.02433 18.9757 1.78924 19.455 2.73005C20 3.79961 20 5.19974 20 8V12C20 14.8003 20 16.2004 19.455 17.27C18.9757 18.2108 18.2108 18.9757 17.27 19.455C16.2004 20 14.8003 20 12 20H8C5.19974 20 3.79961 20 2.73005 19.455C1.78924 18.9757 1.02433 18.2108 0.544967 17.27C0 16.2004 0 14.8003 0 12V8Z" fill="white"/>
      <path d="M13.3979 10.9584C13.1648 10.7361 12.8551 10.6121 12.5329 10.6121C12.2108 10.6121 11.9011 10.7361 11.6679 10.9584L11.4796 11.1468C10.4551 10.2865 9.50738 9.33867 8.64727 8.31401L8.83564 8.12565C9.06494 7.89628 9.19375 7.58522 9.19375 7.26089C9.19375 6.93656 9.06494 6.62551 8.83564 6.39613L7.79793 5.35842C7.68434 5.24481 7.54949 5.15469 7.40107 5.0932C7.25265 5.03171 7.09358 5.00006 6.93292 5.00006C6.77227 5.00006 6.61319 5.03171 6.46477 5.0932C6.31636 5.15469 6.1815 5.24481 6.06792 5.35842L5.49892 5.92743C5.22112 6.20683 5.04797 6.57336 5.00859 6.96539C4.96921 7.35743 5.06599 7.75107 5.28267 8.08015C6.97518 10.6314 9.16122 12.8181 11.712 14.5114C12.0421 14.725 12.435 14.8199 12.8262 14.7806C13.2174 14.7413 13.5837 14.5701 13.8647 14.2952L14.4342 13.7262C14.5478 13.6126 14.6379 13.4777 14.6994 13.3293C14.7609 13.1809 14.7925 13.0218 14.7925 12.8612C14.7925 12.7005 14.7609 12.5414 14.6994 12.393C14.6379 12.2446 14.5478 12.1097 14.4342 11.9961L13.3979 10.9584Z" fill="#2D2D2D"/>
    </svg>
  );
}

export default PhoneBoxed;