import "./ComplianceError.scss";

import cx from "classnames";
import React from "react";

import Popover from "../Popover";

export interface ComplianceErrorItem {
    message?: string;
    title?: string;
    value: string;
    content: string;
    count: number;
}

interface TComplianceErrorProp {
    errors?: ComplianceErrorItem[];
    id: string;
    message: string;
    popoverPosition?: "top" | "bottom";
    rounded?: boolean;
    shouldShowPopover?: boolean;
    testId?: string;
}

const ComplianceError = ({
    errors = [],
    id,
    message,
    popoverPosition = "top",
    rounded = false,
    shouldShowPopover = true,
    testId = "compliance-error",
}: TComplianceErrorProp) => {
    const wrapperClass = cx({
        "c-compliance-error": true,
        "c-compliance-error__rounded": rounded,
    });

    const renderPill = (error: ComplianceErrorItem): React.ReactElement => {
        return (
            <span className="c-compliance-error__pill" data-testid={`${testId}-${error.value}`}>
                {error.value}
            </span>
        );
    };

    return (
        <div id={id} className={wrapperClass} data-testid={testId}>
            <p className="c-compliance-error__message" data-testid={`${testId}-message`}>
                {message}
            </p>
            {errors.length > 0 && (
                <div className="c-compliance-error__pills" data-testid={`${testId}-pills`}>
                    {errors.map((error, idx) => (
                        <div key={`${error.value}-${idx}`} className="c-compliance-error__pill-item">
                            {shouldShowPopover && (
                                <Popover
                                    id={`${id}-popover`}
                                    count={error.count}
                                    title={error.title}
                                    message={error.message}
                                    phrase={error.content}
                                    position={popoverPosition}
                                    trigger="hover"
                                >
                                    {renderPill(error)}
                                </Popover>
                            )}
                            {!shouldShowPopover && renderPill(error)}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ComplianceError;
