import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { entitiesSelector } from "@/messenger/selectors";
import { TIncoming } from "@/messenger/types/entities/incoming";
import { NormalizedEntities, TStore  } from "@/messenger/types/store.types";


export const incomingsAdapter = createEntityAdapter<TIncoming>({
    selectId: (incoming) => incoming.incomingId,
});

export const initialState = incomingsAdapter.getInitialState({});


const incomings = createSlice({
    name: "incomings",
    initialState,
    reducers: {
        upsertIncomings(state, action: PayloadAction<NormalizedEntities>) {
            incomingsAdapter.upsertMany(state, action.payload.incomings);
        },
    },
});

export const incomingsSelectors = incomingsAdapter.getSelectors((state: TStore) => entitiesSelector(state).incomings);

export const {
    upsertIncomings,
} = incomings.actions;



export default incomings.reducer;