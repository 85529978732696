import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} fill="currentColor" {...props}>
      <path d="M23.876 11.263l-3.07-5.5a1.956 1.956 0 00-1.7-1.009H4.9a1.955 1.955 0 00-1.7 1.005L.13 11.263a1.382 1.382 0 00-.13.487v6a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-6a1.218 1.218 0 00-.124-.487zM4.83 6.879a.248.248 0 01.218-.129l13.865-.016a.248.248 0 01.218.128l2.241 4.016a.25.25 0 01-.218.372H17.5a1.491 1.491 0 00-1.061.439A1.51 1.51 0 0016 12.75a1.5 1.5 0 01-1.5 1.5h-5a1.5 1.5 0 01-1.5-1.5 1.5 1.5 0 00-1.5-1.5H2.844a.251.251 0 01-.219-.371z" />
    </svg>
  );
}

export default SvgComponent;
