import React from "react";

const LoadedChatCard = () => (
    <div className="c-chat-card c-chat-card--loaded">
        <div className="c-chat-card__stroke-wrapper">
            <span className="c-chat-card__stroke c-chat-card__stroke--28" />
            <span className="c-chat-card__stroke c-chat-card__stroke--13" />
        </div>
        <span className="c-chat-card__stroke c-chat-card__stroke--91" />
    </div>
);

export default LoadedChatCard;
