import "./index.scss";
import "../../locales/i18n";

import { PayloadAction } from "@reduxjs/toolkit";
import i18next from "i18next";
import groupby from "lodash/groupBy";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { batch, useDispatch, useSelector } from "react-redux";
import { useUnmount } from "react-use";

import { subscribeEventChannelAC, unsubscribeAllEventChannelAC } from "@/messenger/actions/eventChannel";
import {
    MESSAGE_LOADED_INITIAL_BATCH,
    MESSAGE_LOADED_MORE,
    MESSAGE_NOT_DELIVERED,
    MESSAGE_RECEIVED,
    MESSAGE_SENT,
    messageGetPreviousAC,
} from "@/messenger/actions/message";
import ChatLabel from "@/messenger/components/ChatLabel";
import LoadMoreMessages from "@/messenger/components/LoadMoreMessages";
import MessageBubble from "@/messenger/components/MessageBubble";
import { DISABLED_CHAT_NEW_FLAG } from "@/messenger/constants/featureFlags";
import { chatsSelectors, updateChatMessage, upsertOneChat } from "@/messenger/ducks/userInterface/chats";
import {
    getMessageIndex,
    getMessageKey,
    getMessageTimestamp,
    shouldShowMessageBubble,
} from "@/messenger/models/message";
import { TMessage, TMessageOrigin } from "@/messenger/types/message";
import { TStore } from "@/messenger/types/store.types";


export const isToday = (momentDate: moment.Moment) => {
    return momentDate.isSame(new moment(), "d");
};

export const isYesterday = (momentDate) => {
    const today = new moment();

    return momentDate.isSame(today.subtract(1, "days").startOf("day"), "d");
};

export const isThisYear = (momentDate) => {

    return (new moment()).year() === momentDate.year();
};
const getDateLabel = (momentDate) => {
    if (isToday(momentDate)) {
        return i18next.t("Today");
    }
    if (isYesterday(momentDate)) {
        return i18next.t("Yesterday");
    }
    if (isThisYear(momentDate)) {
        return momentDate.format("MMM DD");
    }

    return momentDate.format("MMM DD YYYY");
};

const createMessageSectionTitle = (message) => {
    return new moment(getMessageTimestamp(message)).format("DD-MM-YYYY");
};


const groupMessages = (messages: TMessage[]) => {
    const filteredMessages = messages.filter(shouldShowMessageBubble);

    const groupedMessages = groupby(filteredMessages, (message) => createMessageSectionTitle(message));

    return groupedMessages;
};

const renderConversationStart = () => {
    const conversationStartMessage = i18next.t("This is the beginning of your <br/>conversation with this customer");
    return <div className="c-chat__conversation-start" dangerouslySetInnerHTML={{ __html: conversationStartMessage }}></div>;
};

interface TChatProps {
    id: string;
}

const componentId = "CHAT_COMPONENT";

function Chat({ id }: TChatProps) {
    const activeConversation = useSelector((s: TStore) => chatsSelectors.selectById(s, id));

    const { messages, loadingMoreMessages: isLoading , paginator, scrollPosition, messageFirstBatchLoaded } = activeConversation;
    const lastSeenMessageIndex = messages?.length > 0 ? getMessageIndex(messages[messages.length - 1]) : -1;

    const hasPrevPage = paginator && paginator.hasPrevPage;
    const dispatch = useDispatch();
    const scrollbarRef = useRef<Scrollbars>(null);
    const scrollHeight = useRef(0);
    const prevScrollHeight = useRef(0);
    const [groupedMessages, setGroupedMessages] = useState<{ [key: string]: TMessage[] }>({});

    const scrollToBottom = () => {
        if (scrollbarRef.current) {
            scrollbarRef.current.scrollToBottom();
        }
    };

    const getScrollPositionDiff = (): number => {
        const currentScrollHeight = scrollbarRef.current.getScrollHeight();
        return currentScrollHeight - prevScrollHeight.current;
    };

    const keepScrollPosition = () => {
        if (scrollbarRef.current) {
            const position = getScrollPositionDiff();
            scrollbarRef.current.scrollTop(position);

            scrollHeight.current = position;
            prevScrollHeight.current = scrollbarRef.current.getScrollHeight();
        }
    };

    const updateScrollPosition = () => {
        dispatch(upsertOneChat({
            id,
            scrollPosition: scrollbarRef.current.getScrollTop(),
        }));
    };

    const scrollToPosition = () => {
        if (scrollbarRef.current) {
           if (!Number.isNaN(scrollPosition)) {
               scrollbarRef.current.scrollTop(scrollPosition + prevScrollHeight.current);
            }
           else {
               const diff = getScrollPositionDiff();
               const position = scrollHeight.current + diff;
               scrollbarRef.current.scrollTop(position);

               scrollHeight.current = position;
               prevScrollHeight.current = scrollbarRef.current.getScrollHeight();
            }
        }
    };

    interface TMessagesLoadedPayload {
        conversationId: string,
        isFirstBatch: boolean
    }

    const scrollToBottomOnDeeplinkLoad = (action: PayloadAction<TMessagesLoadedPayload>) => {
        if(action.payload.isFirstBatch && action.payload.conversationId === id){
            scrollToBottom();
        }
    };

    useUnmount(() => {
        dispatch(upsertOneChat({
            id,
            scrollPosition: scrollbarRef.current.getScrollTop(),
        }));
    });

    const renderMessages = () => {
        const output = [];

        let lastSeenMessageFound = false;
        let newMessagesInserted = false;
        const chatLabelComponent = <ChatLabel key={"NEW_MESSAGES"} label={i18next.t("New")}/>;

        Object.keys(groupedMessages).forEach(key => {
            output.push(<div key={key} className={"c-chat__header"}>
                    {getDateLabel(new moment(key, "DD-MM-YYYY"))}
                </div>,
            );

            groupedMessages[key].forEach(message => {
                if (!DISABLED_CHAT_NEW_FLAG) {
                    if (lastSeenMessageFound && !newMessagesInserted) {
                        output.push(chatLabelComponent);
                        newMessagesInserted = true;
                    }
                }

                const onLoad = () => {
                    const origin = message.computed.messageOrigin;

                    if (origin === TMessageOrigin.NEW) {
                        scrollToBottom();
                    } else if (origin === TMessageOrigin.LOAD_MORE) {
                        scrollToPosition();
                    }

                    if (origin) {
                        message.computed.messageOrigin = null;
                        dispatch(updateChatMessage({
                            id: message.twilio.conversation.attributes.conversation_id,
                            message,
                        }));
                    }
                };

                output.push(<MessageBubble key={getMessageKey(message)} message={message} onLoad={onLoad} onZoom={updateScrollPosition} />);

                if (!DISABLED_CHAT_NEW_FLAG) {
                    if (!newMessagesInserted) {
                        const isLastMessageOverall = message === messages[messages.length - 1];
                        const isLastMessageLocally = message === groupedMessages[key][groupedMessages[key].length - 1];
                        if (getMessageIndex(message) === lastSeenMessageIndex) {
                            lastSeenMessageFound = true;
                        }

                        if (lastSeenMessageFound && !isLastMessageOverall && !isLastMessageLocally) {
                            output.push(chatLabelComponent);
                            newMessagesInserted = true;
                        }
                    }
                }
            });
        });

        return output;
    };

    useEffect(() => {
        if(messageFirstBatchLoaded){
            if (scrollPosition !== null) {
                // setTimeout delays the scrollToPosition call just long enough for messages to render before the scrolling occurs.
                setTimeout(scrollToPosition, 0);
            } else {
                setTimeout(scrollToBottom, 0);
            }
        }

        batch(() => {
            dispatch(subscribeEventChannelAC(MESSAGE_SENT, componentId, scrollToBottom));
            dispatch(subscribeEventChannelAC(MESSAGE_NOT_DELIVERED, componentId, scrollToBottom));
            dispatch(subscribeEventChannelAC(MESSAGE_RECEIVED, componentId, scrollToBottom));
            dispatch(subscribeEventChannelAC(MESSAGE_LOADED_INITIAL_BATCH, componentId, scrollToBottomOnDeeplinkLoad));
            dispatch(subscribeEventChannelAC(MESSAGE_LOADED_MORE, componentId, keepScrollPosition));
        });

        return function cleanup() {
            dispatch(unsubscribeAllEventChannelAC(componentId));
        };
    }, []);

    useEffect(() => {
        setGroupedMessages(groupMessages(messages));
    }, [messages]);

   const handleLoadMore = () => {
       const currentScrollHeight = scrollbarRef.current.getScrollHeight();
       scrollHeight.current = currentScrollHeight;
       prevScrollHeight.current = currentScrollHeight;

       dispatch(upsertOneChat({
           id,
           scrollPosition: null,
       }));

       dispatch(messageGetPreviousAC());
    };

    const renderHeader = () => {
        if (Object.keys(groupedMessages).length > 0) {
            if (hasPrevPage) {
                return <LoadMoreMessages isLoading={isLoading}/>;
            }
            return renderConversationStart();
        }
        return null;
    };

    const handleOnScroll = () => {
        // if there are unloaded messages, load them when user scrolls to top of chat
        if (Object.keys(groupedMessages).length > 0) {
            if(hasPrevPage){
                const scrollTop = scrollbarRef.current.getScrollTop();
                if(scrollTop === 0){
                    handleLoadMore();
                }
            }
        }
    };
    
    return (
        <Scrollbars ref={scrollbarRef} hideTracksWhenNotNeeded={true} key={id} onScroll={handleOnScroll} tabIndex={0}>
            <div className="c-chat">
                {renderHeader()}
                {renderMessages()}
            </div>
        </Scrollbars>
    );
}


export default Chat;
