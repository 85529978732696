import { useEffect, useState } from "react";
import useInterval from "use-interval";

import { VERIFY_MESSAGE_POLLING_TIME_VALUE } from "@/messenger/constants/compliance.settings";
import { useVerifyMessage } from "@/messenger/hooks/useVerifyMessage";
import { TComplianceAdvice } from "@/messenger/types/message";

interface TUseVerifyMessagePollingProps {
    content: string;
    conversationId: string;
    shouldVerify: boolean;
    cursorOffset: number; // current cursor position inside input
    textOffset?: number; // placeholder offset in message template
 }

export const useVerifyMessagePolling = ({ content, conversationId, shouldVerify, cursorOffset, textOffset = 0 }: TUseVerifyMessagePollingProps) => {
    const [shouldVerifyInputOnInterval, setShouldVerifyInputOnInterval] = useState<boolean>(false);
    const [complianceAdvice, setComplianceAdvice] = useState<TComplianceAdvice[]>();
  
    const { data } = useVerifyMessage({
        content,
        conversationId,
        shouldVerify: shouldVerifyInputOnInterval,
    });
    
    useInterval(() => {
        if ( shouldVerify) {
            setShouldVerifyInputOnInterval(true);
        }
    }, VERIFY_MESSAGE_POLLING_TIME_VALUE);
    
    useEffect(() => {
        if (data?.verifyMessage.content) {
            setShouldVerifyInputOnInterval(false);
            const diff = data.verifyMessage.content.length - content.length;

            const ca = data.verifyMessage.complianceAdvices.map(item => {
                // correct compliance advice ranges only if advice starts after current cursor position
                if (item.contentArea && item.contentArea.start - textOffset > cursorOffset + diff)
                    return {
                        ...item,
                        contentArea: {
                            start: item.contentArea.start - diff,
                            end: item.contentArea.end - diff,
                        },
                    };
                return item;
            });
            setComplianceAdvice(ca);
        }
    }, [data]);

    return { complianceAdvice };
};
