import "./index.scss";
import "../../locales/i18n";

import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";

import ComplianceError from "@/components/components/ComplianceError";
import { TComplianceAdvice } from "@/messenger/types/message";

import { getComplianceErrors } from "../../utils/messageTemplates.helper";

interface TMessageTemplateComplianceErrorBoxProps {
    contentComplianceAdvice: TComplianceAdvice[];
    shouldDisplayComplianceError: boolean;
    isJustOpened: boolean;
}

export default function MessageTemplateComplianceErrorBox({
    contentComplianceAdvice,
    shouldDisplayComplianceError,
    isJustOpened,
}: TMessageTemplateComplianceErrorBoxProps): ReactElement {
    const { t } = useTranslation();
    const renderComplianceErrorBox = (): ReactElement => {
        return (
            <ComplianceError
                id="complianceError"
                message={t("These words or phrases cannot be used:")}
                errors={getComplianceErrors(contentComplianceAdvice)}
                rounded={true}
                popoverPosition={"bottom"}
            />
        );
    };

    return (
        <div className="с-message-template-compliance-error-box">
            <CSSTransition
                in={shouldDisplayComplianceError && !isJustOpened}
                timeout={shouldDisplayComplianceError ? 300 : 0}
                unmountOnExit={true}
                classNames="с-message-template-compliance-error-box--animation"
            >
                {renderComplianceErrorBox()}
            </CSSTransition>
            {isJustOpened && shouldDisplayComplianceError && renderComplianceErrorBox()}
        </div>
    );
}
