import { TActionV2 } from "@/messenger/types/action.types";

enum HasLogoActionTypes {
    SET = "hummingbird/hasLogo/SET",
}

export const setHasLogoAC = (hasLogo: boolean) => ({
    type: HasLogoActionTypes.SET,
    payload: hasLogo,
});

const hasLogo = (state = false, action: TActionV2<HasLogoActionTypes, boolean>) => {
    switch (action.type) {
        case HasLogoActionTypes.SET:
            return action.payload;
        default:
            return state;
    }
};

export default hasLogo;
