import React from "react";

import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";

import { ellipsis } from "../../../helpers";
import Badge from "../../Badge";

interface SimpleDropdownHeadingProps {
    isMobileView: boolean;
    option: TDropdownOption;
    heading: string | JSX.Element;
}

function SimpleDropdownHeading({isMobileView, option, heading}: SimpleDropdownHeadingProps) {

    if (!option) {
        return <>{heading}</>;
    }
    
    const o = option;
    
    const formattedHeading = isMobileView ? ellipsis(o.heading, 15) : o.heading;
    
    return (
        <>
            {formattedHeading}
            {o.badge && o.badge.value > 0 && (
                <Badge style={{marginLeft: "8px"}} color={o.badge.color}>
                    {o.badge.value}
                </Badge>
            )}
        </>
    );
}

export default SimpleDropdownHeading;