import { Paginator } from "@twilio/conversations";
import { RouterState } from "connected-react-router";
import { Dispatch } from "redux";

import contactsReducer from "@/messenger/ducks/entities/contacts";
import conversationsReducer from "@/messenger/ducks/entities/conversations";
import customersReducer from "@/messenger/ducks/entities/customers";
import incomingsReducer from "@/messenger/ducks/entities/incomings";
import messageTemplatesReducer from "@/messenger/ducks/entities/messageTemplates";
import participationsReducer from "@/messenger/ducks/entities/participations";
import representativesReducer from "@/messenger/ducks/entities/representatives";
import representativeSettingsReducer from "@/messenger/ducks/entities/representativeSettings";
import topicsReducer from "@/messenger/ducks/entities/topics";
import twilioChannelsReducer from "@/messenger/ducks/entities/twilioChannels";
import widgetsReducer from "@/messenger/ducks/entities/widgets";
import chatsReducer from "@/messenger/ducks/userInterface/chats";
import nodeOffsetsReducer from "@/messenger/ducks/userInterface/nodeOffsets";
import widgetsStateReducer from "@/messenger/ducks/userInterface/widgetsState";
import { RequestTracker } from "@/messenger/models/requestTracker";
import { EDeviceType } from "@/messenger/utils/deviceType";
import { WidgetViewSize } from "@/messenger/widgets/api";
import { WidgetScope, WidgetType } from "@/messenger/widgets/api/events";

import { TJWTToken, TUser } from "./auth.types";
import { ConversationCategory, TContact, TConversationNormalized, TCustomer, TWidget, TWidgetTab } from "./entities/conversation";
import { TIncoming } from "./entities/incoming";
import { TMessageTemplate, TMessageTemplatePlaceholderInPlace } from "./entities/messageTemplate";
import { TParticipationNormalized } from "./entities/participation";
import { TRepresentative } from "./entities/representative";
import { TTopicNormalized } from "./entities/topic";
import { TError } from "./error.types";
import { TComposedComplianceAdvice, TDraftMessage, TMessage } from "./message";
import { TNotification } from "./notification.types";
import { TTwilioChannel, TTwilioMessage } from "./twilioChatSDK.types";

export interface TChatStarterState {
    messageTemplates: TMessageTemplate[];
    selectedMessageTemplateId: string;
    selectedPlaceholder: TMessageTemplatePlaceholderInPlace;
    inputComplianceAdvice: TComposedComplianceAdvice[];
}

export interface TContainer {
    state?: TStore;
    dispatch?: Dispatch;
}

export interface TAuthStore {
    token: TJWTToken;
    conversationId: string;
    user: TUser;
}

export type ChatCategoryCounts = {
    [key in ConversationCategory]: number
}
export interface TPromptsStore {
    werePromptsShown: boolean;
    isPromptShowing: boolean;
    tooltips: boolean;
}
export interface TConversationRefinements {
    filter: {
        isOpen: boolean,
        selectedTopicName: string,
        prevSelectedTopicName: string,
        selectedMonth: string,
        prevSelectedMonth: string,
        isSmsCapable: boolean,
        prevIsSmsCapable: boolean,
    },
    search: {
        value: string
    },
    result: TConversationNormalized[]
}
export interface TGeneralStore {
    conversationRefinements: TConversationRefinements;
    currentEditableTemplateId: string;
    currentSelectedFieldName: string;
    deviceType: EDeviceType;
    errors: TError[];
    hasLogo: boolean;
    isOnline: boolean;
    isAddAContactOpen: boolean;
    notifications: TNotification[];
    selectedCategory: ConversationCategory;
    tertiaryCategory: ConversationCategory;
    prompts: TPromptsStore;
    widgetTab: TWidgetTab;
}

export interface NormalizedEntities {
    contacts: NormalizedEntityResult<TContact>
    conversation: NormalizedEntityResult<TConversationNormalized>
    conversations: NormalizedEntityResult<TConversationNormalized>
    customers: NormalizedEntityResult<TCustomer>
    messageTemplates: NormalizedEntityResult<TMessageTemplate>;
    participations: NormalizedEntityResult<TParticipationNormalized>
    topics: NormalizedEntityResult<TTopicNormalized>
    twilioChannels: NormalizedEntityResult<TTwilioChannel>
    widgets: NormalizedEntityResult<TWidget>
    representatives: NormalizedEntityResult<TRepresentative>
    representativeSettings: NormalizedEntityResult<TRepresentative>
    incomings: NormalizedEntityResult<TIncoming>
}

interface NormalizedEntityResult<T> {
    [key: string]: T
}

export interface NormalizerResult {
    result: any;
    entities: NormalizedEntities;
}


export enum SDKLoadingStatus {
    NOT_LOADED = "NOT_LOADED",
    LOADING = "LOADING",
    LOADED_CONVERSATIONS = "LOADED_CONVERSATIONS",
}

export interface TSDKStore {
    session: string;
    loadingStatus: SDKLoadingStatus
}

export interface EntitiesStore {
    customers: ReturnType<typeof customersReducer>
    conversations: ReturnType<typeof conversationsReducer>
    twilioChannels: ReturnType<typeof twilioChannelsReducer>
    messageTemplates: ReturnType<typeof messageTemplatesReducer>
    contacts: ReturnType<typeof contactsReducer>
    widgets: ReturnType<typeof widgetsReducer>
    topics: ReturnType<typeof topicsReducer>
    participations: ReturnType<typeof participationsReducer>
    representatives: ReturnType<typeof representativesReducer>
    representativeSettings: ReturnType<typeof representativeSettingsReducer>
    incomings: ReturnType<typeof incomingsReducer> 
}

export interface TNodeOffsets {
    width: number
    height: number
    top: number
    left: number
}
export interface TWidgetState {
    type: WidgetType
    url: string
    isReady: boolean
    widgetDomain: string
    id: string
    scope: WidgetScope
    isEnabled: boolean
}

export interface TStandardWidgetState extends TWidgetState {
    size: WidgetViewSize
    header: string
    footer?: string
}

export interface TActionWidgetState extends TWidgetState {
    label: string
    header: string
    isOpen: boolean
}

export interface TSendableWidgetState extends TWidgetState {
    label: string
    isShown: boolean
}

export interface TModuleWidgetState extends TWidgetState {
    path: string
}

export type TWidgetStates = TStandardWidgetState | TActionWidgetState | TSendableWidgetState

export interface TChatState {
    id: string
    paginator?: Paginator<TTwilioMessage>
    localMessages: TMessage[]
    messages: TMessage[]
    unreadMessagesCount: number
    category: ConversationCategory
    contact: string
    error: any
    draftMessage: TDraftMessage
    loadingMoreMessages: boolean
    scrollPosition?: number
    messageFirstBatchLoaded: boolean
    chatStarter: TChatStarterState
    hydration: string
    hasPriority: boolean
}

export interface WidgetsStore {
    [name: string]: TWidgetState
}

export interface UserInterfaceStore {
    chatFrame: {
        messageTemplates: TChatStarterState,
    },
    chats: ReturnType<typeof chatsReducer>,
    widgetsState: ReturnType<typeof widgetsStateReducer>
    nodeOffsets: ReturnType<typeof nodeOffsetsReducer>
}

export interface TStore {
    auth: TAuthStore;
    customer: TCustomer;
    entities: EntitiesStore
    general: TGeneralStore;
    requests: { [key: string]: RequestTracker }
    router: RouterState
    sdk: TSDKStore;
    userInterface: UserInterfaceStore
}
