import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} fill="currentColor" {...props}>
      <path d="M3.751 13.501a.75.75 0 01-.636-1.147l7.5-12 .02-.029a.889.889 0 01.133-.146.739.739 0 01.054-.042l.032-.022a.782.782 0 01.117-.058.656.656 0 01.139-.041.623.623 0 01.207-.01.666.666 0 01.078.011l.068.017a.661.661 0 01.187.082c.009.005.018.012.027.019l.052.04a.646.646 0 01.163.187l7.495 11.992a.751.751 0 01-.636 1.147h-15zm13.647-1.5l-6.147-9.835-6.147 9.835h12.294z" />
      <circle cx={11.251} cy={10.126} r={1.125} />
      <path d="M11.251 8.251a.75.75 0 01-.75-.75v-1.5a.75.75 0 011.5 0v1.5a.75.75 0 01-.75.75zM2.251 24.001a.75.75 0 010-1.5h2.625c.62 0 1.125-.504 1.125-1.124 0-.899-.277-1.01-1.569-1.13-1.096-.101-2.931-.271-2.931-2.622V15.75a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-3.75v1.125c0 .898.277 1.008 1.569 1.128 1.096.101 2.931.271 2.931 2.622A2.628 2.628 0 014.877 24H2.251zM12.001 24.001c-1.654 0-3-1.346-3-3v-3c0-1.654 1.346-3 3-3s3 1.346 3 3v3c0 1.655-1.346 3-3 3zm0-7.5c-.827 0-1.5.673-1.5 1.5v3c0 .827.673 1.5 1.5 1.5s1.5-.673 1.5-1.5v-3c0-.827-.673-1.5-1.5-1.5zM19.501 24.001c-1.654 0-3-1.346-3-3v-3c0-1.654 1.346-3 3-3s3 1.346 3 3v3c0 1.655-1.346 3-3 3zm0-7.5c-.827 0-1.5.673-1.5 1.5v3c0 .827.673 1.5 1.5 1.5s1.5-.673 1.5-1.5v-3c0-.827-.673-1.5-1.5-1.5z" />
    </svg>
  );
}

export default SvgComponent;
