
import { TAction } from "@/messenger/types/action.types";
import {
    TPromptsStore,
    TStore,
} from "@/messenger/types/store.types";
import { detectMobile } from "@/messenger/utils/deviceType";

enum ActionType {
    ADD = "hummingbird/general/prompts/ADD",
    REMOVE = "hummingbird/general/prompts/REMOVE",
    SHOW_TIP = "hummingbird/general/prompts/SHOW_TIP",
    HIDE_TIP = "hummingbird/general/prompts/HIDE_TIP",
    SET_WERE_SHOWN = "hummingbird/general/prompts/SET_WERE_SHOWN",
}

const initialState = {
    werePromptsShown: false,
    isPromptShowing: false,
    tooltips: false,
};

export const setPromptsWereShown = {
    type: ActionType.SET_WERE_SHOWN,
};

export const addPrompt = () => (dispatch, getState: () => TStore) => {
    const state = getState();
    if (!state.general.prompts.werePromptsShown && !detectMobile(state.general.deviceType)) {
        dispatch({
            type: ActionType.ADD,
        });
    }
};

export const removePrompt = {
    type: ActionType.REMOVE,
};

export const showTip = {
    type: ActionType.SHOW_TIP,
};

export const hideTip = {
    type: ActionType.HIDE_TIP,
};

const promptReducer = (state: TPromptsStore = initialState, action: TAction<ActionType>) => {
    switch (action.type) {
        case ActionType.ADD:
            return { ...state, isPromptShowing: true };
        case ActionType.REMOVE:
            return { ...state, isPromptShowing: false };
        case ActionType.SHOW_TIP:
            return { ...state, tooltips: true };
        case ActionType.HIDE_TIP:
            return { ...state, tooltips: false };
        case ActionType.SET_WERE_SHOWN:
            return { isPromptShowing: false, werePromptsShown: true };
        default:
            return state;
    }
};

export default promptReducer;
