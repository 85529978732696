import { MockedProvider } from "@apollo/client/testing";
import { WidgetClient } from "@statflo/widget-sdk";
import { ConnectedRouter,routerMiddleware  } from "connected-react-router";
import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";

import { config } from "@/messenger/config";
import { Auth0Provider } from "@/messenger/providers/Auth0Provider";
import ApolloProvider from "@/messenger/providers/AuthorizedApolloProvider";
import rootSaga from "@/messenger/sagas";
import { segment } from "@/messenger/services/analytics.service";

import App from "./App";
import { ApolloMockResponses, getMockWidgetTypeByName } from "./constants/apolloSeedMock";
import { getWidgetReadyEvent } from "./constants/misc";
import rootReducer from "./ducks";
import { onRedirectCallback } from "./services/auth0Config.service";
import { isSeedEnv, isWidgetInstanceOfTextKit } from "./utils/helpers";
import { createWidgetState } from "./widgets";
import { WidgetExampleComponent } from "./widgets/api/examples";


const Apollo = ({ children }: any) => {
  return isSeedEnv ? <MockedProvider mocks={ApolloMockResponses} addTypename={true}>{children}</MockedProvider> : <ApolloProvider >{children}</ApolloProvider>;
};

segment.init();

export const ROOT_NODE = document.getElementById("root");


if (!isWidgetInstanceOfTextKit) {
  const sagaMiddleware = createSagaMiddleware();
  const history = createBrowserHistory();

  const store = createStore(rootReducer(history), composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      thunk,
      sagaMiddleware,
    ),
  ));

  sagaMiddleware.run(rootSaga);

  ReactDOM.render(<Provider store={store}>
      <ConnectedRouter history={history}>
        <Auth0Provider
          customer={config.customerId()}
          onRedirectCallback={onRedirectCallback}
          initOptions={config.authConfig()}
        >
          <Apollo>
            <App />
          </Apollo>
        </Auth0Provider>
      </ConnectedRouter>
    </Provider>,
    ROOT_NODE,
  );
} else {
  const { name } = window;
  const type = getMockWidgetTypeByName(name);
  const client = new WidgetClient({ 
    id: name,
    window,
    createWidgetState,
  });

  ReactDOM.render(<WidgetExampleComponent type={type} client={client} emitEventsOnMount={ getWidgetReadyEvent } />, ROOT_NODE);
}