import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 278.2 307.8"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st0{opacity:.69}.prefix__st1{fill:#f2f2f2}.prefix__st4{fill:#bfc6d1}.prefix__st5{fill:#e1e6e7}.prefix__st11{fill:none;stroke:#a5acaf;stroke-width:3;stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <g id="prefix__Background_Simple">
        <g className="prefix__st0">
          <path
            className="prefix__st1"
            d="M254.1 70.5c-1.7 1.7-4.3 2.2-6.7 1.1-22.3-10.4-47.9-13.6-71.9-8.9-3.2.6-6.4-1.5-7-4.7-.7-3.2 1.4-6.4 4.7-7 26.5-5.1 54.7-1.6 79.3 9.8 3 1.4 4.3 5 2.9 7.9-.3.7-.8 1.3-1.3 1.8z"
          />
        </g>
        <g className="prefix__st0">
          <path
            className="prefix__st1"
            d="M45.2 214.3c13.3 19.3 38 24.3 64.3 24.5 26.1.2 100.6 7.1 116.3-13.7 30.3-40.1-51.6-24.1-11.8-54.7 18.6-14.3 20.6-.9 35.5-19.9 17-21.7 29.8-44.4 8.8-64.3-30.5-28.8-70.3-15.6-109.6-15.5-50.9.1-87.4 19.8-109.6 57.1-23.2 39.1-1.1 76 6.1 86.5z"
          />
        </g>
      </g>
      <g id="prefix__Shadow_1_">
        <ellipse
          id="prefix___x3C_Path_x3E__1_"
          cx={149.3}
          cy={287.3}
          rx={97.8}
          ry={11.3}
          fill="#f5f5f5"
        />
      </g>
      <g id="prefix__Mailbox">
        <path fill="#b6b6b7" d="M66.6 163.6l61.2-6.2v11.3z" />
        <path className="prefix__st4" d="M152.7 145.8h24v140.1h-24z" />
        <path className="prefix__st5" d="M175.7 143.8h9.4v142h-9.4z" />
        <path
          d="M96.6 82.6l90.7.6 13.8.1c2.1 0 4.2.2 6.3.7h.1c5.9 1.3 11.4 3.8 16.2 7.5 1.4 1.1 2.7 2.2 3.9 3.4 1.2 1.2 2.2 2.4 3 3.9l2.4 4.2c.8 1.4 1.5 3 2.1 4.5l.2.6c1.2 3.5 2 7.2 2.4 10.9l.2 2c.1.9.1 1.8.2 2.7l.6 44.8-113.1 2.1-23.7-60.9-5.3-27.1z"
          fill="#e4e8ea"
        />
        <path
          d="M127.8 125.2c0 23.8-13.9 43-31 43-16.4 0-29.9-17.7-30.9-40.1 0-1-.1-1.9-.1-2.9 0-23.8 13.9-43 31-43s31 19.2 31 43z"
          fill="#ced2d8"
        />
        <path
          className="prefix__st4"
          d="M66.5 164.7l61.2 5v-50.2l-.8-5.5c-.3-2.2-.9-4.4-1.8-6.5l-1.8-4.5c-.7-1.6-1.5-3.1-2.4-4.5l-3-4.3c-1.5-2.2-3.3-4.1-5.3-5.8-2.9-2.4-6.3-4.1-9.9-4.9l-4.2-.9c-1.8-.4-3.7-.3-5.5.2l-4.5 1.4c-1.8.6-3.5 1.5-4.9 2.6l-4.7 3.8c-1.4 1.1-2.6 2.4-3.5 3.9l-2.9 4.6c-.6 1-1.2 2-1.6 3.1l-2 5.2c-.3.7-.5 1.3-.7 2l-1.1 3.7c-.3 1.2-.6 2.4-.7 3.6l-.4 3.7c-.1 1.2-.2 2.5-.2 3.7l.7 40.6z"
        />
        <path fill="#dddee0" d="M177.7 47.2l24.5-.4-8.3 8.7 9.8 8.8-25.2.8z" />
        <path className="prefix__st4" d="M175.8 44.4h5.5v82.1h-5.5z" />
        <path
          className="prefix__st5"
          d="M65 164.7l-8.7 19.1s0 .1-.1.1l-1.8 5.6c-.9 2.7-1.3 5.5-1.3 8.3v5.1c0 1 .1 2 .4 3l1 3.6c.7 2.5 2.1 4.7 3.9 6.3l.9.7c1.4 1.2 3.1 2.1 4.8 2.5l4.9 1.2h6.5c4.2 0 8.4-1 12.3-3l1.8-.9c1.4-.7 2.7-1.7 3.9-2.8l4.8-4.5.1-.1 8.6-9.3.1-.1 10.6-13.3c.6-.7 1.1-1.5 1.6-2.3l8.3-14.1c.5-.8 0-1-.8-1.1l-60.1-5.3c-.5.3-1.5.9-1.7 1.3z"
        />
        <path
          d="M145.5 281.4l-2.8-25.4 9 31.6-2.3-29.3 7.3 27.1 15.2-24.8-4.5 25.4 24.3-28.2-12.4 29.9 13-15.2v11.8l2.6 2.6c1 1 .4 2.7-1 3l-22.5 3.3c-.8.1-8.1.4-8.9.4l-17.6-.7c-1.4-.1-2.4-1.5-2-2.8l2.6-8.7z"
          fill="#edf2f4"
        />
        <g>
          <ellipse
            transform="rotate(-57.999 133.38 21.806)"
            className="prefix__st4"
            cx={133.4}
            cy={21.8}
            rx={6.5}
            ry={7.5}
          />
          <ellipse
            transform="rotate(-19.999 142.015 27.402)"
            className="prefix__st5"
            cx={142}
            cy={27.4}
            rx={12.3}
            ry={7.8}
          />
          <ellipse
            transform="rotate(-14.999 140.787 20.297)"
            className="prefix__st4"
            cx={140.8}
            cy={20.3}
            rx={6.5}
            ry={7.5}
          />
          <ellipse
            className="prefix__st5"
            cx={155.1}
            cy={21.4}
            rx={4.5}
            ry={4.6}
          />
          <circle cx={157.2} cy={19.4} r={2} fill="#a7afb5" />
          <path className="prefix__st11" d="M115.3 144.2l-5.5 2.2" />
          <path
            d="M98.3 151l-1.2.5c-.1 0-.2.1-.3.1l-14.5 3.6c-.2 0-.3.1-.5.1l-19.6 1.1-16.1.3h-.5l-9.2-1.6c-.4-.1-.8-.3-1.1-.5l-10.9-9.2-.2-.2-9-10.3c-.2-.2-.3-.5-.4-.8l-2.3-15.8v-.4l1.7-10.6c0-.2.1-.4.2-.6L21 95.6c.1-.2.3-.4.6-.6l7.9-5.2c1.5-.2 3.7-.8 4.3-.7l5.4.1c4.7.9 8.5 3.8 9.9 7.7l.3.9c.1.4.1.8-.1 1.2-2.6 5.2-8.6 8.7-15.4 9.1h0-.4l-8.9-1c-.4 0-.7-.2-1-.4l-9.7-6.2c-.4-.2-.7-.6-.8-1l-2.9-9.1c0-.1-.1-.3-.1-.4V78.1c0-.2.1-.5.2-.7l4.1-8.1c.9-1.8 2.2-3.3 4-4.6l13.1-9.3c.1-.1.3-.2.5-.3l12-5c.3-.1.6-.2.9-.2l17-.3c.2 0 .3 0 .5-.1l20.5-4.2c.1 0 .2 0 .3-.1l20.6-6.5"
            fill="none"
            stroke="#a5acaf"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="12.3582,12.3582"
          />
          <path className="prefix__st11" d="M109.6 36.9l5.7-1.8" />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
