import "./index.scss";

import React from "react";
import { useSelector } from "react-redux";

import { RIGHT_SIDEBAR_STANDIN } from "@/messenger/constants/domID";
import { isAppLoadedSelector } from "@/messenger/ducks/selectors/aggregate";
import { activeConversationSelector } from "@/messenger/selectors";

const baseClassName = "c-right-sidebar-standin";
interface TRightSidebarStandinProps {
    customStyle?: React.CSSProperties
    style?: React.CSSProperties
}
const RightSidebarStandin = ({ style }: TRightSidebarStandinProps) => {
    const isAppReady = useSelector(isAppLoadedSelector);
    const conversation = useSelector(activeConversationSelector);

    if (isAppReady && conversation) {
        return <div 
        className={baseClassName} 
        id={RIGHT_SIDEBAR_STANDIN} 
        style={style}
    />;
    }
    return null;
};

export default RightSidebarStandin;