import "./index.scss";

import cx from "classnames";
import React, { ReactElement } from "react";
import { CSSTransition } from "react-transition-group";

interface TMessageTemplateContentProps {
    children: ReactElement;
    isSent: boolean;
    isPlaceholderSelected: boolean;
    topicName?: string;
    handleSendChatStarterFinish: () => void;
}

export default function MessageTemplateContent({
    children,
    isSent,
    isPlaceholderSelected,
    topicName,
    handleSendChatStarterFinish,
}: TMessageTemplateContentProps): ReactElement {
    return (
        <CSSTransition
            in={!isSent}
            enter={false}
            timeout={800}
            unmountOnExit={true}
            onExited={handleSendChatStarterFinish}
            classNames={"с-message-template-content--animation"}
        >
            <div
                className={cx("с-message-template-content__wrapper", {
                    "c-message-template-content__wrapper--shadowed": isPlaceholderSelected,
                    "c-message-template-content__wrapper--view-only": Boolean(topicName),
                })}
                data-testid="messageTemplateContent"
            >
                <div className="с-message-template-content">
                    {children}
                    {topicName && !isSent && (
                        <div className="с-message-template-content__information" data-testid="messageTemplateContentInformation">
                            <span className="с-message-template-content__information-campaign">{topicName}</span>
                        </div>
                    )}
                </div>
            </div>
        </CSSTransition>
    );
}
