import "./index.scss";
import "../../locales/i18n";

import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { MessageBubbleV2,NoEntry  } from "@/components/icons";
import { activeConversationIsOptOutSms, activeConversationIsOptOutVoice } from "@/messenger/selectors";
import { ConversationCategory } from "@/messenger/types/entities/conversation";

interface DisabledMessageInputProps {
    currentCategory: ConversationCategory
}

const getOptOutMessage = (optOutSms, optOutVoice) => {
    if(optOutSms){
        if(optOutVoice){
            return i18next.t("Customer has been opted-out from all channels");
        }
        return i18next.t("Customer has been opted-out of SMS");
    }
    return "";
};

const DisabledMessageInput = ({ currentCategory } : DisabledMessageInputProps) => {
    const isOptOutSms = useSelector(activeConversationIsOptOutSms);
    const isOptOutVoice = useSelector(activeConversationIsOptOutVoice);
    const { t } = useTranslation();

    return (
        <div className="c-disabled-message-input">
            
                {(isOptOutSms) && (
                    <div className="c-disabled-message-input__info-card c-disabled-message-input__info-card-opt-out">
                        <span className="c-disabled-message-input__info-card-opt-out-icon">
                            <NoEntry width={16} height={16} viewBox="0 0 16 16" />
                        </span>
                        <span>{getOptOutMessage(isOptOutSms,isOptOutVoice)}</span>
                    </div>
                )}
                {currentCategory === ConversationCategory.CLOSED && (
                    <div className="c-disabled-message-input__info-card c-disabled-message-input__info-card-closed">
                        <div className="c-disabled-message-input__info-card-closed-icon">
                            <MessageBubbleV2 width={17} height={16} viewBox="0 0 17 16" />
                        </div>
                        <div>{t("This conversation has been closed without initiating the campaign")}</div>
                    </div>
                )}
                
            
        </div>
    );

};

export default DisabledMessageInput;
