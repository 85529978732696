import "./index.scss";

import cx from "classnames";
import IframeResizer from "iframe-resizer-react";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";

import { onWidgetError, WidgetErrorComponent } from "@/messenger/containers/WidgetContainer";
import { activeModuleIdSelector } from "@/messenger/selectors";
import { TModuleWidgetState } from "@/messenger/types/store.types";




interface TWidgetProps {
    widgetState: TModuleWidgetState
}

const baseClassName = "c-widget";

export const getWidgetContainerId = (name: string) => `${name.replace(/ /g,"_")}--container`;

const ModuleWidget = ({ widgetState }: TWidgetProps) => {
    const { id, url, path } = widgetState;
    const activeModule = useSelector(activeModuleIdSelector);

    const isActiveModule = path === activeModule;

    const height = "100vh";
    const hardWidth = "100%";

    const outerContainerStyles: React.CSSProperties = {
        width: hardWidth,
        height,
        top: 0,
        left: 48, // accounts for navigation
    };

    const hideWidget = !isActiveModule ? { height: 0, overflow: "hidden" } : {};

    const widgetContainerClassName = cx(
        {[`${baseClassName}__widget-container`]: true},
    );

    return <div style={hideWidget}>
        <div style={{...outerContainerStyles}} key={id}>
            <div className={widgetContainerClassName} style={outerContainerStyles} id={getWidgetContainerId(id)}>
                <ErrorBoundary FallbackComponent={WidgetErrorComponent} onError={onWidgetError}>
                    <IframeResizer
                        className={`${baseClassName}__iframe`}
                        seamless
                        src={url} 
                        id={id}
                        title={id}
                        name={id}
                        scrolling={true}
                        />
                </ErrorBoundary>
            </div>
        </div>
    </div>;
};

export default ModuleWidget;