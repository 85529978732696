import "./index.scss";
import "../../locales/i18n";

import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import Button from "@/components/components/Button";
import { ButtonColor } from "@/components/components/Button/button.enum";
import { LoadingLines,SendEmail } from "@/components/icons";

const baseClassName = "MessageInputSendButton";

interface TSendButtonProps {
    disabled: boolean;
    showCompliance: boolean;
    showLoading: boolean;
    showDefault: boolean;
    onClick: () => void;
}

const SendButton = ({ disabled, onClick, showLoading, showCompliance, showDefault }: TSendButtonProps) => {
    const { t } = useTranslation();

    const getIcon = () => {
        if (showLoading) {
            return <LoadingLines />;
        } if (showCompliance) {
            return <div className={`${baseClassName}__error-state`}>!</div>;
        } 
            return <SendEmail />;
        
    };

    const containerClassName = cx({
        [`${baseClassName}__button-container`]: true,
        [`${baseClassName}__button-container--compliance`]: showCompliance && !showLoading,
        [`${baseClassName}__button-container--default`]: showDefault,
        [`${baseClassName}__button-container--disabled`]: disabled,
    });

    const buttonClassName = cx({
        [`${baseClassName}__button-container__button`]: true,
        [`${baseClassName}__button-container__button--loading`]: showLoading,
    });

    return (
        <div className={containerClassName}>
            <Button
                id={"SEND_MESSAGE_BUTTON"}
                ariaLabel={t("Send Message Button")}
                border={false}
                className={buttonClassName}
                color={ButtonColor.SECONDARY}
                disabled={disabled}
                icon={getIcon()}
                onClick={disabled ? null : onClick}
            />
        </div>
    );
};

export default SendButton;
