import { TActionV2 } from "@/messenger/types/action.types";

enum IsOnlineActionTypes {
    SET = "hummingbird/isonline/SET",
}

export const setIsOnlineAC = (isOnline: boolean) => ({
    type: IsOnlineActionTypes.SET,
    payload: isOnline,
});

const isOnlineReducer = (state = true, action: TActionV2<IsOnlineActionTypes, boolean>) => {
    switch (action.type) {
        case IsOnlineActionTypes.SET:
            return action.payload;
        default:
            return state;
    }
};

export default isOnlineReducer;
