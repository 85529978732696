import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useSelector } from "react-redux";

import { chatsSelectors } from "@/messenger/ducks/userInterface/chats";
import { startConversation } from "@/messenger/graphql/mutations";
import { activeConversationIsOptOutSms } from "@/messenger/selectors";
import { TStore } from "@/messenger/types/store.types";

interface TUseSendChatStarterProps {
    activeConversationId: string
}
export const useSendChatStarter = ({ activeConversationId }: TUseSendChatStarterProps) => {
    const isOptOutSms = useSelector(activeConversationIsOptOutSms);
    const [startConversationData, setStartConversationData] = useState(null);
    const [startConversationError, setStartConversationError] = useState(null);
    const [startConversationLoading, setStartConversationLoading] = useState(false);

    const chatState = useSelector((s: TStore) => chatsSelectors.selectById(s, activeConversationId));
    const { chatStarter: { selectedMessageTemplateId }} = chatState;
    
    const [startConversationMutation] = useMutation(startConversation, {
        onCompleted: data => {
            setStartConversationData(data);
            setStartConversationLoading(false);
        },
        onError: e => {
            setStartConversationError(e);
            setStartConversationLoading(false);
        },
    });

    const sendChatStarter = (message: string): void => {
        if(isOptOutSms){
            console.log("Could not send chat starter as contact is opted out of SMS");
            return;
        }
        setStartConversationLoading(true);
        startConversationMutation({
            variables: {
                StartConversationInput: {
                    // campaignMemerId expects the conversationId to send the chat starter.
                    campaignMemberId: chatState.id,
                    message,
                    messageTemplateId: selectedMessageTemplateId,
                },
            },
        })
            .catch(ex => console.log(`Failed to start conversation: ${ex}`));
    };

    const handleSendChatStarterSuccess = () => {
        // this does not get executed, but we may need the lifecycle in the future.
    };

    return {
        startConversationLoading,
        startConversationData,
        startConversationError,
        sendChatStarter,
        handleSendChatStarterSuccess,
    };
};
