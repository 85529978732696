import "./index.scss";
import "../../locales/i18n";

import React from "react";
import { useTranslation } from "react-i18next";

import Button from "@/components/components/Button";
import { ButtonColor, ButtonSize } from "@/components/components/Button/button.enum";
import { SimpleDropdown } from "@/components/components/Dropdown";
import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";
import { Plus } from "@/components/icons";

const baseClassName = "c-sendables-dropdown";

interface SendablesDropdownProps {
    isMobileView: boolean;
    options: TDropdownOption[];

    onSelect: (o: TDropdownOption) => void;
}

function SendablesDropdown(props: SendablesDropdownProps) {
    const { options, onSelect, isMobileView } = props;
    const { t } = useTranslation();

    const renderOverride = (_, toggleMenu: () => void) => <Button
        id={"SENDABLES_AND_MEDIA_BUTTON"}
        className={`${baseClassName}__toggle`}
        ariaLabel={t("Sendables Dropdown Button")}
        color={ButtonColor.SECONDARY}
        icon={<Plus width={14} height={14} />}
        onClick={toggleMenu}
        size={ButtonSize.REGULAR}
    />;

    const optionClassNames = {
        icon: `${baseClassName}__icon`,
    };

    return <SimpleDropdown
        className={`${baseClassName}__action-button`}
        isMobileView={isMobileView}
        onSelect={onSelect}
        options={options}
        optionsContainerClassNames={{ menu: `${baseClassName}__menu`, container: `${baseClassName}__container` }}
        renderOverride={renderOverride}
        optionClassNames={optionClassNames}
        optionsContainerAriaLabel={t("Sendables Dropdown Options List")}
    />;
}
export default SendablesDropdown;