import "./index.scss";

import React from "react";

import { NoSMS } from "@/components/icons";
import { handleOnEnter } from "@/messenger/utils/helpers";

interface ChatCardProps {
    domId: string;
    header: string;
    rightLabel?: any
    subheader?: any;
    onClick?: () => void;
    onHover?: () => void;
    rightContent?: any
    
    isLast?: boolean;
    isActive?: boolean;
    className?: string;
    isMobileView?: boolean;
    isSmsCapable?: boolean;
}

function ChatCard({ 
    domId, 
    header, 
    rightLabel, 
    subheader, 
    onClick, 
    onHover, 
    isActive, 
    isLast, 
    className, 
    rightContent, 
    isSmsCapable, 
}: ChatCardProps) {
    const getClassNames = (): string => `
        c-chat-card
        ${isActive ? "c-chat-card--active" : ""}
        ${isLast ? "c-chat-card--last" : ""}
        ${className || ""}
    `;

    const handleOnClick = (): void => {
        if (onClick) onClick();
    };

    const handleOnHover = () => {
        if (onHover) {
            onHover();
        }
    };
    
    return (
        <div
            className={getClassNames()}
            data-testid={"ChatCardId"}
            id={domId}
            onClick={handleOnClick}
            onFocus={handleOnHover}
            onKeyDown={e => handleOnEnter(e, handleOnClick)}
            onMouseEnter={handleOnHover}
            tabIndex={0}
        >
            <div className="c-chat-card__top-wrapper">
                <h2 className={`c-chat-card__heading ${isActive ? "c-chat-card__heading--active" : ""}`}>
                    {header} {!isSmsCapable ? <NoSMS width={14} height={14} style={{marginLeft: 8}}/> : null}
                </h2>
                {rightLabel}
            </div>
            <div className="c-chat-card__bottom-wrapper">
                <span className={`c-chat-card__message ${isActive ? "c-chat-card__message--active" : ""}`}>
                    {subheader}
                </span>
                <div className="c-chat-card__bottom-wrapper-right">
                    {rightContent}
                </div>
            </div>
        </div>
    );
}

export default ChatCard;

