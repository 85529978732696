import "./index.scss";
import "../../locales/i18n";

import cx from "classnames";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import Button from "@/components/components/Button";
import { ButtonColor, ButtonIconPosition } from "@/components/components/Button/button.enum";
import icons from "@/components/icons";

interface TMessageTemplateBackToAllButtonProps {
    hideButton: boolean;
    onClick: (e: React.MouseEvent) => void;
    buttonId: string;
}

export default function BackButton({
    hideButton,
    onClick,
    buttonId,
}: TMessageTemplateBackToAllButtonProps): ReactElement {
    const { t } = useTranslation();
    return (
        <div className={cx("c-message-template-back-button", {
            "c-message-template-back-button--hidden": hideButton,
        })} data-testid="messageTemplateBackToAllId">
            <Button
                id={buttonId}
                ariaLabel={t("Message Template Back To All Button")}
                icon={icons.ANGLE_LEFT}
                color={ButtonColor.SECONDARY}
                iconPosition={ButtonIconPosition.LEFT}
                className={"c-message-template-back-button__button"}
                onClick={onClick}
            >
                <div>{t("Back to all")}</div>
            </Button>
        </div>
    );
}
