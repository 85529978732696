import "./index.scss";
import "../../locales/i18n";

import React from "react";
import { useTranslation } from "react-i18next";

import Badge from "@/components/components/Badge";
import { BadgeColor } from "@/components/components/Badge/badgeColor.enum";
import { SimpleDropdown } from "@/components/components/Dropdown";
import { OptionVariant } from "@/components/components/Dropdown/dropdown.enum";
import { H2 } from "@/components/components/Headings/H";
import Caret from "@/components/icons/Caret";
import { ConversationCategory } from "@/messenger/types/entities/conversation";

import { TDropdownOption } from "../../../../ui-components/src/components/Dropdown/dropdown.type";

export interface MobileChatHeaderProp {
    category: ConversationCategory;
    nextCategories: ConversationCategory[];
    name: string;
    options: TDropdownOption[];
    onSelect: (o: TDropdownOption) => void;
    isInbound: boolean;
}

const MobileChatHeader = ({ category, nextCategories, name, options, onSelect, isInbound }: MobileChatHeaderProp) => {
    const baseClassName = "c-mobile-chat-header";
    const actionableCategories = [ConversationCategory.OPEN, ConversationCategory.TO_ANSWER, ConversationCategory.TO_BE_CONTACTED];

    const { t } = useTranslation();

    const showCaret = actionableCategories.includes(category) ||
    (category === ConversationCategory.CLOSED && nextCategories.includes(ConversationCategory.TO_BE_CONTACTED));

    const renderLabel = () => {
        switch(category) {
            case ConversationCategory.TO_ANSWER:
                return t("To Answer");
            case ConversationCategory.TO_BE_CONTACTED:
                return t("To Contact");
            case ConversationCategory.OPEN:
                return t("Open");
            case ConversationCategory.CLOSED:
                return t("Closed");
            case ConversationCategory.OPT_OUT:
                return t("Opt Out");
            default:
                return "";
        }
    };

    const renderOverride = () => (
        <div className={`${baseClassName}__container`}>
            <div className={`${baseClassName}__title`}>
                <H2 className={`${baseClassName}__contact`} style={{ flex: "1" }}>
                    {name}
                </H2>
                {isInbound && <Badge color={BadgeColor.MAIN}><span>Inbound</span></Badge>}
            </div>
            <div className={`${baseClassName}__category ${baseClassName}__category--${category}`}>
                <span>Status:&nbsp;<span className={`${baseClassName}__category-type`}>{renderLabel()}</span></span>
                {showCaret &&
                    <div style={{ marginLeft: 4 }}>
                        <Caret fill={"currentColor"} />
                    </div>
                }
            </div>
        </div>
    );

    return (
        <SimpleDropdown
            disabled={!showCaret}
            dropdownDefaultRenderClassNames={{}}
            heading={t("Select Status")}
            isMobileView={true}
            onSelect={onSelect}
            options={options}
            optionsVariant={OptionVariant.ACCENTED}
            renderOverride={renderOverride}
            search={false}
            selectedOption={null}
        />
    );
};

export default MobileChatHeader;