import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} fill="currentColor" {...props}>
      <path d="M8.45002 0C6.86777 0 5.32105 0.469192 4.00545 1.34824C2.68986 2.22729 1.66448 3.47672 1.05898 4.93853C0.453478 6.40034 0.295052 8.00887 0.603734 9.56072C0.912415 11.1126 1.67434 12.538 2.79316 13.6569C3.91198 14.7757 5.33745 15.5376 6.88929 15.8463C8.44114 16.155 10.0497 15.9965 11.5115 15.391C12.9733 14.7855 14.2227 13.7602 15.1018 12.4446C15.9808 11.129 16.45 9.58225 16.45 8C16.4477 5.87897 15.6041 3.84547 14.1043 2.34568C12.6045 0.845885 10.571 0.00229405 8.45002 0V0ZM6.11668 8C6.11668 8.26371 6.03848 8.52149 5.89198 8.74076C5.74547 8.96002 5.53723 9.13092 5.29359 9.23184C5.04996 9.33276 4.78187 9.35916 4.52323 9.30771C4.26459 9.25627 4.02701 9.12928 3.84054 8.94281C3.65407 8.75634 3.52708 8.51876 3.47564 8.26012C3.42419 8.00148 3.45059 7.73339 3.55151 7.48976C3.65243 7.24612 3.82332 7.03788 4.04259 6.89137C4.26185 6.74486 4.51964 6.66667 4.78335 6.66667C5.13697 6.66667 5.47611 6.80714 5.72616 7.05719C5.97621 7.30724 6.11668 7.64638 6.11668 8ZM8.45002 6.66667C8.71373 6.66667 8.97151 6.74486 9.19078 6.89137C9.41004 7.03788 9.58094 7.24612 9.68186 7.48976C9.78277 7.73339 9.80918 8.00148 9.75773 8.26012C9.70628 8.51876 9.5793 8.75634 9.39283 8.94281C9.20636 9.12928 8.96878 9.25627 8.71014 9.30771C8.4515 9.35916 8.18341 9.33276 7.93977 9.23184C7.69614 9.13092 7.4879 8.96002 7.34139 8.74076C7.19488 8.52149 7.11668 8.26371 7.11668 8C7.11668 7.64638 7.25716 7.30724 7.50721 7.05719C7.75726 6.80714 8.09639 6.66667 8.45002 6.66667ZM12.1167 9.33333C11.853 9.33333 11.5952 9.25513 11.3759 9.10863C11.1567 8.96212 10.9858 8.75388 10.8848 8.51024C10.7839 8.26661 10.7575 7.99852 10.809 7.73988C10.8604 7.48124 10.9874 7.24366 11.1739 7.05719C11.3603 6.87072 11.5979 6.74373 11.8566 6.69229C12.1152 6.64084 12.3833 6.66724 12.6269 6.76816C12.8706 6.86908 13.0788 7.03997 13.2253 7.25924C13.3718 7.47851 13.45 7.73629 13.45 8C13.45 8.35362 13.3095 8.69276 13.0595 8.94281C12.8094 9.19286 12.4703 9.33333 12.1167 9.33333Z" fill="#757575"/>
    </svg>

  );
}

export default SvgComponent;
