import { createEntityAdapter,createSlice, PayloadAction } from "@reduxjs/toolkit";

import { entitiesSelector } from "@/messenger/selectors";
import { TMessageTemplate } from "@/messenger/types/entities/messageTemplate";
import { NormalizedEntities, TStore } from "@/messenger/types/store.types";


const templatesAdapter = createEntityAdapter<TMessageTemplate>();

export const initialState = templatesAdapter.getInitialState({});

const messageTemplates = createSlice({
    name: "messageTemplates",
    initialState,
    reducers: {
        upsertMessageTemplates(state, action: PayloadAction<NormalizedEntities>) {
            templatesAdapter.upsertMany(state, action.payload.messageTemplates);
        },
    },
});

export const {
    upsertMessageTemplates,
} = messageTemplates.actions;

export const messageTemplatesSelectors = templatesAdapter.getSelectors((state: TStore) => entitiesSelector(state).messageTemplates);

export default messageTemplates.reducer;
