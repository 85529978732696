export enum WidgetType {
    Standard = "Standard",
    Action = "Action",
    Timeline = "Timeline",
    Sendable = "Sendable",
    Module = "Module",
}

export enum WidgetScope {
    User = "user",
    Conversation = "conversation",
}