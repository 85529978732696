import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 73 73" width={73} height={73} fill="currentColor" {...props}>
        <path d="M70.7412 70.2407C71.866 69.1155 72.4979 67.5897 72.4979 65.9987C72.4979 64.4077 71.866 62.8819 70.7412 61.7567L58.9572 49.9727C58.718 49.7328 58.567 49.419 58.5289 49.0823C58.4907 48.7457 58.5677 48.406 58.7472 48.1187C62.8319 41.5484 64.3579 33.7054 63.0347 26.0829C61.7114 18.4603 57.6313 11.5905 51.5711 6.78136C45.511 1.97217 37.8939 -0.440577 30.17 0.00244523C22.4462 0.445467 15.1549 3.71332 9.68437 9.18388C4.21381 14.6544 0.945955 21.9457 0.502934 29.6695C0.0599115 37.3934 2.47266 45.0105 7.28185 51.0706C12.091 57.1308 18.9608 61.2109 26.5834 62.5342C34.2059 63.8574 42.0489 62.3314 48.6192 58.2467C48.9065 58.0672 49.2462 57.9902 49.5828 58.0284C49.9195 58.0665 50.2333 58.2175 50.4732 58.4567L62.2572 70.2407C63.3824 71.3655 64.9082 71.9974 66.4992 71.9974C68.0902 71.9974 69.616 71.3655 70.7412 70.2407ZM31.9992 5.99872C37.0426 5.99872 41.9728 7.49426 46.1662 10.2962C50.3597 13.0982 53.6281 17.0808 55.5581 21.7403C57.4882 26.3998 57.9932 31.527 57.0092 36.4735C56.0253 41.42 53.5967 45.9637 50.0304 49.5299C46.4642 53.0962 41.9205 55.5248 36.974 56.5087C32.0275 57.4927 26.9003 56.9877 22.2408 55.0576C17.5813 53.1276 13.5987 49.8592 10.7967 45.6658C7.99475 41.4723 6.4992 36.5421 6.4992 31.4987C6.50714 24.7381 9.19629 18.2567 13.9768 13.4763C18.7572 8.6958 25.2386 6.00666 31.9992 5.99872Z" fill="#424242"/>
        <path d="M28.457 27.6146C28.597 27.5644 28.7188 27.4738 28.8071 27.3541C28.8955 27.2345 28.9462 27.0913 28.953 26.9428C28.9597 26.7942 28.9222 26.647 28.8451 26.5199C28.768 26.3927 28.6548 26.2914 28.52 26.2286C26.8169 25.4363 25.4351 24.0865 24.6032 22.4023C23.7713 20.7182 23.539 18.8005 23.9448 16.9665C24.3505 15.1324 25.37 13.4917 26.8347 12.3156C28.2994 11.1396 30.1216 10.4985 32 10.4985C33.8784 10.4985 35.7006 11.1396 37.1653 12.3156C38.63 13.4917 39.6495 15.1324 40.0552 16.9665C40.461 18.8005 40.2287 20.7182 39.3968 22.4023C38.5649 24.0865 37.1831 25.4363 35.48 26.2286C35.3452 26.2914 35.232 26.3927 35.1549 26.5199C35.0778 26.647 35.0403 26.7942 35.047 26.9428C35.0538 27.0913 35.1045 27.2345 35.1929 27.3541C35.2812 27.4738 35.403 27.5644 35.543 27.6146C37.5798 28.3447 39.3413 29.6862 40.5866 31.4556C41.8319 33.225 42.5002 35.336 42.5 37.4997V41.9997C42.5 42.3975 42.342 42.779 42.0607 43.0603C41.7794 43.3416 41.3978 43.4996 41 43.4996H38.513C38.3289 43.5 38.1513 43.568 38.0141 43.6908C37.8768 43.8137 37.7896 43.9827 37.769 44.1656L37.169 49.6647C37.1282 50.0332 36.9523 50.3736 36.6753 50.6202C36.3983 50.8667 36.0398 51.0019 35.669 50.9997H28.334C27.9632 51.0019 27.6047 50.8667 27.3277 50.6202C27.0507 50.3736 26.8748 50.0332 26.834 49.6647L26.234 44.1656C26.2134 43.9827 26.1262 43.8137 25.9889 43.6908C25.8517 43.568 25.6741 43.5 25.49 43.4996H23C22.6022 43.4996 22.2206 43.3416 21.9393 43.0603C21.658 42.779 21.5 42.3975 21.5 41.9997V37.4997C21.4998 35.336 22.1681 33.225 23.4134 31.4556C24.6587 29.6862 26.4202 28.3447 28.457 27.6146Z" fill="#424242"/>
    </svg>
  );
}

export default SvgComponent;
