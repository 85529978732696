


import * as React from "react";

function Landscape(props) {
  return (
  <svg viewBox="0 0 24 24" fill={"green"} {...props}>
    <path d="M24,3a3,3,0,0,0-3-3H3A3,3,0,0,0,0,3V21a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3ZM22,21a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1Z"/><path d="M4,8a2,2,0,0,0,2,2H9A3,3,0,1,0,6.238,5.845.252.252,0,0,1,6,6a1.945,1.945,0,0,0-1.134.35A2,2,0,0,0,4,8Z"/><path d="M17.483,13.852a1.9,1.9,0,0,0-3.348-.11l-1.978,3.391a.249.249,0,0,1-.4.043,5.1,5.1,0,0,0-3.486-1.865c-2.366,0-4.051,4.043-4.235,4.5A.5.5,0,0,0,4.5,20.5H20a.5.5,0,0,0,.447-.724Z"/>
    </svg>
  );
}

export default Landscape;


