import "./index.scss";
import "../../locales/i18n";

import cx from "classnames";
import i18next from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import Button from "@/components/components/Button";
import { ButtonColor } from "@/components/components/Button/button.enum";
import icons, { MessageBubble, NoEntry, NoPhone, OfficeFile, Phone, Pin, Shop,Tag } from "@/components/icons";
import { ACCOUNT_DETAILS_CONTENT } from "@/messenger/constants/domID";
import { CHAT } from "@/messenger/constants/routing";
import { incomingsSelectors } from "@/messenger/ducks/entities/incomings";
import { topicsSelectors } from "@/messenger/ducks/entities/topics";
import { setWidgetTab } from "@/messenger/ducks/general/widgetTab.duck";
import { setHideRingCentral } from "@/messenger/integration/RingCentral";
import { formatContactAddress, formatNAPhoneNumber, valueExists } from "@/messenger/models/contact";
import { activeConversationIsOptOutSms, activeConversationIsOptOutVoice, activeConversationSelector } from "@/messenger/selectors";
import { TContact } from "@/messenger/types/entities/conversation";
import { ChannelTypeEnum } from "@/messenger/types/graphql/autogenerated";
import { TStore } from "@/messenger/types/store.types";
import { detectMobile } from "@/messenger/utils/deviceType";
import { getLocaleLabelFromCode } from "@/messenger/utils/helpers";
import { WidgetType } from "@/messenger/widgets/api/events";


interface AccountDetailsProps {
    locked?: boolean;
    contact: TContact
    infoBarRef: any
    isHidden?: boolean
    onClick: () => void
    isPinned: boolean
    selectedWidgetTab: WidgetType
    hasTab: boolean
}

export const ACCOUNT_DETAILS_HEIGHT = 94;

export const widgetSelectorTestId = "widget-selector";
export const businessNameTestId = "business-name";
export const addressNameTestId = "address-name";
export const phoneNumberTestId = "phone-number";
export const contactSmsTestId = "contact-sms";
export const contactOptOutTestId = "contact-optout";

const AccountDetails = ({
    contact,
    infoBarRef,
    isPinned,
    onClick,
    selectedWidgetTab,
    hasTab,
}: AccountDetailsProps) => {

    const isOptOutSms = useSelector(activeConversationIsOptOutSms);
    const isOptOutVoice = useSelector(activeConversationIsOptOutVoice);
    const isOptedOutAllChannels = isOptOutSms && isOptOutVoice;
    const activeConversation = useSelector(activeConversationSelector);
    // const state = useSelector((s: TStore) => s);
    const incomings = useSelector(incomingsSelectors.selectAll);
    const topicId = activeConversation?.topic;
    const topic = useSelector((state: TStore) => topicsSelectors.selectById(state, topicId));
    let name; let description;

    const descriptionRef = useRef(null);
    const [allowTooltip, setAllowTooltip] = useState(false);
    useEffect(() => {
        if (
        !allowTooltip && descriptionRef &&
        descriptionRef?.current?.scrollHeight > descriptionRef?.current?.offsetHeight
        ) {
        setAllowTooltip(true);
        }
    }, [descriptionRef.current, description]);

    const { t } = useTranslation();

    const getLanguage = () => {
        if (contact?.locale) {
            const contactLanguage = getLocaleLabelFromCode(contact.locale, false);
            return i18next.t(contactLanguage);
        }
        return "-";
    };

    const getContactPreferredMethod = () => {
        const caps = contact?.capabilities || [];
        if (caps.includes(ChannelTypeEnum.SmsAndVoice)) {
            return t("SMS or Voice Preferred");
        }

        if (caps.includes(ChannelTypeEnum.Sms) && caps.includes(ChannelTypeEnum.Voice)) {
            return t("SMS or Voice Preferred");
        }

        if (caps.includes(ChannelTypeEnum.Sms)) {
            return t("SMS Preferred");
        }

        if (caps.includes(ChannelTypeEnum.Voice)) {
            return t("Non-SMS capable");
        }

        return null;
    };

    const renderContactBusinessNameSection = () => {
        if (valueExists(contact?.businessName)) {
            return <div className="c-account-details__info-card" data-testid={businessNameTestId}>
                        <span className="c-account-details__info-card-icon">
                            <Shop width={18} height={18} />
                        </span>
                        <span className="c-account-details__info-card-text">{contact.businessName}</span>
                    </div>;
        }
        return null;
    };

    const renderContactAddressSection = () => {
        const formattedAddress = contact?.address ? formatContactAddress(contact.address) : null;
        if (formattedAddress) {
            return <div className="c-account-details__info-card" data-testid={addressNameTestId}>
                        <span className="c-account-details__info-card-icon">
                            <Pin width={18} height={18} />
                        </span>
                        <span className="c-account-details__info-card-text">{formattedAddress}</span>
                    </div>;
        }
        return null;
    };


    const renderContactCallSection = () => {
            if (isOptOutVoice) {
                return <div className="c-account-details__info-card">
                            <span className="c-account-details__info-card-icon">
                                {isOptedOutAllChannels ? <Phone width={18} height={18} /> : <div className={"c-account-details__info-card-icon-dnc"}><NoPhone width={18} height={18} /></div>}
                            </span>
                            <span className="c-account-details__info-card-text">{!isOptedOutAllChannels && `${t("Call opted-out")} | `}{contact?.phoneNumber ? formatNAPhoneNumber(contact.phoneNumber) : "-"}</span>
                        </div>;
            }
            return <div className="c-account-details__info-card" data-testid={phoneNumberTestId}>
                        <span className="c-account-details__info-card-icon">
                            <Phone width={18} height={18} />
                        </span>
                        <a id={"CONTACT_PHONE_NUMBER_LINK"} href={ contact?.phoneNumber.number ? `tel:${ contact.phoneNumber.number }` : null } onClick={() => setHideRingCentral(false)} className="c-account-details__info-card-link">
                            {contact?.phoneNumber.number ? formatNAPhoneNumber(contact.phoneNumber) : "-"}
                        </a>
                    </div>;
            
    };

    const renderTitleDescriptionSection = () => {
        
        if (activeConversation?.isInbound && incomings.length > 0) {
            const contactIncoming = incomings.find(incoming => incoming.outboundNumber === activeConversation.representativePhone);
            name = contactIncoming?.name;
            description = contactIncoming?.description;
        } else {
            
            name = topic?.name;
            description = topic?.description;
        }
        
        if(name && description) {
            return (
                <>
                    <div className="c-account-details__info-card">
                        <span className="c-account-details__info-card-icon">
                            <Tag width={20} height={20} />
                        </span>
                        <span>{i18next.t(name)} </span>
                    </div>
                    <div className="c-account-details__info-card">
                        <span className="c-account-details__info-card-icon">
                            <OfficeFile width={20} height={20} />
                        </span>
                        <span 
                        ref={descriptionRef} 
                        data-tip
                        data-for={"description-tooltip"} 
                        className="c-account-details__info-card-topic-description">
                            {i18next.t(description)} 
                        </span>
                        {allowTooltip && 
                        <ReactTooltip id={"description-tooltip"} place="bottom" effect="solid" className="c-account-details__info-card-topic-description-tooltip">
                            {i18next.t(description)} 
                        </ReactTooltip>}
                    </div>
                </>
            );
        }
        return null;
        
    };

    const renderContactSmsSection = () => {
        if(isOptedOutAllChannels){
            return null;
        }
        return <div className="c-account-details__info-card" data-testid={contactSmsTestId}>
            <span className="c-account-details__info-card-icon">
                <MessageBubble width={18} height={18}/>
            </span>
            <span className="c-account-details__info-card-text">{getContactPreferredMethod()} | {getLanguage()}</span>
        </div>;
    };

    const renderContactOptOutSection = () => {
        const optOutChannels = activeConversation?.optOutChannels;

        if (!optOutChannels || optOutChannels?.length === 0) {
            return null;
        }
        
        let text = "";
        if((isOptOutSms && isOptOutVoice) || optOutChannels.includes(ChannelTypeEnum.SmsAndVoice)){
            text = t("Opted-out across all channels");
        } else if (isOptOutSms) {
            text = t("SMS opted-out");
        } else {
            return null;
        }
        return <div className="c-account-details__info-card" data-testid={contactOptOutTestId}>
            <span className="c-account-details__info-card-icon c-account-details__info-card-icon-dnc">
                <NoEntry width={16} height={16} viewBox="0 0 16 16" />
            </span>
            <span className="c-account-details__info-card-text c-account-details__info-card-text-dnc">{text}</span>
        </div>;
    };

    const dispatch = useDispatch();

    const selectWidgetTab = (selectedTab) => {
        dispatch(setWidgetTab({
            hasTab: true,
            selectedTab,
        }));
    };

    return (
        <div
            ref={infoBarRef}
            id={ACCOUNT_DETAILS_CONTENT}
            className={cx("c-account-details", { "c-account-details__pinned": isPinned })}
        >
            <div className='c-account-details__content'>
                {renderContactBusinessNameSection()}
                {renderContactAddressSection()}
                {renderTitleDescriptionSection()}
                {renderContactCallSection()}
                {renderContactSmsSection()}
                {renderContactOptOutSection()}
                {hasTab && <div data-testid={widgetSelectorTestId} className="c-account-details__widget-selector">
                    <div className="c-account-details__widget-selector__tabs">
                        <button
                            id={"WIDGETS_TAB_BUTTON"}
                            className={`c-account-details__widget-selector__tabs-${selectedWidgetTab === WidgetType.Standard ? "active" : "inactive"}`} 
                            onClick={() => selectWidgetTab(WidgetType.Standard)}
                        >
                            {t("Widgets")}
                        </button>
                        <button
                            id={"TIMELINE_TAB_BUTTON"}
                            className={`c-account-details__widget-selector__tabs-${selectedWidgetTab === WidgetType.Timeline ? "active" : "inactive"}`}
                            onClick={() => selectWidgetTab(WidgetType.Timeline)}
                        >
                            {t("Timeline")}
                        </button>
                    </div>
                </div>}
                
            </div>
            {isPinned && (
                <div className="c-account-details__details-container c-account-details__details-container__pinned">
                    <button
                        id={"HIDE_ACCOUNT_DETAILS_BUTTON"}
                        className="c-account-details__details-container-button"
                        onClick={onClick}
                    >
                        {t("Hide Details")}
                    </button>
                </div>
            )}
        </div>
    );
};

export default AccountDetails;



interface TAccountDetailsHeaderProps extends RouteComponentProps<any> {
    contactName: string
    isHidden: boolean
    isPinned: boolean
    onClick: () => void
}


export const AccountDetailsHeader = withRouter(({ contactName, isHidden, onClick, isPinned, history, location}: TAccountDetailsHeaderProps) => {
    const deviceType = useSelector((state: TStore) => state.general.deviceType);
    const { t } = useTranslation();
    
    const openPreviousSection = (): void => {
        const id = location.pathname.split("/")[2];

        history.push(`/${CHAT}/${id}`);
    };

    return <div className={cx({
        "c-account-details": true,
        "c-account-details--pinned": isHidden && !isPinned,
    })}>
        <div className={"c-account-details__name-wrapper"}>
            {detectMobile(deviceType) && (
                <Button
                    id={"ACCOUNT_DETAILS_BACK_BUTTON"}
                    icon={icons.ANGLE_LEFT}
                    color={ButtonColor.SECONDARY}
                    className={"c-account-details__button-back"}
                    onClick={openPreviousSection}
                    ariaLabel={"Account Details Back Button"}
                >
                    <div className={"c-account-details__button-back-text"}>{t("Chat")}</div>
                </Button>
            )}
            <div
                className={cx("c-account-details__name", {
                    "c-account-details__name--mobile": detectMobile(deviceType),
                })}
            >
                <div>{contactName}</div>
            </div>

            {isHidden && !isPinned && (
                <div className="c-account-details__details-container">
                    <button 
                        id={"SHOW_ACCOUNT_DETAILS_BUTTON"}
                        className="c-account-details__details-container-button" 
                        onClick={onClick}
                    >
                        {t("Show Details")}
                    </button>
                </div>
            )}
        </div>
    </div>;
});