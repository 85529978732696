import React from "react";

function AnalogClock(props) {
    return (<svg viewBox="0 0 16 16" width={16} height={16} {...props} >
                <path d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM11.4667 11.4807C11.3458 11.6086 11.1799 11.6844 11.004 11.6922C10.8282 11.6999 10.6563 11.6388 10.5247 11.522L7.21867 8.49133C7.1501 8.42911 7.09524 8.35329 7.0576 8.26869C7.01995 8.1841 7.00033 8.09259 7 8V4.33333C7 4.15652 7.07024 3.98695 7.19527 3.86193C7.32029 3.7369 7.48986 3.66667 7.66667 3.66667C7.84348 3.66667 8.01305 3.7369 8.13807 3.86193C8.2631 3.98695 8.33334 4.15652 8.33334 4.33333V7.70667L11.4227 10.5387C11.4875 10.5976 11.5401 10.6688 11.5774 10.7481C11.6148 10.8274 11.6361 10.9133 11.6402 11.0009C11.6442 11.0884 11.631 11.1759 11.6013 11.2584C11.5715 11.3408 11.5258 11.4166 11.4667 11.4813V11.4807Z" fill="#FE9B6C"/>
            </svg>
    );
}

export default AnalogClock;
