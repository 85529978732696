import cx from "classnames";
import React from "react";

import Badge from "../Badge";
import { TDropdownOption } from "./dropdown.type";

const baseClassName = "c-dropdown-default-render-selected";

export interface DropdownDefaultRenderSelectedClassNames {
    icon?: string;
    header?: string
}

interface DropdownDefaultRenderSelectedProps {
    heading: string | JSX.Element;
    actionButton?: JSX.Element;
    headerIcons: {up: React.ReactNode, down: React.ReactNode};
    disabled: boolean;
    isOpen: boolean;
    selectedOption?: TDropdownOption;
    classNames?: DropdownDefaultRenderSelectedClassNames;
    headerTextClassName?: string;
    search?: boolean;
}

function DropdownDefaultRenderSelected(props: DropdownDefaultRenderSelectedProps) {
    const { selectedOption, headerIcons, heading, actionButton, classNames, disabled, isOpen, headerTextClassName, search } = props;
    const { icon, header } = classNames;

    const renderHeaderBadge = () => {
        const opt = selectedOption;
        return opt && opt.badge && opt.badge.value > 0 ? (
            <Badge style={{ marginLeft: "16px" }} color={opt.badge.color}>
                {opt.badge.value}
            </Badge>
        ) : null;
    };


    const toggleMenuClassName = cx(
        header,
        `${baseClassName}__header`,
        { [`${baseClassName}__header--disabled`]: disabled },
        { [`${baseClassName}__header--active'`]: isOpen },
    );

    const headerTextClassNames = cx(
        { [`${baseClassName}__header-text`] : true },
        { [`${headerTextClassName}`] : !!headerTextClassName },
    );

    return <div
        className={toggleMenuClassName}
    >
        <div className={cx(
                `${baseClassName}__header-wrapper`,
                {[`${baseClassName}__header-wrapper--action`]: !!actionButton},
            )}>
            <span className={`${baseClassName}__header-container`}>
            {selectedOption && selectedOption.icon && <div className={`${baseClassName}__header-left-icon`}>
                {selectedOption.icon}
            </div>
            }
            <div className={headerTextClassNames}>
                {(selectedOption && !search) ? selectedOption.heading : heading}
                
            </div>
                {renderHeaderBadge()}
            </span>
            <span className={cx(`${baseClassName}__header-icon`, icon)}>
            {isOpen ? headerIcons.up : headerIcons.down}
            </span>
        </div>
        {actionButton && <div className={`${baseClassName}__header-action`}>
            {actionButton}
        </div>}
    </div>;
}


export { DropdownDefaultRenderSelected };