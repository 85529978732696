import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "@/components/components/Alert";

import { removeNotification } from "../ducks/general/notifications.duck";
import { TStore } from "../types/store.types";

const styles: React.CSSProperties = {
    zIndex: 99999,
    width: "calc(100% - 32px)",
    bottom: "16px",
    marginLeft: "16px",
    position: "absolute",
};


const Notifications = () => {
    const notifications = useSelector((s: TStore) => s.general.notifications);
    const dispatch = useDispatch();

    if (notifications.length === 0) {
        return null;
    }

    return  <div style={styles} className="c-notifications">
        {notifications.map(n => (
            <Alert key={n.id} onClose={() => dispatch(removeNotification(n.id))} id={n.id} type={n.type}>
                {n.message}
            </Alert>
        ))}
    </div>;
};

export default Notifications;
