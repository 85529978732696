import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} fill="currentColor" {...props}>
      <path d="M14.5 10.25a.25.25 0 00-.25.25v3c0 .275.5.275.5 0v-3a.25.25 0 00-.25-.25zM19 10.75a.25.25 0 10.25.25.25.25 0 00-.25-.25z" />
      <path d="M23.78 6.81L17.19.22a.747.747 0 00-.53-.22H7.34a.75.75 0 00-.53.22L.22 6.81a.747.747 0 00-.22.53v9.32a.747.747 0 00.22.53l6.59 6.59a.75.75 0 00.53.22h9.32a.747.747 0 00.53-.22l6.59-6.59a.747.747 0 00.22-.53V7.34a.747.747 0 00-.22-.53zM5.95 11.4a2.553 2.553 0 011.3 2.1 1.752 1.752 0 01-1.75 1.75h-1a.75.75 0 010-1.5h1a.25.25 0 00.25-.25c0-.343-.192-.519-.7-.9a2.553 2.553 0 01-1.3-2.1A1.752 1.752 0 015.5 8.75h1a.75.75 0 010 1.5h-1a.25.25 0 00-.25.25c0 .343.192.519.7.9zM11 10.25a.25.25 0 00-.25.25v4a.75.75 0 01-1.5 0v-4a.25.25 0 00-.25-.25.75.75 0 010-1.5h2a.75.75 0 010 1.5zm5.25 3.25a1.75 1.75 0 01-3.5 0v-3a1.75 1.75 0 013.5 0zm2.7-.307a.249.249 0 00-.2.245V14.5a.75.75 0 01-1.5 0v-5a.75.75 0 01.75-.75h.5a2.244 2.244 0 01.45 4.443z" />
    </svg>
  );
}

export default SvgComponent;
