import React, { Fragment, ReactNode } from "react";

import useOutsideClick from "./useOutsideClick";

interface ClickOutsideProps {
    parentRef: any;
    onClickOutsideHandler: () => void;
    children: ReactNode;
}

const ClickOutside = ({ parentRef, onClickOutsideHandler, children }: ClickOutsideProps) => {
    useOutsideClick(parentRef, onClickOutsideHandler);

    return (<Fragment>{children}</Fragment>);
};

export default ClickOutside;