export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  /** Long type */
  Long: any;
  Url: any;
};

export type AddressType = {
  __typename?: "AddressType";
  streetNumber?: Maybe<Scalars["String"]>;
  streetName?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  province?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export enum AdviceAction {
  Modify = "MODIFY",
  Reject = "REJECT",
}

export type AssignRepresentativeToContact = {
  contact: Scalars["ID"];
  representative: Scalars["ID"];
};

export type BasicTopicInfoType = {
  __typename?: "BasicTopicInfoType";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export enum CallOutcome {
  Accepted = "ACCEPTED",
  HangUp = "HANG_UP",
}

export enum ChannelTypeEnum {
  Sms = "SMS",
  Voice = "VOICE",
  SmsAndVoice = "SMS_AND_VOICE",
  /**
   * TODO: Move this to a OptOutChannelType
   * OptOut could not be determined.
   */
  Unknown = "UNKNOWN",
}

export type ComplianceAdvice = {
  __typename?: "ComplianceAdvice";
  complianceRule: Scalars["String"];
  adviceAction: AdviceAction;
  reasonCode: Scalars["String"];
  reasonMessage: Scalars["String"];
  reasonDescription: Scalars["String"];
  content?: Maybe<Scalars["String"]>;
  contentArea?: Maybe<ContentArea>;
};

export type Contact = {
  __typename?: "Contact";
  id: Scalars["ID"];
  externalId: Scalars["String"];
  firstName: Scalars["String"];
  lastName?: Maybe<Scalars["String"]>;
  businessName?: Maybe<Scalars["String"]>;
  locale: Scalars["String"];
  phoneNumber: PhoneNumberType;
  external: ExternalType;
  capabilities: Array<Maybe<ChannelTypeEnum>>;
  address?: Maybe<AddressType>;
};

export type ContentArea = {
  __typename?: "ContentArea";
  start: Scalars["Int"];
  end: Scalars["Int"];
};

export type Conversation = {
  __typename?: "Conversation";
  id: Scalars["ID"];
  category: Scalars["String"];
  nextCategories: Array<Maybe<Scalars["String"]>>;
  contact: Contact;
  externalId: Scalars["String"];
  hydration: HydrationEnum;
  optOutChannels: Array<Maybe<ChannelTypeEnum>>;
  participations?: Maybe<Array<Maybe<Participation>>>;
  topicIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  topic?: Maybe<BasicTopicInfoType>;
  participationMonths?: Maybe<Array<Maybe<Scalars["String"]>>>;
  isInbound?: Maybe<Scalars["Boolean"]>;
  representativePhone?: Maybe<Scalars["String"]>;
};

export enum ConversationCategory {
  ToAnswer = "TO_ANSWER",
  ToBeContacted = "TO_BE_CONTACTED",
  Open = "OPEN",
}

export type ConversationCategoryChangedEvent = ConversationEvent & {
  __typename?: "ConversationCategoryChangedEvent";
  id: Scalars["ID"];
  category?: Maybe<Scalars["String"]>;
  nextCategories?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ConversationContactChangedEvent = ConversationEvent & {
  __typename?: "ConversationContactChangedEvent";
  id: Scalars["ID"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  locale?: Maybe<Scalars["String"]>;
  businessName?: Maybe<Scalars["String"]>;
};

export type ConversationEvent = {};

export type ConversationOptOutChangedEvent = ConversationEvent & {
  __typename?: "ConversationOptOutChangedEvent";
  id: Scalars["ID"];
  optOutChannels: Array<Maybe<ChannelTypeEnum>>;
};

export enum ConversationStatus {
  Open = "OPEN",
  Closed = "CLOSED",
}

/** Cool off period configuration between the chat starter and follow-up message */
export type CoolOffPeriodType = {
  __typename?: "CoolOffPeriodType";
  /**
   * Cool-off period duration between the chat starter and follow-up message.
   * Default value is 3 minutes
   */
  duration: Scalars["Long"];
  /**
   * Cool-off period unit (MINUTES, HOURS, DAYS) between the chat starter and follow-up message.
   * Default value is MINUTES
   */
  unit: TimeUnitEnum;
};

/** Creates a new Contact and assigns it to the current Representative */
export type CreateContactInput = {
  /** A firstName for the Contact */
  firstName: Scalars["String"];
  /** A lastName for the Contact */
  lastName?: Maybe<Scalars["String"]>;
  /** The Contact's phone number */
  phoneNumber: Scalars["String"];
};

export type CustomerConfigType = {
  __typename?: "CustomerConfigType";
  /** Cool-off period configuration for the customer. */
  coolOffPeriod?: Maybe<CoolOffPeriodType>;
  /** Webapp configuration for the customer. */
  webapp?: Maybe<WebappType>;
  /** Inbound configuration for new conversations. */
  inbound?: Maybe<InboundType>;
  /** enable ring central for customer */
  ringCentralEnabled: Scalars["Boolean"];
  /** enable casl link for customer */
  caslLinkEnabled: Scalars["Boolean"];
  /** Denotes the type of TextKit environment in use by the customer (Default is UNKNOWN) */
  textkitEnvironment: TextkitEnvironmentEnum;
  /** Default outbound phone number for new contacts */
  defaultOutboundNumber?: Maybe<Scalars["String"]>;
};

/** Represents Customer Information */
export type CustomerType = {
  __typename?: "CustomerType";
  /** Customer Id for which the configuration is being returned */
  customerId: Scalars["ID"];
  /** Customer's configuration */
  config: CustomerConfigType;
};


export type ExternalType = {
  __typename?: "ExternalType";
  externalId: Scalars["ID"];
  metadata?: Maybe<Scalars["JSON"]>;
};

export type FoundConversation = {
  __typename?: "FoundConversation";
  conversation: Conversation;
  matchedFields: Array<MatchedField>;
};

export enum HydrationEnum {
  Hydrated = "HYDRATED",
  Dehydrated = "DEHYDRATED",
}

export type InboundType = {
  __typename?: "InboundType";
  /** URL for inbound conversations */
  serviceUrl?: Maybe<Scalars["Url"]>;
  /** Default topic ID for inbound conversations */
  topicId?: Maybe<Scalars["ID"]>;
};

export type IncomingType = {
  __typename?: "IncomingType";
  incomingId: Scalars["ID"];
  outboundNumber: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  defaultRepresentative: Scalars["ID"];
};


export type LastTopicParticipationRemovedEvent = ConversationEvent & {
  __typename?: "LastTopicParticipationRemovedEvent";
  participantId: Scalars["ID"];
  previousTopicId: Scalars["ID"];
};


export type ManageRepresentativeIncomingAvailableInput = {
  representativeId: Scalars["ID"];
  availability: Scalars["Boolean"];
};

export type MatchedField = {
  __typename?: "MatchedField";
  path: Array<Scalars["String"]>;
  valueIndexes: ValueIndexes;
};

export type MessageTemplate = {
  __typename?: "MessageTemplate";
  /** Maps to `Topic.topicId`_`Topic.template.name` */
  id?: Maybe<Scalars["ID"]>;
  /** Maps to `Topic.topicId` */
  topicId?: Maybe<Scalars["ID"]>;
  /** Maps to `Topic.template.locale` */
  locale?: Maybe<Scalars["String"]>;
  /** Maps to `Topic.template.text` */
  template: Array<Maybe<Scalars["String"]>>;
  /**
   * Placeholders extracted from `Topic.template.text`
   *
   * ## For example:
   * Given `Topic.template.text`:
   * "Hello {{displayName}}, this is {{representativeName}} from the {{branchName}} branch. How are you doing? Your {{manufacturer}} {{model}} is due for maintenance. Would you like to schedule an appointment for next week?"
   * Then returns:
   * ```
   * {
   * "placeholder": "displayName"
   *     "indices": [0]
   * }
   * ```
   */
  placeholders: Array<Maybe<MessageTemplatePlaceholder>>;
};

export type MessageTemplatePlaceholder = {
  __typename?: "MessageTemplatePlaceholder";
  /** Generated based on template text */
  placeholder: Scalars["String"];
  /** Generated based on template text */
  indices: Array<Maybe<Scalars["Int"]>>;
};

export type Mutation = {
  __typename?: "Mutation";
  startConversation: Scalars["ID"];
  updateConversationStatus: Scalars["Boolean"];
  updateConversationCategory: Scalars["Boolean"];
  saveOptOut: Scalars["Boolean"];
  saveConversationCall: Scalars["Boolean"];
  hydrateConversation: Scalars["Boolean"];
  dehydrateConversation: Scalars["Boolean"];
  assignRepresentativeToContact: Scalars["ID"];
  /** Create a Contact and assign it to the current Representative */
  createContact: Scalars["Boolean"];
  /** Update a Contact */
  updateContact: Scalars["ID"];
  /** Update the availability to receive Incoming conversations */
  saveRepresentativeIncomingAvailable: Scalars["Boolean"];
  /** Allows a manager with  to set Representative availability for assigning of Incoming/Inbound Conversations. Requires `incoming:write` permission. */
  manageRepresentativeIncomingAvailable: Scalars["Boolean"];
  replayEvents?: Maybe<Scalars["String"]>;
};


export type MutationStartConversationArgs = {
  input: StartConversationInput;
};


export type MutationUpdateConversationStatusArgs = {
  input: UpdateConversationStatusInput;
};


export type MutationUpdateConversationCategoryArgs = {
  input: UpdateConversationCategoryInput;
};


export type MutationSaveOptOutArgs = {
  input: SaveOptOutInput;
};


export type MutationSaveConversationCallArgs = {
  input?: Maybe<SaveConversationCallInput>;
};


export type MutationHydrateConversationArgs = {
  conversationId: Scalars["ID"];
};


export type MutationDehydrateConversationArgs = {
  conversationId: Scalars["ID"];
};


export type MutationAssignRepresentativeToContactArgs = {
  input: AssignRepresentativeToContact;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationSaveRepresentativeIncomingAvailableArgs = {
  availability: Scalars["Boolean"];
};


export type MutationManageRepresentativeIncomingAvailableArgs = {
  input?: Maybe<ManageRepresentativeIncomingAvailableInput>;
};


export type MutationReplayEventsArgs = {
  processorName: Scalars["String"];
};

export type ParticipantAddedToConversationEvent = ConversationEvent & {
  __typename?: "ParticipantAddedToConversationEvent";
  conversation: Conversation;
  highlight?: Maybe<Scalars["Boolean"]>;
  fromHydrationEvent?: Maybe<Scalars["Boolean"]>;
};

export type ParticipantRemovedFromConversationEvent = ConversationEvent & {
  __typename?: "ParticipantRemovedFromConversationEvent";
  conversationId: Scalars["ID"];
  fromHydrationEvent?: Maybe<Scalars["Boolean"]>;
};

export type Participation = {
  __typename?: "Participation";
  participantId: Scalars["ID"];
  topic?: Maybe<TopicType>;
  topicId: Scalars["ID"];
  templateVariableMappings?: Maybe<Array<Maybe<TemplateVariableMappingsType>>>;
};

export type PhoneNumberType = {
  __typename?: "PhoneNumberType";
  number: Scalars["String"];
  extension?: Maybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  /** Returns Customer information */
  findCustomer: CustomerType;
  /** In general Campaigns map to Topic */
  topics?: Maybe<Array<Maybe<TopicType>>>;
  participationForConversation?: Maybe<Array<Maybe<Participation>>>;
  findConversations: Array<Maybe<FoundConversation>>;
  findAllConversations: Array<Maybe<Conversation>>;
  isPhoneUnique?: Maybe<UniquePhoneResponse>;
  /** Returns all widgets for user's customerId */
  findAllWidgets: Array<Maybe<WidgetType>>;
  verifyMessage?: Maybe<VerifyMessageResult>;
  findAllRepresentatives?: Maybe<Array<Maybe<RepresentativeType>>>;
  findRepresentativeSettings?: Maybe<RepresentativeType>;
  findAllIncoming?: Maybe<Array<Maybe<IncomingType>>>;
};


export type QueryParticipationForConversationArgs = {
  conversationId?: Maybe<Scalars["ID"]>;
};


export type QueryFindConversationsArgs = {
  searchText?: Maybe<Scalars["String"]>;
};


export type QueryIsPhoneUniqueArgs = {
  phoneNumber: Scalars["String"];
};


export type QueryVerifyMessageArgs = {
  input: VerifyMessageInput;
};

export type RepresentativeIncomingAvailableChangedEvent = ConversationEvent & {
  __typename?: "RepresentativeIncomingAvailableChangedEvent";
  incomingEnabled: Scalars["Boolean"];
};

export type RepresentativeType = {
  __typename?: "RepresentativeType";
  participantId: Scalars["ID"];
  identifier: Scalars["String"];
  locale?: Maybe<Scalars["String"]>;
  incomingEnabled?: Maybe<Scalars["Boolean"]>;
};

export type SaveConversationCallInput = {
  conversationId: Scalars["ID"];
  callStartDatetime: Scalars["DateTime"];
  callDuration: Scalars["Int"];
  callOutcome: CallOutcome;
  callExternalId?: Maybe<Scalars["String"]>;
};

export type SaveOptOutInput = {
  conversationId: Scalars["ID"];
  channelTypes: Array<ChannelTypeEnum>;
};

export type StartConversationInput = {
  campaignMemberId: Scalars["ID"];
  message: Scalars["String"];
  messageTemplateId?: Maybe<Scalars["String"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  subscribeToConversations: ConversationEvent;
};

export type TemplateVariableMappingsType = {
  __typename?: "TemplateVariableMappingsType";
  templateName: Scalars["String"];
  variables?: Maybe<Array<Maybe<TemplateVariableType>>>;
};

export type TemplateVariableType = {
  __typename?: "TemplateVariableType";
  name: Scalars["String"];
  value: Scalars["String"];
};

/** Denotes the type of TextKit environment in use by the customer */
export enum TextkitEnvironmentEnum {
  /** Textkit Standalone (no classic connection) */
  Textkit = "TEXTKIT",
  /** ClassiKit environment (TextKit plus Classic) */
  Classikit = "CLASSIKIT",
  /** Unknown (default, disables features that require either TextKit Standalone or ClassiKit) */
  Unknown = "UNKNOWN",
}

/** Represents time durations at a given unit of granularity. */
export enum TimeUnitEnum {
  /** Represents time unit as MINUTES */
  Minutes = "MINUTES",
  /** Represents time unit as HOURS */
  Hours = "HOURS",
  /** Represents time unit as DAYS */
  Days = "DAYS",
}

export type TopicType = {
  __typename?: "TopicType";
  /** Maps to `Topic.topicId` */
  id?: Maybe<Scalars["ID"]>;
  /** Maps to `Topic.name` */
  name: Scalars["String"];
  /** Maps to `Topic.description` */
  description?: Maybe<Scalars["String"]>;
  /** Maps to `Topic.templates` */
  messageTemplates?: Maybe<Array<Maybe<MessageTemplate>>>;
  /** Number of contacts in the topic */
  contactCount?: Maybe<Scalars["Int"]>;
};

export type UniquePhoneResponse = {
  __typename?: "UniquePhoneResponse";
  isUnique: Scalars["Boolean"];
  existingConversationId?: Maybe<Scalars["ID"]>;
};

/** Updates an existing contact */
export type UpdateContactInput = {
  /** A UUID to uniquely identify the Contact. */
  participantId: Scalars["ID"];
  /** A firstName for the Contact */
  firstName?: Maybe<Scalars["String"]>;
  /** A lastName for the Contact */
  lastName?: Maybe<Scalars["String"]>;
  /**
   * The Locale for the Contact
   * It must comply with [IETF-BCP47](https://www.w3.org/International/articles/bcp47/)
   */
  locale?: Maybe<Scalars["String"]>;
  /** A businessName for the Contact. Max of 200 characters */
  businessName?: Maybe<Scalars["String"]>;
};

export type UpdateConversationCategoryInput = {
  conversationId: Scalars["ID"];
  conversationCategory: ConversationCategory;
};

export type UpdateConversationStatusInput = {
  conversationId: Scalars["ID"];
  conversationStatus: ConversationStatus;
};


export type ValueIndexes = {
  __typename?: "ValueIndexes";
  start: Scalars["Int"];
  end: Scalars["Int"];
};

export type VerifyMessageInput = {
  conversationId: Scalars["ID"];
  content?: Maybe<Scalars["String"]>;
};

export type VerifyMessageResult = {
  __typename?: "VerifyMessageResult";
  originalContent: Scalars["String"];
  content: Scalars["String"];
  complianceAdvices: Array<Maybe<ComplianceAdvice>>;
};

/** Webapp configuration (if any) associated with the TextKit customer */
export type WebappType = {
  __typename?: "WebappType";
  /** URL for the Chat link */
  chatUrl: Scalars["Url"];
  /** URL for the Welcome link */
  welcomeUrl: Scalars["Url"];
  /** URL for the Accounts link */
  accountsUrl: Scalars["Url"];
  /** URL for the Reports link */
  reportsUrl: Scalars["Url"];
  /** Javascript function for the Support link */
  supportJsFunction: Scalars["String"];
  /** URL for the Logout link */
  logoutUrl: Scalars["Url"];
};

export enum WidgetScopeEnum {
  User = "USER",
  Conversation = "CONVERSATION",
}

/** Widget Information */
export type WidgetType = {
  __typename?: "WidgetType";
  /** Widget's name */
  name: Scalars["String"];
  /** Widget's URl */
  url: Scalars["Url"];
  /** Widget's domains */
  domains?: Maybe<Array<Maybe<Scalars["Url"]>>>;
  /** Widget's metadata */
  metadata?: Maybe<Scalars["JSON"]>;
  /** Widget's type (Action, Default, Timeline) */
  type?: Maybe<WidgetTypeEnum>;
  /** Widget's scope (User, Conversation) */
  scope?: Maybe<WidgetScopeEnum>;
  /** Widget enabled */
  isEnabled?: Maybe<Scalars["Boolean"]>;
  /** Widget's path in the URL (for Modules) */
  path?: Maybe<Scalars["String"]>;
};

export enum WidgetTypeEnum {
  Standard = "STANDARD",
  Action = "ACTION",
  Timeline = "TIMELINE",
  Sendable = "SENDABLE",
  Module = "MODULE",
}
