import * as React from "react";

function SvgComponent(props) {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none"  {...props}>
            <path d="M15.5828 0.691193C15.3672 0.53667 15.0991 0.474092 14.8375 0.517225C14.5758 0.560358 14.342 0.705669 14.1874 0.921193L4.83075 13.9665L1.70742 10.8405C1.61457 10.7476 1.50434 10.6739 1.38302 10.6237C1.26169 10.5734 1.13165 10.5475 1.00032 10.5474C0.868987 10.5474 0.738934 10.5732 0.617587 10.6235C0.496239 10.6737 0.385973 10.7473 0.293085 10.8402C0.200196 10.933 0.126505 11.0433 0.0762171 11.1646C0.0259296 11.2859 3.09789e-05 11.416 2.77749e-08 11.5473C-3.09234e-05 11.6786 0.0258064 11.8087 0.0760368 11.93C0.126267 12.0514 0.199907 12.1616 0.292751 12.2545L4.24942 16.2105C4.3533 16.3105 4.47725 16.3872 4.61304 16.4356C4.74884 16.484 4.89337 16.503 5.03706 16.4912C5.18074 16.4795 5.32029 16.4374 5.44644 16.3676C5.57259 16.2978 5.68246 16.202 5.76875 16.0865L15.8128 2.08653C15.9673 1.87099 16.0299 1.6029 15.9867 1.34123C15.9436 1.07956 15.7983 0.845732 15.5828 0.691193Z" fill="#4B64D7"/>
        </svg>

    );
}

export default SvgComponent;
