import { TActionV2 } from "@/messenger/types/action.types";

enum CurrentSelectedFieldNameActionTypes {
    SET = "hummingbird/currentEditableTemplateId/SET",
}

export const currentSelectedFieldNameAC = (id: string) => ({
    type: CurrentSelectedFieldNameActionTypes.SET,
    payload: id,
});

const currentSelectedFieldNameReducer = (state = true, action: TActionV2<CurrentSelectedFieldNameActionTypes, string>) => {
    switch (action.type) {
        case CurrentSelectedFieldNameActionTypes.SET:
            return action.payload;
        default:
            return state;
    }
};

export default currentSelectedFieldNameReducer;
