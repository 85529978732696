import "./index.scss";
import "../../locales/i18n";

import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";

import Button from "@/components/components/Button";
import { ButtonColor } from "@/components/components/Button/button.enum";
import { getTermsAndConditionsTemplate } from "@/messenger/services/data.service";

interface TermsAndConditionsProps {
    onAccept: () => void;
}

const TermsAndConditions: React.FunctionComponent<TermsAndConditionsProps> = (
    termsAndConditionsProps: TermsAndConditionsProps,
) => {
    const [template, setTemplate] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        getTermsAndConditionsTemplate().then(data => {
            setTemplate(data);
        });
    }, []);

    return (
        <div className="c-terms-and-conditions">
            <div className="c-terms-and-conditions__header">
                <div className="c-terms-and-conditions__header-title">{t("Terms and Conditions")}</div>
                <div className="c-terms-and-conditions__header-text">
                    {t("You must read and agree to the Terms and Conditions to use this product.")}
                </div>
            </div>

            {template && (
                <>
                    <div className="c-terms-and-conditions__content-wrapper">
                        <Scrollbars
                            renderTrackVertical={props => (
                                <div {...props} className="c-terms-and-conditions__content-scrollbar-track" />
                            )}
                            renderThumbVertical={props => (
                                <div {...props} className="c-terms-and-conditions__content-scrollbar" />
                            )}
                        >
                            <div
                                className="c-terms-and-conditions__content"
                                dangerouslySetInnerHTML={{ __html: template }}
                            />
                        </Scrollbars>
                    </div>

                    <div className="c-terms-and-conditions__button-wrapper">
                        <Button
                            id={"TERMS_AND_CONDITIONS_ACCEPT_BUTTON"}
                            className="c-terms-and-conditions__button"
                            color={ButtonColor.PRIMARY}
                            onClick={termsAndConditionsProps.onAccept}
                            ariaLabel={t("Terms and Conditions Accept Button")}
                        >
                            {t("I have read and accept the terms")}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default TermsAndConditions;
