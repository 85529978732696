import { TActionV2 } from "@/messenger/types/action.types";
import { TWidgetTab } from "@/messenger/types/entities/conversation";

enum ActionType {
    SET = "hummingbird/conversations/widgetTab/SET",
}

export const setWidgetTab = (widgetTabState) => ({
    type: ActionType.SET,
    payload: widgetTabState,
});

const initialState = {
    hasTab: false,
    selectedTab: null,
};

const widgetTabReducer = (
    state = initialState,
    action: TActionV2<ActionType, TWidgetTab>,
) => {
    switch (action.type) {
        case ActionType.SET:
            return action.payload;
        default:
            return state;
    }
};

export default widgetTabReducer;