import "./index.scss";

import cx from "classnames";
import { ContentState } from "draft-js";
import React, { ReactElement, useEffect, useState } from "react";

import Popover from "@/components/components/Popover";
import { TComplianceAdvice } from "@/messenger/types/message";

interface DraftDecoratorComponentProps {
    blockKey: string;
    children?: ReactElement[];
    contentState: ContentState;
    decoratedText: string;
    dir?: any;
    end: number;
    entityKey?: string;
    offsetKey: string;
    start: number;
    shouldShowPopover: boolean;
}

const ComplianceAdvice = (props: DraftDecoratorComponentProps): ReactElement => {
    const [complianceAdvice, setComplianceAdvice] = useState<TComplianceAdvice[]>(null);
    const [index, setIndex] = useState<number>(0);

    useEffect(() => {
        const entity = props.contentState.getEntity(props.entityKey);
        setComplianceAdvice(entity?.getData());
        setIndex(0);
    }, [props.entityKey]);

    const getMessage = (): ReactElement => {
        return (
            <>
                <div>{!!complianceAdvice && complianceAdvice[index]?.reasonDescription}</div>
            </>
        );
    };

    const onNext = () => {
        const i = index === complianceAdvice?.length - 1 ? 0 : index + 1;
        setIndex(i);
    };

    const onPrev = () => {
        const i = index === 0 ? complianceAdvice?.length - 1 : index - 1;
        setIndex(i);
    };

    const getTitle = (): ReactElement => {
        return (
            !!complianceAdvice && (
                <span>
                    {complianceAdvice.length > 1 && <span>{index + 1}.&nbsp;</span>}
                    {complianceAdvice[index]?.reasonMessage}
                </span>
            )
        );
    };

    const getPhrase = (): string => {
        return !!complianceAdvice && complianceAdvice[index]?.content;
    };

    const renderComplianceAdvice = (): ReactElement => {
        return (
            <span
                data-offset-key={props.offsetKey}
                className={cx("c-compliance-advice", { "c-compliance-advice--with-popover": props.shouldShowPopover })}
            >
                {props.children}
            </span>
        );
    };

    if (props.shouldShowPopover)
        return (
            <Popover
                id="Popover"
                trigger="hover"
                count={complianceAdvice?.length}
                title={getTitle()}
                phrase={getPhrase()}
                message={getMessage()}
                onNext={onNext}
                onPrev={onPrev}
            >
                {renderComplianceAdvice()}
            </Popover>
        );
    return renderComplianceAdvice();
};

export default ComplianceAdvice;
