import {Option} from "prelude-ts";

import {
  AnalyticsConfig,
  ApiConfig,
  AuthConfig,
  Config,
} from "./interface";

const domains = window.location.hostname.split(".");


const customerId = domains[0];

const api: ApiConfig = {
  messenger: {
    rest: () => new URL("http://localhost:8080"),
    graphql: () => new URL("http://localhost:8080/graphql"),
    websocket: () => new URL("ws://localhost:8080/subscriptions"),
  },
  admin: {
    graphql: () => new URL("http://localhost:8082/graphql"),
  },
};

const config: Config = {
    customerId: () => customerId,
    workspaceUrl: (): URL => new URL(`${window.location.protocol}//${window.location.hostname}:3000`),
    workspaceSelectorUrl: (): URL => new URL(`${window.location.protocol}//${window.location.hostname}:3030`),
    authConfig: (): Promise<Option<AuthConfig>> => ((): Promise<Option<AuthConfig>> =>
      fetch(`${api.messenger.rest().origin}/auth0?customer=${customerId}&type=messenger`)
        .then(
          async (response): Promise<Option<AuthConfig>> => {
            const json = await response.json();
            return Option.of({
              domain: json.domain,
              client_id: json.clientId,
              audience: json.audience,
              redirect_uri: new URL(window.location.origin).toString(),
            });
          },
        ).catch(
        async (): Promise<Option<AuthConfig>> => Option.none<AuthConfig>(),
      )
    )(),

    api,

  analyticsConfig:
    (): AnalyticsConfig => ({segmentKey: Option.of("Yi9VNV3kOENvelGf4dTddfp7HRHZz7Hh")}),

};

export default config;