import "../../../locales/i18n";

import i18next from "i18next";
import React from "react";

import Button from "../../Button";
import { ButtonColor, ButtonSize } from "../../Button/button.enum";

function dropdownOptionsFooter({ onClick, className }: { onClick: () => void, className: string }) {
    return (
        <Button
            ariaLabel={i18next.t("Dropdown Close")}
            className={className}
            border={true}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.BIG}
            onClick={onClick}
        >
            {i18next.t("Close")}
        </Button>
    );
}

export default dropdownOptionsFooter;
