import { Conversation , Media , Message } from "@twilio/conversations";


export interface TTwilioMessage extends Message {
    attributes: {
        session: string;
        chatStarter: boolean;
        // eslint-disable-next-line camelcase
        error_message: string;
    };
    media: Media
    conversation: TTwilioChannel
}

export interface TTwilioChannel extends Conversation {
    attributes: {
        // eslint-disable-next-line camelcase
        conversation_id?: string;
    };
}

export enum EChatSDKEvents {
    TOKEN_EXPIRED = "tokenExpired",
    TOKEN_ABOUT_TO_EXPIRE = "tokenAboutToExpire",
    CONNECTION_STATE_CHANGED = "connectionStateChanged",
    CONVERSATION_JOINED = "conversationJoined",
    MESSAGE_ADDED = "messageAdded",
    CONVERSATION_UPDATED = "conversationUpdated",
    CONVERSATION_LEFT = "conversationLeft",
}
