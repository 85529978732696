import { TStore } from "@/messenger/types/store.types";

export const getRequestTrackers = (state: TStore) => state.requests;
export const getRequestTrackerById = (id) => (state: TStore) => {
    const tracker = getRequestTrackers(state)[id]; 

    if (!tracker) {
        return {
            uri: id,
            status: {
                complete: false,
                loading: false,
                sent: false,
            },
        };
    }

    return tracker;
};
