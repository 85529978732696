import "./index.scss";

import cx from "classnames";
import { t } from "i18next";
import React from "react";

import Badge from "@/components/components/Badge";
import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";
import ChatCategoriesDropdown from "@/messenger/components/ChatCategoriesDropdown";
import { ConversationCategory } from "@/messenger/types/entities/conversation";
import { CategoryOption, hiddenCategories } from "@/messenger/utils/getChatCategoryOptions";

interface TChatLabelProps {
    selectedCategory: ConversationCategory
    tertiaryCategory: ConversationCategory
    options: CategoryOption[]
    onClick: (category: ConversationCategory) => void
    isMobile: boolean
    setTertiaryCategory: (category: ConversationCategory) => void
}

const baseClassName = "c-category-cards";

const CategoryCards = ({ options, onClick, selectedCategory, isMobile, tertiaryCategory, setTertiaryCategory }: TChatLabelProps) => {
    const tertiaryCategoryOption = options.find(opt => opt.label === tertiaryCategory);

    const getContainerClassNames = (category: ConversationCategory) => {
        const classNames = cx(
            `${baseClassName}__card`,
            `${baseClassName}__card__${category}`,
            `${baseClassName}__card__label__${category}`,
            {[`${baseClassName}__card__label__selected`]: selectedCategory === category},
            {[`${baseClassName}__card__${category}__selected`]: selectedCategory === category},
            );

        return classNames;
    };

    const getLabelClassNames = (category: ConversationCategory) => {
        const classNames = cx(
            `${baseClassName}__label`,
            {[`${baseClassName}__label__${category}`]: selectedCategory === category},
            {[`${baseClassName}__label__selected`]: selectedCategory === category},
        );

        return classNames;
    };

    const getFixedCategories = (opts: CategoryOption[]) => {
        return opts.filter(o => o.label === ConversationCategory.TO_ANSWER || o.label === ConversationCategory.TO_BE_CONTACTED);
    };

    const getHiddenCategories = (opts: CategoryOption[]) => {
        return opts.filter(o => hiddenCategories.includes(o.label) && o.label !== tertiaryCategory)
        .map(o => ({ ...o, heading: o.badge.value !== 0 ? `${o.heading} (${o.badge.value})` : o.heading , badge: undefined }));
    };

    const runCategoryOnclick = (tdOpt: TDropdownOption) => {
        const categoryOption = options.filter(o => o.label === tdOpt.label)[0];
        setTertiaryCategory(categoryOption.label);
        onClick(categoryOption.label);
    };

    const getBadgeClassNames = (badgeCount: number) => {
        const badgeCountText = `${badgeCount}`;
        const contentLength = badgeCountText.length < 3 ? badgeCountText.length : 3;
        const classNames = cx(
            `${baseClassName}__badge`,
            `${baseClassName}__badge-${contentLength}-char`,
            );
        return classNames;
    };

    return <div className={baseClassName}>
        {getFixedCategories(options).map(o => (
            <button
                className={getContainerClassNames(o.label)}
                key={o.label}
                onClick={() => onClick(o.label)}
                id={`${o.label}_CARD`}
            >
                {o.badge.value > 0 && <Badge className={getBadgeClassNames(o.badge.value)}>
                    <span className={`${baseClassName}__badge-text`}>
                        <strong>{o.badge.value > 999 ? "999+" : o.badge.value}</strong>
                    </span>
                </Badge>}
                <div className={`${baseClassName}__icon`}>{o.icon}</div>
                <div className={getLabelClassNames(o.label)}>{o.heading}</div>
            </button>
        ))}
        <button
            id={`${tertiaryCategory}_CARD`}
            className={getContainerClassNames(tertiaryCategory)}
            key={tertiaryCategory}
            onClick={() => onClick(tertiaryCategory)}
        >
            {tertiaryCategoryOption.badge.value > 0 && (
                <Badge className={getBadgeClassNames(tertiaryCategoryOption.badge.value)}>
                    <span className={`${baseClassName}__badge-text`}>
                        <strong>{tertiaryCategoryOption.badge.value > 999 ? "999+" : tertiaryCategoryOption.badge.value}</strong>
                    </span>
                </Badge>
            )}
            <div className={`${baseClassName}__icon`}>{tertiaryCategoryOption.icon}</div>
            <div className={getLabelClassNames(tertiaryCategory)}>{tertiaryCategoryOption.heading}</div>
        </button>
        <div className={`${baseClassName}__card-container`}>
            <ChatCategoriesDropdown
                isMobileView={isMobile}
                onSelect={(o) => runCategoryOnclick(o)}
                options={getHiddenCategories(options)}
                label={t("Others")}
            />
        </div>
    </div>;

};

export default CategoryCards;
