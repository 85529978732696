import "./index.scss";
import "../../locales/i18n";

import cx from "classnames";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import Badge from "@/components/components/Badge";
import { BadgeColor } from "@/components/components/Badge/badgeColor.enum";
import Button from "@/components/components/Button";
import { ButtonColor } from "@/components/components/Button/button.enum";
import Checkbox from "@/components/components/Checkbox";
import { ArrowLeft,Close  } from "@/components/icons";
import ChatFilterDesktop from "@/messenger/components/ChatFilterDesktop";
import ChatFilterMobile from "@/messenger/components/ChatFilterMobile";
import { TConversationNormalized } from "@/messenger/types/entities/conversation";
import { TTopicNormalized } from "@/messenger/types/entities/topic";

interface TChatFilterContentProps {
    topics: TTopicNormalized[];
    conversations: TConversationNormalized[];
    conversationCount: number,
    selectedCampaignName: string;
    selectedMonth: string;
    isSmsCapable: boolean;
    isMobile: boolean;
    onApplyButtonClick: () => void;
    onCancelButtonClick: () => void;
    onResetButtonClick: () => void;
    onSelectCampaign: (name: string) => void;
    onSelectMonth: (name: string) => void;
    onFilterSmsCapable: (value: boolean) => void;
}


export enum SelectableFilters {
    campaign = "campaign",
    month = "month",
}

export default function ChatFilterContent({
                                              topics,
                                              conversations,
                                              conversationCount,
                                              selectedCampaignName,
                                              selectedMonth,
                                              isSmsCapable,
                                              isMobile,
                                              onCancelButtonClick,
                                              onApplyButtonClick,
                                              onSelectCampaign,
                                              onSelectMonth,
                                              onFilterSmsCapable,
                                              onResetButtonClick,
                                          }: TChatFilterContentProps): ReactElement {

    const [selectedFilter, setSelectedFilter] = useState<SelectableFilters>(null);


    const { t } = useTranslation();

    const onReset = () => {
        onResetButtonClick();
    };

    const onApply = () => {
        onApplyButtonClick();
    };

    const onCancel = () => {
        onCancelButtonClick();
    };

    const onBackButtonClick = () => {
        setSelectedFilter(null);
    };

    const onCampaignFilterHeaderClick = () => {
        setSelectedFilter(SelectableFilters.campaign);
    };

    const onMonthFilterHeaderClick = () => {
        setSelectedFilter(SelectableFilters.month);
    };

    const participantCounts = topics.reduce((acc, campaign, _) => {
        acc[campaign.id] = 0;
        return acc;
      }, {});

    conversations.forEach(convo => {
        convo.topicIds.forEach(topicId => {
            participantCounts[topicId] += 1;
        });
    });

    const campaignsOptions = topics?.map(item => ({
                label: item.name,
                ariaLabel: item.name,
                heading: item.name,
            }))
            .sort((c1, c2) => c1.label.toLowerCase().localeCompare(c2.label.toLowerCase()));

    const renderCampaignFilterDesktop = () => {
        const emptyHeader = t("No Campaigns have been added yet.");
        const emptySubheader = t("To start using Textkit please add campaigns now");
        return (
            <ChatFilterDesktop
                name={t("Campaign")}
                options={campaignsOptions}
                optionsContainerAriaLabel={t("Campaign Dropdown Options")}
                selectedFilterContent={selectedCampaignName}
                onSelectFilter={onSelectCampaign}
                whenEmptyHeader={emptyHeader}
                whenEmptySubheader={emptySubheader}
                headingValue={selectedCampaignName || t("Select Campaign")}
            />
        ) ;
    };

    const monthsLabels = [t("Jan"), t("Feb"), t("Mar"), t("Apr"), t("May"), t("Jun"), t("Jul"), t("Aug"), t("Sep"), t("Oct"), t("Nov"), t("Dec")];
    const monthsHeadings = [t("January"), t("February"), t("March"), t("April"), t("May"), t("June"), t("July"), t("August"), t("September"), t("October"), t("November"), t("December")];

    const getMonthsOptions = () => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const monthsOptions = [];

        for(let i = 0; i < 3; i ++) {
            let monthIndex = currentMonth - i;
            if (monthIndex < 0) {
                monthIndex = 12 + monthIndex;
            }
            monthsOptions.push({label: monthsLabels[monthIndex], heading: monthsHeadings[monthIndex]});
        }

        return monthsOptions;
    };

    const renderMonthFilterDesktop = () => {
        const monthsOptions = getMonthsOptions();

        return (
            <ChatFilterDesktop
                name={t("Month")}
                options={monthsOptions}
                optionsContainerAriaLabel={t("Month Dropdown Options")}
                selectedFilterContent={selectedMonth}
                onSelectFilter={onSelectMonth}
                headingValue={selectedMonth || t("Select Month")}
            />
        ) ;
    };

    const onSmsFilterClick = () => {
        onFilterSmsCapable(!isSmsCapable);
    };

    const renderSmsFilter = () => {
        return (<>
            {(!isMobile || (!selectedFilter)) &&
            <div
                id={"SMS_FILTER"}
                onClick={onSmsFilterClick}
                className={cx("c-chat-filter-content__sms-filter",
                {"c-chat-filter-content__sms-filter--checked": isSmsCapable},
                {"c-chat-filter-content__sms-filter--mobile": isMobile})}>
                    <p>{t("Show only SMS preferred")}</p>
                    <Checkbox onChange={onSmsFilterClick} value={{checked: isSmsCapable}}></Checkbox>

            </div>}

        </>);
    };
    
    const renderBackButton = (): React.ReactElement => {
        return (
            <Button
                id={"FILTER_BACK_BUTTON"}
                icon={<ArrowLeft height={"16px"}/>}
                color={ButtonColor.SECONDARY}
                className={"c-chat-filter-content__back-button"}
                onClick={onBackButtonClick}
                ariaLabel={t("Top Bar Back Button")}
            >
                <div className={"c-chat-filter-content__back-button-text"}>
                    {t("Filters")}
                </div>
            </Button>
        );
    };

    const renderHeaderMobile = () => {
        const getHeaderText = () => {
            if(selectedFilter === SelectableFilters.campaign) {
                return t("Campaigns");
            } if (selectedFilter === SelectableFilters.month) {
                return t("Months");
            }
                return t("Filters");
        };

        return <header className={"c-chat-filter-content__header"}>
            {selectedFilter && renderBackButton()}
            <span className={"c-chat-filter-content__header-text"}>
                <b>{getHeaderText()}</b>
            </span>
            <Button
                id={"FILTER_RESET_BUTTON"}
                className={"c-chat-filter-content__header-button"}
                onClick={onReset}
                color={ButtonColor.SECONDARY}
                ariaLabel={t("Chat Filter Clear Filters Button")}
            >
                {t("Reset")}
            </Button>
        </header>;
    };

    const renderCampaignFilterMobile = () => {
        return <>
            {<ChatFilterMobile
                name={t("Campaigns")}
                options={campaignsOptions}
                selectedFilterContent={selectedCampaignName}
                onSelectFilter={onSelectCampaign}
                onHeaderClick={onCampaignFilterHeaderClick}
                isSelected={selectedFilter === SelectableFilters.campaign}
            />}
        </>
        ;
    };

    const renderMonthFilterMobile = () => {
        const monthsOptions = getMonthsOptions();

        return <>
        { <ChatFilterMobile
                name={t("Months")}
                options={monthsOptions}
                selectedFilterContent={selectedMonth}
                onSelectFilter={onSelectMonth}
                onHeaderClick={onMonthFilterHeaderClick}
                isSelected={selectedFilter === SelectableFilters.month}
            />}

        </>
        ;
    };

    const renderButtonsDesktop = () => {
        return <div className="c-chat-filter-content__buttons">
            <Button
                id={"FILTER_RESET_BUTTON"}
                color={ButtonColor.SECONDARY}
                className="c-chat-filter-content__button"
                onClick={onReset}
                ariaLabel={t("Chat Filter Clear Filters Button")}
            >
                {t("Reset")}
            </Button>
            <Button
                id={"FILTER_APPLY_BUTTON"}
                color={ButtonColor.PRIMARY}
                className="c-chat-filter-content__button-save"
                onClick={onApply}
                ariaLabel={t("Campaigns Filter Apply Button")}
            >
                {t("Show Results")} {<Badge color={BadgeColor.SECONDARY} style={{marginLeft: "6px"}}>{conversationCount}</Badge>}
            </Button>
        </div>;
    };

    const renderButtonsMobile = () => {
        return <div className="c-chat-filter-content__buttons">
            <Button
                id={"FILTER_CANCEL_BUTTON"}
                color={ButtonColor.SECONDARY}
                className="c-chat-filter-content__button"
                onClick={onCancel}
                ariaLabel="Chat Filter Cancel Filters Button"
            >
                {t("Cancel")}
            </Button>
            <Button
                id={"FILTER_APPLY_BUTTON"}
                color={ButtonColor.PRIMARY}
                className="c-chat-filter-content__button-save"
                onClick={onApply}
                ariaLabel={t("Campaigns Filter Apply Button")}
            >
                {t("Show Results")} {<Badge style={{marginLeft: "6px"}}>{conversationCount}</Badge>}
            </Button>
        </div>;
    };

    const renderDesktop = () => {
        return (
            <div className="c-chat-filter-desktop" role="form">
                <div className="c-chat-filter-desktop__header-container">
                    <div className="e-heading e-heading--h1 c-chat-filter-desktop__header-text">
                        {t("Filter By")}
                    </div>
                    <button id={"FILTER_CLOSE_BUTTON"} aria-label="Close" className="c-chat-filter-desktop__mobile-close" onClick={onCancel} data-testid="chat-filter-cancel-button">
                        <Close width={16} height={16} />
                    </button>
                </div>
                {renderCampaignFilterDesktop()}
                {renderMonthFilterDesktop()}
                {renderSmsFilter()}
                {renderButtonsDesktop()}
            </div>
        );
    };

    const renderMobile = () => {
        return (
            <>
            {renderHeaderMobile()}
            {renderCampaignFilterMobile()}
            {renderMonthFilterMobile()}
            {renderSmsFilter()}
            {renderButtonsMobile()}
            </>
        );
    };

    return (
        <div className="c-chat-filter-content">
            {isMobile ? renderMobile():  renderDesktop()}
        </div>
    );
}
