import { TMessageDirection } from "@/messenger/components/MessageBubble";
import { TComputed, TLocalMessage, TMessage, TMessageDeliveryStatus } from "@/messenger/types/message";
import { TTwilioMessage } from "@/messenger/types/twilioChatSDK.types";
import { getFileDataUrl, makeCancelable } from "@/messenger/utils/helpers";


export interface TCreateMessage {
    twilio?: TTwilioMessage
    local?: TLocalMessage
    computed?: TComputed
}
export const createMessage = ({ twilio, local, computed = {} }: TCreateMessage): TMessage => {
    return {
        twilio,
        local,
        computed,
    };
};

export const shouldShowMessageBubble = ({ twilio, local }: TMessage) => {
    if (twilio?.body || twilio?.media || local?.body || local?.media) {
        return true;
    }
    return false;
};

export const getMessage = ({ twilio, local }: TMessage) => {
    if (twilio) {
        return twilio;
    }
    return local;
};

export const getMessageKey = ({ twilio, local }: TMessage) => {
    if (twilio) {
        return twilio.sid;
    }
    return local.sid;
};

export const getBody = ({ twilio, local }: TMessage) => {
    if (twilio) {
        return twilio.body;
    }
    return local.body;
};

export const isNotDelivered = ({ local }: TMessage) => {
    return local?.status === TMessageDeliveryStatus.NOT_DELIVERED;
};

export const getMessageTimestamp = ({ twilio }: TMessage) => {
    return twilio?.dateUpdated;
};

export const getMediaUrl = (message: TMessage): Promise<string> => {
    if (isNotDelivered(message)) {
        return getFileDataUrl(message.local.media);
    }

    return message.twilio?.media?.getContentTemporaryUrl();
};

export const messageHasMedia = ({ twilio, local }: TMessage) => {
    return !!twilio?.media || !!local?.media;
};

export const getMessageDirection = async (message: TTwilioMessage): Promise<TMessageDirection> => {
    const member = await message.getParticipant();
    return member.type === "sms" ? TMessageDirection.INCOMING : TMessageDirection.OUTGOING;
};

export const getMediaContentType = ({ twilio, local }: TMessage) => {
    if (twilio) {
        return twilio.media?.contentType ?? "image/jpeg";
    }
    return local.media.type;

};

export const getMessageStatus = ({ local }: TMessage) => {
    return local?.status || TMessageDeliveryStatus.DELIVERED;
};

export const getMessageIndex = ({ twilio, local }: TMessage) => {
    if (twilio) {
        return twilio.index;
    }

    return local?.index;
};

export const getMediaGenerator = function* (message: TMessage, onSuccess: (url: string) => void, onFailure?: (boolean) => void) {
    if (messageHasMedia(message)) {
        try {
            const mediaUrl: string = yield getMediaUrl(message);

            return yield onSuccess(mediaUrl);
        } catch(e) {
            if (!e.isCanceled) {
                console.log(e);
                if (onFailure) {
                    return yield onFailure(true);
                }
            }
            if (onFailure) {
                return yield onFailure(false);
            }
            return null;
        };
    }

    return null;
};

export const getMedia = (message: TMessage, onSuccess: (url: string) => void, onFailure?: (boolean) => void) => {
    if (messageHasMedia(message)) {
        const mediaUrlPromise = makeCancelable(getMediaUrl(message));

        return mediaUrlPromise.promise
            .then((mediaUrl: string) => {
                return onSuccess(mediaUrl);
            })
            .catch(e => {
                if (!e.isCanceled) {
                    console.log(e);
                    if (onFailure) {
                        return onFailure(true);
                    }
                }
                if (onFailure) {
                    return onFailure(false);
                }
                return null;
            });
    }

    return null;
};