import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { entitiesSelector } from "@/messenger/selectors";
import { TWidget } from "@/messenger/types/entities/conversation";
import { NormalizedEntities, TStore  } from "@/messenger/types/store.types";


export const widgetsAdapter = createEntityAdapter<TWidget>({
    selectId: (widget) => widget.name,
});

export const initialState = widgetsAdapter.getInitialState({});


const widgets = createSlice({
    name: "widgets",
    initialState,
    reducers: {
        upsertWidgets(state, action: PayloadAction<NormalizedEntities>) {
            widgetsAdapter.upsertMany(state, action.payload.widgets);
        },
    },
});

export const widgetsSelectors = widgetsAdapter.getSelectors((state: TStore) => entitiesSelector(state).widgets);

export const {
    upsertWidgets,
} = widgets.actions;



export default widgets.reducer;
