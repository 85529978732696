import { DraftEntityMutability } from "draft-js";

export enum EntityType {
    PLACEHOLDER = "PLACEHOLDER",
    COMPLIANCE_ADVICE = "COMPLIANCE_ADVICE",
    COMPLIANCE_ADVICE_WITH_PLACEHOLDER = "COMPLIANCE_ADVICE_WITH_PLACEHOLDER",
}

interface TMutabilityType {
    [key: string]: DraftEntityMutability
}

export const MutabilityType: TMutabilityType = {
    COMPLIANCE_ADVICE: "MUTABLE",
    MAPPED_FIELD: "IMMUTABLE",
};

export enum EditorContentBlockKeys {
    INPUT = "inputBlockKey",
    PLACEHOLDER = "placeholderBlockKey",
    MESSAGE_TEMPLATE_CONTENT = "messageTemplateContentBlockKey",
}

export const COMPLIANCE_ADVICE_KEY = "complianceAdvice";
export const COMPLIANCE_ADVICE_WITH_PLACEHOLDER_KEY = "complianceAdviceWithPlaceholder";
export const PLACEHOLDER_KEY = "placeholder";

export const VERIFY_MESSAGE_DEBOUNCE_TIME_VALUE = 500;
export const VERIFY_MESSAGE_POLLING_TIME_VALUE = 2000;
