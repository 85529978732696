import { convertFromRaw, EditorState, SelectionState } from "draft-js";

import { TComplianceAdvice, TDraftMessage } from "@/messenger/types/message";

import { EditorContentBlockKeys } from "../constants/compliance.settings";
import {
    categorizeComplianceAdviceByType,
    composeComplianceAdvice,
    createEditorStateWithComplianceAdvice,
    getEditorRawContentWithComplianceAdvice,
} from "./compliance.helper";

export const addComplianceToDraftMessage = (complianceAdvice: TComplianceAdvice[], draftMessage: TDraftMessage) => {
    const { content } = categorizeComplianceAdviceByType(complianceAdvice);
    
    const composedComplianceAdvice = composeComplianceAdvice(content);
    const dm: TDraftMessage = {
        ...draftMessage,
        metadata: {...draftMessage.metadata, complianceAdvice: composedComplianceAdvice},
    };
    
    return dm;
};

export const composeEditorContentFromDraftMessage = (
    dm: TDraftMessage,
    selectionState: SelectionState,
): EditorState => {
    const rawContent = getEditorRawContentWithComplianceAdvice(dm.body, dm?.metadata?.complianceAdvice);
    const content = convertFromRaw(rawContent);
    const state = createEditorStateWithComplianceAdvice(content);
    return EditorState.forceSelection(state, selectionState);
};

export const getEmptySelectionState = (): SelectionState => {
    return new SelectionState({
        anchorKey: EditorContentBlockKeys.INPUT,
        focusKey: EditorContentBlockKeys.INPUT,
        hasFocus: true,
    });
};
export const getEmptyDraftMessage = (): TDraftMessage => {
    return {
        body: "",
        metadata: {
            linkPreview: null,
            mediaFile: null,
            complianceAdvice: [],
        },
    };
};
