import gql from "graphql-tag";


export const getTopics = gql`
    query topics {
        topics {
            id
            name
            description
            messageTemplates {
                id
                topicId
                locale
                template
                placeholders {
                    placeholder
                    indices
                }
            }
            contactCount
        }
    }
`;


export const findCustomer = gql`
    query FindCustomer {
        findCustomer {
            customerId
            config {
                coolOffPeriod {
                    unit
                    duration
                }
                webapp {
                    chatUrl
                    welcomeUrl
                    accountsUrl
                    reportsUrl
                    supportJsFunction
                    logoutUrl
                }
                ringCentralEnabled
                caslLinkEnabled
                textkitEnvironment
            }
        }
    }
`;


export const findAllWidgets = gql`
    query findAllWidgets {
      findAllWidgets {
          name
          url
          scope
          type
          domains
          path
          isEnabled
        }
    }
`;

export const getParticipationsForConversation = gql`
    query participationForConversation ($conversationId: ID!) {
        participationForConversation(conversationId: $conversationId) {
            participantId
            topic {
                id
                name
                description
            }
            topicId
            templateVariableMappings {
                templateName
                variables {
                    name
                    value
                }
            }
        }
    }
`;



export const FindAllConversations = gql`
    query FindAllConversations {
        findAllConversations {
            id
            category
            nextCategories
            externalId
            contact {
                id
                firstName
                lastName
                businessName
                address {
                  streetNumber
                  streetName
                  city
                  province
                  country
                  postalCode
                }
                externalId
                locale
                phoneNumber {
                    number
                    extension
                }
                capabilities
                external {
                    externalId
                    metadata
                }
            }
            topic {
                id
                name
            }
            topicIds
            optOutChannels
            participationMonths
            hydration
            isInbound
            representativePhone
        }
    }
`;

export const VERIFY_MESSAGE = gql`
    query VerifyMessage($VerifyMessageInput: VerifyMessageInput!) {
        verifyMessage(input: $VerifyMessageInput) {
            originalContent
            content
            complianceAdvices {
                complianceRule
                adviceAction
                reasonCode
                reasonMessage
                reasonDescription
                content
                contentArea {
                    start
                    end
                }
            }
        }
    }
`;

export const findAllRepresentatives = gql`
    query findAllRepresentatives {
        findAllRepresentatives {
          participantId
          identifier
          locale
          incomingEnabled
        }
    }
`;

export const findRepresentativeSettings = gql`
    query findRepresentativeSettings {
        findRepresentativeSettings {
        participantId
        identifier
        locale
        incomingEnabled
        incomingVisible
        }
    }
`;

export const findAllIncoming = gql`
    query findAllIncoming {
        findAllIncoming{
            incomingId
            outboundNumber
            name
            description
            defaultRepresentative
        }
        
    }
`;

export const isPhoneUnique = gql`
    query isPhoneUnique($phoneNumber: String!) {
        isPhoneUnique(phoneNumber: $phoneNumber) {
            isUnique
            existingConversationId
        }
    }
`;