import { call, select, take } from "redux-saga/effects";

import { TStore } from "@/messenger/types/store.types";



export function* onSelectorDataExists(selector: (s: TStore) => any, actionType: string)  {
    let value = yield select(selector);

    while (!value) {
        yield take(actionType);

        value = yield select(selector);
    }

    return value;
}

export function* onSelectorDataChange(selector, callback, takePattern = "*") {
    while (true) {
        const originalValue = yield select(selector);
        let nextValue = originalValue;

        while (originalValue === nextValue) {
            yield take(takePattern);

            nextValue = yield select(selector);
        }

        yield call(callback, nextValue);
    }
} 

export function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }