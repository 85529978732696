import { createEntityAdapter, createSlice,PayloadAction  } from "@reduxjs/toolkit";

import { localStorageKeys } from "@/messenger/app.settings";
import { TMessageTemplate } from "@/messenger/types/entities/messageTemplate";
import { TemplateVariableMappingsType } from "@/messenger/types/graphql/autogenerated";
import { TMessage } from "@/messenger/types/message";
import {  TChatStarterState, TChatState, TStore  } from "@/messenger/types/store.types";
import { getFromLocalStorage, setToLocalStorage } from "@/messenger/utils/helpers";
import { getEmptyDraftMessage } from "@/messenger/utils/MessageInput.helper";
import { calculatePlaceholdersOffsets } from "@/messenger/utils/messageTemplates.helper";

import { getUserInterfaceSelector } from "../selectors/userInterface";

export const chatsAdapter = createEntityAdapter<TChatState>();

export const initialState = chatsAdapter.getInitialState({});

const createChatStarter: () => TChatStarterState = () => ({
    messageTemplates: [],
    selectedMessageTemplateId: null,
    selectedPlaceholder: null,
    inputComplianceAdvice: [],
});

const DEFAULT_CHAT_STATE: TChatState = {
    id: null,
    localMessages: [],
    messages: [],
    unreadMessagesCount: 0,
    category: null,
    contact: null,
    loadingMoreMessages: false,
    paginator: null,
    error: null, 
    draftMessage: getEmptyDraftMessage(),
    scrollPosition: null,
    messageFirstBatchLoaded: false,
    chatStarter: createChatStarter(),
    hydration: null,
    hasPriority: false,
};

interface TChatStateOptions extends Partial<TChatState> {
    id: string
}

export const createChatState = (opts: TChatStateOptions) => {
    return {...DEFAULT_CHAT_STATE, ...opts};
};

interface TUpdateChatMessagePayload {
    id: string
    message: TMessage
}

interface TUpdateUnreadPayload {
    conversationId: string,
}

interface TUpdateChatMessageTemplatePayload {
    id: string
    messageTemplate: TMessageTemplate
}

interface TUpdateChatStarterPayload {
    id: string
    chatStarter: Partial<TChatStarterState>
}

const chats = createSlice({
    name: "chats",
    initialState,
    reducers: {
        upsertOneChat(state, action: PayloadAction<Partial<TChatState>>) {
            chatsAdapter.upsertOne(state as any, action.payload as any);
        },
        upsertManyChats(state, action: PayloadAction<TChatState[]>) {
            chatsAdapter.upsertMany(state as any, action.payload);
        },
        removeChat(state, action: PayloadAction<any>) {
            chatsAdapter.removeOne(state as any, action.payload.conversationId);
        },
        updateChatStarter(state, action: PayloadAction<TUpdateChatStarterPayload>) {
            const chatState = state.entities[action.payload.id];

            Object.keys(action.payload.chatStarter).forEach((key) => {
                const value = action.payload.chatStarter[key]; 
                chatState.chatStarter[key] = action.payload.chatStarter[key];

                if (key === "selectedMessageTemplateId" && value === null) {
                    chatState.chatStarter.selectedPlaceholder = null;
                    chatState.chatStarter.inputComplianceAdvice = [];
                }

                if (key === "selectedPlaceholder" && value === null) {
                    chatState.chatStarter.inputComplianceAdvice = [];
                }
            });
        },
        updateChatMessageTemplate(state, action: PayloadAction<TUpdateChatMessageTemplatePayload>) {
            const chatState = state.entities[action.payload.id];

            const index = chatState.chatStarter.messageTemplates.findIndex(m => m.id === action.payload.messageTemplate.id);
            
            chatState.chatStarter.messageTemplates[index] = action.payload.messageTemplate;
        },
        updateChatMessage(state, action: PayloadAction<TUpdateChatMessagePayload>) {
            const chatState = state.entities[action.payload.id];
            const index = chatState.messages.findIndex(m => m.twilio.sid === action.payload.message.twilio.sid); 
            chatState.messages[index] = action.payload.message;
        },
        incrementUnreadMessageCount(state, action: PayloadAction<TUpdateUnreadPayload>) {
            const chatState = state.entities[action.payload.conversationId];
            const newCount = (chatState.unreadMessagesCount || 0) +1;
            chatsAdapter.updateOne(state as any, {
                id: action.payload.conversationId,
                changes:{
                    unreadMessagesCount: newCount,
                },
            });
            const unreadObj = getFromLocalStorage(localStorageKeys.unreadMessages) || {};
            unreadObj[action.payload.conversationId] = newCount;
            setToLocalStorage(localStorageKeys.unreadMessages, unreadObj);
        },
        resetUnreadMessageCount(state, action: PayloadAction<TUpdateUnreadPayload>) {
            chatsAdapter.updateOne(state as any, {
                id: action.payload.conversationId,
                changes:{
                    unreadMessagesCount: 0,
                },
            });
            const unreadObj = getFromLocalStorage(localStorageKeys.unreadMessages) || {};
            delete unreadObj[action.payload.conversationId];
            setToLocalStorage(localStorageKeys.unreadMessages, unreadObj);
        },
    },
});


export const chatsSelectors = chatsAdapter.getSelectors((state: TStore) => getUserInterfaceSelector(state).chats);

export const {
    incrementUnreadMessageCount,
    removeChat,
    resetUnreadMessageCount,
    updateChatMessage,
    updateChatMessageTemplate,
    updateChatStarter,
    upsertManyChats,
    upsertOneChat,
} = chats.actions;

export const initializeChatStarter = (id: string, templateVariableMappings: TemplateVariableMappingsType[], messageTemplates: TMessageTemplate[], topicId: string) => (dispatch) => {
    const chatStarter = createChatStarter();

    chatStarter.messageTemplates = messageTemplates
        .map(messageTemplate => {
            const placeholders = messageTemplate.placeholders.map(placeholder => {
                const templateVariableMapping = templateVariableMappings.find(tvm => `${topicId}_${tvm.templateName}` === messageTemplate.id);
                const templateVariable = templateVariableMapping?.variables.find(ffc => ffc.name === placeholder.placeholder);
                if (templateVariable)
                    return { ...placeholder, value: templateVariable.value, draftValue: templateVariable.value };
                return placeholder;
            });

            const formattedMessageTemplate = calculatePlaceholdersOffsets({ ...messageTemplate, placeholders, complianceAdvice: [] });

            return formattedMessageTemplate;
        });

    dispatch(upsertOneChat({
        id,
        chatStarter,
    }));
};



export default chats.reducer;
