import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, NormalizedCacheObject, split } from "@apollo/client";
import {setContext } from "@apollo/client/link/context";
import {WebSocketLink} from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import React from "react";

import { config } from "@/messenger/config";

import { useAuth0 } from "./Auth0Provider";

const AuthorizedApolloProvider = ({ children }: { children: React.ReactNode}) => {
    const { token, isReady } = useAuth0();

    if (!isReady) {
        return null;
    }
    
    const cache = new InMemoryCache({
        typePolicies: {
            Conversation: {
                keyFields: ["id"],
            },
        },
    });

    const httpLink = createHttpLink({
        uri: config.api.messenger.graphql().href,
    });

    const authLink = setContext(async (_, { headers }) => {
        return {
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
            },
        };
    });

    const webSocketLink = new WebSocketLink({
        uri: config.api.messenger.websocket().href,
        options: {
          reconnect: true,
          connectionParams: {
              Authorization: `Bearer ${token}`,
          },
        },
      });


    /**
     *The split function takes three parameters:
    * A function that's called for each operation to execute
    * The Link to use for an operation if the function returns a "truthy" value
    * The Link to use for an operation if the function returns a "falsy" value
    */
    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
            );
        },
        webSocketLink,
        authLink.concat(httpLink),
    );

    const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
        cache,
        link: splitLink,
    });
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
