import "./index.scss";
import "../../locales/i18n";

import React from "react";
import { useTranslation } from "react-i18next";

import { Landline } from "@/components/icons";

const ContactNotSMS = () => {
    const { t } = useTranslation();

    return (
        <div className="c-contact-not-sms">
            <div className="c-contact-not-sms__info-card">
                <span className="c-contact-not-sms__info-card-icon">
                    <Landline width={16} height={14} />
                </span>
                <span>{t("This is a landline without SMS capability")}</span>
            </div>
        </div>
    );
};

export default ContactNotSMS;
