import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { loadActiveConversationAC } from "@/messenger/actions/conversation";
import { activeConversationIdSelector, activeConversationSelector, conversationById, entitiesSelector, nextConversationIdSelector } from "@/messenger/selectors";
import { ConversationCategory, TConversationNormalized } from "@/messenger/types/entities/conversation";
import { ChannelTypeEnum, HydrationEnum } from "@/messenger/types/graphql/autogenerated";
import { NormalizedEntities, TStore  } from "@/messenger/types/store.types";

import { chatsSelectors, createChatState, removeChat, upsertOneChat } from "../userInterface/chats";
import { contactsSelectors, upsertContacts, upsertOneContact } from "./contacts";
import { removeParticipationByConversation as removeParticipationByContactId } from "./participations";
import { upsertTopics } from "./topics";


export enum ConversationActions {
    SET_CONVERSATIONS = "hummingbird/conversations/SET",
    SET_CONVERSATION_CATEGORY = "hummingbird/conversations/SET_CATEGORY",
    SET_CONVERSATION_DNC = "hummingbird/conversations/SET_CONVERSATION_DNC",
}

const conversationsAdapter = createEntityAdapter<TConversationNormalized>();

export const initialState = conversationsAdapter.getInitialState({});

interface TSetOptOutsPayload {
    conversationId: string,
    optOutChannels: ChannelTypeEnum[],
}

const conversations = createSlice({
    name: "conversations",
    initialState,
    reducers: {
        upsertConversations(state, action: PayloadAction<NormalizedEntities>) {
            conversationsAdapter.upsertMany(state, action.payload.conversations);
        },
        removeConversation(state, action: PayloadAction<any>) {
            conversationsAdapter.removeOne(state, action.payload.conversationId);
        },
        setOptOutChannels(state, action: PayloadAction<TSetOptOutsPayload>){
            conversationsAdapter.updateOne(state, {
                id: action.payload.conversationId,
                changes:{
                    optOutChannels: action.payload.optOutChannels,
                },
            });
        },
        upsertOneConversation(state, action: PayloadAction<any>) {
            conversationsAdapter.upsertOne(state, action.payload);
        },
    },
});

export const conversationsSelectors = conversationsAdapter.getSelectors((state: TStore) => entitiesSelector(state).conversations);

export const {
    upsertConversations,
    setOptOutChannels,
    removeConversation,
    upsertOneConversation,
} = conversations.actions;

export const setConversationCategoryAC = (eventPayload) => (dispatch, getState: () => TStore) => {
    const { id, category, nextCategories } = eventPayload;
    const state = getState();
    const convo = conversationById(id)(state);
    // const contact = contactsSelectors.selectById(state, convo.contact);

    // Reinsert the conversation at the bottom of the list of entities
    dispatch(removeConversation({ conversationId: id }));
    setTimeout( () => {
        dispatch(upsertOneConversation({...convo, id: convo.id, category, nextCategories}));
    }, 0);
    
    const activeConvo = activeConversationSelector(state);

    if (activeConvo && id === activeConvo.id) {
        if (category === ConversationCategory.OPEN && convo?.category === ConversationCategory.TO_BE_CONTACTED) {
            dispatch(loadActiveConversationAC());
        } else if (category === ConversationCategory.TO_BE_CONTACTED) {            
            // show message templates
        } 
    }
};

export const setConversationOptOutAC = (eventPayload) => (dispatch, getState: () => TStore) => {
    const { id, optOutChannels } = eventPayload;
    const state = getState();
    const convo = conversationById(id)(state);

    const updateOptOut = () => {
        dispatch(setOptOutChannels({
            conversationId: id,
            optOutChannels,
        }));
    };

    if (convo) {
        updateOptOut();
    } else {
        setTimeout( () => {
            updateOptOut();
        }, 10);
    }
    
};

export const removeConversationAC = (eventPayload, removeConversationCallback: (id1: string, id2: string, id3: string) => void) => (dispatch, getState: () => TStore) => {
    const { conversationId } = eventPayload;
    
    const state = getState();

    const contactId = conversationsSelectors.selectById(state, conversationId).contact;
    dispatch(removeParticipationByContactId({ contactId }));
    dispatch(removeConversation({ conversationId }));
    dispatch(removeChat({ conversationId }));


    
    const activeConversationId = activeConversationIdSelector(state);
    const nextConversationId = nextConversationIdSelector(state);
    
    removeConversationCallback(conversationId, activeConversationId, nextConversationId);
};

export const detatchConversationAC = (eventPayload, removeConversationCallback: (id1: string, id2: string, id3: string) => void) => (dispatch, getState: () => TStore) => {
    const { conversationId } = eventPayload;
    const state = getState();
    const convo = conversationById(conversationId)(state);

    dispatch(upsertOneConversation({id: convo.id, hydration: HydrationEnum.Dehydrated}));
    dispatch(upsertOneChat({...createChatState({ id: conversationId, hydration: HydrationEnum.Dehydrated })}));

    const activeConversationId = activeConversationIdSelector(state);
    const nextConversationId = nextConversationIdSelector(state);
    removeConversationCallback(conversationId, activeConversationId, nextConversationId);
};

export const addConversationAC = (normalizedConversationData, conversationId, isHighlight, addHighlightConversationCallback) => (dispatch, getState: () => TStore) => {
    const state = getState();
    const currentChatState = chatsSelectors.selectById(state, conversationId);
    const chatState = {...createChatState({ id: conversationId, unreadMessagesCount: 0 }), ...currentChatState};
    dispatch(upsertOneChat({...chatState, hasPriority: true}));
    dispatch(upsertOneConversation(normalizedConversationData.entities.conversations[conversationId]));
    dispatch(upsertTopics(normalizedConversationData.entities));
    dispatch(upsertContacts(normalizedConversationData.entities));

    if (isHighlight) {
        addHighlightConversationCallback(conversationId, normalizedConversationData.entities.conversations[conversationId].category);
    }
};

export const updateConversationContactAC = (eventPayload) => (dispatch, getState: () => TStore) => {
    const state = getState();
    const { id, firstName, lastName, locale } = eventPayload;
    
    const contact = contactsSelectors.selectById(state, id);

    const updatedContact = {...contact, firstName, lastName, locale};

    dispatch(upsertOneContact(updatedContact));
};

export default conversations.reducer;
