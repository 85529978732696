import { SET_REQUEST_TRACKER } from "@/messenger/actions/requestTracker";
import { RequestTracker } from "@/messenger/models/requestTracker";
import { TAction } from "@/messenger/types/action.types";

type ACTIONS = typeof SET_REQUEST_TRACKER

const setRequestTrackerReducer = (state = {}, action: TAction<ACTIONS, RequestTracker>) => {
    switch (action.type) {
        case SET_REQUEST_TRACKER:
            return {...state, [action.data.uri]: action.data};
        default:
            return state;
    }
};

export default setRequestTrackerReducer;
