import "./index.scss";
import "../../locales/i18n";

import React from "react";
import { useTranslation } from "react-i18next";

import { AnalogClock } from "@/components/icons";

interface TMessageCooldownProps {
    cooldownUntilDatetime: string
}

const MessageCooldown = ({ cooldownUntilDatetime }: TMessageCooldownProps) => {
    const { t } = useTranslation();
    const cooldownMessage = t("For results wait until", {cooldownUntilDatetime});

    return (
        <div className="c-message-cooldown">
            <div className="c-message-cooldown__info-card">
                <span className="c-message-cooldown__info-card-icon">
                    <AnalogClock width={16} height={16} />
                </span>
                <span className="c-message-cooldown__info-card-text" dangerouslySetInnerHTML={{__html:cooldownMessage}}></span>
            </div>
        </div>
    );
};

export default MessageCooldown;
