import React from "react";

function NoSMS(props) {
    return (<svg viewBox="0 0 16 16" width={16} height={16} fill="currentColor" {...props} >
                <path fillRule="evenodd" clipRule="evenodd" d="M11.5676 1.61752C10.5015 1.15395 9.28759 0.891357 8.00065 0.891357C3.77332 0.891357 0.333984 3.72402 0.333984 7.20736C0.333984 8.70223 0.967408 10.0774 2.0247 11.1603L11.5676 1.61752ZM5.4217 13.1564L14.5928 3.98541C15.2751 4.92928 15.6673 6.03129 15.6673 7.20736C15.6543 8.04457 15.458 8.86877 15.0923 9.62202C14.7267 10.3753 14.2004 11.0393 13.5506 11.5674L15.084 14.6254C15.115 14.6873 15.126 14.7573 15.1155 14.8258C15.1049 14.8942 15.0732 14.9577 15.0249 15.0073C14.9766 15.057 14.914 15.0903 14.8459 15.1028C14.7777 15.1152 14.7074 15.1061 14.6446 15.0767L10.5453 13.1687C9.71804 13.4063 8.86137 13.5259 8.00065 13.524C7.09628 13.524 6.22798 13.3943 5.4217 13.1564Z" fill="#B2B2B2"/>
                <path d="M14.8045 1.20007C14.6795 1.07509 14.51 1.00488 14.3332 1.00488C14.1564 1.00488 13.9869 1.07509 13.8619 1.20007L1.19519 13.8667C1.07021 13.9918 1 14.1613 1 14.3381C1 14.5148 1.07021 14.6844 1.19519 14.8094C1.32106 14.9328 1.49028 15.0019 1.66652 15.0019C1.84277 15.0019 2.01199 14.9328 2.13786 14.8094L14.8045 2.14274C14.9295 2.01772 14.9997 1.84818 14.9997 1.67141C14.9997 1.49463 14.9295 1.32509 14.8045 1.20007Z" fill="#B2B2B2"/>
            </svg>
    );
}

export default NoSMS;
