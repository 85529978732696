import { TAction } from "@/messenger/types/action.types";
import { EDeviceType } from "@/messenger/utils/deviceType";

enum ActionTypes {
    SET = "hummingbird/general/deviceType/SET",
}

export const setDeviceType = (data: EDeviceType) => ({
    type: ActionTypes.SET,
    data,
});

const deviceTypeReducer = (state: EDeviceType = null, action: TAction<ActionTypes, EDeviceType>) => {
    switch (action.type) {
        case ActionTypes.SET:
            return action.data;
        default:
            return state;
    }
};

export default deviceTypeReducer;
