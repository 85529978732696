import { LOCATION_CHANGE, replace, RouterState } from "connected-react-router";
import { Action } from "redux";
import { call, put, select, take, takeEvery } from "redux-saga/effects";

import { LOAD_ACTIVE_CONVERSATION } from "@/messenger/actions/conversation";
import { SET_REQUEST_TRACKER } from "@/messenger/actions/requestTracker";
import { RequestURIs } from "@/messenger/constants/requests";
import { CHAT, CONVERSATION_NOT_FOUND, MODULE, MODULE_NOT_FOUND } from "@/messenger/constants/routing";
import { addOneTwilioChannel, twilioChannelsSelectors } from "@/messenger/ducks/entities/twilioChannels";
import { setFilterIsOpenAC, setFilterIsSmsCapableAC, setSelectedCampaignNameAC, setSelectedMonthAC } from "@/messenger/ducks/general/conversationRefinementsReducer.duck";
import { SET_LOADING_STATUS } from "@/messenger/ducks/sdk/loadingStatus.duck";
import { getRequestTrackerById } from "@/messenger/ducks/selectors/requestTrackers";
import { resetUnreadMessageCount, upsertOneChat } from "@/messenger/ducks/userInterface/chats";
import { widgetStateSelectorsCustom } from "@/messenger/ducks/userInterface/widgetsState";
import { RequestTracker } from "@/messenger/models/requestTracker";
import { handleGetFirstMessageBatch } from "@/messenger/sagas/message";
import { onSelectorDataExists } from "@/messenger/sagas/util";
import { activeConversationIdSelector, activeModuleIdSelector, conversationRefinementsSelector, conversationsSelector } from "@/messenger/selectors";
import { segment } from "@/messenger/services/analytics.service";
import { TConversationNormalized } from "@/messenger/types/entities/conversation";
import { SDKLoadingStatus, TStore } from "@/messenger/types/store.types";
import { isSeedEnv } from "@/messenger/utils/helpers";

import { hideShownSendable } from "./widget";

interface TLocationChangeAction extends Action {
    type: typeof LOCATION_CHANGE;
    payload: RouterState;
}

function* loadActiveConversation() {
    // waits for conversation subscription to exist before continuing
    const conversationId = yield select(activeConversationIdSelector);
    const channelSelector = (s: TStore) => twilioChannelsSelectors.selectById(s, conversationId);
    yield call(onSelectorDataExists, channelSelector, addOneTwilioChannel.toString());

    yield put(resetUnreadMessageCount({
        conversationId,
    }));

    yield put(upsertOneChat({
        id: conversationId,
        error: null,
    }));

    yield call(handleGetFirstMessageBatch,conversationId);
}


function* handleLocationChange(action: TLocationChangeAction) {
    segment.page();

    // waits for campaigns and conversations to be initialized before continuing
    let getConversationsRequest: RequestTracker = yield select(getRequestTrackerById(RequestURIs.GET_CONVERSATIONS));

    while (!getConversationsRequest.status.complete) {
        yield take(SET_REQUEST_TRACKER);

        getConversationsRequest = yield select(getRequestTrackerById(RequestURIs.GET_CONVERSATIONS));
    }

    yield call(hideShownSendable);

    const { payload: { location: { pathname } }} = action;

    if (pathname.startsWith(`/${CHAT}`) && !pathname.includes(CONVERSATION_NOT_FOUND) && pathname.length > `/${CHAT}/`.length) {
        const conversationId = pathname.split("/")[2];

        const conversations: TConversationNormalized[] = yield select(conversationsSelector);
        const conversation = conversations.find(c => c.id === conversationId);

        if (!conversation) {
            yield put(replace(`/${CHAT}/${CONVERSATION_NOT_FOUND}/${conversationId}`));

            return null;
        }
        let isLoaded = yield select((s: TStore) => s.sdk.loadingStatus);
        while (isLoaded !== SDKLoadingStatus.LOADED_CONVERSATIONS) {
            yield take(SET_LOADING_STATUS);

            isLoaded = yield select((s: TStore) => s.sdk.loadingStatus);
        }

        const channelSelector = (s: TStore) => twilioChannelsSelectors.selectById(s, conversationId);
        const twilioChannel = yield call(onSelectorDataExists, channelSelector, addOneTwilioChannel.toString());

        if (!twilioChannel && !isSeedEnv){
            yield put(replace(`/${CHAT}/${CONVERSATION_NOT_FOUND}/${conversationId}`));

            return null;
        }
        yield call(loadActiveConversation);
    }

    if (pathname.startsWith(`/${MODULE}`) && !pathname.includes(MODULE_NOT_FOUND) && pathname.length > `/${MODULE}/`.length) {
        const activeModuleId = yield select (activeModuleIdSelector);
        const moduleWidgetStates = yield select (widgetStateSelectorsCustom.selectAllByTypeModule);
        const hasActiveModule = moduleWidgetStates.some(w => w.path === activeModuleId);
        if (activeModuleId && !hasActiveModule) {
            yield put(replace(`/${MODULE}/${MODULE_NOT_FOUND}/${activeModuleId}`));
        }
    }

    // cancels any unsaved chat filter changes
    const {prevSelectedCampaignName, selectedCampaignName, prevSelectedMonth, selectedMonth, isOpen, isSmsCapable, prevIsSmsCapable} = yield select(conversationRefinementsSelector);

    if (prevSelectedCampaignName && prevSelectedCampaignName !== selectedCampaignName) {
        yield put(setSelectedCampaignNameAC(prevSelectedCampaignName));
    }
    if (prevSelectedMonth && prevSelectedMonth !== selectedMonth) {
        yield put(setSelectedMonthAC(prevSelectedMonth));
    }
    if (isSmsCapable !== prevIsSmsCapable) {
        yield put(setFilterIsSmsCapableAC(prevIsSmsCapable));
    }
    if (isOpen) {
        yield put(setFilterIsOpenAC(false));
    }

    return null;
}


export default function* routingSaga() {
    yield takeEvery(LOCATION_CHANGE, handleLocationChange);
    yield takeEvery(LOAD_ACTIVE_CONVERSATION, loadActiveConversation);
}

