import React from "react";

function ArrowDown(props) {
    return (
        <svg width="16" height="6" viewBox="0 0 16 6" fill="none">
            <path d="M15 1L8.32978 4.91964C8.28649 4.94512 8.23509 4.96533 8.1785 4.97911C8.12191 4.9929 8.06126 5 8 5C7.93874 5 7.87809 4.9929 7.8215 4.97911C7.76491 4.96533 7.71351 4.94512 7.67022 4.91964L1 1" stroke="#4B64D7"/>
        </svg>

    );
}

export default ArrowDown;
