import { put, select, takeEvery } from "redux-saga/effects";

import { LISTEN_FOR_CONVO_LOAD_COMPLETE } from "@/messenger/actions/twilioChannel";
import { setSelectedCategory } from "@/messenger/ducks/general/selectedCategory.duck";
import { activeConversationSelector, conversationsHasToAnswerSelector } from "@/messenger/selectors";
import { ConversationCategory } from "@/messenger/types/entities/conversation";

function* selectCategory() {
    const hasToAnswer = yield select(conversationsHasToAnswerSelector);
    const activeConversation = yield select(activeConversationSelector);

    if (activeConversation) {
        yield put(setSelectedCategory(activeConversation.category));
    } else if (hasToAnswer) {
        yield put(setSelectedCategory(ConversationCategory.TO_ANSWER));
    } else {
        yield put(setSelectedCategory(ConversationCategory.TO_BE_CONTACTED));
    }
}

export default function* twilioChannel() {
    yield takeEvery(LISTEN_FOR_CONVO_LOAD_COMPLETE, selectCategory);
}


