import React from "react";

function AddCircle(props) {
    return (<svg viewBox="0 0 16 16" width={16} height={16} {...props} >
            <path d="M8 16.1667C9.51632 16.1667 10.9986 15.717 12.2594 14.8746C13.5201 14.0322 14.5028 12.8348 15.0831 11.4339C15.6633 10.033 15.8152 8.49151 15.5193 7.00432C15.2235 5.51713 14.4933 4.15106 13.4211 3.07886C12.3489 2.00666 10.9829 1.27648 9.49569 0.98066C8.0085 0.68484 6.46699 0.836665 5.06609 1.41694C3.66519 1.99721 2.46782 2.97986 1.62539 4.24064C0.78297 5.50142 0.333328 6.98369 0.333328 8.50001C0.335622 10.5326 1.14409 12.4813 2.58138 13.9186C4.01866 15.3559 5.96737 16.1644 8 16.1667ZM3.99999 7.83335H7.16666C7.21086 7.83335 7.25326 7.81579 7.28451 7.78453C7.31577 7.75327 7.33333 7.71088 7.33333 7.66668V4.50001C7.33333 4.3232 7.40357 4.15363 7.52859 4.02861C7.65361 3.90358 7.82318 3.83335 8 3.83335C8.17681 3.83335 8.34638 3.90358 8.4714 4.02861C8.59642 4.15363 8.66666 4.3232 8.66666 4.50001V7.66668C8.66666 7.71088 8.68422 7.75327 8.71548 7.78453C8.74673 7.81579 8.78913 7.83335 8.83333 7.83335H12C12.1768 7.83335 12.3464 7.90358 12.4714 8.02861C12.5964 8.15363 12.6667 8.3232 12.6667 8.50001C12.6667 8.67682 12.5964 8.84639 12.4714 8.97142C12.3464 9.09644 12.1768 9.16668 12 9.16668H8.83333C8.78913 9.16668 8.74673 9.18424 8.71548 9.2155C8.68422 9.24675 8.66666 9.28914 8.66666 9.33335V12.5C8.66666 12.6768 8.59642 12.8464 8.4714 12.9714C8.34638 13.0964 8.17681 13.1667 8 13.1667C7.82318 13.1667 7.65361 13.0964 7.52859 12.9714C7.40357 12.8464 7.33333 12.6768 7.33333 12.5V9.33335C7.33333 9.28914 7.31577 9.24675 7.28451 9.2155C7.25326 9.18424 7.21086 9.16668 7.16666 9.16668H3.99999C3.82318 9.16668 3.65361 9.09644 3.52859 8.97142C3.40357 8.84639 3.33333 8.67682 3.33333 8.50001C3.33333 8.3232 3.40357 8.15363 3.52859 8.02861C3.65361 7.90358 3.82318 7.83335 3.99999 7.83335Z" fill="#757575"/>
        </svg>
    );
}

export default AddCircle;
