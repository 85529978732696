import { TAction } from "@/messenger/types/action.types";
import { ConversationCategory } from "@/messenger/types/entities/conversation";

enum ActionType {
    SET = "hummingbird/conversations/tertiaryCategory/SET",
}

export const setTertiaryCategory = (category: ConversationCategory) => ({
    type: ActionType.SET,
    data: category,
});

const initialState = ConversationCategory.OPEN;

const tertiaryCategoryReducer = (
    state: ConversationCategory = initialState,
    action: TAction<ActionType, ConversationCategory>,
) => {
    switch (action.type) {
        case ActionType.SET:
            return action.data;
        default:
            return state;
    }
};

export default tertiaryCategoryReducer;
