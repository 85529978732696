import "./index.scss";
import "../../locales/i18n";
import "react-medium-image-zoom/dist/styles.css";

import cx from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";

import VideoView from "@/messenger/components/VideoView";
import { TMessageDeliveryStatus } from "@/messenger/types/message";

interface TMediaViewProps {
    url: string;
    contentType: string;
    status: TMessageDeliveryStatus;
    hasFailed: boolean;
    onLoad?: () => void;
    onZoom: () => void;
}

export const MediaView = (props: TMediaViewProps) => {
    const [isZoomed, setIsZoomed] = useState(false);
    const { onZoom } = props;

    const { t } = useTranslation();

    const handleZoomChange = () => {
        setIsZoomed(!isZoomed);
        onZoom();
    };

    if (props.hasFailed) return <p>{t("(Failed to download media!)")}</p>;
    if (!props.url) return <p>{t("Downloading…")}</p>;
    if (props.contentType.includes("image")) {
        return (
            <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
                <img
                alt={""}
                onLoad={props.onLoad}
                className={cx("c-image-view", `c-image-view--${props.status}`)}
                src={props.url}
            />
            </ControlledZoom>
        );
    }
        
    if (props.contentType.includes("video"))
        return <VideoView status={props.status} onLoad={props.onLoad} url={props.url} />;
    return null;
};

export default MediaView;
