import "./index.scss";
import "../../locales/i18n";

import React from "react";
import { useTranslation } from "react-i18next";

import Conversations from "@/messenger/illustrations/Conversations";


interface TNoChatSelectedProps {
    style?: React.CSSProperties
}
const NoChatSelected = ({ style }: TNoChatSelectedProps) => {
    const { t } = useTranslation();

    return (
        <div className="no-chat-selected__message-wrapper" style={style}>
            <Conversations width={445} />
            <span className="no-chat-selected__message-title">{t("Welcome to your conversations")}</span>
            <span className="no-chat-selected__message">{t("Keep track of all your customers conversations in one place")}</span>
        </div>
    );
};

export default NoChatSelected;