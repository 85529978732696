import "./index.scss";
import "../../locales/i18n";

import React from "react";
import { useTranslation } from "react-i18next";

import Button from "@/components/components/Button";
import { ButtonColor, ButtonSize } from "@/components/components/Button/button.enum";
import { SimpleDropdown } from "@/components/components/Dropdown";
import { OptionVariant } from "@/components/components/Dropdown/dropdown.enum";
import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";

const baseClassName = "c-action-bar";

interface TActionsBarProps {
    onSelect: (o: TDropdownOption) => void;
    options: TDropdownOption<string>[]
}

export enum ActionsBarOptions {
    DNC = "dnc",
    EditAContact = "editAContact",
}

export const ACTIONS_BAR_HEIGHT = 48;

const ActionsBar: React.FC<TActionsBarProps> = (props: TActionsBarProps) => {
    const { options, onSelect } = props;
    const { t } = useTranslation();

    const renderOverride = (isOpened: boolean, toggleMenu: () => void) => <Button
        className={`${baseClassName}__toggle`}
        size={ButtonSize.SMALL}
        color={isOpened ? ButtonColor.SECONDARY : ButtonColor.PRIMARY}
        ariaLabel={t("Actions Bar Button")}
        onClick={toggleMenu}
        id={"ACTIONS_BUTTON"}
    > {isOpened ? t("Close") : t("Actions")} </Button>;

    return <SimpleDropdown
        className={`${baseClassName}`}
        onSelect={onSelect}
        options={options}
        optionsContainerAriaLabel={t("Actions Bar Options")}
        optionsVariant={OptionVariant.ACCENTED}
        optionsContainerClassNames={{ container: `${baseClassName}__container`, menu: `${baseClassName}__dropdown-menu` }}
        renderOverride={renderOverride}
    />;
};

export default ActionsBar;