import * as React from "react";

function LogoutTextKit(props) {
    return (
        <svg viewBox="0 0 24 24" {...props}>
            <path d="M13.5,17.5a1,1,0,0,0-1,1V21a.5.5,0,0,1-.5.5H2.5A.5.5,0,0,1,2,21V3a.5.5,0,0,1,.5-.5H12a.5.5,0,0,1,.5.5V5.5a1,1,0,1,0,2,0v-3a2,2,0,0,0-2-2H2a2,2,0,0,0-2,2v19a2,2,0,0,0,2,2H12.5a2,2,0,0,0,2-2v-3A1,1,0,0,0,13.5,17.5Z" />
            <path d="M23.555,11.669l-6-4A1,1,0,0,0,16,8.5V11H7a1.5,1.5,0,1,0,0,3h9v2.5a1.014,1.014,0,0,0,1,1,1,1,0,0,0,.555-.168l6-4a1,1,0,0,0,0-1.664Z"/>
        </svg>
    );
}

export default LogoutTextKit;
