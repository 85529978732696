import "./index.scss";

import cx from "classnames";
import React, { ReactElement } from "react";

interface TMessageTemplateValidateHintProps {
    hintText: string;
}

export default function MessageTemplateValidateHint({
    hintText,
}: TMessageTemplateValidateHintProps): ReactElement {
    return (
        <div
            className={cx("с-message-template-validate-hint__text")}
        >
            {hintText}
        </div>
    );
}
