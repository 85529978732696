import "./index.scss";
import "../../locales/i18n";

import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { TDropdownOption } from "@/components/components/Dropdown/dropdown.type";
import { AddCircle, NoEntry } from "@/components/icons";
import ActionsBar, { ActionsBarOptions } from "@/messenger/components/ActionsBar";
import { RIGHT_SIDEBAR_STANDIN } from "@/messenger/constants/domID";
import AccountDetails, { AccountDetailsHeader } from "@/messenger/containers/AccountDetails";
import ActionsBarDialog from "@/messenger/containers/ActionsBarDialog";
import TextkitEditAContact from "@/messenger/containers/TextkitEditAContact";
import WidgetContainer from "@/messenger/containers/WidgetContainer";
import { contactsSelectors } from "@/messenger/ducks/entities/contacts";
import { widgetTabSelector } from "@/messenger/ducks/selectors/general";
import { widgetStateSelectorsCustom } from "@/messenger/ducks/userInterface/widgetsState";
import { usePosition } from "@/messenger/hooks/usePosition";
import { formatContactName } from "@/messenger/models/contact";
import { activeConversationSelector, textkitEnvSelector } from "@/messenger/selectors";
import { TextkitEnvironmentEnum } from "@/messenger/types/graphql/autogenerated";
import { TStore } from "@/messenger/types/store.types";
import { widgetContainerClient } from "@/messenger/widgets";
import { WidgetType } from "@/messenger/widgets/api/events";
import { WidgetState } from "@/messenger/widgets/types";


interface TRightSidebarProps {
    conversationId: string
    contactId: string
}

const RightSidebarBase = ({ conversationId, contactId }: TRightSidebarProps) => {
    const hasTab = useSelector(widgetTabSelector)?.hasTab; 
    const selectedWidgetTab = useSelector(widgetTabSelector)?.selectedTab;

    const { t } = useTranslation();

    const [isHidden, setIsHidden] = useState(false);
    const [isPinned, setIsPinned] = useState(false);
    const [actionsBarDialogOpened, setActionsBarDialogOpened] = useState(false);
    const [textkitEditAContactOpened, setTextkitEditAContactOpened] = useState(false);

    const { pathname } = useLocation();

    const [dimensions, updatePosition] = usePosition(RIGHT_SIDEBAR_STANDIN);

    const isTextkitStandalone = useSelector(textkitEnvSelector) === TextkitEnvironmentEnum.Textkit;

    useEffect(() => {
        updatePosition();
        setTextkitEditAContactOpened(false);
    }, [pathname]);

    const contact = useSelector((s: TStore) => contactsSelectors.selectById(s, contactId));
    const actionWidgetState = useSelector(widgetStateSelectorsCustom.selectAllByTypeAction);
    const standardWidgetStates = useSelector(widgetStateSelectorsCustom.selectAllByTypeStandard);

    const onSelectAction = (o: TDropdownOption) => {
        if (o.label === ActionsBarOptions.DNC) {
            setActionsBarDialogOpened(true);
        } else if (o.label === ActionsBarOptions.EditAContact) {
            setTextkitEditAContactOpened(true);
        } else {
            const widgetName = o.label;

            widgetContainerClient.setState(widgetName, WidgetState.isOpen, true);
        }
    };

    const closeActionsBarDialog = () => {
        setActionsBarDialogOpened(false);
    };

    const onTextkitEditAContactClose = () => {
        setTextkitEditAContactOpened(false);
    };

    const infoBarRef = useRef<HTMLDivElement>(null);

    const handleScroll = e => {
        const isScrolledOutOfView = e.currentTarget.scrollTop > infoBarRef?.current?.clientHeight;
        const scrollTop = e.currentTarget.scrollTop === 0;

        if (isScrolledOutOfView && !isHidden) {
            setIsHidden(true);
        } else if (!isScrolledOutOfView && isHidden) {
            setIsHidden(false);
        } else if (scrollTop) {
            setIsPinned(false);
        }
    };
    
    const onTogglePin = () => {
        setIsPinned(!isPinned);
    };

    const getActionWidgetLabels = () => {
        return actionWidgetState
            .filter(s => s.label?.length > 0)
            .map(s => ({
                label: s.id,
                heading: s.label,
                icon: <AddCircle />,
            }));
    };
    
    const options: TDropdownOption[] = 
    isTextkitStandalone ? [
        { label: ActionsBarOptions.DNC, heading: t("Mark as Opt-out"), icon: <span className="c-right-sidebar__actions--dnc"><NoEntry width={16} height={16} viewBox="0 0 16 16"/></span>},
        { label: ActionsBarOptions.EditAContact, heading: t("Edit Contact"), icon: <span><AddCircle /></span>},
        ...getActionWidgetLabels(),
    ] : [
        { label: ActionsBarOptions.DNC, heading: t("Mark as Opt-out"), icon: <span className="c-right-sidebar__actions--dnc"><NoEntry width={16} height={16} viewBox="0 0 16 16"/></span>},
        ...getActionWidgetLabels(),
    ];



    const rightSidebarClassnames = cx(
        "c-right-sidebar", 
        { "c-right-sidebar--backdrop": actionsBarDialogOpened },
    );
    return (
        <div 
            className={rightSidebarClassnames} 
            role="application" 
            aria-label={t("Right Sidebar")} 
            id="rightSidebar" 
            style={dimensions}
        >
            <AccountDetailsHeader 
                contactName={formatContactName(contact) || "-"} 
                isHidden={isHidden} 
                onClick={onTogglePin} 
                isPinned={isPinned} 
            />
            <Scrollbars
                onScroll={handleScroll}
                renderView={(props: any) => <div {...props} 
                    style={{ 
                        ...props.style, 
                        overflowX: "hidden", 
                        ...{overflowY: standardWidgetStates.length > 0 && selectedWidgetTab !== WidgetType.Timeline ? "scroll" : "hidden"},
                    }} 
                />}
            >
                <AccountDetails
                    contact={contact}
                    infoBarRef={infoBarRef}
                    isHidden={isHidden}
                    isPinned={isPinned}
                    onClick={onTogglePin}
                    selectedWidgetTab={selectedWidgetTab}
                    hasTab={hasTab}
                />
                {window.name === "" && <WidgetContainer 
                    conversationId={conversationId}
                    /> 
                }
            </Scrollbars>
            {actionsBarDialogOpened && <ActionsBarDialog onClose={closeActionsBarDialog} />}
            
            {textkitEditAContactOpened && <TextkitEditAContact contact={contact} onClose={onTextkitEditAContactClose} />}
            {!actionsBarDialogOpened && <ActionsBar onSelect={onSelectAction} options={options} />}
        </div>
    );
};

const RightSidebar = React.memo(RightSidebarBase, (_: TRightSidebarProps, { conversationId, contactId }: TRightSidebarProps) => !conversationId || !contactId);

export const RightSideBarWrapper = () => {
    const activeConversation = useSelector(activeConversationSelector);

    return <RightSidebar 
        conversationId={activeConversation?.id} 
        contactId={activeConversation?.contact} 
        />;
};

export default RightSideBarWrapper; 
