import { useEffect } from "react";
import { useSelector } from "react-redux";

import { customerSelector } from "@/messenger/ducks/entities/customers";
import { representativeSettingsSelectors } from "@/messenger/ducks/entities/representativeSettings";
import { useAuth0 } from "@/messenger/providers/Auth0Provider";
import { segment } from "@/messenger/services/analytics.service";

export interface SegmentUser {
    email: string,
    name: string,
    displayName: string,
    UUID: string,
    customerId: string
}

const Analytics = () => {
    const representativeSettings = useSelector(representativeSettingsSelectors.selectAll);
    const customers = useSelector(customerSelector.selectAll);
    const auth0 = useAuth0();
    const auth0Client = auth0?.user;
    
    useEffect(() => {
        if (representativeSettings?.length > 0 && customers?.length > 0 && auth0Client) {
            const user: SegmentUser = {
                email: auth0Client.email,
                name: auth0Client.name,
                displayName: auth0Client.name,
                UUID: representativeSettings[0].participantId,
                customerId: customers[0].customerId,
            };
            segment.identify(user);
        }
    }, [representativeSettings, auth0Client]);

    return null;        
};

export default Analytics;