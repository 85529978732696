export enum BadgeColor {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    MAIN = "main",
    MAIN_L4 = "main-l4",
    MAIN_L6 = "main-l6",
    INFO = "info",
    SUCCESS = "success",
    WARNING = "warning",
    WARNING_L1 = "warning-l1",
    ERROR = "error",
    TARGARYEN = "targaryen",
    DOTHRAKI = "dothraki",
}
