import { TAction } from "@/messenger/types/action.types";
import { TUser } from "@/messenger/types/auth.types";

enum ActionTypes {
    SET = "hummingbird/auth/user/SET",
}

export const setUser = (user: TUser) => ({
    type: ActionTypes.SET,
    data: user,
});

const userReducer = (state: TUser = null, action: TAction<ActionTypes, TUser>) => {
    switch (action.type) {
        case ActionTypes.SET:
            return action.data;
        default:
            return state;
    }
};

export default userReducer;
