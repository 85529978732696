import * as React from "react";

function SvgComponent(props) {
    return (
        <svg viewBox="0 0 24 24" width={24} height={24} fill="currentColor" {...props}>
            <path d="M19.5 8.99h-15a.5.5 0 00-.5.5v12.5a2 2 0 002 2h12a2 2 0 002-2V9.49a.5.5 0 00-.5-.5zm-9.25 11.5a.75.75 0 01-1.5 0v-8.625a.75.75 0 011.5 0zm5 0a.75.75 0 01-1.5 0v-8.625a.75.75 0 011.5 0zM20.922 4.851A11.8 11.8 0 0016.8 3.78a4.945 4.945 0 00-9.607 0 12.165 12.165 0 00-4.012 1.025A1.943 1.943 0 002 6.476 1 1 0 003 7.49h18a1 1 0 001-.985 1.874 1.874 0 00-1.078-1.654zM11.977 2.01A2.884 2.884 0 0114.6 3.579a44.676 44.676 0 00-5.2 0 2.834 2.834 0 012.577-1.569z" />
        </svg>
    );
}

export default SvgComponent;
