import "./index.scss";

import React from "react";

import { SENDABLE_STANDIN } from "@/messenger/constants/domID";
import { MESSAGE_INPUT_HEIGHT } from "@/messenger/containers/MessageInput";
import { TOPBAR_HEIGHT } from "@/messenger/containers/TopBar";

const baseClassName = "c-sendable-standin";

const SendableStandin = () => {
    const sendableStandinHeight = window.innerHeight - TOPBAR_HEIGHT - MESSAGE_INPUT_HEIGHT;

    return <div 
        style={{height: sendableStandinHeight}}
        className={baseClassName} 
        id={SENDABLE_STANDIN} 
    />;
    
};

export default SendableStandin;