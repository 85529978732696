import cx from "classnames";
import React from "react";

const baseClassName = "c-dropdown-options-container";

export interface OptionsContainerClassNames {
    container?: string;
    menu?: string;
}

interface DropdownOptionsContainerProps {
    classNames?: OptionsContainerClassNames;
    ariaLabel?: string;
    children?: React.ReactElement;
}

function DropdownOptionsContainer(props: DropdownOptionsContainerProps) {
    const { classNames = {}, ariaLabel } = props;
    const { container, menu } = classNames;

    return (
        <div className={cx(`${baseClassName}`, `${container}`)} role="listbox" aria-label={ariaLabel}>
            <div className={cx(`${baseClassName}__menu`, menu)}>{props.children}</div>
        </div>
    );
}

export default DropdownOptionsContainer;
