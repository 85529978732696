import "./index.scss";
import "../../locales/i18n";

import cx from "classnames";
import moment from "moment-timezone";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Exclamation } from "@/components/icons";
import { loadMessageMediaAC } from "@/messenger/actions/message";
import MediaView from "@/messenger/components/MediaView";
import { getBody, getMediaContentType, getMessageDirection, getMessageStatus, isNotDelivered, messageHasMedia } from "@/messenger/models/message";
import {
    TMessage,
} from "@/messenger/types/message";

export enum TMessageDirection {
    OUTGOING = "outgoing",
    INCOMING = "incoming",
}
interface MessageBubbleProps {
    message: TMessage;
    onLoad: () => void;
    onZoom: () => void;
}

const MessageBubble = ({ message, onLoad, onZoom }: MessageBubbleProps) =>  {
        const [direction, setDirection] = useState(TMessageDirection.OUTGOING);
        let { mediaUrl, hasMediaDownloadFailed } = message.computed;

        const { t } = useTranslation();

        const dispatch = useDispatch();
        const hasMedia = messageHasMedia(message);
        const isNotDeliveredMessage = isNotDelivered(message);
        if (isNotDeliveredMessage) {
            mediaUrl = message.local.mediaUrl;
            hasMediaDownloadFailed = false;
        }
        const status = getMessageStatus(message);

        useEffect(() => {
            if (messageHasMedia(message) && !message.computed.mediaUrl) {
                dispatch(loadMessageMediaAC(message));
            }
        }, []);

        useEffect(()=>{
            if (!isNotDeliveredMessage) {
                getMessageDirection(message.twilio).then(d => setDirection(d));
            }
        }, [setDirection, isNotDeliveredMessage]);

        return (
            <div className={`c-message-bubble c-message-bubble--${direction}`}>
                <div
                    className={cx(
                        "c-message-bubble__content",
                        `c-message-bubble__content--${direction}`,
                        { [`c-message-bubble__content--${status}`]: !hasMedia },
                        { "c-message-bubble__content--media": !!hasMedia },
                    )}
                >
                    {getBody(message)}
                    {mediaUrl && (
                        <MediaView
                            url={mediaUrl}
                            onLoad={onLoad}
                            contentType={getMediaContentType(message)}
                            status={status}
                            hasFailed={hasMediaDownloadFailed}
                            onZoom={onZoom}
                        />
                    )}
                </div>
                <div
                    className={cx(
                        "c-message-bubble__status",
                        `c-message-bubble__status--${direction}`,
                        `c-message-bubble__status--${status}`,
                    )}
                >
                    {isNotDeliveredMessage && (
                        <>
                            {t("NOT DELIVERED")}&nbsp; <Exclamation />
                        </>
                    )}
                    {!isNotDeliveredMessage && moment(message.twilio.dateUpdated).format("h:mm A")}
                </div>
            </div>
        );
};

export default MessageBubble;
