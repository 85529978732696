import { WidgetMethods } from "@statflo/widget-sdk/dist/shared";

import { WidgetType } from "@/messenger/widgets/api/events";
import { WidgetState } from "@/messenger/widgets/types";


// supports custom widget behaviours for tele-comm widgets
export enum WidgetNames {
    AddaLead = "addalead",
    LogAnActivity = "laa",
}

interface TGetWidgetReadyEventOptions {
  type: WidgetType
  name: string
}

export const getWidgetReadyEvent = ({ type, name }: TGetWidgetReadyEventOptions) => {
    const events = [];
    if ((type === WidgetType.Action && !name.includes(WidgetNames.AddaLead)) || type === WidgetType.Sendable) {
      events.push({
        method: WidgetMethods.setState,
        name: WidgetMethods.setState,
        payload: {
            property: WidgetState.label,
            value: name,
        },
    });
    }
    
    events.push({
        method: WidgetMethods.setState,
        name: WidgetMethods.setState,
        payload: {
            property: WidgetState.isReady,
            value: true,
        },
    });
    
    return events;
  };

  