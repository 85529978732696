import {Option} from "prelude-ts";

import {AnalyticsConfig, Config} from "./interface";
import config from "./production";

const devConfig: Config = {
  customerId: config.customerId,
  workspaceUrl: config.workspaceUrl,
  workspaceSelectorUrl: config.workspaceSelectorUrl,
  authConfig: config.authConfig,
  analyticsConfig: (): AnalyticsConfig =>({segmentKey: Option.of("YDA8IfUXtHONn81RkPXBKMjuD7x3SDt3")}),
  api: config.api,
}; 

export default devConfig;
