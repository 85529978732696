import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 14 14" width={14} height={14} fill="currentColor" {...props}>
      <path d="M10.3997 7.61717C10.4289 7.60776 10.4544 7.58929 10.4724 7.56443C10.4905 7.53958 10.5001 7.50963 10.5 7.47892V7.36225C10.5 7.14739 10.5594 6.93671 10.6716 6.75344C10.7837 6.57018 10.9443 6.42144 11.1356 6.32365C11.327 6.22585 11.5416 6.18278 11.7558 6.19919C11.97 6.21561 12.1756 6.29086 12.3498 6.41667L12.4443 6.48433C12.4633 6.49781 12.4854 6.50651 12.5085 6.50972C12.5317 6.51293 12.5553 6.51056 12.5773 6.50279C12.5994 6.49502 12.6192 6.48209 12.6352 6.46508C12.6513 6.44807 12.663 6.42747 12.6694 6.405C12.7766 6.0293 12.8317 5.64068 12.8333 5.25C12.8333 2.3555 9.95458 0 6.41667 0C2.87875 0 0 2.3555 0 5.25C0.0109235 5.89496 0.154118 6.53082 0.420718 7.1182C0.687318 7.70558 1.07165 8.23199 1.54992 8.66483L0.600833 11.2752C0.581559 11.3285 0.578181 11.3861 0.591112 11.4412C0.604043 11.4963 0.632724 11.5465 0.673659 11.5855C0.714594 11.6246 0.766015 11.651 0.821652 11.6613C0.877289 11.6717 0.93474 11.6656 0.987 11.6439L4.389 10.227C5.04976 10.4077 5.73165 10.4995 6.41667 10.5C6.70523 10.4984 6.99347 10.4805 7.28 10.4463C7.30532 10.4432 7.32938 10.4336 7.34984 10.4183C7.3703 10.4031 7.38643 10.3828 7.39667 10.3594C7.67616 9.71681 8.0881 9.1404 8.60557 8.66787C9.12303 8.19533 9.73438 7.8373 10.3997 7.61717Z" fill="#757575"/>
      <path d="M11.8364 7.12626C11.7928 7.09505 11.7414 7.07647 11.6879 7.07255C11.6344 7.06864 11.5809 7.07956 11.5332 7.1041C11.4855 7.12863 11.4455 7.16585 11.4176 7.21164C11.3897 7.25744 11.375 7.31005 11.375 7.36368V8.28301C10.4102 8.41513 9.52529 8.89051 8.88247 9.62203C8.23966 10.3536 7.88199 11.2922 7.875 12.266V13.4163C7.875 13.5711 7.93646 13.7194 8.04585 13.8288C8.15525 13.9382 8.30362 13.9997 8.45833 13.9997C8.61304 13.9997 8.76142 13.9382 8.87081 13.8288C8.98021 13.7194 9.04167 13.5711 9.04167 13.4163V12.2678C9.04792 11.6035 9.28365 10.9619 9.70891 10.4516C10.1342 9.94127 10.7228 9.5937 11.375 9.46776V10.2844C11.375 10.3618 11.4057 10.436 11.4604 10.4907C11.5151 10.5454 11.5893 10.5761 11.6667 10.5761C11.7275 10.5762 11.7869 10.5572 11.8364 10.5218L13.8781 9.06351C13.9159 9.03653 13.9467 9.00091 13.968 8.95962C13.9892 8.91832 14.0003 8.87255 14.0003 8.8261C14.0003 8.77965 13.9892 8.73387 13.968 8.69258C13.9467 8.65128 13.9159 8.61566 13.8781 8.58868L11.8364 7.12626Z" fill="#757575"/>
    </svg>
  );
}

export default SvgComponent;
