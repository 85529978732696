import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { normalizeRepresentativeSettings } from "src/utils/normalize";

import { setRequestTrackerAC } from "@/messenger/actions/requestTracker";
import { RequestURIs } from "@/messenger/constants/requests";
import { GraphQLUrl } from "@/messenger/constants/urls";
import { findRepresentativeSettings } from "@/messenger/graphql/queries";
import { createRequestTracker } from "@/messenger/models/requestTracker";
import { Query } from "@/messenger/types/graphql/autogenerated";

const GetRepresentativeSettings = () => {
    const [skipQuery, setSkipQuery] = useState(false);
    const dispatch = useDispatch();

    useQuery(findRepresentativeSettings, {
        skip: skipQuery,
        onCompleted: (data: { findRepresentativeSettings: Query[GraphQLUrl.findRepresentativeSettings]}) => {
            try {
                const representativeSettings: Query[GraphQLUrl.findRepresentativeSettings] = data.findRepresentativeSettings;
                dispatch(normalizeRepresentativeSettings(representativeSettings));
                dispatch(setRequestTrackerAC(createRequestTracker(RequestURIs.GET_REPRESENTATIVESETTINGS, {
                    complete: true,
                    loading: false,
                    sent: true,
                })));

                setSkipQuery(true);
            } catch (e) {
                console.error(e);
            }
        },
    });

    useEffect(() => {
        dispatch(setRequestTrackerAC(createRequestTracker(RequestURIs.GET_REPRESENTATIVESETTINGS, {
            complete: false,
            loading: true,
            sent: true,
        })));
    }, []);

    return null;
};

export default GetRepresentativeSettings;