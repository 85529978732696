import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { entitiesSelector } from "@/messenger/selectors";
import { TCustomer } from "@/messenger/types/entities/conversation";
import { NormalizedEntities, TStore  } from "@/messenger/types/store.types";

export const customerAdapter = createEntityAdapter<TCustomer>({
    selectId: (customer) => customer.customerId,
});

export const initialState = customerAdapter.getInitialState({});

const customer = createSlice({
    name: "customers",
    initialState,
    reducers: {
        setCustomer(state, action: PayloadAction<NormalizedEntities>) {
            customerAdapter.upsertMany(state, action.payload.customers);
        },
    },
});

export const customerSelector = customerAdapter.getSelectors((state: TStore) => entitiesSelector(state).customers);

export const {
    setCustomer,
} = customer.actions;

export default customer.reducer;