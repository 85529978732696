export enum WidgetViewSize {
    Large = "Large",
    Medium = "Medium",
    Small = "Small",
}

export interface StandardWidgetViewState {
    size: WidgetViewSize
}

export interface ActionWidgetViewState {
    isOpen: boolean
}

export enum TimelineWidgetViewState {

}

export interface SendableWidgetViewState {
    isShowing: boolean
}
