import "./index.scss";
import "../../../locales/i18n";

import cx from "classnames";
import IframeResizer from "iframe-resizer-react";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button from "@/components/components/Button/Button";
import { ButtonColor, ButtonSize } from "@/components/components/Button/button.enum";
import icons from "@/components/icons";
import { onWidgetError, WidgetErrorComponent } from "@/messenger/containers/WidgetContainer";
import { deviceTypeSelector } from "@/messenger/ducks/selectors/general";
import { TActionWidgetState } from "@/messenger/types/store.types";
import { detectMobile } from "@/messenger/utils/deviceType";
import { widgetContainerClient } from "@/messenger/widgets";
import { WidgetState } from "@/messenger/widgets/types";



interface TWidgetProps {
    widgetState: TActionWidgetState
}

const baseClassName = "c-widget";

export const getWidgetContainerId = (name: string) => `${name.replace(/ /g, "_")}--container`;

const ActionWidget = ({ widgetState }: TWidgetProps) => {
    const { id, url, isOpen, header } = widgetState;
    const { t } = useTranslation();

    const deviceType = useSelector(deviceTypeSelector);

    const isMobile = detectMobile(deviceType);


    const height = "calc(100vh - 25px)";
    const hardWidth = isMobile ? "100%" : "380px";

    const actionWidgetStyles: React.CSSProperties = isOpen ? {
        position: "fixed",
        marginTop: 0,
        top: 25,
        height,
        width: hardWidth,
        marginRight: 0,
    } : {};

    const hideWidget = !isOpen ? { height: 0, overflow: "hidden" } : {};

    const classNames = cx(
        `${baseClassName}`, `${baseClassName}__action`,
    );
    const widgetContainerClassName = cx(
        `${baseClassName}__widget-container`,
    );
    const headerClassName = cx(
        `${baseClassName}__header`, `${baseClassName}__action-header`,
    );
    const headerTextClassName = cx(
        `${baseClassName}__header-text`, `${baseClassName}__action-header-text`,
    );

    const handleWidgetClose = () => {
        widgetContainerClient.setState(id, WidgetState.isOpen, false);
    };

    const shortenText = (text: string) => {
        if (!text) {
            return "";
        }
        return text.length <= 40 ? text : `${text.substring(0, 37)}...`;
    };

    const shortenedHeader = shortenText(header);

    return <div style={hideWidget}>
        <div key={id} className={classNames} style={actionWidgetStyles}>
            <header className={headerClassName}>
                <span className={headerTextClassName}>
                    {shortenedHeader}
                </span>
                <Button
                    id={"CLOSE_ACTION_WIDGET_BUTTON"}
                    className={`${baseClassName}__action-header__close-button`}
                    color={ButtonColor.SECONDARY}
                    icon={icons.CLOSE}
                    size={ButtonSize.REGULAR}
                    onClick={handleWidgetClose}
                    ariaLabel={t("Close Action widget")}
                />
            </header>
            <div className={widgetContainerClassName} id={getWidgetContainerId(id)}>
                <ErrorBoundary FallbackComponent={WidgetErrorComponent} onError={onWidgetError}>
                    <IframeResizer
                        className={`${baseClassName}__iframe`}
                        seamless
                        src={url}
                        id={id}
                        title={id}
                        name={id}
                    />
                </ErrorBoundary>
            </div>
        </div>
    </div>;
};

export default ActionWidget;