import "./index.scss";
import "../../locales/i18n";

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button from "@/components/components/Button";
import { LostConnection } from "@/components/icons";
import { TStore } from "@/messenger/types/store.types";

const LostInternet = () => {
    const isOnline = useSelector((state: TStore) => state.general.isOnline);
    const { t } = useTranslation();

    const onRefresh = () => {
        window.location.reload();
    };

    return (
        !isOnline && (
            <div className="c-lost-internet__wrapper">
                <div className="c-lost-internet">
                    <div className="c-lost-internet__icon">
                        <LostConnection />
                    </div>
                    <div className="c-lost-internet__header">{t("Oops! We lost you")}</div>
                    <div className="c-lost-internet__text">
                        {t("Try checking your internet connection")}
                    </div>
                    <div className="c-lost-internet__button-wrapper">
                        <Button id={"LOST_INTERNET_REFRESH_BUTTON"} className="c-lost-internet__button" onClick={onRefresh} ariaLabel={t("Lost Internet Refresh Button")}>
                            {t("Refresh")}
                        </Button>
                    </div>
                </div>
            </div>
        )
    );
};

export default LostInternet;
