import { TTopic } from "@/messenger/types/entities/topic";
import { GraphQLBase } from "@/messenger/types/graphql";
import { ChannelTypeEnum, HydrationEnum, TextkitEnvironmentEnum } from "@/messenger/types/graphql/autogenerated";
import { WidgetScope, WidgetType } from "@/messenger/widgets/api/events";


export enum ConversationCategory {
    OPEN = "OPEN",
    TO_ANSWER = "TO_ANSWER",
    TO_BE_CONTACTED = "TO_BE_CONTACTED",
    CLOSED = "CLOSED",
    OPT_OUT = "OPT_OUT",
    // FOLLOW_UP = "FOLLOW_UP",
    // ACTION_REQUIRED = "ACTION_REQUIRED",
}

export interface TAddress {
    streetNumber?: string;
    streetName?: string;
    city?: string;
    postalCode?: string;
    province?: string;
    country?: string;
}
export interface TContact extends GraphQLBase {
    id: string;
    firstName: string;
    lastName: string;
    businessName?: string;
    address?: TAddress;
    externalId: string;
    locale: string;
    phoneNumber: TPhoneNumber;
    capabilities: ChannelTypeEnum[];

    external: {
        externalId: string,
        metadata: null
    }
}

export interface TPhoneNumber {
    number: string;
    extension?: string;
}

export interface TKeyValue {
    key: string;
    value: string;
}

export interface TConversation extends GraphQLBase {
    id: string;
    category: ConversationCategory;
    contact: TContact;
    topic?: TTopic
}

export interface TWidget extends GraphQLBase {
    name: string;
    url: string;
    scope: WidgetScope
    type: WidgetType
    domains: string[]
    path: string
    isEnabled: boolean
}

export interface TWidgetTab extends GraphQLBase {
    hasTab: boolean;
    selectedTab?: WidgetType
}

export interface TConversationNormalized extends GraphQLBase {
    id: string;
    category: ConversationCategory;
    nextCategories: ConversationCategory[];
    contact: string;
    capabilities: ChannelTypeEnum[];
    topic: string;
    topicIds: string[];
    optOutChannels: ChannelTypeEnum[];
    participationMonths: string[];
    isInbound: boolean;
    representativePhone: string;
    hydration: HydrationEnum;
}

export interface TCustomer extends GraphQLBase {
    customerId: string;
    config: {
        coolOffPeriod: {
            unit: ECustomerConfigCooloffUnitType
            duration: number
        }
        webapp: {
            chatUrl: string
            welcomeUrl: string
            accountsUrl: string
            reportsUrl: string
            supportJsFunction: string
            logoutUrl: string
        }
        ringCentralEnabled: boolean,
        caslLinkEnabled: boolean,
        textkitEnvironment: TextkitEnvironmentEnum,
    }
}

export enum ECustomerConfigCooloffUnitType {
    MINUTES = "MINUTES",
    HOURS = "HOURS",
    DAYS = "DAYS",
}

export enum MessageInputTabType {
    STANDARD = "STANDARD",
    CHAT_STARTER = "CHAT_STARTER",
}