export const RIGHT_SIDEBAR_STANDIN = "RIGHT_SIDEBAR_STANDIN";
export const SENDABLE_STANDIN = "SENDABLE_STANDIN";
export const MODULE_STANDIN = "MODULE_STANDIN";

export const ACCOUNT_DETAILS_CONTENT = "ACCOUNT_DETAILS_CONTENT";

export const ACTIVE_CHAT_CARD = "ACTIVE_CHAT_CARD";

export const SELECTED_PLACEHOLDER = "SELECTED_PLACEHOLDER";

export const NAV_CHAT = "TEXTKIT__NAV_CHAT";
export const NAV_WELCOME = "TEXTKIT__NAV_WELCOME";
export const NAV_ACCOUNTS = "TEXTKIT__NAV_ACCOUNTS";
export const NAV_REPORTS = "TEXTKIT__NAV_REPORTS";
export const NAV_SUPPORT = "TEXTKIT__NAV_SUPPORT";
export const NAV_LOG_OUT = "TEXTKIT__NAV_LOG_OUT";
export const NAV_MODULE = "TEXTKIT__NAV_MODULE";