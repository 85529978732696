import React from "react";

interface SVGComponent {
    fill: string
}

function Caret(props: SVGComponent) {
    const { fill } = props;

    return (
        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.170436 1.53604L3.58894 5.10135C3.81544 5.33624 4.18456 5.33624 4.41106 5.10135L7.82956 1.53604C8.19448 1.15434 7.93443 0.5 7.4185 0.5H0.581496C0.0655741 0.504194 -0.194484 1.15434 0.170436 1.53604Z"
                fill={fill}
            />
        </svg>
    );
}

export default Caret;