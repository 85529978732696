/* eslint-disable */
import React, {
    useContext,
    useEffect,
    useState,
} from 'react';

import { Option } from 'prelude-ts';
import { AuthConfig } from "@/messenger/config/interface";
import {
    useHistory
} from "react-router-dom";
import { useSelector } from 'react-redux';

import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import config from "@/messenger/config";
import { setFilterIsSmsCapableAC, setPrevFilterIsSmsCapableAC, setPrevSelectedCampaignNameAC, setSelectedCampaignNameAC, setSelectedMonthAC, setPrevSelectedMonthAC } from '@/messenger/ducks/general/conversationRefinementsReducer.duck';
import { LOGOUT, LOGOUT_REDIRECT_PARAM } from '@/messenger/constants/routing';

import { routerSelector } from '@/messenger/selectors';

interface Auth0Props {
    children: React.ReactElement;
    customer: string;
    onRedirectCallback: (_) => void;
    initOptions: Promise<Option<AuthConfig>>;
}

export interface Auth0User {
    email: string;
    email_verified: boolean;
    name: string;
    nickname: string;
    picture: string;
    sub: string;
    customer: string | undefined;
    updated_at: string;
}

const DEFAULT_REDIRECT_CALLBACK = (_) => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext(null);
export const useAuth0 = () => useContext(Auth0Context);
export const useUpdateMetadata = (
    apiUrl: string,
    accessToken: string,
    onMetadataChange: (m: any) => void,
    user: any,
    userMetadata: any,
) => (updatedMetadata: any) => {
    const newMetadata = {
        ...userMetadata,
        ...updatedMetadata,
    };
    const body = {
        user_metadata: newMetadata,
    };

    fetch(`${apiUrl}users/${user.sub}`, {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'content-type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then(() => onMetadataChange(newMetadata));
};

export const Auth0Provider = (
    { children,
        customer,
        onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
        initOptions, }: Auth0Props,
) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
    const [user, setUser] = useState<Auth0User | undefined>();
    const [auth0Client, setAuth0] = useState<Auth0Client>(null);
    const [token, setToken] = useState<string>(null);
    const [loading, setLoading] = useState(true);
    const [popupOpen, setPopupOpen] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const history = useHistory();

    const router = useSelector(routerSelector)

    useEffect(() => {
        const initAuth0 = async () =>
            (await initOptions)
                .ifNone(() => window.location.replace(config.workspaceSelectorUrl().toString()))
                .ifSome(async auth0Config => {
                    const auth0FromHook = await createAuth0Client(auth0Config);
                    setAuth0(auth0FromHook);
                    
                    if (window.location.search.includes('error=')) {
                        // Display error page or error message
                        console.log('Access Denied!');
                        // TODO should handle unauthorized error
                    } else if (
                        window.location.search.includes('code=') &&
                        window.location.search.includes('state=')
                    ) {
                        const { appState } = await auth0FromHook.handleRedirectCallback();
                        if (appState) {
                            onRedirectCallback(appState);
                        } else if (window.location.hash) {
                            history.push(window.location.hash.split("#")[1]);
                        }
                    }

                    const isAuthenticated = await auth0FromHook.isAuthenticated();

                    setIsAuthenticated(isAuthenticated);

                    if (isAuthenticated) {
                        const user: Auth0User | undefined = await auth0FromHook.getUser();
                        setUser(user);

                        const newToken = await auth0FromHook.getTokenSilently()

                        setToken(newToken)
                    }

                    setLoading(false);
                });
        initAuth0();
    }, []);

    useEffect(() => {
        if (isReady) {
            const isLogoutURL = router.location.pathname.startsWith(`/${LOGOUT}`)

            if (isLogoutURL) {
                const logoutQuery = new URLSearchParams(router.location.search)
                const logoutRedirectUrl = logoutQuery.get(LOGOUT_REDIRECT_PARAM)

                if (isAuthenticated) {
                    if (logoutRedirectUrl) {
                        auth0Client.logout({ returnTo: logoutRedirectUrl, federated: true });
                    } else {
                        auth0Client.logout({ federated: true });
                    }
                } else {
                    window.location.href = logoutRedirectUrl
                }
            }
        }
    },[router.location.pathname, isReady])

    useEffect(() => {
        if (!isAuthenticated && !loading) {
            const isLogoutURL = router.location.pathname.startsWith(`/${LOGOUT}`)
            if (isLogoutURL) {
                setIsReady(true)
            } else {
                auth0Client.loginWithRedirect({fragment: window.location.pathname});
            }
        } else if (isAuthenticated && !loading && user !== undefined) {
            setIsReady(true);
        }
    }, [isAuthenticated, loading, auth0Client, user]);

    const loginWithPopup = async (params = {}) => {
        setPopupOpen(true);
        try {
            await auth0Client.loginWithPopup(params);
        } catch (error) {
            console.error(error);
        } finally {
            setPopupOpen(false);
        }
        const user = await auth0Client.getUser();
        setUser(user);
        setIsAuthenticated(true);
    };

    const handleRedirectCallback = async () => {
        setLoading(true);
        await auth0Client.handleRedirectCallback();
        const user = await auth0Client.getUser();
        setLoading(false);
        setIsAuthenticated(true);
        setUser(user);
    };

    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                loading,
                popupOpen,
                loginWithPopup,
                handleRedirectCallback,
                customer,
                token,
                isReady,
                getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
                loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
                getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
                getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
                logout: (...p) => {

                    // clears cached filters
                    setSelectedCampaignNameAC(null);
                    setPrevSelectedCampaignNameAC(null);
                    setSelectedMonthAC(null);
                    setPrevSelectedMonthAC(null);
                    setPrevFilterIsSmsCapableAC(false);
                    setFilterIsSmsCapableAC(false);
                    auth0Client.logout(...p)

                },
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};
