import "./index.scss";
import "../../locales/i18n";

import React from "react";
import { useTranslation } from "react-i18next";

import Switch from "@/components/components/Switch";
import { SwitchValue } from "@/components/components/Switch/switch.type";
import { Close  } from "@/components/icons";

interface TAvailabilityToggleProps {
    onToggle: (value: SwitchValue) => void,
    isOn: boolean,
    onClose: () => void,
}

export const toggleTestId = "availability-toggle";
export const closeButtonTestId = "availability-close-button";

const AvailabilityToggle = ({ onToggle, isOn, onClose }: TAvailabilityToggleProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="c-availability-toggle">
            <div className="c-availability-toggle__header">
                <div className="e-heading e-heading--h1 c-availability-toggle__header-text">
                    {t("Inbound Queue")}
                </div>
                <button id={"AVAILABILITIES_BUTTON"} data-testid={closeButtonTestId} aria-label="Close" className="c-availability-toggle__header-close" onClick={onClose}>
                    <Close width={16} height={16} />
                </button>
            </div>
            <div className="c-availability-toggle__switch">
                <span>{t("Available")}</span>
                <Switch 
                    id={"AVAILABILITY_SWITCH"}
                    testId={toggleTestId}
                    onChange={onToggle} 
                    value={{on: isOn}}
                />
            </div>
        </div>
    );
};

export default AvailabilityToggle;
