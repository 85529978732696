import { createEntityAdapter, createSlice,PayloadAction  } from "@reduxjs/toolkit";

import {  TActionWidgetState, TModuleWidgetState, TSendableWidgetState,TStandardWidgetState, TStore,TWidgetState  } from "@/messenger/types/store.types";
import { WidgetType } from "@/messenger/widgets/api/events";

import { getUserInterfaceSelector } from "../selectors/userInterface";

export const widgetsStateAdapter = createEntityAdapter<TWidgetState>();

export const initialState = widgetsStateAdapter.getInitialState({});

export const DEFAULT_WIDGET_STATE: Partial<TWidgetState> = {
    id: "",
    url: "",
    isReady: false,
    type: WidgetType.Standard,
    widgetDomain: "*",
    scope: null,
    isEnabled: false,
};


const widgetsState = createSlice({
    name: "widgetsState",
    initialState,
    reducers: {
        upsertOneWidgetsState(state, action: PayloadAction<Partial<TWidgetState>>) {
            widgetsStateAdapter.upsertOne(state as any, action.payload as any);
        },
    },
});


export const widgetsStateSelectors = widgetsStateAdapter.getSelectors((state: TStore) => getUserInterfaceSelector(state).widgetsState);

export const widgetStateSelectorsCustom = {
    selectAllByTypeAction: (state: TStore) => widgetsStateSelectors.selectAll(state).filter(widget => widget.type === WidgetType.Action) as TActionWidgetState[],
    selectAllByTypeStandard: (state: TStore) => widgetsStateSelectors.selectAll(state).filter(widget => widget.type === WidgetType.Standard) as TStandardWidgetState[],
    selectAllByTypeTimeline: (state: TStore) => widgetsStateSelectors.selectAll(state).filter(widget => widget.type === WidgetType.Timeline) as TWidgetState[],
    selectAllByTypeSendable: (state: TStore) => widgetsStateSelectors.selectAll(state).filter(widget => widget.type === WidgetType.Sendable) as TSendableWidgetState[],
    selectAllByTypeModule: (state: TStore) => widgetsStateSelectors.selectAll(state).filter(widget => widget.type === WidgetType.Module) as TModuleWidgetState[],
};

export const {
    upsertOneWidgetsState,
} = widgetsState.actions;



export default widgetsState.reducer;